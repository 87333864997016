//react imports
import React, { useEffect } from "react";

//third party imports
import OTPInput from "react-otp-input";
import { InputError } from "../../app/constants/InputError";

const OtpBox = ({
  otp = null,
  setOtp,
  width = "44px",
  height = "44px",
  borderRadius = "8px",
  backgroundColor = "#ffffff",
  fontSize = "13px",
  gap = "10px",
  actionText = "",
  actionFontSize = "13px",
  handleActionClick,
  disabled = false,
  errorMsg = "",
  timerValue,
  otpexpired,
}) => {
  const containerStyle = {
    display: "flex",
    gap: gap,
    alignItems: "center",
    justifyContent: "center",
  };

  const inputStyles = {
    width: width,
    height: height,
    borderRadius: borderRadius,
    backgroundColor: backgroundColor,
    fontFamily: "inherit",
    fontSize: fontSize,
    fontWeight: "400",
    textAlign: "center",
    border: errorMsg ? "1px solid #e01e5a" : "1px solid #046cb8",
  };

  const focusStyle = {
    outline: "none",
    border: "1px solid #046cb8",
    border: errorMsg ? "1px solid #e01e5a" : "1px solid #046cb8",
  };

  const converTimeToString = () => {
    return (
      (~~(timerValue / 60) >= 10
        ? ~~(timerValue / 60)
        : "0" + ~~(timerValue / 60)) +
      ":" +
      (timerValue % 60 >= 10 ? timerValue % 60 : "0" + (timerValue % 60))
    );
  };

  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    document.querySelector('input[type="text"]').focus();
  }, [errorMsg]);

  return (
    <div>
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        containerStyle={containerStyle}
        inputStyle={inputStyles}
        focusStyle={focusStyle}
        renderInput={(props) => (
          <input
            type="text"
            onKeyPress={handleKeyPress}
            {...props}
            disabled={disabled}
          />
        )}
      />
      {errorMsg && <p className="s-ipotp__error">{errorMsg}</p>}
      {actionText && (
        <div className="o-login__otp-container" style={{justifyContent: "flex-end", gap: "2px", marginTop: "12px", marginRight: "46px"}}>
          <div className="o-login__otp-container-closer" style={{minWidth: "135px"}}>
            {
              <p
                className={`o-login__countdown--small ${
                  otpexpired && "o-login__countdown--red"
                }`}
              >
                {otpexpired
                  ? InputError?.OtpTimerExpire?.message
                  : "Remaining time:"}
              </p>
            }
            {!otpexpired && (
              <p className={`o-login__countdown `}>
                {converTimeToString(timerValue)}
              </p>
            )}
          </div>
          <p
            className="s-ipotp__resend"
            style={{ fontSize: actionFontSize }}
            onClick={handleActionClick}
          >
            {actionText}
          </p>
        </div>
      )}
    </div>
  );
};

export default OtpBox;
