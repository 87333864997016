import React, { useEffect, useState } from "react";

const OptionDots = ({ color = "#888888", hoverColor = "#046cb8" }) => {
  const [internalColor, setInternalColor] = useState(color);
  useEffect(() => {
    setInternalColor(color);
  }, [color]);
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => {
        setInternalColor(hoverColor);
      }}
      onMouseLeave={() => {
        setInternalColor(color);
      }}
    >
      <path
        d="M10 5.00586V4.99986"
        stroke={internalColor}
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 9.99219V9.98619"
        stroke={internalColor}
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15V14.994"
        stroke={internalColor}
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OptionDots;
