// react imports
import React from "react";

// third party imports

// in-app imports

const Tag = ({ text = "Standard", type = "standard", extraClass = "" }) => {
  return <div className={`s-tag s-tag--${type} ${extraClass}`}>{text}</div>;
};

export default Tag;
