import axios from "axios";

//TODO: CHANGE URL FOR PROD
const api = axios.create({
  baseURL: "https://api.bluno.in",
});

// Request interceptor for adding the bearer token
api.interceptors.request.use(
  (config) => {
    let access_token = localStorage.getItem("access"); // Assuming you store the token in localStorage
    if (!access_token) access_token = sessionStorage.getItem("access");

    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
