import React, { useEffect, useRef, useState } from "react";
import TextInput from "./TextInput";
import Dropdown from "./Dropdown";
import BlunoCalender from "./BlunoCalender";
import Button from "./Button";
import BlunoTooltip from "./BlunoTooltip";
import { useNavigate } from "react-router-dom";

const BlunoFilter = (props) => {
  const {
    data,
    filterObj = {},
    handleInputChange,
    handleDateRangeChange,
    onClearFilter,
    filterCategory = "",
    actionButton,
    selectedText,
    selectedSubText,
    showCompactFilters,
    setShowCompactFilters,
  } = props;

  const navigate = useNavigate();

  // local states
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const [showScrollArrows, setShowScrollArrows] = useState(false);
  const scrollRef = useRef(null);
  const filterRefs = useRef([]);

  // other variables
  const activeRightArrow =
    "https://static.bluno.in/miscl/scroll-right-active.png";
  const inactiveRightArrow =
    "https://static.bluno.in/miscl/scroll-right-inactive.png";
  const activeLeftArrow =
    "https://static.bluno.in/miscl/scroll-left-active.png";
  const inactiveLeftArrow =
    "https://static.bluno.in/miscl/scroll-left-inactive.png";
  const clearFilter = "https://static.bluno.in/miscl/clear-filter.png";
  const clearFilterHover =
    "https://static.bluno.in/miscl/clear-filter-hover.png";

  const scrollToView = (index) => {
    const filterElement = filterRefs.current[index];
    const { left, right } = filterElement.getBoundingClientRect();
    const { left: parentLeft, right: parentRight } =
      scrollRef.current.getBoundingClientRect();

    if (left < parentLeft) {
      filterElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    } else if (right > parentRight) {
      filterElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "end",
      });
    }
  };

  const renderFilterComponent = (item, index) => {
    const filterComponent = () => {
      switch (item?.slug) {
        case "remitter_name":
        case "reference_no":
        case "irm_ref_no":
        case "prepared_by":
        case "ticket_number":
        case "ebrc_no":
        case "invoice_no":
        case "sb_no":
        case "buyer_name":
          return (
            <TextInput
              placeholder={item?.placeholder}
              type={item?.sub_type}
              value={filterObj?.[item?.slug]}
              height="30px"
              minWidth="150px"
              width="150px"
              icon={item?.icon}
              isHighlighted={!!filterObj?.[item?.slug]}
              showMuiInput="true"
              onChange={(e) => handleInputChange(e.target.value, item?.slug)}
            />
          );
        case "duration":
          return (
            <BlunoCalender
              placeholder={item?.placeholder}
              standardDurations={item?.standard_durations}
              // defaultSelection={item?.default}
              isHighlighted={!!filterObj?.["start_date"]}
              isDateCleared={
                !filterObj?.["start_date"] || !filterObj?.["end_date"]
              }
              icon={item?.icon}
              height="30px"
              minWidth="200px"
              onDateRangeChange={handleDateRangeChange}
              fancyPlaceholder={true}
            />
          );
        case "filter_category":
          return (
            <Dropdown
              options={item?.options}
              defaultValue={filterCategory || item?.default?.value}
              height="30px"
              minWidth="120px"
              width="120px"
              fontSize="12px"
              isHighlighted={filterCategory ? true : false}
              onSelectChange={(e) => handleInputChange(e.value, item?.slug)}
              placeholder={item?.placeholder}
              placeholderFontSize="13px"
              placeholderFontWeight="400"
              fancyPlaceholder={true}
              topSpacing="-9px"
              iconWidth="10px"
            />
          );
        case "currency":
        case "ticket_status":
          return (
            <Dropdown
              options={item?.options}
              defaultValue={filterObj?.[item?.slug] || item?.default?.value}
              height="30px"
              minWidth="100px"
              width="100px"
              fontSize="12px"
              isHighlighted={
                !!filterObj?.[item?.slug] && filterObj?.[item?.slug] !== "ALL"
              }
              isDisabled={item?.disabled}
              label={item?.label}
              onSelectChange={(e) => handleInputChange(e.value, item?.slug)}
              placeholder={item?.placeholder}
              placeholderFontSize="13px"
              placeholderFontWeight="400"
              fancyPlaceholder={true}
              topSpacing="-9px"
              iconWidth="10px"
            />
          );
        case "bank":
          return (
            <Dropdown
              options={item?.options}
              defaultValue={filterObj?.[item?.slug] || item?.default?.value}
              height="30px"
              minWidth="100px"
              width="100px"
              menuWidth={
                item?.options?.some((item) => {
                  return item?.label?.length > 11;
                })
                  ? "max-content"
                  : undefined
              }
              fontSize="12px"
              isHighlighted={
                !!filterObj?.[item?.slug] && filterObj?.[item?.slug] !== "ALL"
              }
              isDisabled={item?.disabled}
              label={item?.label}
              onSelectChange={(e) => handleInputChange(e.value, item?.slug)}
              placeholder={item?.placeholder}
              placeholderFontSize="13px"
              placeholderFontWeight="400"
              fancyPlaceholder={true}
              topSpacing="-9px"
              iconWidth="10px"
            />
          );
        case "invoice_type":
          return (
            <Dropdown
              options={item?.options}
              defaultValue={filterObj?.[item?.slug] || item?.default?.value}
              height="30px"
              minWidth="120px"
              width="120px"
              menuWidth={
                item?.options?.some((item) => {
                  return item?.label?.length > 11;
                })
                  ? "max-content"
                  : undefined
              }
              fontSize="12px"
              isHighlighted={
                !!filterObj?.[item?.slug] && filterObj?.[item?.slug] !== "ALL"
              }
              isDisabled={item?.disabled}
              label={item?.label}
              onSelectChange={(e) => handleInputChange(e.value, item?.slug)}
              placeholder={item?.placeholder}
              placeholderFontSize="13px"
              placeholderFontWeight="400"
              fancyPlaceholder={true}
              topSpacing="-9px"
              iconWidth="10px"
            />
          );
        default:
          return null;
      }
    };

    return !item?.in_compact || showCompactFilters ? (
      <div
        className="s-filter__component"
        ref={(el) => (filterRefs.current[index] = el)}
        onClick={() => scrollToView(index)}
      >
        {filterComponent()}
      </div>
    ) : (
      <></>
    );
  };

  const updateScrollState = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(Math.round(scrollLeft) + clientWidth < scrollWidth);
      setShowScrollArrows(clientWidth !== scrollWidth);
    }
  };

  const onLeftScroll = () => {
    scrollRef.current.scrollLeft -= 150;
    updateScrollState();
  };

  const onRightScroll = () => {
    scrollRef.current.scrollLeft += 150;
    updateScrollState();
  };

  useEffect(() => {
    updateScrollState();
    // const handleResize = () => updateScrollState();
    window.addEventListener("resize", updateScrollState);
    return () => window.removeEventListener("resize", updateScrollState);
  }, []);

  return (
    <div className="s-filter">
      {/* {showScrollArrows && (
        <div>
          <img
            src={canScrollLeft ? activeLeftArrow : inactiveLeftArrow}
            alt=""
            onClick={onLeftScroll}
            className="s-filter__scroll-icon"
          />
        </div>
      )} */}
      <div className="s-filter__first">
        {(selectedText || selectedText === 0) &&
          (JSON.stringify(filterObj) !== "{}" || filterCategory !== "") && (
            <>
              <div className="s-filter__selected">
                <p className="s-filter__selected-text">
                  {selectedText}
                  <span>{selectedSubText}</span>
                </p>
              </div>
              <div className="s-filter__partition"></div>
            </>
          )}
        <div
          onClick={onClearFilter}
          className={`s-filter__clear ${
            JSON.stringify(filterObj) !== "{}" || filterCategory !== ""
              ? ""
              : "s-filter__clear--disabled"
          }`}
        >
          {JSON.stringify(filterObj) === "{}" && filterCategory === "" ? (
            <>
              <img src={clearFilter} alt="" className="s-filter__clear-icon" />
            </>
          ) : (
            <div className="s-filter__clear-container">
              <img
                src={clearFilter}
                alt=""
                className="s-filter__clear-icon s-filter__clear-icon--blue"
              />
              <p className="s-filter__clear-text">Clear filter</p>
            </div>
          )}
        </div>
      </div>
      <div
        className="s-filter__filters"
        ref={scrollRef}
        onScroll={updateScrollState}
      >
        {data?.map((item, index) => {
          if (index === data?.length - 1 && item?.type === "button") {
            return (
              <Button
                buttonType={item?.sub_type}
                buttonLabel={showCompactFilters ? item?.alt_text : item?.text}
                extraClass={
                  showCompactFilters ? "s-filter__more-button--active" : ""
                }
                icon={showCompactFilters ? item?.alt_icon : item?.icon}
                iconAlignment={item?.icon_alignment}
                onClick={(e) => {
                  setShowCompactFilters((prev) => !prev);
                }}
                buttonSize={item?.size}
              />
            );
          }
          return renderFilterComponent(item, index);
        })}
      </div>
      {/* {showScrollArrows && (
        <div>
          <img
            src={canScrollRight ? activeRightArrow : inactiveRightArrow}
            alt=""
            onClick={onRightScroll}
            className="s-filter__scroll-icon"
          />
        </div>
      )} */}
      {actionButton && (
        <div className="s-filter__action">
          {actionButton?.map(
            (action) =>
              action?.type === "button" && (
                <Button
                  key={action?.slug}
                  buttonLabel={action?.text}
                  buttonType={action?.sub_type}
                  icon={action?.icon}
                  disabled={action?.disabled}
                  iconAlignment={action?.icon_alignment}
                  tooltipText={action?.tooltip_text}
                  extraClass="s-filter__action-btn"
                  onClick={() => {
                    action?.slug === "generate_bulk_ebrc_info" &&
                      navigate("/sb/bulk-ebrc");
                  }}
                />
              )
          )}
        </div>
      )}
    </div>
  );
};

export default BlunoFilter;
