import React from "react";

const RadioGroup = (props) => {
  const { item, value = "", onChange, disabled, extraClass="" } = props;
  const { title = "", options = [], name = "" } = item;

  return (
    <>
      <div className={`df-group ${extraClass}`}>
        {title && <p>{title}</p>}
        <div className="df-group__radio">
          {options?.map((option, idx) => {
            return (
              <div>
                <input
                  type="radio"
                  name={name}
                  id={`${item?.element_id}_${idx}`}
                  value={option?.value}
                  onChange={onChange}
                  checked={option?.value === value}
                  disabled={disabled}
                />
                <label
                  className="df-group__label"
                  htmlFor={`${item?.element_id}_${idx}`}
                >
                  {option?.label}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RadioGroup;
