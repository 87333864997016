import React from "react";
import { getKeyValueFormat } from "../utils";
import Docinfo from "./Docinfo";
import Button from "./Button";

const BankReview = ({ data }) => {
  return (
    <>
      <div className="bank-review">
        <div className="bank-review__content">
          <div className="bank-review__container">
            <div className="bank-review__heading">
              <div className="recon-summary__invoice-header">
                <div className="recon-summary__invoice-header-left">
                  <h2 className="recon-summary__section-title">
                    {data?.title}
                  </h2>
                </div>
                <div className="recon-summary__invoice-header-right">
                  {getKeyValueFormat(
                    data?.bank_info?.key,
                    data?.bank_info?.value,
                    true,
                    false,
                    148
                  )}
                </div>
              </div>
              <div className="bank-review__message">{data?.message}</div>
            </div>
            <div className="bank-review__body">
              <div className="bank-review__docinfo">
                {data?.sub_sections?.map((item, index) => {
                  return (
                    <div className="bank-review__card">
                      <Docinfo
                        data={item}
                        isPartition={index !== 0 ? true : false}
                        // uploadSingleFile={uploadSingleFile}
                        // docId={docId}
                        // onDocAction={onDocAction}
                        // section={data?.slug}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="bank-review__support">Help Section</div>
            </div>
          </div>
          {!data?.help_data && (
            <div className="bank-review__help">
              <div>
                <h2 className="bank-review__help-title">
                  {data?.help_data?.title}
                </h2>
              </div>
              <div className="bank-review__help-body">
                <div className="bank-review__help-header">
                  <img
                    src={data?.help_data?.rbi_section?.heading?.icon}
                    alt=""
                  />
                  <h2 className="bank-review__help-header-text">
                    {data?.help_data?.rbi_section?.heading?.text}
                  </h2>
                </div>
                <div className="bank-review__help-content">
                  {data?.help_data?.rbi_section?.body?.map((item, index) => {
                    return (
                      <p className="bank-review__help-item">
                        <span>{index + 1}. </span> {item?.text}
                      </p>
                    );
                  })}
                </div>
              </div>
              <div>
                <p className="bank-review__help-extra-info">
                  {data?.help_data?.extra_info?.text}
                </p>
              </div>
            </div>
          )}
        </div>
        {data?.buttons && (
          <div className="bank-review__submit">
            {data?.buttons?.map((action) => {
              return (
                action?.type === "button" && (
                  <Button
                    buttonLabel={action?.text}
                    buttonType={action?.sub_type}
                    icon={action.icon}
                    disabled={action?.disabled}
                    iconAlignment={action?.icon_alignment}
                    // onClick={() => onActionClick(data?.slug, action)}
                  />
                )
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default BankReview;
