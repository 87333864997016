// react imports
import { createSlice } from "@reduxjs/toolkit";

// in-app imports

export const decisionTaskSlice = createSlice({
  name: "decision_task",
  initialState: {
    currentStage: {
      applicationId: "",
      screen: "",
      currentTask: "",
      previousTask: "",
      nextTask: "",
      currentSubTask: "",
    },
  },
  reducers: {
    setCurrentStage: (state, action) => {
      state.currentStage = action?.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentStage } = decisionTaskSlice.actions;

export default decisionTaskSlice.reducer;
