import React from "react";
import usePagination from "@mui/material/usePagination";
import { TablePagination } from "@mui/material";

const BlunoPagination = (props) => {
  const {
    pageNo = 1,
    setPageNo,
    totalPages = 1,
    totalResults = 1,
    resultCount,
    perPageLimit = 1,
  } = props;
  const handleChange = (event, value) => {
    setPageNo(value + 1);
  };

  const { items } = usePagination({
    count: totalPages,
    page: pageNo,
    onChange: handleChange,
  });

  const selectedStyles = {
    color: "#046CB8",
    border: "1px solid #046CB8",
  };

  return (
    <TablePagination
      component="div"
      count={totalResults}
      rowsPerPageOptions={[]}
      page={pageNo - 1}
      onPageChange={handleChange}
      rowsPerPage={perPageLimit}
      sx={{
        ".MuiTablePagination-actions": {
          marginLeft: "10px",
        },
        ".MuiSvgIcon-root": {
          width: "16px",
          height: "16px",
        },
        ".MuiButtonBase-root": { padding: "5px" },
        ".MuiTablePagination-displayedRows": {
          fontFamily: "Lato",
          fontSize: "11px",
          fontWeight: "400",
          lineHeight: "16px",
        },
        ".MuiTablePagination-displayedRows > span": {
          fontFamily: "Lato",
          fontSize: "13px",
          fontWeight: "600",
          lineHeight: "16px",
        },
      }}
      labelDisplayedRows={function defaultLabelDisplayedRows({
        from,
        to,
        count,
      }) {
        return (
          <>
            <span>
              {from}-{to}
            </span>{" "}
            of {count !== -1 ? count : `more than ${to}`}
          </>
        );
      }}
    />
  );
};

export default BlunoPagination;
