import React, { useEffect, useRef, useState } from "react";
import OptionDots from "../assets/svg/OptionDots";
import { v4 as uuidv4 } from "uuid";
import BlunoTooltip from "./BlunoTooltip";

export default function BlunoTableOptions({
  row,
  OptionsComponent,
  onOptionsFunction,
  header,
  tableRef,
  tableHeadRef,
  showHoverBlueRow,
  padding,
  highlightRow,
  unHighlightRow,
}) {
  const component = useRef();
  const parent = useRef();
  const isScrollable = useRef();

  const [showComponent, setShowComponent] = useState(false);
  const [hover, setHover] = useState(false);

  const checkOutsideClick = (event) => {
    if (component?.current && !component?.current?.contains(event.target)) {
      unHighlightRow(row?.id);
      setShowComponent(false);
    }
  };

  const spaceTop = () => {
    const parentBound = parent?.current?.getBoundingClientRect();
    const componentBound = component?.current?.getBoundingClientRect();
    const tableHead = tableHeadRef?.current?.getBoundingClientRect();
    return parentBound?.top - tableHead.bottom + 10 >= componentBound?.height;
  };
  const spaceBottom = () => {
    const parentBound = parent?.current?.getBoundingClientRect();
    const componentBound = component?.current?.getBoundingClientRect();
    const table = tableRef?.current?.getBoundingClientRect();
    return table?.bottom - parentBound?.bottom >= componentBound?.height;
  };
  const overflowComponent = () => {
    const componentBound = component?.current?.getBoundingClientRect();
    const table = tableRef?.current?.getBoundingClientRect();
    const tableHead = tableHeadRef?.current?.getBoundingClientRect();
    return (
      tableHead.bottom > componentBound.top ||
      table?.bottom < componentBound?.bottom
    );
  };
  const positionLeft = () => {
    if (!component?.current?.style) return undefined;
    component.current.style.left =
      parent?.current?.getBoundingClientRect()?.x -
      component?.current?.clientWidth +
      "px";
  };
  const positionAbove = () => {
    if (!component?.current?.style) return undefined;
    component.current.style.top =
      parent?.current?.getBoundingClientRect()?.y -
      component?.current?.clientHeight +
      10 +
      "px";
  };
  const positionBelow = () => {
    if (!component?.current?.style) return undefined;
    component.current.style.top =
      parent?.current?.getBoundingClientRect()?.y + 35 + "px";
  };

  const changePosition = () => {
    if (isScrollable.current && overflowComponent()) {
      setShowComponent(false);
      unHighlightRow(row?.id);
    } else {
      if (spaceBottom()) positionBelow();
      else if (spaceTop()) positionAbove();
      positionLeft();
    }
  };
  const positionModal = () => {
    if (spaceBottom()) positionBelow();
    else if (spaceTop()) positionAbove();
    else positionBelow();
    positionLeft();
  };
  useEffect(() => {
    document.addEventListener("mousedown", checkOutsideClick);
    document.addEventListener("wheel", changePosition);
    isScrollable.current =
      tableRef?.current?.firstElementChild?.clientHeight <
      tableRef?.current?.firstElementChild.scrollHeight;
    return () => {
      document.removeEventListener("mousedown", checkOutsideClick);
      document.removeEventListener("wheel", changePosition);
    };
  }, []);
  useEffect(() => {
    setShowComponent(false);
    unHighlightRow(row?.id);
  }, [row]);
  return (
    <>
      <div
        className={`s-table__options ${
          row?.[header?.slug]?.disabled ? "s-table__options--disabled" : ""
        }`}
      >
        <BlunoTooltip
          useChildren={true}
          position="fixed"
          text={row?.disabled ? "" : "More"}
          left="12px"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (!row?.disabled && !row?.[header?.slug]?.disabled) {
                setShowComponent(true);
                highlightRow(row?.id);
              }
            }}
            ref={parent}
            className={`s-table__options-dots ${
              row?.disabled
                ? ""
                : showHoverBlueRow
                ? "s-table__options-dots--hover-effect"
                : "s-table__options-dots--hover-effect--blue"
            } ${
              row?.[header?.slug]?.disabled
                ? "s-table__options-dots--disabled"
                : ""
            }`}
            style={{ padding: padding }}
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
          >
            <OptionDots
              hoverColor={
                row?.disabled ||
                showHoverBlueRow ||
                row?.[header?.slug]?.disabled
                  ? "#000000"
                  : "#046cb8"
              }
              color={
                hover
                  ? row?.disabled ||
                    showHoverBlueRow ||
                    row?.[header?.slug]?.disabled
                    ? "#000000"
                    : "#046cb8"
                  : "#000000"
              }
            />
          </div>
        </BlunoTooltip>
        <div className="s-table__options-modal" ref={component}>
          {showComponent && (
            <OptionsComponent
              data={row?.[header?.slug]?.modal_data}
              id={row?.id}
              onOptionsFunction={onOptionsFunction}
              changePosition={changePosition}
              positionModal={positionModal}
            />
          )}
        </div>
      </div>
    </>
  );
}
