import React, { useEffect, useState } from "react";
import CopyToClipboard from "../../components/CopyToClipboard";

export default function ManageUsersOptionsComponent({
  data,
  id,
  onOptionsFunction,
  positionModal,
}) {
  // local states
  const [showCopyIcon, setShowCopyIcon] = useState(false);

  useEffect(() => {
    positionModal();
  }, []);
  return (
    <>
      <div className="manage-settings__options-modal">
        {data?.map((item, idx) => {
          if (item)
            return (
              <div
                className={`manage-settings__options-modal-item manage-settings__options-modal-item--${item?.slug}`}
                key={idx}
                onClick={(e) => {
                  onOptionsFunction(e, item?.slug, id);
                }}
                onMouseEnter={() => item?.enable_copy && setShowCopyIcon(true)}
                onMouseLeave={() => item?.enable_copy && setShowCopyIcon(false)}
              >
                <img
                  src={item?.icon}
                  className="manage-settings__options-modal-icon"
                />
                <div className="manage-settings__options-modal-text-container">
                  <div className="manage-settings__options-modal-text-par">
                    <p>{item?.text}</p>
                    <div
                      className={`manage-settings__options-modal-copy ${
                        item.enable_copy && showCopyIcon
                          ? "manage-settings__options-modal-copy--visible"
                          : ""
                      }`}
                    >
                      {item.enable_copy && showCopyIcon && (
                        <CopyToClipboard
                          text={item?.text}
                          textPosition={"above"}
                        />
                      )}
                    </div>
                  </div>
                  {item?.sub_text && (
                    <p className="manage-settings__options-modal-subText">
                      {item?.sub_text}
                    </p>
                  )}
                </div>
              </div>
            );
        })}
      </div>
    </>
  );
}
