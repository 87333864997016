import React from "react";
import Button from "../Button";

export default function ExpiredLink({ data, setShowModal }) {
  return (
    <>
      <div className="o-expired-link">
        <h2 className="o-expired-link-title">{data?.title}</h2>
        <img src={data?.image} className="o-expired-link-image" />
        <p className="o-expired-link-body">{data?.body}</p>
        {data?.buttons?.map((item, idx) => {
          return (
            <Button
              buttonType={item?.sub_type}
              buttonLabel={item?.text}
              onClick={(e) => {
                setShowModal(false);
              }}
            />
          );
        })}
      </div>
    </>
  );
}
