import React, { useState, useRef, useEffect } from "react";
import Dropdown from "../Dropdown";
import MuiTextInput from "../blunoInputs/MuiTextInputs";
import Button from "../Button";
import UploadFileCard from "../UploadFile/UploadFile";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import { useDispatch } from "react-redux";
import { replace, useLocation, useNavigate, useParams } from "react-router-dom";
import { downloadDocumentUtility } from "../../utils/downloadDocument";
import { DOCUMENT_ACTION } from "../../utils/constants/DocumentConstants";

const IRMAdditionModal = ({
  data,
  irmId,
  isIrmAddedManually = false,
  setIsIrmAddedManually,
  setActiveRowId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const { sbId } = useParams();
  const [irmMetadata, setIrmMetadata] = useState();
  const [docId, setDocId] = useState();
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [uploadEnabled, setUploadEnabled] = useState();

  const handleInputChange = (theValue, theSlug) => {
    setIrmMetadata({
      ...irmMetadata,
      [theSlug]: theValue,
    });
  };

  const formData = new FormData();
  const uploadSingleFile = (selectedFiles, doc_type) => {
    const metadata = {
      ...irmMetadata,
      flow: "reconciliation",
    };
    formData.append("file", selectedFiles[0]);
    formData.append("doc_type", doc_type);
    formData.append("metadata", JSON.stringify(metadata));

    const uploadApiCall = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.uploadFile, formData, null, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      );

      if (dataRes?.success && dataRes?.data) {
        setUploadEnabled(false);

        if (dataRes?.data?.document_id) {
          setDocId(dataRes?.data?.document_id);
        }

        if (dataRes?.data?.uploaded_document_info) {
          setUploadedDocuments(
            dataRes?.data?.uploaded_document_info?.[0]?.uploaded_documents
          );
        }
      }
    };

    uploadApiCall();
  };

  const onDocAction = (
    section,
    actionBtn,
    documentId,
    fileName,
    element_id = ""
  ) => {
    const docAction = async (
      section,
      actionBtn,
      documentId,
      fileName
      // element_id
    ) => {
      const dataRes = await dispatch(
        apiRequest(endpoints.docAction, {
          section: section,
          action: actionBtn?.action,
          document_id: documentId,
          sb_id: sbId,
          document_type: "MISCELLANEOUS",
        })
      );

      if (dataRes?.success && dataRes?.data) {
        if (dataRes?.data?.document_link) {
          if (actionBtn?.action === DOCUMENT_ACTION?.["DOWNLOAD"]) {
            downloadDocumentUtility(dataRes?.data?.document_link, fileName);
          }
        } else {
          setUploadedDocuments([]);
          setUploadEnabled(true);
        }
      }
    };

    docAction(section, actionBtn, docId, fileName);
  };

  const handleAddManualIrm = async (e) => {
    const dataRes = await dispatch(
      apiRequest(
        endpoints.reconManualAddIrm,
        null,
        `/${sbId}/irm/${irmId}/attach/manual?docId=${docId}`
      )
    );

    if (dataRes?.success && dataRes?.data) {
      // navigate(0);
      // navigate(`/bills/sb`, { replace: true });
      setIsIrmAddedManually(true);
      setActiveRowId(null);
    }
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setActiveRowId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setUploadEnabled(true);
  }, []);

  const renderUiComponent = (theItem) => {
    switch (theItem?.type) {
      case "dropdown":
        return (
          <div className="mapirm__modal-dropdown">
            <Dropdown
              options={theItem?.options}
              defaultValue={irmMetadata?.manual_attach_irm_reason}
              height="40px"
              minWidth="243px"
              fontSize="13px"
              onSelectChange={(e) => handleInputChange(e.value, theItem?.slug)}
              isDisabled={theItem?.disabled}
              placeholder={theItem.placeholder}
              placeholderFontSize="13px"
              placeholderFontWeight="400"
              fancyPlaceholder={true}
              topSpacing="-5px"
            />
          </div>
        );

      case "text_box":
        return (
          <>
            {irmMetadata?.manual_attach_irm_reason === "other" && (
              <div className="mapirm__modal-textbox">
                <MuiTextInput
                  placeholder={theItem?.placeholder}
                  type={"text"}
                  value={irmMetadata?.manual_attach_irm_other_reason}
                  onChange={(e) => {
                    handleInputChange(e.target.value, theItem?.slug);
                  }}
                  disabled={theItem?.disabled}
                  height="40px"
                  minWidth="250px"
                  width={"250px"}
                  fontSize="13px"
                />
              </div>
            )}
          </>
        );
      case "upload_box":
        return (
          <div className="mapirm__modal-uploadbox">
            {theItem?.upload_box_list?.map((obj) => {
              return (
                <UploadFileCard
                  upload_enabled={uploadEnabled}
                  documents={uploadedDocuments}
                  doc_type={obj?.document?.slug}
                  uploadSingleFile={uploadSingleFile}
                  docId={docId}
                  onDocAction={onDocAction}
                  section="map_irms"
                />
              );
            })}
          </div>
        );
    }
  };

  return (
    <div ref={modalRef} className="mapirm__modal">
      <div className="mapirm__modal-inputs">
        {data?.input_list?.map((item) => {
          {
            return renderUiComponent(item);
          }
        })}
      </div>
      <div className="mapirm__modal-buttons">
        {data?.buttons?.map((btn) => {
          {
            return (
              <Button
                buttonLabel={btn?.text}
                buttonType={btn?.sub_type}
                extraClass={"mapirm__modal-button--extra"}
                onClick={(e) => handleAddManualIrm(e)}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default IRMAdditionModal;
