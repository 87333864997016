import React from "react";

const ProfileIcon = (props) => {
  const { color = "#000000" } = props;

  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6284 4.99975C12.6284 7.55393 10.5578 9.6245 8.00365 9.6245C5.44948 9.6245 3.37891 7.55393 3.37891 4.99975C3.37891 2.44557 5.44948 0.375 8.00365 0.375C10.5578 0.375 12.6284 2.44557 12.6284 4.99975Z"
        fill="white"
        stroke={color}
        stroke-width="0.75"
      />
      <rect
        x="0.375"
        y="10.375"
        width="15.25"
        height="11.2494"
        rx="4.625"
        fill="white"
        stroke={color}
        stroke-width="0.75"
      />
    </svg>
  );
};

export default ProfileIcon;
