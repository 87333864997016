// react imports
import React from "react";

// third-party imports

// in-app imports
import { getKeyValueFormat } from "../../utils";

const SubmissionInfo = ({ data }) => {
  return (
    <>
      {data?.submission && (
        <div className="recon-summary__submission">
          <div className="recon-summary__submission-header">
            <h2 className="recon-summary__section-title">
              {data?.submission?.title}
            </h2>
          </div>
          <div className="recon-summary__submission-body">
            <div className="recon-summary__submisson-reasons">
              {data?.submission?.info_list?.map((item, index) => {
                return getKeyValueFormat(
                  item?.key,
                  item?.value,
                  true,
                  true,
                  null,
                  item?.icon
                );
              })}
            </div>
          </div>
        </div>
      )}
      {data?.documents && (
        <div className="recon-summary__documents">
          <div className="recon-summary__documents-header">
            <h2 className="recon-summary__section-title">
              {data?.documents?.title}
            </h2>
            <p className="recon-summary__section-count">
              {data?.documents?.count}
            </p>
          </div>
          <div className="recon-summary__documents-body">
            <div className="recon-summary__documents-docs">
              {data?.documents?.info_list?.map((item, index) => {
                return getKeyValueFormat(
                  item?.key,
                  item?.value,
                  index % 2 === 0,
                  false,
                  null,
                  item?.icon
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubmissionInfo;
