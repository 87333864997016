import React from "react";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import BlunoTooltip from "../BlunoTooltip";

export default function RequestActionColumn({
  row,
  data,
  rowSelectionState,
  handleClick,
}) {
  const navigate = useNavigate();

  return (
    <div className="s-table__request-column">
      {data?.buttons?.map((item, idx) => {
        return (
          <BlunoTooltip
            text={`${
              item?.slug === "remind" && item?.disabled
                ? "Remind Admin option is currently disabled and will be available after 24 hours."
                : ""
            }`}
            useChildren={true}
            left="-75px"
            position="fixed"
          >
            <Button
              key={idx}
              buttonLabel={item?.text}
              buttonType={item?.sub_type}
              extraClass={`s-table__request-column-button ${
                item?.slug === "view"
                  ? "s-table__request-column-button--blue"
                  : ""
              } ${
                rowSelectionState[row?.id] && item?.slug === "view"
                  ? "s-table__request-column-button--hover"
                  : ""
              }`}
              fontSize={"11px"}
              onClick={(e) => {
                e.stopPropagation();
                if (item?.slug === "view") {
                  const path = document.location.pathname;
                  navigate(item?.deeplink, {
                    state: {
                      link: path,
                      text: path.includes("buyers") ? "Back To Buyer" : null,
                    },
                  });
                }
                handleClick(item?.slug, [row?.id]);
              }}
              variant={item?.slug === "remind" ? "gray-white" : ""}
              disabled={item?.disabled}
            />
          </BlunoTooltip>
        );
      })}
    </div>
  );
}
