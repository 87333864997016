import { useLocation } from "react-router-dom";

export const truncateText = (text, maxLength = 15) => {
  // if (text?.length < maxLength) return <div>{text}</div>;
  if (!text || text?.length <= maxLength) return text;
  return <div>{`${text?.substring(0, maxLength ?? 15)}...`}</div>;
};

export const GetQueryParamValue = (queryParamKey) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get(queryParamKey);

  return paramValue;
};

export const checkIfAllValuesTrue = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== true) {
      return false;
    }
  }
  return true;
};

export const checkIfAllValuesFalse = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
      return false;
    }
  }
  return true;
};

export const getKeyValueFormat = (
  key,
  value,
  isBold,
  isHighlighted = false,
  minWidth = null,
  icon
) => {
  return (
    <div className="recon-summary__pair">
      {key && (
        <div
          className={`recon-summary__pair-key`}
          style={minWidth && { minWidth: `${minWidth}px` }}
        >
          {key}
          <span>:</span>
        </div>
      )}
      {value && (
        <div
          className={`recon-summary__pair-value ${
            isBold ? "recon-summary__pair--bold" : ""
          } ${isHighlighted ? "recon-summary__pair--highlighted" : ""}`}
        >
          {truncateText(value, 40)}
        </div>
      )}
      {/* {icon && <img src={icon} alt="" />} */}
    </div>
  );
};

export const converTimeToString = (timerValue) => {
  return (
    (~~(timerValue / 60) >= 10
      ? ~~(timerValue / 60)
      : "0" + ~~(timerValue / 60)) +
    ":" +
    (timerValue % 60 >= 10 ? timerValue % 60 : "0" + (timerValue % 60))
  );
};
