// react imports
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// third-party imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// in-app imports
import RedirectIcon from "../assets/media/table/Redirect.png";
import SkeletonBasic from "./SkeletonLoaders/SkeletonBasic";
import { sanitizeHtml } from "../utils/sanitizeHtml";
import { updateShowIrmForm } from "../redux/common/slice";
import { useDispatch } from "react-redux";
import Button from "./Button";

import arrowPrev from "../assets/media/arrow-prev-v2.png";
import { apiRequest } from "../redux/common/actions";
import endpoints from "../api/endpoints";
import { showToast } from "../redux/toast/slice";
import { REFRESH_IRM_ALERT } from "../app/constants/common";

const HomeIrmSummaryV2 = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sliderRef = useRef();
  const [popupData, setPopupData] = useState({
    visible: false,
    x: 0,
    y: 0,
    utilised: 0,
    unutilised: 0,
  });

  const refreshIrm = async () => {
    const dataRes = await dispatch(apiRequest(endpoints.refreshIRMData));
    if (dataRes?.success) {
      dispatch(showToast(REFRESH_IRM_ALERT));
    }
  };

  const handleButtonClick = (e, action) => {
    switch (action?.slug) {
      case "add_irm":
        e.stopPropagation();
        dispatch(updateShowIrmForm(true));
        break;
      case "refresh_irm":
        refreshIrm();
        break;
    }
  };

  const currencyData =
    data?.currency_wise_data?.length === 1
      ? [
          data?.currency_wise_data[0],
          {
            icon: "https://static.bluno.in/country/united-states.png",
            currency: "USD",
            amount_value: "357.67M",
            total_count: "# IRM: <span>6</span>",
            utilised_count: "0",
            unutilised_count: "6",
            utilised_percent: "0%",
            utilised_info: "Utilised: <span><strong>0</strong> USD</span> (0%)",
          },
          {
            icon: "https://static.bluno.in/country/united-states.png",
            currency: "USD",
            amount_value: "357.67M",
            total_count: "# IRM: <span>6</span>",
            utilised_count: "0",
            unutilised_count: "6",
            utilised_percent: "0%",
            utilised_info: "Utilised: <span><strong>0</strong> USD</span> (0%)",
          },
        ]
      : data?.currency_wise_data;

  const redirectToLink = (deeplink) => {
    deeplink && navigate(deeplink);
  };

  const handleMouseEnter = (e, utilised, unutilised, index) => {
    const centeredSlideIndex =
      sliderRef.current?.innerSlider?.state.currentSlide;
    if (centeredSlideIndex === index) {
      setPopupData({
        visible: true,
        x: e.clientX,
        y: e.clientY,
        utilised,
        unutilised,
      });
    }
  };

  const handleMouseMove = (e) => {
    if (popupData.visible) {
      setPopupData((prev) => ({ ...prev, x: e.clientX, y: e.clientY }));
    }
  };

  const handleMouseLeave = () => {
    setPopupData({ ...popupData, visible: false });
  };

  const settings = {
    focusOnSelect: data?.currency_wise_data?.length > 1,
    centerMode: true,
    centerPadding: "0px",
    slidesToShow: 1,
    autoplay: data?.currency_wise_data?.length > 1,
    draggable: data?.currency_wise_data?.length > 1,
    arrows: data?.currency_wise_data?.length > 1,
    swipeToSlide: data?.currency_wise_data?.length > 1,
    touchThreshold: 100,
    autoplaySpeed: 3000,
    infinite: true,
    dots: data?.currency_wise_data?.length > 1,
    nextArrow: data?.currency_wise_data?.length > 1 ? <NextArrow /> : null,
    prevArrow: data?.currency_wise_data?.length > 1 ? <PrevArrow /> : null,
  };

  return (
    <>
      <div className="homeSummary">
        <SkeletonBasic showLoader={data ? false : true}>
          <div className="homeSummary__heading">
            <div className="homeSummary__title">{data?.title}</div>

            <div className="homeSummary__link">
              <img
                src={RedirectIcon}
                alt="Link"
                onClick={() => redirectToLink(data?.deeplink)}
              />
            </div>
          </div>
          {data?.default_view ? (
            <>
              <div className="homeSummary__default">
                <img
                  className="homeSummary__default-img"
                  src={data?.default_view?.img}
                  alt="default img"
                />
                <div className="homeSummary__default-text">
                  <p>{data?.default_view?.text}</p>
                  {data?.default_view?.buttons?.map((btn) => {
                    return (
                      <Button
                        buttonLabel={btn?.text}
                        buttonType={btn?.sub_type}
                        icon={btn?.icon}
                        hoverIcon={btn?.hover_icon}
                        disabled={btn?.disabled}
                        iconAlignment={btn?.icon_alignment}
                        tooltipText={btn?.tooltip_text}
                        tooltipPosition={{ bottom: "20px", left: "40px" }}
                        onClick={(e) => {
                          handleButtonClick(e, btn);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="homeSummary__irm"
                onClick={(e) => e.stopPropagation()}
              >
                <Slider
                  {...settings}
                  style={{
                    maxWidth: "310px",
                    overflow: "visible",
                    padding: "0px",
                  }}
                  ref={(slider) => (sliderRef.current = slider)}
                >
                  {currencyData?.map((card, index) => {
                    return (
                      <div className="homeSummary__irm-slide">
                        <div className="homeSummary__irm-header">
                          <div className="homeSummary__irm-header-left">
                            {card?.icon && <img src={card?.icon} alt="" />}
                            <p className="homeSummary__irm-currency">
                              {card?.currency}
                            </p>
                          </div>
                          <div className="homeSummary__irm-header-right">
                            <p className="homeSummary__irm-amount">
                              {card?.amount_value}
                            </p>
                            <p
                              className="homeSummary__irm-count"
                              dangerouslySetInnerHTML={sanitizeHtml(
                                card?.total_count
                              )}
                            />
                          </div>
                        </div>
                        <div
                          className="homeSummary__irm-middle"
                          onMouseEnter={(e) =>
                            handleMouseEnter(
                              e,
                              card.utilised_count,
                              card.unutilised_count,
                              index
                            )
                          }
                          onMouseMove={handleMouseMove}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div
                            className={`homeSummary__irm-progress ${
                              popupData.visible
                                ? "homeSummary__irm-progress--hovered"
                                : ""
                            }`}
                          >
                            <div
                              className="homeSummary__irm-progress--utilised"
                              style={{ width: `${card?.utilised_percent}` }}
                            />
                          </div>
                        </div>

                        <div
                          className={`homeSummary__irm-bottom ${
                            popupData.visible &&
                            sliderRef.current?.innerSlider?.state
                              .currentSlide === index
                              ? "homeSummary__irm-bottom--disabled"
                              : ""
                          }`}
                        >
                          <div className="homeSummary__irm-bottom-bullet" />
                          <p
                            className={`homeSummary__irm-utilised-info`}
                            dangerouslySetInnerHTML={sanitizeHtml(
                              card?.utilised_info
                            )}
                          />
                        </div>
                      </div>
                    );
                  })}
                </Slider>
                {popupData.visible && (
                  <div
                    className="homeSummary__irm-popup"
                    style={{
                      position: "fixed",
                      top: popupData.y + 10,
                      left: popupData.x - 70,
                      pointerEvents: "none",
                    }}
                  >
                    <div className="homeSummary__irm-popup-data">
                      <div className="homeSummary__irm-popup-bullet homeSummary__irm-popup-bullet--utilised" />
                      <p>
                        # Utilised :{" "}
                        <span className="homeSummary__irm-popup-count homeSummary__irm-popup-count--utilised">
                          {popupData.utilised}
                        </span>
                      </p>
                    </div>
                    <div className="homeSummary__irm-popup-data">
                      <div className="homeSummary__irm-popup-bullet" />
                      <p>
                        # Un-utilised :{" "}
                        <span className="homeSummary__irm-popup-count">
                          {popupData.unutilised}
                        </span>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </SkeletonBasic>
      </div>
    </>
  );
};

const NextArrow = ({ onClick }) => (
  <div
    className="homeSummary__irm-arrow homeSummary__irm-arrow--next"
    onClick={onClick}
  >
    <img src={arrowPrev} alt="" />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div
    className="homeSummary__irm-arrow homeSummary__irm-arrow--prev"
    onClick={onClick}
  >
    <img src={arrowPrev} alt="" />
  </div>
);

export default HomeIrmSummaryV2;
