// react imports
import { createSlice } from "@reduxjs/toolkit";

// in-app imports

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    sidebarItems: {},
    userBasicInfo: {},
    showSbForm: false,
    showIrmForm: false,
    showBrForm: false,
    showSupportModal: false,
    notificationObject: {
      showNotificationBanner: false,
    },
  },
  reducers: {
    updateSidebarItems: (state, action) => {
      state.sidebarItems = action?.payload;
    },
    updateUserBasicInfo: (state, action) => {
      state.userBasicInfo = action?.payload;
    },
    updateShowSbForm: (state, action) => {
      state.showSbForm = action?.payload;
    },
    updateShowIrmForm: (state, action) => {
      state.showIrmForm = action?.payload;
    },
    updateShowBrForm: (state, action) => {
      state.showBrForm = action?.payload;
    },
    updateShowSupportForm: (state, action) => {
      state.showSupportModal = action?.payload;
    },
    updateNotificationObject: (state, action) => {
      state.notificationObject = {
        ...state.notificationObject,
        showNotificationBanner: action?.payload?.showNotificationBanner,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateSidebarItems,
  updateUserBasicInfo,
  updateShowSbForm,
  updateShowIrmForm,
  updateShowBrForm,
  updateShowSupportForm,
  updateNotificationObject,
} = commonSlice.actions;

export default commonSlice.reducer;
