// react imports
import React from "react";

// third-party imports

// in-app imports
import blunoLogo from "../../assets/media/common/Bluno-Logo.png";
import { getKeyValueFormat } from "../../utils";
import sampleLogo from "../../assets/media/common/sample-company-name.png";

const SummaryDetails = ({ data }) => {
  return (
    <>
      <div className="recon-summary__header">
        <div className="recon-summary__header-left">
          <div className="recon-summary__header-company">
            <img src={data?.logo} alt="" />
          </div>
          <div className="recon-summary__header-buyer">
            <p className="recon-summary__header-buyer-key">{data?.key}</p>
            <p className="recon-summary__header-buyer-value">
              {data?.buyer_name}
            </p>
            {data?.bill_lodgement_number && (
              <div className="s-sbHeader__lodgement s-sbHeader__lodgement--negative">
                <p className="s-sbHeader__lodgement-text">
                  {`${data?.bill_lodgement_number?.key} : `}
                </p>
                <div className="s-sbHeader__lodgement-value">
                  <p>{data?.bill_lodgement_number?.value}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="recon-summary__header-right">
          <p className="recon-summary__header-powered">
            {data?.powered_by_text}
          </p>
          <img src={data?.powered_by_logo} alt="" />
        </div>
      </div>
      <div className="recon-summary__details">
        <div className="recon-summary__details-info">
          {data?.info_list?.map((item, index) => {
            return getKeyValueFormat(
              item?.key,
              item?.value,
              true,
              index === 0,
              145
            );
          })}
        </div>
        <div className="recon-summary__details-sb">
          <div className="recon-summary__details-sb-left">
            {data?.sb_details?.content?.map((item) => {
              return (
                <div className="recon-summary__details-sb-info">
                  <p className="recon-summary__details-sb-key">{item?.key}</p>
                  <p className="recon-summary__details-sb-value">
                    {item?.value}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="recon-summary__details-sb-right">
            <p className="recon-summary__details-sb-key">
              {data?.sb_details?.balance_amount?.key}
            </p>
            <p
              className="recon-summary__details-sb-value recon-summary__details-sb--amt"
              style={{ color: data?.sb_details?.balance_amount?.color }}
            >
              {data?.sb_details?.balance_amount?.value}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SummaryDetails;
