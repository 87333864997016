import React from "react";
import { FormControlLabel, Switch, styled } from "@mui/material";

const BlunoSwitch = ({
  isToggleActive,
  handleToggleChange,
  onLabel,
  offLabel,
  disabled,
}) => {
  return (
    <>
      <div className="s-switch">
        <FormControlLabel
          control={
            <AndroidSwitch
              checked={isToggleActive}
              onChange={handleToggleChange}
              inputProps={{ "aria-label": "controlled" }}
              disabled={disabled}
            />
          }
          label={
            <p className="s-switch__label">
              {isToggleActive ? onLabel : offLabel}
            </p>
          }
          style={{ marginRight: "0px" }}
        />
      </div>
    </>
  );
};

const AndroidSwitch = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 18,
  padding: 0,
  display: "flex",
  alignItems: "center",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 12,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(18px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 3,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#046cb8",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 9,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default BlunoSwitch;
