import React, { useEffect, useState } from "react";

//redux imports
import { useSelector, useDispatch } from "react-redux";
import { apiRequest } from "../redux/common/actions";
import { updateShowBrForm } from "../redux/common/slice";

//in app imports
import endpoints from "../api/endpoints";
import Button from "./Button";
import UploadFileV2 from "./UploadFileV2/UploadFileV2";
import BlunoTooltip from "./BlunoTooltip";
import { downloadDocumentUtility } from "../utils/downloadDocument";
import SkeletonBasic from "./SkeletonLoaders/SkeletonBasic";

const PendingOnboardingDocs = () => {
  // redux state variables
  const currentStage = useSelector((state) => state.decisionTask.currentStage);
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [files, setFiles] = useState(null);

  const getPendingDocuments = async () => {
    const onboardingRes = await dispatch(
      apiRequest(
        endpoints.fetchOnboardingPendingTask,
        `?application_id=${currentStage?.applicationId}`
      )
    );

    if (onboardingRes?.success && onboardingRes?.data) {
      setData(onboardingRes?.data?.content?.success);
    }
  };

  const handleUploadLater = async () => {
    const apiRes = await dispatch(
      apiRequest(
        endpoints.submitUploadLater,
        {},
        `?application_id=${currentStage?.applicationId}`
      )
    );
    if (apiRes?.success && apiRes?.data) {
      dispatch(updateShowBrForm(false));
    }
  };

  const handleButtonClick = (theSlug) => {
    switch (theSlug) {
      case "upload_later":
      case "submit":
        handleUploadLater();
        break;
    }
  };

  useEffect(() => {
    getPendingDocuments();
  }, [files]);

  return (
    <div className="o-pending">
      <SkeletonBasic showLoader={data ? false : true}>
        <span className="o-pending__title">{data?.title}</span>
        <div className="o-completed__list o-pending__list">
          {data?.documents?.map((item, idx) => {
            return (
              <div
                className={`o-completed__document ${
                  idx !== data?.documents?.length - 1 &&
                  "o-completed__document--underline"
                }`}
              >
                <p className="o-completed__document-title">
                  <span>{item?.title?.title}</span>
                  <BlunoTooltip
                    icon={item?.title?.back_info?.icon}
                    text={item?.title?.back_info?.hover_text}
                    iconWidth="16px"
                    iconHeight="16px"
                    top="-50px"
                    width="400px"
                    height="45px"
                  />
                </p>
                <div className="o-completed__document-content">
                  <UploadFileV2
                    upload_enabled={item?.document_info?.upload_enabled}
                    multiple_files={item?.document_info?.multiple_files}
                    icon={item?.document_info?.icon}
                    primary_text={item?.document_info?.primary_text}
                    extraClass="o-completed__document-box"
                    flow={"onboarding"}
                    slug={item?.document_info?.slug}
                    onFileChange={setFiles}
                    allowed_doc_types={item?.document_info?.allowed_doc_types}
                    uploaded_documents={item?.document_info?.uploaded_documents}
                    extraMetadata={{
                      application_id: currentStage?.applicationId,
                      task: "welcome_congratulations",
                      sub_task: item?.document_info?.sub_title,
                    }}
                    getApi={getPendingDocuments}
                    callGetApi={true}
                    singleUploadAllowed={true}
                  />

                  <Button
                    buttonType={item?.sample?.sub_type}
                    buttonLabel={item?.sample?.text}
                    icon={item?.sample?.icon}
                    iconAlignment={item?.sample?.icon_alignment}
                    iconExtraClass="o-completed__blueicon"
                    iconParDiv={true}
                    iconParDivClass="o-completed__blueicon-par"
                    extraClass="o-completed__hyperlink"
                    height="30px"
                    onClick={() => {
                      downloadDocumentUtility(
                        item?.sample?.deeplink,
                        "br_form"
                      );
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        {data?.buttons && (
          <div className="o-pending__buttons">
            {data?.buttons?.map((action) => {
              return (
                action?.type === "button" && (
                  <Button
                    buttonLabel={action?.text}
                    buttonType={action?.sub_type}
                    icon={action.icon}
                    disabled={action?.disabled}
                    iconAlignment={action?.icon_alignment}
                    extraClass={`o-verify-iec__continue-button`}
                    height="40px"
                    onClick={() => {
                      handleButtonClick(action?.slug);
                    }}
                  />
                )
              );
            })}
          </div>
        )}
      </SkeletonBasic>
      <img
        className="request-feature__close"
        src="https://static.bluno.in/miscl/cross-thin.png"
        onClick={() => {
          handleButtonClick("upload_later");
        }}
      />
    </div>
  );
};

export default PendingOnboardingDocs;
