// react imports
import React, { useState } from "react";

// redux imports
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import { useDispatch } from "react-redux";

//in app imports
import Button from "../../components/Button";
import { DGFT_PLATFORM_SLUGS } from "../../utils/constants/SettingsConstants";

const DgftConnectModal = ({
  data,
  setShowModal,
  setShowConfirmationCard,
  setShowDgftGuide,
  setSubmitValues,
}) => {
  const dispatch = useDispatch();
  const [showNeedAssistBtn, setShowNeedAssistBtn] = useState(true);

  const handleButtonClick = (theItem) => {
    switch (theItem?.slug) {
      case "finish_later":
        setShowModal(false);
        break;
      case "securely_connect":
        window.open(theItem?.deeplink, "_blank");
        setSubmitValues(null);
        setShowConfirmationCard(true);
        setShowDgftGuide(false);
        setShowModal(false);
        break;
    }
  };

  const handleNeedAssistanceRequest = async () => {
    const apiRes = await dispatch(
      apiRequest(endpoints.handleNeedAssistanceRequest)
    );
    if (apiRes?.success && apiRes?.data) {
      setShowNeedAssistBtn(false);
    }
  };

  const onModalActionClick = (action) => {
    switch (action?.slug) {
      case DGFT_PLATFORM_SLUGS?.needAssistance:
        handleNeedAssistanceRequest();
    }
  };

  return (
    <div className="p-settings__modal">
      <img
        className="o-gethelp-close"
        src={data?.cross_icon}
        onClick={() => {
          setShowModal(false);
        }}
      ></img>
      <div className="p-settins__modal-cont">
        <div className="p-settings__modal-header">
          <h3>{data?.title}</h3>
          <>
            {data?.header_buttons?.map((action) => {
              if (
                action?.slug === DGFT_PLATFORM_SLUGS?.needAssistance &&
                showNeedAssistBtn
              ) {
                return (
                  action?.type === "button" && (
                    <Button
                      buttonLabel={action?.text}
                      buttonType={action?.sub_type}
                      icon={action.icon}
                      disabled={action?.disabled}
                      iconAlignment={action?.icon_alignment}
                      onClick={() => onModalActionClick(action)}
                    />
                  )
                );
              } else if (action?.slug !== DGFT_PLATFORM_SLUGS?.needAssistance) {
                return (
                  action?.type === "button" && (
                    <Button
                      buttonLabel={action?.text}
                      buttonType={action?.sub_type}
                      icon={action.icon}
                      disabled={action?.disabled}
                      iconAlignment={action?.icon_alignment}
                      onClick={() => onModalActionClick(action)}
                    />
                  )
                );
              }
            })}
            {!showNeedAssistBtn && (
              <div className="dgft-partner__assistance">
                <p>Assistance requested</p>
                <img src="https://static.bluno.in/miscl/green_tick_20_icon.png" />
              </div>
            )}
          </>
        </div>
        <div className="p-settings__modal-icon">
          <img src={data?.icon} alt="dgft" />
        </div>

        <div className="p-settings__modal-benefits">
          {data?.benefits?.map((benefit) => {
            return (
              <div className="p-settings__modal-benefit">
                <img
                  className="p-settings__modal-point"
                  src={benefit?.icon}
                  alt="benefit"
                />
                <p>
                  <span className="p-settings__modal-benefit-title">
                    {benefit?.title}
                  </span>
                  <span className="p-settings__modal-benefit-text">
                    {benefit?.text}
                  </span>
                </p>
              </div>
            );
          })}
        </div>
        <p className="p-settings__modal-text">{data?.text}</p>
        <div className="p-settings__modal-security">
          {data?.security?.map((item) => {
            return (
              <div className="p-settings__modal-sec">
                <img src={item?.icon} alt="security" />
                <p>{item?.text}</p>
              </div>
            );
          })}
        </div>
        <div className="p-settings__modal-buttons">
          {data?.buttons?.map((btn) => {
            return (
              <Button
                buttonLabel={btn?.text}
                buttonType={btn?.sub_type}
                onClick={() => handleButtonClick(btn)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DgftConnectModal;
