export const GENERAL_SETTINGS_SLUGS = {
  COMPANY_WEBSITE: "company_website",
  CORRESPONDENCE_ADDRESS: "correspondence_address",
  IS_ADDRESS_SAME: "is_correspondence_address_same",
  ADDRESS_LINE_1: "correspondence_address_line_1",
  ADDRESS_LINE_2: "correspondence_address_line_2",
  CITY: "correspondence_city",
  STATE: "correspondence_state",
  COUNTRY: "correspondence_country",
  PIN_CODE: "correspondence_pin_code",
  COMPANY_ADDRESS: "company_address",
  PHONE_NUMBER: "user_phone_number",
  EMAIL_ID: "email_id",
  SAVE_WEBSITE: "save_company_website",
  SAVE_CORRESPONDENCE_ADDRESS: "save_correspondence_address",
  SAVE_EDIT_IDENTITY_POPUP: "save_edit_identity_popup",
  CLOSE_EDIT_ADDRESS_POPUP: "close_edit_address_popup",
};

export const CHECKBOX_VALUE = {
  NO: "no",
  YES: "yes",
};

export const SETTING_STATE = {
  EDIT_STATE: "edit_state",
};

export const INPUT_TYPES = {
  TEXT_BOX: "text_box",
  DROPDOWN: "dropdown",
  BUTTON: "button",
};

export const IDENTITY_TYPE = {
  PHONE: "PHONE",
  EMAIL: "EMAIL",
};

export const IDENTITY_SLUGS = {
  VERIFY_NEW: "verify_new",
  EDIT_OLD_IDENTITY: "edit_old_identity",
  VERIY_OLD_IDENTITY: "verify_old_identity",
  UPDATE_NEW_IDENTITY: "update_new_identity",
  VERIFY_NEW_IDENTITY: "verify_new_identity",
  RESEND_OTP: "resend_otp",
};

export const SECURITY_PASSWORD_SLUGS = {
  CURRENT_PASSWORD: "current_password",
  NEW_PASSWORD: "enter_new_password",
  REENTERED_NEW_PASSWORD: "re_enter_new_password",
};

export const POPUP_SLUGS = {
  REMOVE_USER: "remove_user",
};

export const ADD_USER_SLUGS = {
  MOBILE: "phone_identity",
  EMAIL: "email_identity",
  NAME: "employee_name",
  ROLE: "role_assigned",
};

export const MANAGE_USER_SLUGS = {
  users: "manage_groups",
  access: "manage_access",
};

export const DGFT_PLATFORM_SLUGS = {
  registeredDgftAccountRadio: "registered_dgft_account",
  iecDgftLinkedRadio: "iec_dgft_linked",
  showDgftGuideButton: "show_dgft_guide",
  yes: "yes",
  requestCallback: "request_a_callback",
  requestCallback2: "request_callback",
  needAssistance: "need_assistance",
  needAssistance2: "need_assistance_2",
};
