//react
import React, { useState } from "react";

//third party imports
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { FormHelperText } from "@mui/material";

const BlunoDatePicker = ({
  placeholder = "",
  height = "40px",
  minWidth = "228px",
  fontSize = "13px",
  placeholderFontSize = "13px",
  placeholderFontWeight = "400",
  paddingLeft = null,
  onChange,
  value,
  errorMessage = "",
  errorMessageFontSize = "13px",
}) => {
  const errorStyles = !!errorMessage
    ? {
        color: "#e01e5a",
        "& .MuiFormLabel-root": {
          color: "#e01e5a",
          fontSize: fontSize,
          lineHeight: "normal",
        },
        "& fieldset": {
          borderColor: "#e01e5a !important",
        },
        "& .MuiInputLabel-shrink": {
          color: "#e01e5a",
          fontSize: "16px",
          paddingTop: "5px",
        },
      }
    : {};

  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        slotProps={{
          textField: {
            onClick: () => setOpen(true),
          },
        }}
        label={placeholder}
        value={value ? dayjs(value) : null}
        onChange={(newValue) => {
          const formattedDate = newValue ? newValue.format("YYYY-MM-DD") : null;
          onChange(formattedDate);
        }}
        format="DD MMM, YYYY"
        maxDate={dayjs()}
        sx={{
          "& .MuiInputBase-root": {
            height: height,
            minWidth: minWidth,
            paddingLeft: paddingLeft,
            borderRadius: "8px",
            bgcolor: "#fff",
          },
          "& .MuiInputBase-input": {
            fontSize: fontSize,
            fontWeight: placeholderFontWeight,
            boxSizing: "border-box",
            lineHeight: height,
          },
          "& .MuiFormLabel-root": {
            fontSize: fontSize,
            lineHeight: "normal",
          },
          "& .MuiFormHelperText-root, & .MuiInputLabel-root": {
            lineHeight: "0.7",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: errorMessage ? "#888888 !important" : "",
            },

            "&.Mui-focused fieldset": {
              borderColor: "#046cb8 !important",
              borderWidth: "1px",
            },
          },
          "& .MuiInputLabel-shrink": {
            fontSize: "16px",
            paddingTop: "5px",
          },
          "& .MuiInputLabel-root": {
            color: errorMessage ? "#888888" : "",
            "&.Mui-focused": {
              color: "#046cb8",
            },
          },
          ...errorStyles,
        }}
      />
      {errorMessage && (
        <FormHelperText
          error
          sx={{ fontSize: errorMessageFontSize, marginLeft: "5px" }}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </LocalizationProvider>
  );
};

export default BlunoDatePicker;
