// react imports
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// third-party imports

// in-app imports
import Tag from "../Tag";

import dropUpIcon from "../../assets/media/exportBills/DropUp-Blue.png";
import dropdownIcon from "../../assets/media/exportBills/dropdown.png";
import Button from "../Button";
import BlunoTooltip from "../BlunoTooltip";
import { sanitizeHtml } from "../../utils/sanitizeHtml";
import CopyToClipboard from "../CopyToClipboard";

const SBHeader = ({
  isDropdown,
  isSBDetailView,
  onDropdownClick,
  data,
  onActionClick,
  onDocAction,
  headingRef,
  sbType = "type_1",
  documentManagement,
  closeModal,
  closeIcon,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isDownloadHover, setIsDownloadHover] = useState(false);
  const [copyHover, setCopyHover] = useState(false);
  const [showLodgementCopy, setShowLodgementCopy] = useState(false);

  const redirectTo = (e, theLink) => {
    e.stopPropagation();
    navigate(theLink);
  };

  return (
    <div className={`s-sbHeader-container`}>
      {documentManagement && (
        <img
          src={closeIcon}
          className="s-sbHeader__close"
          onClick={(e) => closeModal()}
        />
      )}
      <div
        className={`s-sbHeader ${
          !isSBDetailView ? "s-sbHeader--hideSBDetail" : null
        } ${documentManagement && "s-sbHeader--doc"}`}
        onClick={onDropdownClick}
        ref={headingRef}
      >
        <div
          className={`s-sbHeader__cont ${
            documentManagement && "s-sbHeader__cont--doc"
          }`}
        >
          <div className="s-sbHeader__content">
            <div className="s-sbHeader__top">
              {!documentManagement && data?.back_info && (
                <BlunoTooltip
                  icon={data?.back_info?.icon}
                  text={location?.state?.text ?? data?.back_info?.hover_text}
                  iconWidth="16px"
                  iconHeight="16px"
                  left="28px"
                  useChildren={true}
                >
                  <div
                    href={data?.back_info?.deeplink}
                    onClick={(e) => {
                      if (location?.state?.link)
                        redirectTo(e, location?.state?.link);
                      else redirectTo(e, data?.back_info?.deeplink);
                    }}
                    className="s-sbHeader__back s-sbHeader__back-background"
                  >
                    <img
                      src={data?.back_info?.icon}
                      className="s-sbHeader__back-img"
                    />
                  </div>
                </BlunoTooltip>
              )}
              <div
                className="s-sbHeader__text"
                onMouseEnter={() => {
                  setCopyHover(true);
                }}
                onMouseLeave={() => {
                  setCopyHover(false);
                }}
              >
                <h2
                  className={`s-sbHeader__text1 ${
                    sbType === "type_2" && "s-sbHeader__text1--type2"
                  }`}
                >
                  {data?.text1}
                </h2>
                <p
                  className={`s-sbHeader__text2 ${
                    sbType === "type_2" && "s-sbHeader__text2--type2"
                  }`}
                >
                  {data?.text2}
                  {data?.tag_info &&
                    data?.tag_info?.sub_type !== "standard" && (
                      <div className="s-sbHeader__text2--tag">
                        <Tag
                          text={data?.tag_info?.text}
                          type={data?.tag_info?.sub_type}
                          extraClass={"s-sbHeader__tag"}
                        />
                      </div>
                    )}
                </p>
                {/* {data?.tag_info?.sub_type === "negative" &&
                  data?.copy_doc_number && (
                    <div
                      style={{
                        position: "absolute",
                        right: "-3px",
                        top: "10.5px",
                      }}
                    >
                      <div
                        style={{
                          display: !copyHover ? "none" : "flex",
                          // display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                          background: "white",
                        }}
                      >
                        ...
                        <CopyToClipboard
                          text={data?.copy_doc_number?.text}
                          tooltipText={data?.copy_doc_number?.sub_text}
                          textPosition={"right"}
                        />
                      </div>
                    </div>
                  )} */}

                {data?.copy_doc_number && (
                  <div className="s-sbHeader__copy">
                    <div
                      className={`${!copyHover && "s-sbHeader__copy--display"}`}
                    >
                      <CopyToClipboard
                        text={data?.copy_doc_number?.text}
                        tooltipText={data?.copy_doc_number?.sub_text}
                        textPosition={
                          data?.tag_info?.sub_type === "standard"
                            ? "above"
                            : "right"
                        }
                      />
                    </div>
                  </div>
                )}
              </div>

              {data?.sb_doc && (
                <div
                  style={{
                    marginLeft:
                      data?.tag_info?.sub_type !== "standard"
                        ? "48px"
                        : "-10px",
                  }}
                >
                  <BlunoTooltip
                    text={data?.sb_doc?.download_tooltip_text}
                    useChildren={true}
                  >
                    <div
                      className="s-sbHeader__sb-download-background"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDocAction(
                          null,
                          data?.sb_doc,
                          data?.sb_doc?.doc_id,
                          data?.sb_doc?.doc_name
                        );
                      }}
                    >
                      <img
                        src={data?.sb_doc?.download_icon}
                        alt=""
                        className={`s-sbHeader__sb-download`}
                      />
                    </div>
                  </BlunoTooltip>
                </div>
              )}
            </div>
            <div className="s-sbHeader__bottom">
              <p
                className={`s-sbHeader__text3 ${
                  documentManagement && "s-sbHeader__text3--doc"
                }`}
                dangerouslySetInnerHTML={sanitizeHtml(data?.text3)}
              />

              {data?.bill_lodgement_number && (
                <div className="s-sbHeader__lodgement">
                  <p className="s-sbHeader__lodgement-text">
                    {data?.bill_lodgement_number?.key}
                  </p>
                  <div
                    onMouseEnter={() => {
                      setShowLodgementCopy(true);
                    }}
                    onMouseLeave={() => {
                      setShowLodgementCopy(false);
                    }}
                    className="s-sbHeader__lodgement-value"
                  >
                    <p>{data?.bill_lodgement_number?.value}</p>
                    {showLodgementCopy && (
                      <CopyToClipboard
                        text={data?.bill_lodgement_number?.value}
                        textPosition={"right"}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="s-sbHeader__summary">
            <div className="s-sbHeader__summary--left">
              {data?.summary?.left?.map((obj) => {
                return (
                  <div className="s-sbHeader__box-left">
                    <p className="s-sbHeader__box-text">{obj?.title}</p>
                    <h3
                      className="s-sbHeader__box-number"
                      dangerouslySetInnerHTML={sanitizeHtml(obj?.text)}
                    ></h3>
                  </div>
                );
              })}
            </div>
            <div className="s-sbHeader__summary--right">
              {data?.summary?.right?.map((obj) => {
                return (
                  <>
                    {obj?.type === "button" ? (
                      <div className="s-sbHeader__box-right">
                        <Button
                          buttonLabel={obj?.text}
                          buttonType={obj?.sub_type}
                          icon={obj.icon}
                          hoverIcon={obj?.hover_icon}
                          iconAlignment={obj?.icon_alignment}
                          tooltipText={obj?.tooltip_text}
                          tooltipPosition={{ bottom: "40px", left: "50px" }}
                          disabled={obj?.disabled}
                          onClick={(e) => {
                            e.stopPropagation();
                            onActionClick(null, obj);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="s-sbHeader__box-right">
                        <p className="s-sbHeader__box-text">{obj?.title}</p>
                        <h3
                          className="s-sbHeader__box-number"
                          style={obj?.color && { color: obj.color }}
                          dangerouslySetInnerHTML={sanitizeHtml(obj?.text)}
                        ></h3>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>

        {isDropdown && (
          <div className="s-sbHeader__dropdown" onClick={onDropdownClick}>
            <p>{!isSBDetailView ? "Show Details" : "Hide Details"}</p>
            <img
              src={dropUpIcon}
              alt="Dropdown Icon"
              className={`doc-summary__dropdown-icon ${
                !isSBDetailView ? "doc-summary__dropdown--rotate" : ""
              }`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SBHeader;
