// react imports
import React from "react";
import { useNavigate } from "react-router-dom";

// third-party imports

// in-app imports
import BlunoTable from "../../components/BlunoTable";
import BlunoPagination from "../../components/BlunoPagination";
import BlunoFilter from "../../components/BlunoFilter";

const AllBuyersTable = (props) => {
  // props variables
  const {
    data,
    pageNo = 1,
    setPageNo,
    onSortOrderClick,
    filterObj,
    handleInputChange,
    onClearFilter,
    handleDateRangeChange,
  } = props;

  // defined for react based apis
  const navigate = useNavigate();

  // other variables
  const configData = {
    headers: data?.headers,
    data: data?.data,
    default_view: data?.default_view,
  };

  const tableStyles = {
    height:
      data?.pagination?.total_pages > 1
        ? "calc(100vh - 590px)"
        : "calc(100vh - 540px)",
  };

  return (
    <div className="a-buyers__table-cont">
      {data?.filters && (
        <div className="a-buyers__table-filter">
          <BlunoFilter
            data={data?.filters}
            filterObj={filterObj}
            handleInputChange={handleInputChange}
            actionButton={data?.buttons}
            onClearFilter={onClearFilter}
            handleDateRangeChange={handleDateRangeChange}
          />
        </div>
      )}
      <div className="a-buyers__table" style={tableStyles}>
        <BlunoTable data={configData} onSortOrderClick={onSortOrderClick} />
      </div>
      {data?.pagination?.total_pages > 1 && (
        <div className="bills__pagination">
          <BlunoPagination
            pageNo={pageNo}
            setPageNo={setPageNo}
            totalPages={data?.pagination?.total_pages}
          />
        </div>
      )}
    </div>
  );
};

export default AllBuyersTable;
