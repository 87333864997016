// react imports
import React from "react";

// third-party imports

// in-app imports
import cross from "../../assets/media/common/cross.png";
import Button from "../Button";

const BuyerDetails = ({ data, closeModal }) => {
  return (
    <>
      <div className="buyer-details">
        <div className="buyer-details__header">
          <p className="buyer-details__header-left">{data?.header?.key}</p>
          <p className="buyer-details__header-middle">:</p>
          <p className="buyer-details__header-right">{data?.header?.value}</p>
        </div>
        <div className="buyer-details__content">
          <div className="buyer-details__address">
            <p className="buyer-details__address-left">
              {data?.details?.buyer_address?.key}
            </p>
            <p className="buyer-details__address-middle">:</p>
            <p className="buyer-details__address-right">
              {data?.details?.buyer_address?.value}
            </p>
          </div>

          <div className="buyer-details__contact-name">
            <p className="buyer-details__contact-name-left">
              {data?.details?.contact_name?.key}
            </p>
            <p className="buyer-details__contact-name-middle">:</p>
            <p className="buyer-details__contact-name-right">
              {data?.details?.contact_name?.value}
            </p>
          </div>
          <div className="buyer-details__email">
            <p className="buyer-details__email-left">
              {data?.details?.email_id?.key}
            </p>
            <p className="buyer-details__email-middle">:</p>
            <p className="buyer-details__email-right">
              {data?.details?.email_id?.value}
            </p>
          </div>
          <div className="buyer-details__contact-no">
            <p className="buyer-details__contact-no-left">
              {data?.details?.contact_no?.key}
            </p>
            <p className="buyer-details__contact-no-middle">:</p>
            <p className="buyer-details__contact-no-right">
              {data?.details?.contact_no?.icon && (
                <img src={data?.details?.contact_no?.flag_icon} alt="" />
              )}
              {data?.details?.contact_no?.value}
            </p>
          </div>
          <div className="buyer-details__business">
            <p className="buyer-details__business-left">
              {data?.details?.business_period?.key}
            </p>
            <p className="buyer-details__business-middle">:</p>
            {data?.details?.business_period?.value && (
              <p className="buyer-details__business-right">
                {data?.details?.business_period?.icon && (
                  <img src={data?.details?.business_period?.icon} alt="" />
                )}
                {data?.details?.business_period?.value}
              </p>
            )}
          </div>
        </div>
        <hr />
        {/* {data?.buttons && (
          <div className="buyer-details__action">
            {data?.buttons?.map((action) => {
              return (
                action?.type === "button" && (
                  <Button
                    buttonLabel={action?.text}
                    buttonType={action?.sub_type}
                    icon={action?.icon}
                    disabled={action?.disabled}
                    iconAlignment={action?.icon_alignment}
                    // onClick={() => onActionClick(data?.slug, action)}
                  />
                )
              );
            })}
          </div>
        )} */}
        <img
          src={cross}
          alt=""
          className="buyer-details__cross"
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
        />
      </div>
    </>
  );
};

export default BuyerDetails;
