// react imports
import React, { useEffect, useState } from "react";
import SBInvoiceCard from "./SBInvoiceCard";

//third-party imports

// in-app imports
import { sanitizeHtml } from "../../utils/sanitizeHtml";
import CopyToClipboard from "../CopyToClipboard";
import BlunoRadio from "../BlunoRadio";
import Dropdown from "../Dropdown";
import TextInput from "../TextInput";
import UploadFileCard from "../UploadFile/UploadFile";
import BlunoTooltip from "../BlunoTooltip";
import MuiTextInput from "../blunoInputs/MuiTextInputs";
import Button from "../Button";

const SBDetails = ({
  buttons,
  middleData,
  invoiceData,
  billLodgementData,
  isSBDetailView,
  onDocAction,
  uploadSingleFile,
  transportData,
  summaryErrorMessage,
  onActionClick,
}) => {
  const [currentHover, setCurrentHover] = useState(null);
  const [transportMetaDeta, setTransportMetadata] = useState(null);
  const [transportDoc, setTransportDoc] = useState();
  const [showLodgementCopy, setShowLodgementCopy] = useState(false);

  const handleInputChange = (theValue, theSlug) => {
    setTransportMetadata({
      ...transportMetaDeta,
      [theSlug]: theValue,
    });
    if (transportDoc) {
      onDocAction(
        "sb_docs",
        { action: "detach" },
        transportDoc?.id,
        transportDoc?.name
      );
    }
  };
  const isTransportDocDisabled = () => {
    if (
      !transportMetaDeta?.document_type ||
      (transportMetaDeta?.document_type === "others" &&
        !transportMetaDeta?.document_type_other)
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setTransportDoc(
      transportData?.data?.[0]?.document?.uploaded_documents?.[0]
    );
  }, [transportData]);
  useEffect(() => {
    setTransportMetadata({
      ...transportMetaDeta,
      document_type: transportData?.document_type?.default?.value,
      document_type_other: transportData?.document_type_other?.default_value,
    });
  }, []);
  return (
    <>
      <div
        className={`s-sbDetails ${
          !isSBDetailView ? "s-sbDetails--hidden" : null
        }`}
      >
        <div className="s-sbDetails__invoice s-sbDetails__invoice--blue">
          <section className="s-sbDetails__transport">
            <p className="s-sbDetails__tr-text">
              <span>{middleData?.source_text}</span>
              {middleData?.source}
            </p>
            <p className="s-sbDetails__tr-hr"></p>
            <BlunoTooltip
              text={middleData?.mode_icon?.hover_text}
              useChildren={true}
            >
              <p className="s-sbDetails__tr-icon">
                <img
                  src={middleData?.mode_icon?.icon}
                  alt={middleData?.mode_icon?.hover_text}
                />
                <span>{middleData?.mode_info}</span>
              </p>
            </BlunoTooltip>

            <img
              className="s-sbDetails__tr-img"
              src={middleData?.arrow_icon}
              alt="arrow right"
            />
            <p className="s-sbDetails__tr-text">
              <span>{middleData?.destination_text}</span>
              {middleData?.destination}
            </p>
          </section>
        </div>

        <div className="s-sbDetails__invoice">
          <div className="s-sbDetails__header">
            <div className="s-sbDetails__title">
              <p>{invoiceData?.title}</p>
              <span>{invoiceData?.total_invoices}</span>
            </div>
            <p
              className="s-sbDetails__ad-code"
              onMouseEnter={() => {
                setCurrentHover(invoiceData?.ad_code?.slug);
              }}
              onMouseLeave={() => {
                setCurrentHover(null);
              }}
            >
              <span>{invoiceData?.ad_code?.label}</span>
              {invoiceData?.ad_code?.icon && (
                <img src={invoiceData?.ad_code?.icon} alt="bank" />
              )}
              {invoiceData?.ad_code?.value}
              <div>
                {invoiceData?.ad_code?.enable_copy &&
                  currentHover === invoiceData?.ad_code?.slug && (
                    <CopyToClipboard
                      text={invoiceData?.ad_code?.value}
                      textPosition={"above"}
                    />
                  )}
              </div>
            </p>
          </div>
          <div className="s-sbDetails__invoice-list">
            {invoiceData?.invoices?.map((item) => {
              return (
                <SBInvoiceCard
                  data={item}
                  onDocAction={onDocAction}
                  uploadSingleFile={uploadSingleFile}
                  summaryErrorMessage={summaryErrorMessage}
                />
              );
            })}
          </div>
        </div>

        {transportData?.e_commerce_data && (
          <div className="s-sbDetails__ecom">
            <p>{transportData?.e_commerce_data?.status_field?.label}</p>
            <span>{transportData?.e_commerce_data?.status_field?.value}</span>
          </div>
        )}

        {transportData && (
          <div className="s-sbDetails__transport-docs">
            <h1 className="s-sbDetails__transport-docs-heading">
              {transportData?.title}
            </h1>
            <div className="s-sbDetails__transport-docs-parent">
              <div
                className={`s-sbDetails__transport-docs-fields ${
                  transportData?.e_commerce_data &&
                  "s-sbDetails__transport-docs-fields--border"
                }`}
              >
                <div className="s-sbDetails__transport-docs-container">
                  {transportData?.submitted_doc_text ? (
                    <div className="s-sbDetails__transport-docs-dropdown">
                      <div className="s-sbDetails__transport-doc-text">
                        <span>Document Type: </span>
                        <p> {transportData?.submitted_doc_text}</p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="s-sbDetails__transport-docs-dropdown">
                        <Dropdown
                          options={transportData?.document_type?.options}
                          defaultValue={transportMetaDeta?.document_type}
                          height="40px"
                          minWidth="225px"
                          fontSize="13px"
                          onSelectChange={(e) =>
                            handleInputChange(
                              e.value,
                              transportData?.document_type?.slug
                            )
                          }
                          isDisabled={transportData?.document_type?.disabled}
                          placeholder={
                            transportData?.document_type?.placeholder
                          }
                          placeholderFontSize="13px"
                          placeholderFontWeight="400"
                          fancyPlaceholder={true}
                          topSpacing="-5px"
                          iconWidth="10px"
                        />
                      </div>
                      {transportMetaDeta?.document_type === "others" && (
                        <div className="s-sbDetails__transport-docs-textfield">
                          <MuiTextInput
                            placeholder={
                              transportData?.document_type_other?.placeholder
                            }
                            type={"text"}
                            value={transportMetaDeta?.document_type_other}
                            onChange={(e) => {
                              handleInputChange(
                                e.target.value,
                                transportData?.document_type_other?.slug
                              );
                            }}
                            disabled={
                              transportData?.document_type_other?.disabled
                            }
                            height="40px"
                            minWidth="225px"
                            width={"225px"}
                            id={transportData?.document_type_other?.slug}
                            fontSize="13px"
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="s-sbDetails__transport-docs__upload">
                  <div className="s-sbDetails__transport-docs__upload-card">
                    <UploadFileCard
                      upload_enabled={
                        transportData?.data?.[0]?.document?.upload_enabled
                      }
                      documents={
                        transportData?.data?.[0]?.document?.uploaded_documents
                      }
                      doc_type={transportData?.data?.[0]?.document?.slug}
                      invoiceId={transportData?.data?.[0]?.id}
                      uploadSingleFile={(
                        selectedFiles,
                        doc_type,
                        invoiceId
                      ) => {
                        const metadata = {
                          document_type: transportMetaDeta?.document_type,
                          document_type_other:
                            transportMetaDeta?.document_type === "others"
                              ? transportMetaDeta?.document_type_other
                              : "",
                        };
                        uploadSingleFile(
                          selectedFiles,
                          doc_type,
                          invoiceId,
                          metadata
                        );
                      }}
                      section="sb_docs"
                      onDocAction={onDocAction}
                      disabled={isTransportDocDisabled()}
                      // padding={"8px 40.5px"}
                    />
                    <p className="sb-invoice__col-error">
                      {
                        summaryErrorMessage?.[
                          transportData?.data?.[0]?.document?.slug
                        ]
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {billLodgementData && (
          <div className="s-sbDetails__transport-docs">
            <div className="s-sbDetails__bill-lodgement-header">
              <div className="s-sbDetails__title">
                <p>Bill Lodgement</p>
                <div
                  onMouseEnter={() => {
                    setShowLodgementCopy(true);
                  }}
                  onMouseLeave={() => {
                    setShowLodgementCopy(false);
                  }}
                  className="s-sbHeader__lodgement-value"
                >
                  <p className="s-sbDetails__bill-lodgement-text">
                    {billLodgementData?.bill_lodgement_id}
                  </p>
                  {showLodgementCopy && (
                    <CopyToClipboard
                      text={billLodgementData?.bill_lodgement_id}
                      textPosition={"right"}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="s-sbDetails__transport-docs-parent">
              <div className="s-sbDetails__bill-lodgement">
                {billLodgementData?.bl_text_container?.map((obj) => {
                  return (
                    <div className="s-sbDetails__transport-doc-text">
                      <span>{`${obj?.key} : `} </span>
                      <p> {obj?.value}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <div className="s-sbDetails__lodgement-buttons">
          {buttons?.map((btn) => {
            return (
              <Button
                buttonLabel={btn?.text}
                buttonType={btn?.sub_type}
                disabled={btn?.disabled}
                onClick={() => onActionClick(null, btn)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SBDetails;
