import React, { useEffect, useRef, useState } from "react";
import ManageUsersCard from "../../modules/Settings/ManageUsersCard";
import { useDispatch } from "react-redux";
import ManageAccessCard from "../../modules/Settings/ManageAccessCard";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import { useLocation, useNavigate } from "react-router-dom";
import { MANAGE_USER_SLUGS } from "../../utils/constants/SettingsConstants";
export default function ManageUsers() {
  const [data, setData] = useState(undefined);
  const [tab, setTab] = useState();
  const changedUrl = useRef(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const getManageUsersData = async (slug) => {
    if (slug === MANAGE_USER_SLUGS.access && tab !== MANAGE_USER_SLUGS.access) {
      const apiRes = await dispatch(
        apiRequest(endpoints.getManageAccessSettings)
      );
      if (apiRes?.success && apiRes?.data) {
        setData(apiRes?.data);
        setTab(slug);
      } else {
        if (!changedUrl.current) {
          changedUrl.current = true;
          navigate("/settings/manage/users", { replace: true });
        }
      }
    } else if (
      slug === MANAGE_USER_SLUGS.users &&
      tab !== MANAGE_USER_SLUGS.users
    ) {
      const apiRes = await dispatch(
        apiRequest(endpoints.getManageGroupsSettings)
      );
      if (apiRes?.success && apiRes?.data) {
        setData(apiRes?.data);
        setTab(slug);
      } else {
        if (!changedUrl.current) {
          changedUrl.current = true;
          navigate("/settings/manage/access", { replace: true });
        }
      }
    }
  };

  useEffect(() => {
    getManageUsersData(
      MANAGE_USER_SLUGS[location.pathname.split("/").pop()] ??
        MANAGE_USER_SLUGS.users
    );
  }, [location.pathname]);

  return (
    <>
      {data && tab === MANAGE_USER_SLUGS.users ? (
        <ManageUsersCard
          getManageUsersData={getManageUsersData}
          data={data}
          setData={setData}
        />
      ) : (
        <ManageAccessCard getManageUsersData={getManageUsersData} data={data} />
      )}
    </>
  );
}
