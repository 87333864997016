// react imports
import React, { useEffect, useState } from "react";

// third-party imports

// in-app imports
import BlunoTable from "./BlunoTable";
import Button from "./Button";

import dropUpIcon from "../assets/media/exportBills/DropUp-Blue.png";

// utils
import { checkIfBalanceZero } from "../utils/Validation";
import BlunoTooltip from "./BlunoTooltip";

const MapIRMSummary = ({
  data,
  onActionClick,
  onEditSection,
  sbBalanceAmount,
  rowFormData,
  setRowFormData,
  showOtherIrms = false,
  setShowOtherIrms,
  isIrmAddedManually = false,
  setIsIrmAddedManually,
  irmFilter,
  setIrmFilter,
}) => {
  // local state variables
  const [open, setOpen] = useState();
  const [hoverEdit, setHoverEdit] = useState(false);

  useEffect(() => {
    if (data?.is_dropdown_open !== null) {
      setOpen(data?.is_dropdown_open);
    }
  }, [data?.is_dropdown_open]);

  const onRowFormDataChange = (value, row, slug) => {
    setRowFormData((prev) => ({
      ...prev,
      [row?.id]: {
        ...prev[row?.id],
        [slug]: { ...prev[row?.id][slug], value: value },
      },
    }));
  };

  return (
    <>
      <div className={`mapirm-summary`}>
        <div
          className="mapirm-summary__container"
          onClick={() => setOpen(!open)}
        >
          <div className="mapirm-summary__title">
            {data?.status_icon && (
              <img
                src={data?.status_icon}
                alt="step-done"
                className="mapirm-summary__title-stepdone"
              />
            )}
            <h2 className="mapirm-summary__title-title">{data?.title}</h2>
            {data?.is_editable && data?.edit_info && (
              <BlunoTooltip
                useChildren={true}
                text={data?.edit_info?.tooltip_text}
              >
                <div
                  className="s-sbHeader__sb-download-background"
                  onClick={(e) => {
                    e.stopPropagation();

                    onEditSection(
                      data?.slug,
                      data?.edit_info?.action,
                      data?.edit_info?.alert_id
                    );
                  }}
                  onMouseEnter={() => {
                    setHoverEdit(true);
                  }}
                  onMouseLeave={() => {
                    setHoverEdit(false);
                  }}
                >
                  <img
                    src={data?.edit_info?.icon}
                    alt={data?.edit_info?.action}
                    className={`mapirm-summary__title-edit ${
                      hoverEdit ? "mapirm-summary__title-edit--hover" : ""
                    }`}
                  />
                </div>
              </BlunoTooltip>
            )}
          </div>
          {data?.is_dropdown && (
            <div
              className="mapirm-summary__dropdown"
              onClick={() => setOpen(!open)}
            >
              <p className="mapirm-summary__dropdown-text">
                {open ? "Hide Details" : "Show Details"}
              </p>
              <img
                src={dropUpIcon}
                alt="Dropdown Icon"
                className={`mapirm-summary__dropdown-icon ${
                  !open ? "mapirm-summary__dropdown--rotate" : ""
                }`}
              />
            </div>
          )}
        </div>
        {open && (
          <div className="mapirm-summary__wrapper">
            {data?.empty_state ? (
              <div className="mapirm-summary__emptylist">
                <div className="mapirm-summary__emptylist-header">
                  {data?.buttons?.map((btn) => {
                    return (
                      <Button
                        buttonLabel={btn?.text}
                        buttonType={btn?.sub_type}
                        icon={btn?.icon}
                        hoverIcon={btn?.hover_icon}
                        iconAlignment={btn?.icon_alignment}
                        onClick={() => {
                          setShowOtherIrms(true);
                        }}
                      />
                    );
                  })}
                </div>
                <div className="mapirm-summary__emptylist-content">
                  {data?.empty_state?.icon && (
                    <img
                      src={data?.empty_state?.icon}
                      alt="No IRM Available"
                      className="mapirm-summary__emptylist-icon"
                    />
                  )}
                  <p className="mapirm-summary__emptylist-text">
                    {data?.empty_state?.text}
                  </p>
                </div>
                {data?.empty_state_buttons && (
                  <div className="mapirm-summary__submit">
                    {data?.empty_state_buttons?.map((action) => {
                      return (
                        action?.type === "button" && (
                          <Button
                            buttonLabel={action?.text}
                            buttonType={action?.sub_type}
                            icon={action.icon}
                            hoverIcon={action?.hover_icon}
                            iconAlignment={action?.icon_alignment}
                            tooltipText={action?.tooltip_text}
                            tooltipPosition={{ bottom: "40px", left: "50px" }}
                            disabled={action?.disabled}
                            onClick={() => onActionClick(data?.slug, action)}
                          />
                        )
                      );
                    })}
                  </div>
                )}
              </div>
            ) : (
              <div className="mapirm-summary__content">
                <div className="mapirm-summary__info">
                  <div className="mapirm-summary__info-header">
                    <div className="mapirm-summary__info-header--left">
                      {data?.irms?.sub_title2 && (
                        <>
                          <div className="mapirm-summary__info-text">
                            {data?.irms?.sub_title2?.icon && (
                              <img
                                src={data?.irms?.sub_title2?.icon}
                                alt={data?.irms?.sub_title2?.icon}
                              />
                            )}
                            <p
                              className={`${
                                data?.irms?.sub_title2?.type === "hyperlink"
                                  ? "mapirm-summary__info-text--link"
                                  : ""
                              }`}
                              onClick={() =>
                                data?.irms?.sub_title2?.type === "hyperlink" &&
                                onActionClick(
                                  data?.slug,
                                  data?.irms?.sub_title2
                                )
                              }
                            >
                              {data?.irms?.sub_title2?.text}
                            </p>
                          </div>
                        </>
                      )}
                      {(data?.irms?.sub_title1 || data?.irms?.sub_title2) && (
                        <p className="mapirm-summary__info-partition">|</p>
                      )}

                      {data?.irms?.sub_title1 && (
                        <h2 className="mapirm-summary__info-title">
                          {data?.irms?.sub_title1}
                        </h2>
                      )}

                      <div className="mapirm-summary__info-tabs">
                        {data?.irms?.filters?.map((filter) => {
                          return (
                            <p
                              className={`mapirm-summary__info-tab ${
                                filter?.slug === irmFilter &&
                                "mapirm-summary__info-tab--active"
                              }`}
                              onClick={() => {
                                onActionClick(
                                  "map_irms",
                                  {
                                    slug: "irm_filter_change",
                                    irm_filter: filter?.slug,
                                  },
                                  null,
                                  null
                                );
                                setIrmFilter(filter?.slug);
                              }}
                            >
                              {filter?.text}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                    <div className="mapirm-summary__info-header--right">
                      {data?.irms?.buttons?.map((btn) => {
                        return (
                          <Button
                            buttonLabel={btn?.text}
                            buttonType={btn?.sub_type}
                            icon={btn?.icon}
                            hoverIcon={btn?.hover_icon}
                            iconAlignment={btn?.icon_alignment}
                            onClick={() => {
                              setShowOtherIrms(true);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <BlunoTable
                    data={data?.irms}
                    onTableRowSelection={(id, section, slug, row) =>
                      onActionClick(section, slug, row)
                    }
                    rowFormData={rowFormData}
                    onRowFormChange={onRowFormDataChange}
                    // onActionClick={onActionClick}
                    section={data?.slug}
                    metadata={{
                      sb_balance_amount: checkIfBalanceZero(sbBalanceAmount),
                    }}
                    showHoverBlueRow={false}
                    genericState={isIrmAddedManually}
                    setGenericState={setIsIrmAddedManually}
                  />
                </div>
                {data?.buttons && (
                  <div className="mapirm-summary__submit">
                    {data?.buttons?.map((action) => {
                      return (
                        action?.type === "button" && (
                          <Button
                            buttonLabel={action?.text}
                            buttonType={action?.sub_type}
                            // icon={action?.icon}
                            disabled={action?.disabled}
                            iconAlignment={action?.icon_alignment}
                            onClick={() => onActionClick(data?.slug, action)}
                          />
                        )
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default MapIRMSummary;
