export const InputError = {
  // Onboarding - Enter Phone
  EmptyPhoneNumber: {
    type: "error",
    message: "Mobile number is required",
    icon: "",
  },
  IncorrectMobileNumber: {
    type: "error",
    message: "Enter correct mobile number",
    icon: "",
  },
  FullMobileNumber: {
    type: "error",
    message: "You have already entered 10 digits",
    icon: "",
  },
  // Onboarding - OTP
  EmptyOTP: {
    type: "error",
    message: "OTP is required",
    icon: "",
  },
  IncorrectOTP: {
    type: "error",
    message: "Enter correct OTP",
    icon: "",
  },
  InvalidOTPInput: {
    type: "error",
    message: "OTP must contain only digits",
    icon: "",
  },
  OtpTimerExpire: {
    type: "expire",
    message: "OTP has expired. Please request a new one",
    icon: "",
  },
  IncorrectOTPAttempt: {
    type: "error",
    message: `Incorrect OTP, you have %s attempts left`,
    icon: "",
  },
  ResendOTP: {
    type: "error",
    message: "You have %s attempts left",
    icon: "",
  },
  TooManyAttemptsOTP: {
    type: "error",
    message: "Too many wrong attempts. Try after some time.",
    icon: "",
  },

  RequiredField: {
    type: "error",
    message: "This field is required.",
    icon: "",
  },
  SelectDirector: {
    type: "error",
    message: "Select the Director Name",
    icon: "",
  },
  SelectCheckbox: {
    type: "error",
    message: "Confirmation is required",
    icon: "",
  },

  // Onboarding - name
  EmptyName: {
    type: "error",
    message: "Name is required",
    icon: "",
  },
  EmptyAuthorizedName: {
    type: "error",
    message: "Authorised signatory name is required",
    icon: "",
  },
  IncorrectName: {
    type: "error",
    message: "Enter correct name",
    icon: "",
  },
  ESignNameMismatch: {
    type: "error",
    message:
      "Authorized signatory name does not match the name on the Aadhaar card. Please update the name as per Aadhaar card.",
    icon: "",
  },

  // Onboarding - Date
  EmptyDOB: {
    type: "error",
    message: "Date of birth is required",
    icon: "",
  },
  YoungAge: {
    type: "error",
    message: "You must be at least 18 years old",
    icon: "",
  },

  // Onboarding - Email
  EmptyEmail: {
    type: "error",
    message: "Email address is required",
    icon: "",
  },
  InvalidEmailFormat: {
    type: "error",
    message: "Invalid Email address format",
    icon: "",
  },

  // Onboarding - Password
  EmptyPassword: {
    type: "error",
    message: "Password cannot be empty",
    icon: "",
  },
  EmptyCurrentPassword: {
    type: "error",
    message: "Current password cannot be empty",
    icon: "",
  },
  EmptyNewPassword: {
    type: "error",
    message: "New password cannot be empty",
    icon: "",
  },
  EmptyReEnteredNewPassword: {
    type: "error",
    message: "Passwords do not match",
    icon: "",
  },
  PasswordMismatch: {
    type: "error",
    message: "Passwords do not match",
    icon: "",
  },
  ShortPassword: {
    type: "error",
    message:
      "Password is too weak. It must contain between 8 to 20 characters, an uppercase letter, a lowercase letter, a special character, and a digit.",
    icon: "",
  },
  PasswordUpperCase: {
    type: "error",
    message:
      "Password is too weak. It must contain between 8 to 20 characters, an uppercase letter, a lowercase letter, a special character, and a digit.",
    icon: "",
  },
  PasswordLowerCase: {
    type: "error",
    message:
      "Password is too weak. It must contain between 8 to 20 characters, an uppercase letter, a lowercase letter, a special character, and a digit.",
    icon: "",
  },
  PasswordNumbericValue: {
    type: "error",
    message:
      "Password is too weak. It must contain between 8 to 20 characters, an uppercase letter, a lowercase letter, a special character, and a digit.",
    icon: "",
  },
  PasswordSpecialCharacter: {
    type: "error",
    message:
      "Password is too weak. It must contain between 8 to 20 characters, an uppercase letter, a lowercase letter, a special character, and a digit.",
    icon: "",
  },
  PasswordContainsSpace: {
    type: "error",
    message: "Invalid Password, password cannot have white space",
    icon: "",
  },
  AllInOnePasswordError: {
    type: "error",
    message:
      "Password is too weak. It must contain between 8 to 20 characters, an uppercase letter, a lowercase letter, a special character, and a digit.",
    icon: "",
  },
  TooLongPassword: {
    type: "error",
    message: "Password can't exceed 20 characters.",
    icon: "",
  },
  RequiredField: {
    type: "error",
    message: "This field is required.",
  },

  // Onboarding-Task-1
  EmptyCompanyName: {
    type: "error",
    message: "Company Name is required",
    icon: "",
  },
  TooLongCompanyName: {
    type: "error",
    message: "Company name can't exceed 80 characters.",
    icon: "",
  },
  InvalidCharacter: {
    type: "error",
    message: "Invalid character entered",
    icon: "",
  },
  EmptyIEC: {
    type: "error",
    message: "IE code is required",
    icon: "",
  },
  InvalidIECLength: {
    type: "error",
    message: "IE code must be of 10 digits",
    icon: "",
  },

  InvalidPANLength: {
    type: "error",
    message: "PAN must contain 10 alphanumeric characters",
    icon: "",
  },
  InvalidIECFormat: {
    type: "error",
    message: "Enter correct IE code",
    icon: "",
  },
  EmptyPan: {
    type: "error",
    message: "PAN is required",
    icon: "",
  },
  IncorrectPAN: {
    type: "error",
    message: "Invalid PAN format",
    icon: "",
  },

  // Onboarding-Task-2
  EmptyIFSC: {
    type: "error",
    message: "IFSC code is required",
    icon: "",
  },
  IncorrectIFSC: {
    type: "error",
    message: "Enter correct IFSC code",
    icon: "",
  },
  InvalidIFSCCodeFormat: {
    type: "error",
    message: "Invalid IFSC code format",
    icon: "",
  },
  TooLongIFSC: {
    type: "error",
    message: "IFSC must contain 11 alphanumeric characters",
    icon: "",
  },
  InvalidAccountNumber: {
    type: "error",
    message: "Enter correct account number",
    icon: "",
  },
  EmptyAccountNo: {
    type: "error",
    message: "Account number is required",
    icon: "",
  },
  AccountNumberMismatch: {
    type: "error",
    message: "Account numbers do not match",
    icon: "",
  },
  InvalidAccountNumberLength: {
    type: "error",
    message: "Account number cannot have more than 20 characters",
    icon: "",
  },
  EmptyCustomerId: {
    type: "error",
    message: "Customer Id is required",
    icon: "",
  },
  InvalidCustomerId: {
    type: "error",
    message: "Enter correct Customer Id",
    icon: "",
  },
  TooLongCustomerId: {
    type: "error",
    message: "Customer Id cannot have more than 40 characters",
    icon: "",
  },
  InvalidBankSelection: {
    type: "error",
    message: "Bank name is required",
    icon: "",
  },

  // Onboarding-Task-3
  CheckTermsConditions: {
    type: "error",
    message: "Please accept terms and condition",
    icon: "",
  },

  IncorrectLengthPincode: {
    type: "error",
    message: "You have already entered 6 digits",
    icon: "",
  },

  InvalidCharacterInput: {
    type: "error",
    message: "Invalid character",
    icon: "",
  },

  LargeImageSize: {
    type: "error",
    message: "Maximum image size allowed is ",
    icon: "",
  },
  AllowedExtensions: {
    type: "error",
    message: "Allowed formats: ",
    icon: "",
  },
};
