// react imports
import React, { useEffect, useState } from "react";

// third-party imports

// in-app imports
import BlunoTable from "../BlunoTable";
import Button from "../Button";

import cross from "../../assets/media/common/cross.png";
import { checkIfAllValuesFalse } from "../../utils";

const RemindAlert = ({
  data,
  alertName,
  onGetRemindClick,
  sendReminder,
  closeModal,
}) => {
  // local states
  const [rowSelectionState, setRowSelectionState] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  const onSortOrderClick = (sortCategory, sortOrder) => {
    const order = sortOrder ? (sortOrder === "asc" ? "desc" : "asc") : "desc";
    onGetRemindClick(sortCategory, order, alertName);
  };

  const onTableRowSelection = (rowId) => {
    setRowSelectionState((prev) => ({
      ...prev,
      [rowId]: prev[rowId] ? undefined : true,
    }));
  };

  const onAllTableRowSelection = (prevState, allRow) => {
    let allCheckedState = allRow.reduce((acc, obj) => {
      acc[obj.id] = true;
      return acc;
    }, {});
    if (prevState) {
      if (checkIfAllValuesFalse(prevState)) {
        setRowSelectionState(allCheckedState);
      } else {
        setRowSelectionState({});
      }
    } else {
      setRowSelectionState(allCheckedState);
    }
  };

  useEffect(() => {
    const selectedData = data?.remind_data?.data
      ?.filter((row) => rowSelectionState?.[row?.id])
      .map((item) => item?.id);
    setSelectedRows(selectedData);
  }, [rowSelectionState]);

  useEffect(() => {
    setRowSelectionState({});
    setSelectedRows([]);
  }, [data]);

  return (
    <>
      <div className="remind-alert">
        <img
          src={cross}
          alt=""
          className="remind-alert__cross"
          onClick={closeModal}
        />
        <div className="remind-alert__table">
          <BlunoTable
            data={data?.remind_data}
            onSortOrderClick={onSortOrderClick}
            onTableRowSelection={onTableRowSelection}
            rowSelectionState={rowSelectionState}
            onAllTableRowSelection={onAllTableRowSelection}
          />
        </div>
        {data?.buttons && (
          <div className="remind-alert__action">
            {data?.buttons?.map((action) => {
              return (
                action?.type === "button" && (
                  <Button
                    buttonLabel={action?.text}
                    buttonType={action?.sub_type}
                    icon={action?.icon}
                    disabled={action?.disabled || selectedRows.length === 0}
                    iconAlignment={action?.icon_alignment}
                    onClick={() =>
                      sendReminder({
                        alertName: alertName,
                        idList: selectedRows,
                      })
                    }
                  />
                )
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default RemindAlert;
