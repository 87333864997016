import React, { useEffect, useRef, useState } from "react";
import BlunoTable from "../../components/BlunoTable";
import Button from "../../components/Button";
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import useCallbackPrompt from "../../hooks/useCallBackPrompt";
import {
  MANAGE_USER_SLUGS,
  SETTING_STATE,
} from "../../utils/constants/SettingsConstants";
import { useNavigate } from "react-router-dom";

export default function ManageAccessCard({ getManageUsersData, data }) {
  const [internalData, setInternalData] = useState(data);
  const orignalRowData = useRef({});
  const [rowData, setRowData] = useState({});
  const [changed, setChanged] = useState(false);
  const [blockerAlertData, setBlockerAlertData] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useCallbackPrompt(changed, blockerAlertData); // Navigation blocker hook

  const onButtonClick = async (slug) => {
    if (slug === SETTING_STATE.EDIT_STATE) {
      const apiRes = await dispatch(
        apiRequest(endpoints.saveManageAccess, rowData)
      );
      if (apiRes?.success && apiRes?.data) {
        setChanged(false);
        setInternalData(apiRes?.data);
      }
    } else {
      const apiRes = await dispatch(apiRequest(endpoints.editManageAccess));
      if (apiRes?.success && apiRes?.data) {
        setInternalData(apiRes?.data);
        orignalRowData.current = JSON.parse(
          JSON.stringify(apiRes?.data?.roles)
        );
        setRowData(apiRes?.data?.roles);
        setBlockerAlertData({
          items: apiRes?.data?.blocker_popup,
          props: ["blocker"],
        });
        setChanged(false);
      }
    }
  };

  const onSwitchChange = (e, id, slug) => {
    setRowData((prevRowData) => {
      const copyPrevRowData = { ...prevRowData };
      const changedRoleHierarchy = copyPrevRowData?.[slug]?.hierarchy;
      if (e.target.checked) {
        for (let key in copyPrevRowData) {
          if (copyPrevRowData[key]?.hierarchy <= changedRoleHierarchy) {
            copyPrevRowData[key].features[id] = true;
          }
        }
      } else {
        for (let key in copyPrevRowData) {
          if (copyPrevRowData[key]?.hierarchy >= changedRoleHierarchy) {
            copyPrevRowData[key].features[id] = false;
          }
        }
      }
      setInternalData((prev) => {
        const copyPrev = { ...prev };
        for (let key in copyPrevRowData) {
          copyPrev?.table?.data?.forEach((item) => {
            item[copyPrevRowData[key].role].default_value =
              copyPrevRowData[key].features[item.id];
          });
        }
        return copyPrev;
      });
      return copyPrevRowData;
    });
  };

  useEffect(() => {
    setInternalData(data);
    if (data?.roles) {
      orignalRowData.current = JSON.parse(JSON.stringify(data?.roles));
      setRowData(data?.roles);
    }
  }, [data]);

  useEffect(() => {
    if (JSON.stringify(rowData) !== JSON.stringify(orignalRowData.current))
      setChanged(true);
    else setChanged(false);
  }, [rowData]);

  return (
    <div className="manage-settings">
      <div className={`manage-settings__header `}>
        <div className="manage-settings__header__tab-container">
          {internalData?.header?.tabs?.map((item, idx) => {
            return (
              <p
                key={idx}
                className={`manage-settings__header__tab ${
                  !item?.disabled
                    ? "manage-settings__header__tab--active"
                    : "manage-settings__header__tab--inactive"
                }`}
                onClick={(e) => {
                  navigate(item?.deeplink);
                }}
              >
                {item?.text}
              </p>
            );
          })}
        </div>
        <div className="manage-settings__header-add">
          {internalData?.header?.buttons?.map((item, idx) => {
            return (
              <Button
                key={idx}
                icon={item?.icon}
                buttonType={item?.sub_type}
                buttonLabel={item?.text}
                iconAlignment={item?.icon_alignment}
                onClick={(e) => {
                  onButtonClick(item?.slug);
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="manage-settings__body manage-settings__body--access">
        <BlunoTable
          data={internalData?.table}
          onSwitchChange={onSwitchChange}
          dataHeight="100%"
          showHoverBlueRow={false}
        />
      </div>
    </div>
  );
}
