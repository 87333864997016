// react imports
import React, { useEffect, useRef, useState } from "react";

// redux imports
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../redux/common/actions";
import endpoints from "../api/endpoints";
import {
  updateNotificationObject,
  updateShowBrForm,
} from "../redux/common/slice";

// third party imports

// in-app imports
import chevronDownIcon from "../assets/media/header/chevron-down-3x.png";
import chevronUpIcon from "../assets/media/header/chevron-up-3x.png";
import LogoutIcon from "../assets/svg/LogoutIcon";
import ProfileIcon from "../assets/svg/ProfileIcon";
import BankIcon from "../assets/svg/BankIcon";
import { HOME_PAGE_URL } from "../app/constants/common";
import ProfileDetails from "../assets/svg/ProfileDetails";
import UploadDocIcon from "../assets/svg/UploadDocIcon";
import Avatar from "react-avatar";

const MenuItem = ({ item, setShowDropDown }) => {
  const [color, setColor] = useState(
    item?.slug === "pending_documents" ? "#e01e5a" : "#000000"
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = async (theItem, theSlug) => {
    switch (theSlug) {
      case "sign_out":
        const logout = await dispatch(apiRequest(endpoints.logout));
        if (logout?.success) {
          localStorage.clear();
          navigate("/onboarding/login");
        }
        break;
      case "pending_documents":
        const notificationObjectTemp1 = {
          showNotificationBanner: false,
        };
        dispatch(updateShowBrForm(true));
        dispatch(updateNotificationObject(notificationObjectTemp1));
        break;
      default:
        navigate(theItem?.deeplink);
        break;
    }

    setShowDropDown(false);
  };

  const renderIcon = (theSlug) => {
    switch (theSlug) {
      case "sign_out":
        return <LogoutIcon color={color} />;
      case "bank_details":
        return <BankIcon color={color} />;
      case "profile_details":
        return <ProfileDetails color={color} />;
      case "pending_documents":
        return <UploadDocIcon color={color} />;
    }
  };

  return (
    <div
      className={`profile__dropdown-item profile__dropdown-item--${item?.slug}`}
      onClick={() => handleClick(item, item?.slug)}
      onMouseEnter={() => {
        if (item?.slug !== "pending_documents") setColor("#046CB8");
      }}
      onMouseLeave={() => {
        if (item?.slug !== "pending_documents") setColor("#000000");
      }}
    >
      {renderIcon(item?.slug)}
      <p
        className={`profile__dropdown-item-text ${
          item?.slug === "pending_documents"
            ? "profile__dropdown-item-text--pending"
            : ""
        }`}
        style={{
          color: color,
        }}
      >
        {item?.text}
      </p>
    </div>
  );
};

const Profile = ({ user }) => {
  //local states
  const [showDropDown, setShowDropDown] = useState(false);
  const profileDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileDropdownRef?.current &&
        !profileDropdownRef?.current?.contains(event.target)
      ) {
        setShowDropDown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileDropdownRef]);

  return (
    <div className="profile__container" ref={profileDropdownRef}>
      <div className="profile" onClick={() => setShowDropDown(!showDropDown)}>
        <div className="profile__img">
          {user?.profile_icon ? (
            <img src={user?.profile_icon} />
          ) : (
            user?.full_name && (
              <Avatar
                name={user?.full_name}
                maxInitials="1"
                size="32px"
                round={true}
                color={"#D6ECFF"}
                fgColor="#000000"
                title={false}
                textSizeRatio={2}
              />
            )
          )}
        </div>
        <div className="profile__right">
          <div className={`profile__text${showDropDown ? "--blue" : ""}`}>
            {user?.full_name}
          </div>
          <div className="profile__dropdown-icon">
            <img src={showDropDown ? chevronUpIcon : chevronDownIcon} />
          </div>
        </div>
      </div>
      {showDropDown && (
        <div className="profile__dropdown">
          {user?.profile_menu?.map((item) => {
            return (
              <MenuItem
                key={item?.slug}
                item={item}
                setShowDropDown={setShowDropDown}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Profile;
