// react imports
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// redux imports
import { useDispatch } from "react-redux";
import { apiRequest } from "../redux/common/actions";
import endpoints from "../api/endpoints";

// third-party imports

// in-app imports
import IrmSummary from "../modules/SingleSB/IrmSummary";
import BlunoTable from "../components/BlunoTable";

const IRMDetailsPage = () => {
  // defined to access react based APIs
  const dispatch = useDispatch();
  const { irmId } = useParams();

  // local state variables
  const [irmData, setIrmData] = useState(null);

  // other variables

  useEffect(() => {
    const getReconciliationData = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.getIRMData, `/${irmId}`)
      );
      if (dataRes?.success && dataRes?.data) {
        setIrmData(dataRes?.data);
      }
    };
    getReconciliationData();
  }, [irmId]);

  return (
    <>
      <div className="single-irm">
        <IrmSummary data={irmData} />
      </div>
    </>
  );
};

export default IRMDetailsPage;
