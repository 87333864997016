import React from "react";

const SortArrowIcon = ({ topColor = "#888888", bottomColor = "#888888" }) => {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.14286 0.5L5.86465 5.21429H0.421063L3.14286 0.5Z"
        fill={topColor}
      />
      <path
        d="M3.14621 11.5L0.424412 6.78571L5.868 6.78571L3.14621 11.5Z"
        fill={bottomColor}
      />
    </svg>
  );
};

export default SortArrowIcon;
