//react imports
import React, { useEffect, useState } from "react";

//third party imports
import { v4 as uuidv4 } from "uuid";
import Cropper from "react-easy-crop";

//in app imports
import Button from "../Button";
import { getCroppedImg } from "../../utils/fileConversionHelper";
import { formatFileSize } from "../../utils/formatFileSize";
import { InputError } from "../../app/constants/InputError";

const ImageUploader = ({
  avatar = "",
  data,
  saveAvatar,
  removeAvatar,
  setShowModal,
  screen = "",
  setScreen,
}) => {
  const avatar_id = uuidv4();

  const [avatarImg, setAvatarImg] = useState("");

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedBase64Data, setCroppedBase64Data] = useState(null);
  const [croppedFile, setCroppedFile] = useState(null);
  const [croppedFileName, setCroppedFileName] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCroppedImage = async () => {
    try {
      const croppedObj = await getCroppedImg(
        avatarImg,
        croppedAreaPixels,
        rotation,
        croppedFileName
      );
      setCroppedFile(croppedObj?.file);
      setCroppedBase64Data(croppedObj?.base64Data);
      setScreen(data?.save_screen?.screen_slug);
    } catch (e) {
      console.error(e);
    }
  };

  const handleRotateAvatar = () => {
    setRotation((prevRotation) => (prevRotation + 90) % 360);
  };

  const handleUploadClick = () => {
    document.getElementById(avatar_id).click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file.size > data?.max_size_in_KB * 1024) {
      setErrorMessage(
        InputError?.LargeImageSize?.message +
          formatFileSize(data?.max_size_in_KB * 1024)
      );
      return;
    }
    if (
      data?.accepted_extensions?.split(",").filter((element) => {
        return element.includes(file?.name?.split(".").pop());
      }).length <= 0
    ) {
      setErrorMessage(
        InputError?.AllowedExtensions?.message +
          data?.accepted_extensions +
          " only."
      );
      return;
    }
    const imageUrl = URL.createObjectURL(file);
    setAvatarImg(imageUrl);
    setCroppedFileName(file?.name);
    setScreen(data?.crop_screen?.screen_slug);
  };

  const handleButtonClick = (theSlug) => {
    switch (theSlug) {
      case "upload_avatar":
        handleUploadClick();
        break;
      case "remove_avatar":
        removeAvatar();
        break;
      case "update_avatar":
        handleCroppedImage();
        break;
      case "rotate_avatar":
        handleRotateAvatar();
        break;
      case "save_avatar":
        saveAvatar(croppedFile, croppedBase64Data);
        break;
      case "cancel_avatar":
        setShowModal(false);
        setScreen("update");
        break;
    }
  };

  useEffect(() => {
    setScreen(
      data?.icon ||
        (avatar &&
          avatar !==
            "https://static.bluno.in/miscl/fallback-profile-picture.png")
        ? data?.update_screen?.screen_slug
        : data?.add_screen?.screen_slug
    );
  }, []);

  return (
    <>
      <div className="s-img-uploader">
        {screen === "add" && (
          <div className="s-img-uploader__update">
            <div className="s-img-uploader__avatar-cont">
              <img
                className="s-img-uploader__avatar"
                src={
                  avatar
                    ? avatar
                    : data?.fallback_icon
                    ? data?.fallback_icon
                    : ""
                }
                alt="avatar"
              />
              {data?.is_editable && (
                <div className="s-img-uploader__upload">
                  <input
                    type="file"
                    id={avatar_id}
                    name="avatar_to_upload"
                    accept={data?.accepted_extensions}
                    className="s-img-uploader__input"
                    onChange={handleImageChange}
                  />
                  {data?.size_text && (
                    <p className="s-img-uploader__size">
                      {data?.add_screen?.size_text}
                    </p>
                  )}
                  {data?.dimension_text && (
                    <p className="s-img-uploader__action">
                      {data?.add_screen?.dimension_text}
                    </p>
                  )}
                </div>
              )}
            </div>
            <p className="s-img-uploader__text">
              {data?.add_screen?.upload_text}
            </p>
            <p className="s-img-uploader__text-error">{errorMessage}</p>
            <div className="s-img-uploader__buttons">
              {data?.add_screen?.buttons?.map((btn) => {
                return (
                  <Button
                    buttonLabel={btn?.text}
                    buttonType={btn?.sub_type}
                    onClick={() => {
                      handleButtonClick(btn?.slug);
                    }}
                  />
                );
              })}
            </div>
          </div>
        )}
        {screen === "update" && (
          <div className="s-img-uploader__update">
            <div className="s-img-uploader__avatar-cont">
              <img
                className="s-img-uploader__avatar"
                src={
                  avatar
                    ? avatar
                    : data?.fallback_icon
                    ? data?.fallback_icon
                    : ""
                }
                alt="avatar"
              />
              {data?.is_editable && (
                <div className="s-img-uploader__upload">
                  <input
                    type="file"
                    id={avatar_id}
                    name="avatar_to_upload"
                    accept={data?.accepted_extensions}
                    className="s-img-uploader__input"
                    onChange={handleImageChange}
                  />
                  {data?.size_text && (
                    <p className="s-img-uploader__size">
                      {data?.update_screen?.size_text}
                    </p>
                  )}
                  {data?.dimension_text && (
                    <p className="s-img-uploader__action">
                      {data?.update_screen?.dimension_text}
                    </p>
                  )}
                </div>
              )}
            </div>
            <p className="s-img-uploader__text">
              {data?.update_screen?.upload_text}
            </p>
            <p className="s-img-uploader__text-error">{errorMessage}</p>
            <div className="s-img-uploader__buttons">
              {data?.update_screen?.buttons?.map((btn) => {
                return (
                  <Button
                    buttonLabel={btn?.text}
                    buttonType={btn?.sub_type}
                    onClick={() => {
                      handleButtonClick(btn?.slug);
                    }}
                  />
                );
              })}
            </div>
          </div>
        )}
        {screen === "crop" && (
          <div className="s-img-uploader__crop">
            <div className="s-img-uploader__avatar-cont">
              <Cropper
                image={avatarImg || avatar}
                aspect={1}
                cropShape="round"
                cropSize={{ width: 180, height: 180 }}
                objectFit="cover"
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                rotation={rotation}
              />
            </div>
            <div className="s-img-uploader__actions">
              {data?.crop_screen?.buttons?.map((btn) => {
                return (
                  <Button
                    buttonLabel={btn?.text}
                    buttonType={btn?.sub_type}
                    onClick={() => {
                      handleButtonClick(btn?.slug);
                    }}
                    icon={btn?.icon}
                    iconAlignment={btn?.icon_alignment}
                  />
                );
              })}
            </div>
          </div>
        )}
        {screen === "save" && (
          <div className="s-img-uploader__save">
            <div className="s-img-uploader__avatar-cont">
              <img
                className="s-img-uploader__avatar"
                src={croppedBase64Data}
                alt="avatar"
              />
            </div>
            <p className="s-img-uploader__text">
              {data?.save_screen?.upload_text}
            </p>
            <div className="s-img-uploader__buttons">
              {data?.save_screen?.buttons?.map((btn) => {
                return (
                  <Button
                    buttonLabel={btn?.text}
                    buttonType={btn?.sub_type}
                    onClick={() => {
                      handleButtonClick(btn?.slug);
                    }}
                  />
                );
              })}
            </div>
          </div>
        )}
        <img
          className="s-img-uploader__close"
          src="https://static.bluno.in/miscl/cross-thin.png"
          alt="close"
          onClick={() => {
            setShowModal(false);
            setScreen("update");
          }}
        />
      </div>
    </>
  );
};

export default ImageUploader;
