import React from "react";
import { sanitizeHtml } from "../../utils/sanitizeHtml";

const BlunoKycBanner = ({ data }) => {
  return (
    <div className="kyc-banner">
      <div className="kyc-banner__left">
        <img
          className="kyc-banner__card"
          src={data?.card_icon}
          alt="kyc-card"
        />
        <div className="kyc-banner__content">
          <h2
            className="kyc-banner__title"
            dangerouslySetInnerHTML={sanitizeHtml(data?.title)}
          />
          <p className="kyc-banner__text">{data?.text}</p>
        </div>
      </div>
      <div className="kyc-banner__right">
        <img
          className="kyc-banner__balloon"
          src={data?.balloon_icon}
          alt="kyc-balloon"
        />
      </div>
    </div>
  );
};

export default BlunoKycBanner;
