//rect imports
import React, { useEffect, useState } from "react";

//in app imports
import CheckboxGroup from "./DynamicForm/CheckboxGroup";
import Button from "./Button";
import dropUpIcon from "../assets/media/exportBills/DropUp-Blue.png";
import Dropdown from "./Dropdown";
import MuiTextInput from "./blunoInputs/MuiTextInputs";
import { sanitizeHtml } from "../utils/sanitizeHtml";

const BillLodgementStage = ({
  data,
  onActionClick,
  blSubmitValues = [],
  setBlSubmitValues,
}) => {
  // const handleChange = (slug, type, value) => {
  //   setBlSubmitValues((prevValues) => {
  //     const existingIndex = prevValues.findIndex((val) => val.key === slug);

  //     if (value === "" || value === "no") {
  //       if (existingIndex > -1) {
  //         return prevValues.filter((val) => val.key !== slug);
  //       }
  //       return prevValues;
  //     }

  //     if (existingIndex > -1) {
  //       const updatedValues = [...prevValues];
  //       updatedValues[existingIndex] = {
  //         ...updatedValues[existingIndex],
  //         value,
  //       };
  //       return updatedValues;
  //     }

  //     return [...prevValues, { key: slug, field_type: type, value }];
  //   });
  // };

  const handleChange = (slug, type, value) => {
    setBlSubmitValues((prevValues) => {
      const existingIndex = prevValues.findIndex((val) => val.key === slug);

      if (value === "" || value === "no") {
        if (existingIndex > -1) {
          return prevValues.filter((val) => val.key !== slug);
        }
        return prevValues;
      }

      if (existingIndex > -1) {
        const updatedValues = [...prevValues];
        updatedValues[existingIndex] = {
          ...updatedValues[existingIndex],
          value,
        };

        // Remove text_box value if dropdown value is not "any_other"
        if (type === "dropdown" && value !== "any_other") {
          return updatedValues.filter((val) => val.field_type !== "text_box");
        }

        return updatedValues;
      }

      // Add new value
      const newValues = [...prevValues, { key: slug, field_type: type, value }];

      // Remove text_box value if dropdown value is not "any_other"
      if (type === "dropdown" && value !== "any_other") {
        return newValues.filter((val) => val.field_type !== "text_box");
      }

      return newValues;
    });
  };

  const checkIfSubmitDisabled = () => {
    const delayReason = blSubmitValues.find(
      (val) => val.key === "bill_lodgement_reason_for_delay"
    )?.value;

    const requiredLength = delayReason === "any_other" ? 3 : 2;

    if (
      data?.is_bill_lodgement_due &&
      blSubmitValues?.length !== requiredLength
    ) {
      return true;
    } else if (!data?.is_bill_lodgement_due && blSubmitValues?.length !== 1) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const initialValues = [];

    data?.inputs?.forEach((item) => {
      if (item?.type === "dropdown" && item?.default?.value) {
        const existingValue = blSubmitValues?.find(
          (val) => val?.key === item?.slug
        );
        if (!existingValue) {
          initialValues?.push({
            key: item?.slug,
            field_type: item?.type,
            value: item?.default?.value,
          });
        }
      } else if (item?.type === "text_box" && item?.default_value) {
        const existingValue = blSubmitValues?.find(
          (val) => val?.key === item?.slug
        );
        if (!existingValue) {
          initialValues?.push({
            key: item?.slug,
            field_type: item?.type,
            value: item?.default_value,
          });
        }
      }
    });

    data?.declaration?.forEach((item) => {
      if (item?.input?.metadata?.value === "yes") {
        const existingValue = blSubmitValues?.find(
          (val) => val?.key === item?.slug
        );
        if (!existingValue) {
          initialValues.push({
            key: item?.slug,
            field_type: "checkbox",
            value: "yes",
          });
        }
      }
    });

    if (initialValues?.length > 0) {
      setBlSubmitValues([...initialValues]);
    }
  }, []);

  const [open, setOpen] = useState(true);
  const renderInput = (theItem) => {
    switch (theItem?.type) {
      case "dropdown":
        return (
          <div className="r-lodgement__input">
            <p className="r-lodgement__label">{theItem?.title}</p>
            <div className="r-lodgement__dropdown">
              <Dropdown
                options={theItem?.options}
                defaultValue={
                  blSubmitValues.find((val) => val?.key === theItem?.slug)
                    ?.value || ""
                }
                height="40px"
                minWidth="380px"
                menuHeight="300px"
                fontSize="13px"
                onSelectChange={(e) =>
                  handleChange(theItem?.slug, theItem?.type, e.value)
                }
                placeholder={theItem?.placeholder}
                placeholderFontSize="13px"
                placeholderFontWeight="400"
                fancyPlaceholder={true}
                isDisabled={theItem?.disabled}
              />
            </div>
          </div>
        );
      case "text_box":
        return (
          <>
            {blSubmitValues.find(
              (val) => val?.key === "bill_lodgement_reason_for_delay"
            )?.value === "any_other" && (
              <div className="r-lodgement__input">
                <p className="r-lodgement__label">{theItem?.title}</p>
                <div className="r-lodgement__textbox">
                  <MuiTextInput
                    placeholder={theItem?.placeholder}
                    type={theItem?.sub_type}
                    value={
                      blSubmitValues.find((val) => val?.key === theItem?.slug)
                        ?.value || ""
                    }
                    height="40px"
                    minWidth="380px"
                    id={theItem?.slug}
                    onChange={(e) => {
                      handleChange(
                        theItem?.slug,
                        theItem?.type,
                        e.target.value
                      );
                    }}
                    disabled={theItem?.disabled}
                    fontSize="13px"
                    subtext={theItem?.subtext}
                  />
                </div>
              </div>
            )}
          </>
        );
    }
  };

  return (
    <div className="r-lodgement">
      <div className="r-lodgement__container">
        <div className="r-lodgement__title">
          {data?.status_icon && (
            <img
              src={data?.status_icon}
              alt="step-done"
              className="r-lodgement__title-stepdone"
            />
          )}
          <h2 className="r-lodgement__title-title">
            {data?.title}
            {data?.status === "in_progress" && <p>{data?.status_text}</p>}
          </h2>
        </div>
        {data?.is_dropdown && (
          <div
            className="mapirm-summary__dropdown"
            onClick={() => setOpen(!open)}
          >
            <p className="mapirm-summary__dropdown-text">
              {open ? "Hide Details" : "Show Details"}
            </p>
            <img
              src={dropUpIcon}
              alt="Dropdown Icon"
              className={`mapirm-summary__dropdown-icon ${
                !open ? "mapirm-summary__dropdown--rotate" : ""
              }`}
            />
          </div>
        )}
      </div>
      {open && data?.status === "pending" && (
        <>
          {data?.alert_container && (
            <div className="r-lodgement__alert">
              <img src={data?.alert_container?.icon} alt="alert" />
              <p>{data?.alert_container?.text}</p>
            </div>
          )}
          {data?.inputs && (
            <div className="r-lodgement__inputs">
              {data?.inputs?.map((item) => {
                return renderInput(item);
              })}
            </div>
          )}
          <div className="r-lodgement__declration">
            {data?.declaration?.map((item) => {
              return (
                <CheckboxGroup
                  key={item?.slug}
                  item={{ title: item?.title, element_id: item?.slug }}
                  value={
                    blSubmitValues.find((val) => val?.key === item?.slug)
                      ?.value === "yes"
                      ? "yes"
                      : "no"
                  }
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    handleChange(
                      item?.slug,
                      "checkbox",
                      isChecked ? "yes" : "no"
                    );
                  }}
                  disabled={false}
                />
              );
            })}
          </div>
          <div className="r-lodgement__buttons">
            {data?.buttons?.map((action) => {
              return (
                <Button
                  buttonLabel={action?.text}
                  buttonType={action?.sub_type}
                  disabled={
                    action?.slug === "bl_submit"
                      ? checkIfSubmitDisabled()
                      : false
                  }
                  onClick={() => onActionClick(data?.slug, action)}
                />
              );
            })}
          </div>
        </>
      )}
      {open && data?.status === "in_progress" && (
        <>
          <div className="r-lodgement__progress">
            <img
              className="r-lodgement__progress-img"
              src={data?.icon}
              alt="in progress"
            />
            <div className="r-lodgement__progress-content">
              <p
                className="r-lodgement__progress-text"
                dangerouslySetInnerHTML={sanitizeHtml(data?.text1)}
              />
              <p
                className="r-lodgement__progress-date"
                dangerouslySetInnerHTML={sanitizeHtml(data?.text2)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BillLodgementStage;
