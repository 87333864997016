import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import endpoints from "../api/endpoints";
import { apiRequest } from "../redux/common/actions";

import { updateShowIrmForm, updateShowSbForm } from "../redux/common/slice";
import NewUploadCard from "../modules/DayZero/NewUploadCard";
import UploadFileV2 from "./UploadFileV2/UploadFileV2";
import Button from "./Button";
import { showToast } from "../redux/toast/slice";
import { showAlert } from "../redux/alert/slice";
import { removeLoader, showLoader } from "../redux/loader/slice";
import { STAGE } from "../utils/constants/UserConstants";

export default function AddShippingBill({ data, stage }) {
  const dispatch = useDispatch();

  //local states
  const [files, setFiles] = useState([]);
  const [totalFilesCount, setTotalFilesCount] = useState();
  const [validFilesCount, setValidFilesCount] = useState();

  const handleSubmit = async (e) => {
    if (stage === STAGE["SETUP"]) {
      dispatch(updateShowSbForm(false));
      dispatch(
        showLoader({
          loaderSuccess: true,
          showLogo: true,
          message: `${validFilesCount} of ${totalFilesCount} files submitted succesfully.`,
        })
      );
      setTimeout(() => {
        dispatch(removeLoader());
      }, [2000]);
      return;
    }
    const bulkOcrCall = async (files) => {
      dispatch(
        showLoader({
          loaderSuccess: false,
          showLogo: false,
        })
      );
      const dataRes = await dispatch(
        apiRequest(endpoints.bulkOCR, files, null, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      );
      if (dataRes?.success && dataRes?.data) {
        dispatch(updateShowSbForm(false));
        dispatch(
          showLoader({
            loaderSuccess: true,
            showLogo: true,
            message: `${validFilesCount} of ${totalFilesCount} files submitted succesfully. Your files will be processed in some time`,
          })
        );
        setTimeout(() => {
          dispatch(removeLoader());
        }, [2000]);
      } else {
        dispatch(showToast(dataRes?.data?.toast));
      }
    };
    const validFiles = files.reduce((fileAccumulator, item) => {
      item?.map((file) => {
        if (
          !file?.error_message &&
          file?.name?.split(".")?.pop() !== "zip" &&
          file?.id &&
          !file?.metadata?.sbId
        ) {
          fileAccumulator.push(file?.id);
        }
      });
      return fileAccumulator;
    }, []);
    bulkOcrCall({ document_ids: validFiles });
  };

  const handleClose = (e) => {
    const docAction = async (documentId) => {
      const dataRes = await dispatch(
        apiRequest(endpoints.docAction, {
          action: "detach",
          document_id: documentId,
          document_type: "SHIPPING_BILL",
        })
      );
    };
    const detachAll = (files) => {
      files?.forEach((item) => {
        docAction(item);
      });
    };
    if (stage !== STAGE["SETUP"]) {
      const validFiles = files.reduce((fileAccumulator, item) => {
        item?.map((file) => {
          if (
            !file?.error_message &&
            file?.name?.split(".")?.pop() !== "zip" &&
            file?.id
          ) {
            fileAccumulator.push(file?.id);
          }
        });
        return fileAccumulator;
      }, []);
      detachAll(validFiles);
    }
    dispatch(updateShowIrmForm(false));
    dispatch(updateShowSbForm(false));
  };

  useEffect(() => {
    let count = undefined;
    let countValid = undefined;
    files.forEach((item) => {
      item?.map((file) => {
        if (file?.name?.split(".")?.pop() !== "zip") {
          if (count === undefined) count = 0;
          count++;
          if (!file?.error_message && file?.id) {
            if (countValid === undefined) countValid = 0;
            countValid++;
          }
        }
      });
    });
    setValidFilesCount(countValid);
    setTotalFilesCount(count);
  }, [files]);
  return (
    <div className="s-add-shipping-bill__container">
      <div className="s-add-shipping-bill">
        <div className="s-add-shipping-bill__header">
          <h1 className="s-add-shipping-bill__header-title">
            {data?.title}{" "}
            {totalFilesCount > 0 &&
              validFilesCount > 0 &&
              `(${validFilesCount} of ${totalFilesCount} are valid)`}
          </h1>
          <img
            className="r-panel__icon"
            src={data?.close_cta?.deeplink}
            alt="close"
            onClick={(e) => {
              handleClose(e);
            }}
          />
        </div>
        <div className="s-add-shipping-bill-break"></div>
        <div className="s-add-shipping-bill__body">
          <div className="s-add-shipping-bill__body-upload">
            <UploadFileV2
              {...data?.document_data?.[0]}
              flow="sb_dashboard"
              onFileChange={setFiles}
            />

            {totalFilesCount > 0 && validFilesCount > 0 && (
              <Button
                buttonLabel={data?.button?.text}
                onClick={(e) => handleSubmit(e)}
                disabled={validFilesCount <= 0}
              />
            )}
          </div>
          <div className="s-add-shipping-bill__body-help">
            <h1 className="s-add-shipping-bill__body-help-title">
              {data?.help_data?.title}
            </h1>
            <div className="s-add-shipping-bill__body-help-section">
              <div className="s-add-shipping-bill__body-help-section-heading">
                <img
                  className="s-add-shipping-bill__body-help-section-heading-img"
                  src={data?.help_data?.rbi_section?.heading?.icon}
                />
                <h2 className="s-add-shipping-bill__body-help-section-heading-title">
                  {data?.help_data?.rbi_section?.heading?.text}
                </h2>
              </div>
              <div className="s-add-shipping-bill__body-help-section-body">
                <p className="s-add-shipping-bill__body-help-section-body-text">
                  {data?.help_data?.rbi_section?.sub_heading?.text}
                </p>
                <ol className="s-add-shipping-bill__body-help-section-body-list">
                  {data?.help_data?.rbi_section?.body?.map((item, idx) => {
                    return (
                      <li
                        key={idx}
                        className="s-add-shipping-bill__body-help-section-body-text"
                      >
                        {item?.text}
                      </li>
                    );
                  })}
                </ol>
              </div>
            </div>
            <div className="s-add-shipping-bill__body-help-section">
              <div className="s-add-shipping-bill__body-help-section-heading">
                <img
                  className="s-add-shipping-bill__body-help-section-heading-img"
                  src={data?.help_data?.data_protection?.heading?.icon}
                />
                <h2 className="s-add-shipping-bill__body-help-section-heading-title">
                  {data?.help_data?.data_protection?.heading?.text}
                </h2>
              </div>
              <div className="s-add-shipping-bill__body-help-section-body">
                {data?.help_data?.data_protection?.body?.map((item, idx) => {
                  return (
                    <p
                      key={idx}
                      className="s-add-shipping-bill__body-help-section-body-text"
                    >
                      {item?.text}
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="s-add-shipping-bill__body-help-footer">
              <p className="s-add-shipping-bill__body-help-section-heading-title">
                {data?.help_data?.powered_by?.text}
              </p>
              <img src={data?.help_data?.powered_by?.icon}></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
