// react imports
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// redux imports
import { useDispatch } from "react-redux";
import endpoints from "../../api/endpoints";
import { apiRequest } from "../../redux/common/actions";

// third-party imports

// in-app imports
import SingleBuyerSummary from "../../modules/AllBuyers/SingleBuyerSummary";

// utils
import SingleBuyerTable from "../../modules/AllBuyers/SingleBuyerTable";
import { GetQueryParamValue } from "../../utils";
import BlunoTooltip from "../../components/BlunoTooltip";
import useDebouncing from "../../hooks/useDebouncing";
import { DEBOUNCE } from "../../app/constants/common";

const SingleBuyer = () => {
  /// defined to access react based apis
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // local states
  const [activeBill, setActiveBill] = useState(
    location?.state?.irm ? "irm" : "sb"
  );
  const [summaryData, setSummaryData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [singleBuyerInfoSec, setSingleBuyerInfoSec] = useState();
  const [filterObj, setFilterObj] = useState({});
  const [loading, setLoading] = useState(false);

  // custom hooks variables
  const debouncedFilterObj = useDebouncing(filterObj, DEBOUNCE.delay);

  // modal states
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({});

  const buyerId = params?.buyerId;
  const filterCategory = GetQueryParamValue("filter_category") || "";

  const getBuyerSbData = async (sortField = "sb_date", order = "desc") => {
    const filters = ["currency", "start_date", "end_date", "sb_no"];
    let params = filters
      .filter((key) => filterObj?.[key])
      .map((key) => `${key}=${filterObj[key]}`)
      .join("&");

    const queryString = `?buyer_id=${buyerId}&filter_category=${filterCategory}&sort_field=${sortField}&order=${order}&page_no=${pageNo}&${params}`;
    setLoading(true);
    const dataRes = await dispatch(
      apiRequest(endpoints.singleBuyerSbSummary, queryString)
    );
    if (dataRes?.success && dataRes?.data) {
      setSummaryData(dataRes?.data?.buyer_summary);
      setTableData(dataRes?.data?.table_data);
    }
    setLoading(false);
  };

  const getBuyerIrmData = async (sortField = "credit_date", order = "desc") => {
    const filters = ["reference_no", "currency", "start_date", "end_date"];
    let params = filters
      .filter((key) => filterObj?.[key])
      .map((key) => `${key}=${filterObj[key]}`)
      .join("&");

    const queryString = `?buyer_id=${buyerId}&filter_category=${filterCategory}&sort_field=${sortField}&order=${order}&page_no=${pageNo}&${params}`;
    setLoading(true);
    const dataRes = await dispatch(
      apiRequest(endpoints.singleBuyerIrmSummary, queryString)
    );

    if (dataRes?.success && dataRes?.data) {
      setSummaryData(dataRes?.data?.buyer_summary);
      setTableData(dataRes?.data?.table_data);
    }
    setLoading(false);
  };

  const onSortOrderClick = (sortCategory, sortOrder) => {
    const order = sortOrder ? (sortOrder === "asc" ? "desc" : "asc") : "desc";
    if (activeBill === "sb") {
      getBuyerSbData(sortCategory, order);
    } else {
      getBuyerIrmData(sortCategory, order);
    }
  };

  useEffect(() => {
    if (activeBill === "sb") {
      getBuyerSbData();
    } else {
      getBuyerIrmData();
    }
  }, [buyerId, activeBill, debouncedFilterObj, pageNo, filterCategory]);

  const onClearFilter = () => {
    setFilterObj({});
    const activeBillcurrent = activeBill;
    navigate(document.location.pathname);
    setActiveBill(activeBillcurrent);
  };

  const onModalClick = (slug, index) => {
    setShowModal(true);
    setModalInfo({ slug: slug, key: index });
  };

  const closeModal = () => {
    setShowModal(false);
    setModalInfo({});
  };

  useEffect(() => {
    const getSingleBuyerData = async () => {
      // Single Buyer Info Resoponse
      const singleBuyerInfoRes = await dispatch(
        apiRequest(endpoints.getBuyerInfoSec, `?buyer_id=${buyerId}`)
      );
      if (singleBuyerInfoRes?.success && singleBuyerInfoRes?.data) {
        setSingleBuyerInfoSec(singleBuyerInfoRes?.data);
      }
    };
    getSingleBuyerData();
  }, []);

  const handleInputChange = (theValue, theSlug) => {
    switch (theSlug) {
      case "filter_category":
        navigate(`/buyers/${buyerId}?filter_category=${theValue}`);
        break;
      default:
        setFilterObj((prev) => {
          if (theValue === "") {
            const { [theSlug]: _, ...rest } = prev;
            return rest;
          } else {
            return {
              ...filterObj,
              [theSlug]: theValue,
            };
          }
        });
    }
    setPageNo(1);
  };

  const handleDateRangeChange = (startDate, endDate) => {
    setFilterObj({
      ...filterObj,
      ["start_date"]: startDate,
      ["end_date"]: endDate,
    });
    setPageNo(1);
  };

  const redirectTo = (e, theLink) => {
    e.stopPropagation();
    navigate(theLink);
  };

  return (
    <>
      <div className="a-buyers">
        <div className="a-buyers__top">
          <div className="a-buyers__top-left">
            {singleBuyerInfoSec?.top_bar?.header?.back_info && (
              <BlunoTooltip
                icon={singleBuyerInfoSec?.top_bar?.header?.back_info?.icon}
                // hoverIcon={
                //   singleBuyerInfoSec?.top_bar?.header?.back_info?.hover_icon
                // }
                text={
                  singleBuyerInfoSec?.top_bar?.header?.back_info?.hover_text
                }
                useChildren={true}
                left="28px"
              >
                <div
                  href={
                    singleBuyerInfoSec?.top_bar?.header?.back_info?.deeplink
                  }
                  onClick={(e) => {
                    redirectTo(
                      e,
                      singleBuyerInfoSec?.top_bar?.header?.back_info?.deeplink
                    );
                  }}
                  className="s-sbHeader__back s-sbHeader__back-background"
                >
                  <img
                    src={singleBuyerInfoSec?.top_bar?.header?.back_info?.icon}
                    className="s-sbHeader__back-img"
                  />
                </div>
              </BlunoTooltip>
            )}

            <h2 className="a-buyers__top-heading">
              {singleBuyerInfoSec?.top_bar?.header?.title}
            </h2>
          </div>
        </div>
        <div className="a-buyers__cards">
          <div className="a-buyers__summary-card">
            <SingleBuyerSummary
              data={summaryData}
              buyerId={buyerId}
              activeBill={activeBill}
              setActiveBill={setActiveBill}
              buttonsData={singleBuyerInfoSec?.top_bar?.tabs}
              buyerDetails={singleBuyerInfoSec?.top_bar?.buyer_details}
              loading={loading}
            />
          </div>
        </div>
        <SingleBuyerTable
          data={tableData}
          pageNo={pageNo}
          setPageNo={setPageNo}
          filterObj={filterObj}
          filterCategory={filterCategory}
          handleInputChange={handleInputChange}
          handleDateRangeChange={handleDateRangeChange}
          onSortOrderClick={onSortOrderClick}
          onClearFilter={onClearFilter}
        />
      </div>
    </>
  );
};

export default SingleBuyer;
