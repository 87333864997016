// react imports
import React from "react";

// third party imports

// in-app imports

const ErrorPage = () => {
  return (
    <>
      <h1>Oops!</h1>
      <div>There was some problem while loading this page!</div>
    </>
  );
};

export default ErrorPage;
