// react imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//redux imports
import { useDispatch } from "react-redux";
import { updateShowSbForm } from "../redux/common/slice";

// in-app imports
import RedirectIcon from "../assets/media/table/Redirect.png";
import SkeletonBasic from "./SkeletonLoaders/SkeletonBasic";
import Button from "./Button";
import { sanitizeHtml } from "../utils/sanitizeHtml";
import { apiRequest } from "../redux/common/actions";
import endpoints from "../api/endpoints";

const HomeSummary = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // local states
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedCurrencyData = data?.sb_currencies?.find(
    (c) => c?.currency === selectedCurrency
  );
  const [selectedSection, setSelectedSection] = useState("open");

  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
  };

  useEffect(() => {
    setSelectedCurrency(data?.default_currency);
  }, [data]);

  const redirectToLink = (deeplink) => {
    deeplink && navigate(deeplink);
  };

  const handleButtonClick = (e, action) => {
    switch (action?.slug) {
      case "add_sb":
        e.stopPropagation();
        dispatch(updateShowSbForm(true));
        break;
      case "add_irm":
        e.stopPropagation();
        dispatch(updateShowSbForm(true));
        break;
    }
  };

  return (
    <div className="homeSummary">
      <SkeletonBasic showLoader={data ? false : true}>
        <div className="homeSummary__heading">
          <div className="homeSummary__title">{data?.title}</div>
          <div className="homeSummary__link">
            <img
              src={RedirectIcon}
              alt="Link"
              onClick={() => redirectToLink(data?.deeplink)}
            />
          </div>
        </div>
        {data?.default_view ? (
          <>
            <div className="homeSummary__default">
              <img
                className="homeSummary__default-img"
                src={data?.default_view?.img}
                alt="default img"
              />
              <div className="homeSummary__default-text">
                <p>{data?.default_view?.text}</p>
                {data?.default_view?.buttons?.map((btn) => {
                  return (
                    <Button
                      buttonLabel={btn?.text}
                      buttonType={btn?.sub_type}
                      onClick={(e) => {
                        handleButtonClick(e, btn);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="homeSummary__sb">
              {selectedCurrencyData?.distribution?.map((item) => {
                return (
                  <div
                    className={`homeSummary__sb-distribution ${
                      selectedSection === item?.slug &&
                      "homeSummary__sb-distribution--active"
                    }`}
                  >
                    <div className="homeSummary__sb-status">
                      <div className="homeSummary__sb-status-left">
                        <img src={item?.icon} alt="" />
                      </div>
                      <div className="homeSummary__sb-status-right">
                        <p
                          className={`homeSummary__sb-status-count ${
                            item?.text === "Approved"
                              ? "homeSummary__sb-status-count--approved"
                              : ""
                          }`}
                        >
                          {item?.count}
                        </p>
                        <p
                          className="homeSummary__sb-status-value"
                          dangerouslySetInnerHTML={sanitizeHtml(item?.value)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="homeSummary__sb-categories">
                {selectedCurrencyData?.distribution?.map((item) => {
                  return (
                    <div
                      className={`homeSummary__sb-categories-text ${
                        selectedSection === item?.slug
                          ? "homeSummary__sb-categories-text--active"
                          : ""
                      }`}
                      onClick={(e) => setSelectedSection(item?.slug)}
                    >
                      {item?.text}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </SkeletonBasic>
    </div>
  );
};

export default HomeSummary;
