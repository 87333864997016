import React from "react";

const DemoCompleteClone = ({ setOpenModal }) => {
  const handleCloseClick = () => {
    setOpenModal(false);
  };

  return (
    <div className="s-demo__complete">
      <div
        onClick={() => handleCloseClick()}
        className="s-demo__complete-close"
      >
        <img
          src="https://static.bluno.in/landing/circular_cross.png"
          alt="close"
        />
      </div>
      <img
        className="s-demo__complete-logo"
        src="https://static.bluno.in/miscl/logo.png"
        alt="bluno"
      />
      <h1 className="s-demo__complete-title">Thank you for your interest!</h1>
      <p className="s-demo__complete-text">
        You've successfully requested early access.
        <br /> We'll get in touch with you soon.
      </p>
    </div>
  );
};

export default DemoCompleteClone;
