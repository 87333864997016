//react imports
import React from "react";

//in app imports
import Button from "../../../components/Button";
import MuiTextInput from "../../../components/blunoInputs/MuiTextInputs";
import { LOGIN_SCREEN_SLUGS } from "../../../utils/constants/OnboardingConstants";

const EnterMobile = ({
  data,
  phone = "",
  handleInputChange,
  errorMessagePhone,
  handleKeyDown,
  onPhoneEnter,
}) => {
  return (
    <div className="o-login__enter">
      <div>
        <h2 className="o-login__title">{data?.title}</h2>
      </div>
      <div className="o-login__inputs o-login__mobile">
        {data?.inputs?.map((item) => {
          return (
            <div className="o-login__input">
              <MuiTextInput
                placeholder={item?.placeholder}
                type={item?.sub_type}
                value={phone}
                icon={item?.icon}
                id={item?.slug}
                errorMessage={errorMessagePhone}
                onChange={(e) => {
                  handleInputChange(e.target.value, item?.slug, e);
                }}
                onKeyDown={(e) => handleKeyDown(e.key, item?.slug, e)}
                disabled={item?.disabled}
                fixedText="+91"
                paddingLeft="20px"
                fixedTextLeftStyle={"20px"}
                height="40px"
                minWidth="360px"
                fontSize="13px"
                errorMsgPostionType="absolute"
                errorMsgBottomPosition="-17px"
              />
            </div>
          );
        })}
      </div>
      {data?.buttons && (
        <div className="o-login__continue">
          {data?.buttons?.map((action) => {
            return (
              action?.type === "button" && (
                <Button
                  buttonLabel={action?.text}
                  buttonType={action?.sub_type}
                  icon={action.icon}
                  disabled={action?.disabled}
                  iconAlignment={action?.icon_alignment}
                  extraClass={`o-login__continue-button`}
                  onClick={() =>
                    onPhoneEnter(action, LOGIN_SCREEN_SLUGS?.login)
                  }
                  height="40px"
                  fontSize="13px"
                />
              )
            );
          })}
        </div>
      )}
      <div className="o-login__terms">
        <p className="o-login__terms-content">
          {data?.terms?.text_list?.map((child) => {
            switch (child?.text_type) {
              case "plain":
                return (
                  <span
                    className="o-login__terms-text"
                    style={{
                      color: child?.color ?? "",
                      fontWeight: child?.font_weight ?? "",
                    }}
                  >
                    {child?.value}
                  </span>
                );
              case "hyperlink":
                return (
                  <a
                    target="_blank"
                    href={child?.deeplink}
                    style={{
                      color: child?.color ?? "",
                      fontWeight: child?.font_weight ?? "",
                      cursor: "pointer",
                    }}
                    className="o-login__terms-text"
                  >
                    {child?.value}
                  </a>
                );
            }
          })}
        </p>
      </div>
    </div>
  );
};

export default EnterMobile;
