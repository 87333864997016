// react imports
import React from 'react'

// third-party imports

// in-app imports

const ModalV2 = ({slug, children}) => {
  return (
    <>
      <div className={`g-modal g-modal--${slug}`}>
        <div className="g-modal__body">
            {children}
        </div>
      </div>
    </>
  )
}

export default ModalV2
