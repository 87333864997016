// react imports
import React, { useEffect, useState } from "react";
import BlunoTooltip from "./BlunoTooltip";
import InputErrorMsg from "./InputErrorMsg";

// third-party imports

// in-app imports

const Button = (props) => {
  // props varibles
  const {
    buttonLabel = "",
    onClick,
    width = "",
    height = "",
    textWidth = "",
    fontSize = "13px",
    buttonType = "primary",
    buttonSize = "sm2",
    variant = "button",
    icon = "",
    hoverIcon = "",
    iconAlignment = "right",
    iconExtraClass = "",
    altText = "",
    extraClass = "",
    disabled = false,
    submitType = "button",
    formId = undefined,
    tooltipText,
    tooltipPosition,
    errorMessage,
    errorIcon,
    errorPosition,
    iconParDiv = false,
    iconParDivClass,
  } = props;

  const buttonStyles = {
    width: width,
    height: height,
  };

  const buttonTextStyles = {
    fontSize: fontSize,
    width: textWidth
  };

  const handleMouseEntered = () => {
    hoverIcon && setButtonIcon(hoverIcon);
    setButtonHovered(true);
  };

  const handleMouseLeave = () => {
    hoverIcon && setButtonIcon(icon);
    setButtonHovered(false);
  };

  const [buttonIcon, setButtonIcon] = useState(icon);
  const [buttonHovered, setButtonHovered] = useState(false);

  useEffect(() => {
    setButtonIcon(icon);
  }, [icon]);

  return (
    <div className={`s-button__error s-button__error--${errorPosition}`}>
      {errorMessage && (
        <InputErrorMsg message={errorMessage} icon={errorIcon} />
      )}
      {tooltipText ? (
        <BlunoTooltip
          useChildren={true}
          text={tooltipText}
          position={tooltipPosition?.position ?? "absolute"}
          bottom={tooltipPosition?.bottom ?? "-25px"}
          left={tooltipPosition?.left ?? "80px"}
        >
          <button
            className={`s-button s-button--${buttonType} s-button--${buttonSize} s-button--${variant} ${extraClass} ${
              disabled ? "s-button--disabled" : null
            } s-button--icon-${iconAlignment}`}
            type={formId ? "submit" : submitType}
            form={formId}
            onClick={!disabled ? onClick : null}
            style={buttonStyles}
            onMouseEnter={() => handleMouseEntered()}
            onMouseLeave={() => handleMouseLeave()}
          >
            <span className={`s-button__text--${buttonSize}`}>
              {buttonLabel}
            </span>
            {buttonIcon && (
              <img
                className={`${
                  hoverIcon ? "s-button__h-icon" : "s-button__icon"
                } s-button__icon--${buttonSize}`}
                src={buttonIcon}
                alt={altText}
                style={{
                  transition: "opacity 0.3s ease",
                  opacity: buttonIcon === hoverIcon ? 1 : 0.9,
                }}
              />
            )}
          </button>
        </BlunoTooltip>
      ) : (
        <button
          className={`s-button s-button--${buttonType} s-button--${buttonSize} s-button--${variant} ${extraClass} ${
            disabled ? "s-button--disabled" : null
          } s-button--icon-${iconAlignment}`}
          type={formId ? "submit" : submitType}
          form={formId}
          onClick={!disabled ? onClick : null}
          style={buttonStyles}
          onMouseEnter={() => handleMouseEntered()}
          onMouseLeave={() => handleMouseLeave()}
        >
          <span
            className={`s-button__text--${buttonSize}`}
            style={buttonTextStyles}
          >
            {buttonLabel}
          </span>
          {buttonIcon &&
            (iconParDiv && buttonHovered ? (
              <div className={`${iconParDivClass}`}>
                <img
                  className={`${
                    hoverIcon ? "s-button__h-icon" : "s-button__icon"
                  } s-button__icon--${buttonSize} ${iconExtraClass}`}
                  src={buttonIcon}
                  alt={altText}
                  style={{
                    transition: "opacity 0.3s ease",
                    opacity: buttonIcon === hoverIcon ? 1 : 0.9,
                  }}
                />
              </div>
            ) : (
              <img
                className={`${
                  hoverIcon ? "s-button__h-icon" : "s-button__icon"
                } s-button__icon--${buttonSize} ${iconExtraClass}`}
                src={buttonIcon}
                alt={altText}
                style={{
                  transition: "opacity 0.3s ease",
                  opacity: buttonIcon === hoverIcon ? 1 : 0.9,
                }}
              />
            ))}
        </button>
      )}
    </div>
  );
};

export default Button;
