// react imports
import React from "react";

// third-party imports

// in-app imports
import DocSnap from "./DocSnap";

const Docinfo = ({
  data,
  isPartition,
  uploadSingleFile,
  docId,
  section,
  onDocAction,
}) => {
  return (
    <>
      <div
        className={`s-docinfo ${isPartition ? "s-docinfo--partition" : null}`}
      >
        <div className="s-docinfo__header">
          <h2 className="s-docinfo__title">{data?.title}</h2>
          {data?.sub_text && (
            <p className="s-docinfo__subtext">{data?.sub_text}</p>
          )}
        </div>
        {data?.message && <p className="s-docinfo__message">{data?.message}</p>}
        <div className="s-docinfo__content">
          {data?.data?.map((docsnap) => {
            return (
              <DocSnap
                data={docsnap}
                uploadSingleFile={uploadSingleFile}
                docId={docId}
                section={section}
                onDocAction={onDocAction}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Docinfo;
