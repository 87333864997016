import React from "react";

export default function LogoutIcon(props) {
  const { color = "#000000" } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1953 5.71289L18.4995 9.89253M18.4995 9.89253L14.1953 14.0722M18.4995 9.89253H9.89113"
        stroke={color}
        stroke-linecap="round"
      />
      <path
        d="M14.7031 2.5022C10.1378 -0.454041 0.5 0.434064 0.5 9.89152C0.5 19.349 10.1378 20.7268 15.2103 17.2793"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
