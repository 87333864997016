// react imports
import React, { useState, useEffect } from "react";

// redux imports
import { useDispatch, useSelector } from "react-redux";

// third-party imports

// in-app imports
import circleLoader from "../assets/media/gifs/loader.gif";
import logo from "../assets/media/common/Logo.png";
import circularCross from "../assets/media/common/circular_cross.png";
import { removeLoader } from "../redux/loader/slice";
import { sanitizeHtml } from "../utils/sanitizeHtml";

const Loader = () => {
  // redux state variables
  const isShowLoader = useSelector((state) => state.loader.isShowLoader);
  const showLogo = useSelector((state) => state.loader.showLogo);
  const loaderMessage = useSelector((state) => state.loader.loaderMessage);
  const timeoutLimit = useSelector((state) => state.loader.timeoutLimit);
  const loaderType = useSelector((state) => state.loader.type);
  const delayedDataLoader = useSelector(
    (state) => state.loader.delayedDataLoader
  );
  const loaderSuccess = useSelector((state) => state.loader.loaderSuccess);

  const [showDelayedLoader, setShowDelayedLoader] = useState(false);
  const [changingLoaderMesage, setChangingLoaderMesage] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (isShowLoader) {
      if (delayedDataLoader?.delayDataTime) {
        setShowDelayedLoader(true);
        let index = 0;
        const intervalTime =
          delayedDataLoader?.delayDataTime /
          delayedDataLoader?.messageList?.length;
        setChangingLoaderMesage(delayedDataLoader?.messageList[index++]);

        const interval = setInterval(() => {
          setChangingLoaderMesage(delayedDataLoader?.messageList[index++]);
        }, [intervalTime]);

        setTimeout(() => {
          setShowDelayedLoader(false);
          clearInterval(interval);
          dispatch(removeLoader());
        }, [delayedDataLoader?.delayDataTime]);
      } else {
        const timer = setTimeout(() => {
          setShowDelayedLoader(true);
        }, timeoutLimit);

        return () => clearTimeout(timer);
      }
    } else {
      setShowDelayedLoader(false);
    }
  }, [isShowLoader]);

  return (
    <>
      {showDelayedLoader && (
        <div className="s-loader__parent">
          <div
            className={`s-loader s-loader--${loaderType} ${
              !loaderSuccess && `s-loader--more-padding`
            }`}
          >
            {loaderSuccess && (
              <img
                src="https://static.bluno.in/miscl/cross-thin.png"
                className="s-loader__cross"
                onClick={() => {
                  dispatch(removeLoader());
                }}
              />
            )}
            <div className="s-loader__icon">
              {!loaderSuccess && (
                <img
                  src={circleLoader}
                  alt=""
                  className="s-loader__icon-loader"
                />
              )}
              {showLogo && (
                <img
                  src={logo}
                  alt=""
                  className={`s-loader__icon-logo ${
                    loaderSuccess && `s-loader__icon-sucess`
                  }`}
                />
              )}
            </div>
            <div className="s-loader__content">
              <h2
                className="s-loader__title"
                dangerouslySetInnerHTML={sanitizeHtml(
                  delayedDataLoader?.delayDataTime
                    ? changingLoaderMesage
                    : loaderMessage
                )}
              ></h2>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
