import React from "react";
import Button from "../../Button";

const ChangePasswordPopup = ({
  data,
  setShowModal,
  getPopupInputElement,
  onChangePassword,
  setEditObject,
  setDisplayObject,
  setErrorMessagesObject,
  setIsBlockerActive
}) => {
  const onPopupClose = () => {
    setShowModal(false);
    setEditObject({});
    setDisplayObject({});
    setErrorMessagesObject({});
    setIsBlockerActive(false);
  };
  
  return (
    <>
      <div className="sec-settings__popup">
        <div className="sec-settings__popup-header">
          <h3 className="sec-settings__popup-title">{data?.title}</h3>
          <img
            className="sec-settings__popup-close"
            src={data?.close_icon}
            onClick={onPopupClose}
          />
        </div>
        <div className="sec-settings__popup-inputs">
          {data?.inputs?.map((item, idx) => {
            return getPopupInputElement(item);
          })}
        </div>
        <div className="sec-settings__popup-buttons">
          {data?.buttons?.map((item, idx) => {
            return (
              <Button
                key={idx}
                icon={item?.icon}
                buttonType={item?.sub_type}
                buttonLabel={item?.text}
                iconAlignment={item?.icon_alignment}
                onClick={() => {
                  onChangePassword(item?.slug);
                }}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ChangePasswordPopup;
