import React, { useEffect, useState } from "react";

import { formatDate } from "../utils/formatDate";
import { TIME_PERIOD_RANGE } from "../utils/constants/HomePageConstants";
import BlunoCalender from "./BlunoCalender";
import BlunoTooltip from "./BlunoTooltip";

const TimePeriodPanel = (props) => {
  const { data, setSummaryDate } = props;

  const [panelData, setPanelData] = useState(null);
  const [timeSlug, setTimeSlug] = useState("");
  const [customDateShow, setCustomDateShow] = useState(false);
  const [panelDataCustom, setPanelDataCustom] = useState(null);
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);

  const getDateFromSlug = (theSlug) => {
    const currentDate = new Date();
    let date = null;

    switch (theSlug) {
      case TIME_PERIOD_RANGE?.SEVEN_DAYS:
        date = new Date(currentDate.setDate(currentDate.getDate() - 7));
        break;
      case TIME_PERIOD_RANGE?.THRTY_DAYS:
        date = new Date(currentDate.setDate(currentDate.getDate() - 30));
        break;
      case TIME_PERIOD_RANGE?.SIX_MONTHS:
        date = new Date(currentDate.setMonth(currentDate.getMonth() - 6));
        break;
      case TIME_PERIOD_RANGE?.ONE_YEAR:
        date = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));
        break;
      case TIME_PERIOD_RANGE?.INCEPTION:
        date = new Date(currentDate.setFullYear(currentDate.getFullYear() - 5));
        break;
      default:
        date = null;
    }

    setSummaryDate({ start_date: formatDate(date), end_date: null });
  };

  const setCustomDate = (startDate, endDate) => {
    setSummaryDate({ start_date: startDate, end_date: endDate });
  };

  const changeActivePeriod = (theSlug) => {
    setTimeSlug(theSlug);

    const updatedData = panelData.map((item) => ({
      ...item,
      isActive: item.slug === theSlug,
    }));

    setPanelData(updatedData);
  };

  useEffect(() => {
    getDateFromSlug(timeSlug);
  }, [panelData]);

  useEffect(() => {
    if (!panelData) {
      setPanelData(data?.time_period_list);
      setPanelDataCustom(data?.custom_time);
      data?.time_period_list?.map((item, idx) => {
        if (item?.slug === "custom_date") {
          setCustomDateShow(item?.isActive);
        }
      });
    }
  }, [data]);

  return (
    <>
      {customDateShow ? (
        <div className="time-period__custom">
          <BlunoCalender
            icon={panelDataCustom?.calendar?.icon}
            placeholder={panelDataCustom?.calendar?.placeholder}
            onDateRangeChange={setCustomDate}
            isHighlighted={true}
            defaultSelection={{
              start_date: panelDataCustom?.calendar?.default?.start_date,
              end_date: panelDataCustom?.calendar?.default?.end_date,
            }}
            standardDurations={panelDataCustom?.calendar?.standard_durations}
            fancyPlaceholder={true}
            isModalOpen={isCalenderOpen}
            minWidth="200px"
          />
          <>
            <span className="time-period__vertical" />
            <div
              className={`time-period__period `}
              onClick={() => {
                setCustomDateShow(false);
                changeActivePeriod("six_months");
              }}
            >
              <BlunoTooltip
                useChildren={true}
                text={"Clear filter"}
                position="fixed"
              >
                <img
                  style={{
                    width: "20px",
                  }}
                  src={panelDataCustom?.clear_filter?.deeplink}
                  alt=""
                />
              </BlunoTooltip>
            </div>
          </>
        </div>
      ) : (
        <div className="time-period">
          {panelData?.map((item, idx) => {
            return (
              <div
                className={`time-period__period ${
                  item?.isActive && "time-period__active"
                }`}
                onClick={() => {
                  changeActivePeriod(item?.slug);
                }}
              >
                <p
                  style={{
                    paddingRight: panelData[idx + 1]?.isActive && "0px",
                  }}
                  className="time-period__text"
                >
                  <span
                    className={`${
                      !item?.isActive && "time-period__text--inactive"
                    }`}
                  >
                    {item?.isActive ? item?.expandText : item?.compactText}
                  </span>
                </p>
                {((idx !== panelData.length - 1 &&
                  !item?.isActive &&
                  !panelData[idx + 1]?.isActive) ||
                  (idx === panelData.length - 1 && !item?.isActive)) && (
                  <span className="time-period__vertical" />
                )}
              </div>
            );
          })}
          <div
            className={`time-period__period-img`}
            onClick={() => {
              setCustomDateShow(true);
              setIsCalenderOpen(true);
            }}
          >
            <BlunoTooltip
              useChildren={true}
              position="fixed"
              text="Select Duration"
            >
              <img
                style={{
                  width: "16px",
                }}
                src={panelDataCustom?.custom_filter?.deeplink}
              />
            </BlunoTooltip>
          </div>
        </div>
      )}
    </>
  );
};

export default TimePeriodPanel;
