//react imports
import React, { useEffect, useRef, useState } from "react";

//redux imports
import endpoints from "../../api/endpoints";
import { apiRequest } from "../../redux/common/actions";
import { showToast } from "../../redux/toast/slice";
import { useDispatch, useSelector } from "react-redux";
import { updateUserBasicInfo } from "../../redux/common/slice";

//third party imports
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Checkbox, FormControlLabel } from "@mui/material";

//in app imports
import useCallbackPrompt from "../../hooks/useCallBackPrompt";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import Dropdown from "../../components/Dropdown";
import EditIdentity from "./EditIdentity";
import BlunoSwitch from "../../components/BlunoSwitch";
import CopyToClipboard from "../../components/CopyToClipboard";
import ImageUploader from "../../components/ImageUploader/ImageUploader";
import {
  BlockerAlert,
  getBlockerAlertBySection,
} from "../../app/constants/BlockerAlert";
import ModalV2 from "../../components/ModalV2";
import { DGFT_CONNECT_MODAL } from "../landing/constants";

import {
  CHECKBOX_VALUE,
  GENERAL_SETTINGS_SLUGS,
  IDENTITY_TYPE,
  INPUT_TYPES,
  SETTING_STATE,
} from "../../utils/constants/SettingsConstants";
import { formatUrl } from "../../utils/formatUrl";
import { InputError } from "../../app/constants/InputError";
import {
  generalKeyDownValidation,
  validateNumber,
} from "../../utils/Validation";
import BlunoTooltip from "../../components/BlunoTooltip";
import EditAddressPopup from "../../components/settings/general/EditAddressPopup";

const CompanyDetailsCard = ({
  data,
  docType = "",
  isEdit,
  setIsEdit,
  editObject = null,
  setEditObject,
  displayObject = null,
  setDisplayObject,
  handleSave,
  extraData = null,
  showPopup,
  setShowPopup,
  setPopupData,
  addressDisplayObject,
  setAddressDisplayObject,
  isBlockerActive,
  setIsBlockerActive,
  getGeneralSettingsData,
}) => {
  // reference variables
  const editingPhone = useRef(false);
  const editingEmail = useRef(false);

  // redux states
  const userInfoData = useSelector((state) => state.common.userBasicInfo);

  // local states
  const dispatch = useDispatch();
  const [consigneeObj, setConsigneeObj] = useState({});
  const [addressChecked, setAddressChecked] = useState(true);
  const [showCopySlug, setShowCopySlug] = useState(null);
  const [errorMessage, setErrorMessage] = useState({});
  const [blockerAlertData, setBlockerAlertData] = useState(
    BlockerAlert?.GENERAL_SETTINGS
  );
  const [avatar, setAvatar] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [docId, setDocId] = useState(null);
  const [screen, setScreen] = useState("");
  const [showEditAddressModal, setShowEditAddressModal] = useState(false);

  // custom hooks variables
  useCallbackPrompt(isBlockerActive, blockerAlertData); // Navigation blocker hook

  const checkPincode = async (pincode, inputSlug) => {
    const apiRes = await dispatch(
      apiRequest(endpoints?.checkPincode, `?pincode=${pincode}`)
    );

    if (apiRes?.success && apiRes?.data) {
      if (!apiRes?.data?.valid_pincode) {
        setErrorMessage({
          ...errorMessage,
          [GENERAL_SETTINGS_SLUGS?.PIN_CODE]: apiRes?.data?.error_message,
        });
      } else {
        setErrorMessage({
          ...errorMessage,
          [GENERAL_SETTINGS_SLUGS?.PIN_CODE]: "",
        });
      }
      if (addressDisplayObject) {
        setDisplayObject({
          ...displayObject,
          ...addressDisplayObject,
          [GENERAL_SETTINGS_SLUGS?.STATE]: apiRes?.data?.state,
          [GENERAL_SETTINGS_SLUGS?.CITY]: apiRes?.data?.city,
          [GENERAL_SETTINGS_SLUGS?.PIN_CODE]: pincode,
        });
        setEditObject({
          ...addressDisplayObject,
          [GENERAL_SETTINGS_SLUGS?.STATE]: apiRes?.data?.state,
          [GENERAL_SETTINGS_SLUGS?.CITY]: apiRes?.data?.city,
          [GENERAL_SETTINGS_SLUGS?.PIN_CODE]: pincode,
        });
        setAddressDisplayObject(null);
      } else {
        setDisplayObject({
          ...displayObject,
          [GENERAL_SETTINGS_SLUGS?.STATE]: apiRes?.data?.state,
          [GENERAL_SETTINGS_SLUGS?.CITY]: apiRes?.data?.city,
          [GENERAL_SETTINGS_SLUGS?.PIN_CODE]: pincode,
        });
        setEditObject({
          ...editObject,
          [GENERAL_SETTINGS_SLUGS?.STATE]: apiRes?.data?.state,
          [GENERAL_SETTINGS_SLUGS?.CITY]: apiRes?.data?.city,
          [GENERAL_SETTINGS_SLUGS?.PIN_CODE]: pincode,
        });
      }
    }
  };

  const validateAllAddressFields = () => {
    const errorObj = {};
    if (
      editObject?.[GENERAL_SETTINGS_SLUGS?.IS_ADDRESS_SAME] ===
        CHECKBOX_VALUE?.NO ||
      (!editObject?.[GENERAL_SETTINGS_SLUGS?.IS_ADDRESS_SAME] &&
        (editObject?.[GENERAL_SETTINGS_SLUGS?.ADDRESS_LINE_1] ||
          editObject?.[GENERAL_SETTINGS_SLUGS?.CITY] ||
          editObject?.[GENERAL_SETTINGS_SLUGS?.STATE] ||
          editObject?.[GENERAL_SETTINGS_SLUGS?.PIN_CODE]))
    ) {
      if (
        !addressChecked &&
        (!editObject?.[GENERAL_SETTINGS_SLUGS?.ADDRESS_LINE_1] ||
          editObject?.[GENERAL_SETTINGS_SLUGS?.ADDRESS_LINE_1]?.length === 0)
      ) {
        errorObj[GENERAL_SETTINGS_SLUGS?.ADDRESS_LINE_1] =
          "Address line 1 cannot be empty";
      } else {
        errorObj[GENERAL_SETTINGS_SLUGS?.ADDRESS_LINE_1] = "";
      }

      if (
        !editObject?.[GENERAL_SETTINGS_SLUGS?.CITY] ||
        editObject?.[GENERAL_SETTINGS_SLUGS?.CITY]?.length === 0
      ) {
        errorObj[GENERAL_SETTINGS_SLUGS?.CITY] = "City cannot be empty";
      } else {
        errorObj[GENERAL_SETTINGS_SLUGS?.CITY] = "";
      }

      if (
        !editObject?.[GENERAL_SETTINGS_SLUGS?.STATE] ||
        editObject?.[GENERAL_SETTINGS_SLUGS?.STATE]?.length === 0
      ) {
        errorObj[GENERAL_SETTINGS_SLUGS?.STATE] = "State cannot be empty";
      } else {
        errorObj[GENERAL_SETTINGS_SLUGS?.STATE] = "";
      }
      if (
        !editObject?.[GENERAL_SETTINGS_SLUGS?.PIN_CODE] ||
        editObject?.[GENERAL_SETTINGS_SLUGS?.PIN_CODE]?.length === 0
      ) {
        errorObj[GENERAL_SETTINGS_SLUGS?.PIN_CODE] = "Pin Code cannot be empty";
      } else if (editObject?.[GENERAL_SETTINGS_SLUGS?.PIN_CODE]?.length < 6) {
        errorObj[GENERAL_SETTINGS_SLUGS?.PIN_CODE] =
          "Pin Code must be 6 characters long";
      } else {
        errorObj[GENERAL_SETTINGS_SLUGS?.PIN_CODE] =
          errorMessage?.[GENERAL_SETTINGS_SLUGS?.PIN_CODE];
      }

      setErrorMessage((prevErrorObj) => ({ ...prevErrorObj, ...errorObj }));
      if (
        errorObj?.[GENERAL_SETTINGS_SLUGS?.ADDRESS_LINE_1] ||
        errorObj?.[GENERAL_SETTINGS_SLUGS?.CITY] ||
        errorObj?.[GENERAL_SETTINGS_SLUGS?.STATE] ||
        errorObj?.[GENERAL_SETTINGS_SLUGS?.PIN_CODE]
      ) {
        return false;
      }
    }
    return true;
  };

  const onUpdateBlockerAlertData = (data) => {
    setBlockerAlertData(data);
  };

  const handleButtonClick = (theSlug) => {
    if (theSlug === GENERAL_SETTINGS_SLUGS?.SAVE_CORRESPONDENCE_ADDRESS) {
      if (!validateAllAddressFields()) {
        return;
      }
      handleSave(theSlug, true);
      setShowEditAddressModal(false);
    } else if (theSlug === GENERAL_SETTINGS_SLUGS?.SAVE_WEBSITE) {
      handleSave(theSlug);
    } else if (theSlug === GENERAL_SETTINGS_SLUGS?.COMPANY_WEBSITE) {
      setIsEdit((prev) =>
        JSON.stringify({ ...JSON.parse(prev), editWebsite: true })
      );
    } else if (theSlug === GENERAL_SETTINGS_SLUGS?.CORRESPONDENCE_ADDRESS) {
      // setIsEdit((prev) =>
      //   JSON.stringify({
      //     ...JSON.parse(prev),
      //     editCorrespondenceAddress: true,
      //   })
      // );
      setShowEditAddressModal(true);
    } else if (theSlug === GENERAL_SETTINGS_SLUGS?.CLOSE_EDIT_ADDRESS_POPUP) {
      setShowEditAddressModal(false);
      setIsBlockerActive(false);
      getGeneralSettingsData();
    }
  };

  const onMouseHoverEnter = (theSlug) => {
    setShowCopySlug(theSlug);
  };

  const onMouseHoverLeave = (theSlug) => {
    setShowCopySlug(null);
  };

  const validateViewOnlyCorrespondenceAddress = (theSlug) => {
    if (addressChecked && theSlug === GENERAL_SETTINGS_SLUGS?.COUNTRY) {
      return false;
    }

    return true;
  };

  const validateEditOnlyCorrespondenceAddress = (theSlug) => {
    if (
      addressChecked &&
      (theSlug === GENERAL_SETTINGS_SLUGS?.ADDRESS_LINE_1 ||
        theSlug === GENERAL_SETTINGS_SLUGS?.ADDRESS_LINE_2 ||
        theSlug === GENERAL_SETTINGS_SLUGS?.CITY ||
        theSlug === GENERAL_SETTINGS_SLUGS?.STATE ||
        theSlug === GENERAL_SETTINGS_SLUGS?.PIN_CODE)
    ) {
      return false;
    }

    return true;
  };

  const renderAddressUtil = (theAddress) => {
    const line3 =
      (theAddress?.city ? theAddress?.city : "") +
      (theAddress?.state ? `, ${theAddress?.state},` : "");

    const line4 =
      (theAddress?.country ? theAddress?.country : "") +
      (theAddress?.pin_code ? ` - ${theAddress?.pin_code}` : "");

    return (
      <p
        className={`g-settings__address ${
          addressChecked ? "g-settings__address--same" : ""
        }`}
      >
        {theAddress?.address_line1 && (
          <span className="g-settings__address-line">
            {`${theAddress?.address_line1},`}
          </span>
        )}
        {theAddress?.address_line2 && (
          <span className="g-settings__address-line">
            {`${theAddress?.address_line2},`}
          </span>
        )}
        {line3 && <span className="g-settings__address-line">{line3}</span>}
        {line4 && <span className="g-settings__address-line">{line4}</span>}
      </p>
    );
  };

  const renderViewOnlyFields = (theItem, showLabel = true, fieldType) => {
    return (
      <>
        {validateViewOnlyCorrespondenceAddress(theItem?.view_only?.slug) && (
          <div
            className="g-settings__fields-item"
            key={theItem?.view_only?.slug}
            style={{ gridColumn: theItem?.colspan }}
          >
            {showLabel && (
              <p
                className="g-settings__fields-label"
                style={{
                  padding: !theItem?.view_only?.label_edit_icon
                    ? "7.88px 0px"
                    : "",
                }}
              >
                {theItem?.view_only?.label}
                {theItem?.view_only?.label_edit_icon && (
                  <BlunoTooltip
                    useChildren={true}
                    text={theItem?.view_only?.label_edit_icon_tooltip}
                    setTextDangerously={true}
                  >
                    <div
                      className={`g-settings__fields-edit ${
                        theItem?.view_only?.slug ===
                          GENERAL_SETTINGS_SLUGS?.COMPANY_ADDRESS &&
                        "g-settings__fields-edit--address"
                      }`}
                      onClick={() => {
                        handleButtonClick(theItem?.view_only?.slug);
                      }}
                    >
                      <img
                        src={theItem?.view_only?.label_edit_icon}
                        className="g-settings__fields-edit-img"
                      />
                    </div>
                  </BlunoTooltip>
                )}
              </p>
            )}
            {fieldType === "hyperlink" ? (
              <div className="g-settings__fields-label-hyperlink">
                {theItem?.view_only?.value ? (
                  <a
                    className="g-settings__fields-value g-settings__fields-hyperlink g-settings__fields--no-padding"
                    href={formatUrl(theItem?.view_only?.value)}
                    target="_new"
                  >
                    {theItem?.view_only?.value}
                  </a>
                ) : (
                  <i className="g-settings__fields-value g-settings__fields-italics g-settings__fields--no-padding">
                    {theItem?.view_only?.info_text}
                  </i>
                )}
                {theItem?.view_only?.label_edit_icon && (
                  <BlunoTooltip
                    useChildren={true}
                    text={theItem?.view_only?.label_edit_icon_tooltip}
                  >
                    <div
                      className="g-settings__fields-edit"
                      onClick={() => {
                        handleButtonClick(theItem?.view_only?.slug);
                      }}
                    >
                      <img
                        src={theItem?.view_only?.label_edit_icon}
                        className="g-settings__fields-edit-img"
                      />
                    </div>
                  </BlunoTooltip>
                )}
              </div>
            ) : (
              <p
                className="g-settings__fields-value"
                onMouseEnter={() => {
                  onMouseHoverEnter(theItem?.view_only?.slug);
                }}
                onMouseLeave={() => {
                  onMouseHoverLeave(theItem?.view_only?.slug);
                }}
              >
                <>
                  {theItem?.view_only?.slug ===
                    GENERAL_SETTINGS_SLUGS?.COMPANY_ADDRESS ||
                  theItem?.view_only?.slug ===
                    GENERAL_SETTINGS_SLUGS?.CORRESPONDENCE_ADDRESS ? (
                    <>
                      {!theItem?.view_only?.info_text ? (
                        <>{renderAddressUtil(theItem?.view_only?.address)}</>
                      ) : (
                        <i className="g-settings__fields-italics">
                          {theItem?.view_only?.info_text}
                        </i>
                      )}
                    </>
                  ) : (
                    <>
                      {theItem?.view_only?.value ? (
                        <span>{theItem?.view_only?.value}</span>
                      ) : (
                        <i className="g-settings__fields-italics">
                          {theItem?.view_only?.info_text}
                        </i>
                      )}
                    </>
                  )}
                </>

                {theItem?.view_only?.icon && (
                  <img
                    className="g-settings__fields-status"
                    src={theItem?.view_only?.icon}
                    alt="status"
                  />
                )}
                {theItem?.view_only?.enable_copy &&
                  theItem?.view_only?.slug === showCopySlug && (
                    <div style={{ marginLeft: "-5px" }}>
                      <CopyToClipboard text={theItem?.view_only?.value} />
                    </div>
                  )}
              </p>
            )}
            {theItem?.view_only?.value_list && (
              <div>
                {theItem?.view_only?.value_list?.map((val, index) => {
                  return (
                    <p
                      className="g-settings__fields-value"
                      onMouseEnter={() => {
                        onMouseHoverEnter(`${val?.value}-${index}`);
                      }}
                      onMouseLeave={() => {
                        onMouseHoverLeave(val?.value);
                      }}
                    >
                      <span>{val?.value}</span>
                      {val?.enable_copy === true &&
                        `${val?.value}-${index}` === showCopySlug && (
                          <div style={{ marginLeft: "-5px" }}>
                            <CopyToClipboard text={val?.copy_text} />
                          </div>
                        )}
                    </p>
                  );
                })}
              </div>
            )}
            {theItem?.view_only?.subtext && (
              <i className="g-settings__fields-subtext">
                {theItem?.view_only?.subtext}
              </i>
            )}

            {theItem?.declaration && (
              <div
                style={{
                  marginTop: "10px",
                  paddingTop: "15px",
                  borderTop: "1px solid #d1d1d1",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checkedIcon={<CheckBoxIcon />}
                      checked={addressChecked ? true : undefined}
                      onChange={() => {
                        setAddressChecked(!addressChecked);
                        handleInputChange(
                          !addressChecked === true ? "yes" : "no",
                          theItem?.declaration?.slug
                        );
                      }}
                      style={{
                        padding: 0,
                      }}
                    />
                  }
                  label={
                    <p className="sec-settings__2fa--label">
                      {theItem?.declaration?.text}
                    </p>
                  }
                  defaultValue={true}
                  style={{ marginLeft: 0 }}
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const handleInputChange = (inputValue, inputSlug) => {
    setIsBlockerActive(true);
    setBlockerAlertData(
      getBlockerAlertBySection(
        data?.section === "user_section" ? "user profile" : "company details"
      )
    );

    if (
      inputSlug === GENERAL_SETTINGS_SLUGS?.PIN_CODE &&
      inputValue?.length === 6
    ) {
      checkPincode(inputValue, inputSlug);
    }

    if (
      inputSlug === GENERAL_SETTINGS_SLUGS?.PIN_CODE &&
      inputValue?.length > 6
    ) {
      setErrorMessage({
        ...errorMessage,
        [GENERAL_SETTINGS_SLUGS?.PIN_CODE]:
          InputError?.IncorrectLengthPincode?.message,
      });

      return;
    }

    if (addressDisplayObject) {
      setDisplayObject({
        ...displayObject,
        ...addressDisplayObject,
        [inputSlug]: inputValue,
      });
      setEditObject({
        ...addressDisplayObject,
        [inputSlug]: inputValue,
      });
      setErrorMessage((prev) => {
        const { [inputSlug]: _, ...rest } = prev;
        return rest;
      });
      setAddressDisplayObject(null);
    } else {
      setDisplayObject({
        ...displayObject,
        [inputSlug]: inputValue,
      });
      setEditObject({
        ...editObject,
        [inputSlug]: inputValue,
      });
      setErrorMessage((prev) => {
        const { [inputSlug]: _, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleKeyDown = (value, slug, e) => {
    switch (slug) {
      case GENERAL_SETTINGS_SLUGS?.PIN_CODE:
        if (generalKeyDownValidation(validateNumber, value, e)) {
          e.preventDefault();
        }
        break;
    }
  };

  const renderEditOnlyFields = (theItem, showLabel = true) => {
    const inputType = theItem?.edit_only?.type;
    switch (inputType) {
      case INPUT_TYPES?.TEXT_BOX:
        return (
          <>
            {validateEditOnlyCorrespondenceAddress(
              theItem?.edit_only?.slug
            ) && (
              <div
                className="g-settings__fields-item"
                key={theItem?.edit_only?.slug}
                style={{ gridColumn: theItem?.colspan }}
              >
                {showLabel && (
                  <p className="g-settings__fields-label">
                    {theItem?.edit_only?.title}
                  </p>
                )}
                <div className="g-settings__fields-input">
                  <TextInput
                    placeholder={theItem?.edit_only?.placeholder}
                    type={theItem?.edit_only?.sub_type}
                    iconAlignment="none"
                    height="30px"
                    value={
                      displayObject?.[theItem?.edit_only?.slug]
                        ? displayObject?.[theItem?.edit_only?.slug]
                        : addressDisplayObject?.[theItem?.edit_only?.slug]
                    }
                    onChange={(e) => {
                      handleInputChange(
                        e.target.value,
                        theItem?.edit_only?.slug
                      );
                    }}
                    onKeyDown={(e) =>
                      handleKeyDown(e.key, theItem?.edit_only?.slug, e)
                    }
                    maxLength={theItem?.edit_only?.limits?.max}
                    errorMessage={errorMessage?.[theItem?.edit_only?.slug]}
                  />
                </div>
              </div>
            )}
          </>
        );
      case INPUT_TYPES?.DROPDOWN:
        return (
          <>
            {validateEditOnlyCorrespondenceAddress(
              theItem?.edit_only?.slug
            ) && (
              <div
                className="g-settings__fields-item"
                key={theItem?.edit_only?.slug}
                style={{ gridColumn: theItem?.colspan }}
              >
                {showLabel && (
                  <p className="g-settings__fields-label">
                    {theItem?.edit_only?.title}
                  </p>
                )}
                <div className="g-settings__fields-input">
                  <Dropdown
                    options={theItem?.edit_only?.options}
                    placeholder={theItem?.edit_only?.placeholder}
                    defaultValue={
                      displayObject?.[theItem?.edit_only?.slug]
                        ? displayObject?.[theItem?.edit_only?.slug]
                        : addressDisplayObject?.[theItem?.edit_only?.slug]
                    }
                    height="30px"
                    fontSize="13px"
                    placeholderFontSize="13px"
                    placeholderFontWeight="400"
                    onSelectChange={(e) => {
                      handleInputChange(e.value, theItem?.edit_only?.slug);
                    }}
                    errorMessage={errorMessage?.[theItem?.edit_only?.slug]}
                  />
                </div>
              </div>
            )}
          </>
        );
      case INPUT_TYPES?.BUTTON:
        return (
          <>
            <Button
              buttonType={theItem?.edit_only?.sub_type}
              buttonLabel={theItem?.edit_only?.text}
              width="100%"
              onClick={() => handleButtonClick(theItem?.edit_only?.slug)}
              icon={theItem?.edit_only?.icon}
              iconAlignment={theItem?.edit_only?.icon_alignment}
            />
          </>
        );
        break;
      default:
        return (
          <>
            {validateEditOnlyCorrespondenceAddress(
              theItem?.edit_only?.slug
            ) && (
              <div
                className="g-settings__fields-item"
                key={theItem?.edit_only?.slug}
                style={{ gridColumn: theItem?.colspan }}
              >
                <p className="g-settings__fields-label">
                  {theItem?.edit_only?.label}
                </p>
                <p className="g-settings__fields-value">
                  <span>
                    {displayObject?.[theItem?.edit_only?.slug]
                      ? displayObject?.[theItem?.edit_only?.slug]
                      : addressDisplayObject?.[theItem?.edit_only?.slug]}
                  </span>
                </p>
              </div>
            )}
          </>
        );
    }
  };

  const getAddressDiplay = (key, address) => {
    return {
      [GENERAL_SETTINGS_SLUGS?.ADDRESS_LINE_1]: address?.address_line1,
      [GENERAL_SETTINGS_SLUGS?.ADDRESS_LINE_2]: address?.address_line2,
      [GENERAL_SETTINGS_SLUGS?.PIN_CODE]: address?.pin_code,
      [GENERAL_SETTINGS_SLUGS?.CITY]: address?.city,
      [GENERAL_SETTINGS_SLUGS?.STATE]: address?.state,
      [GENERAL_SETTINGS_SLUGS?.COUNTRY]: address?.country,
    };
  };

  useEffect(() => {
    const tempObject = {};

    data?.fields?.forEach((item) => {
      if (item?.is_editable) {
        tempObject[item?.edit_only?.slug] =
          item?.edit_only?.default_value || item?.edit_only?.value || "";
      }
      if (item?.declaration) {
        tempObject[item?.declaration?.slug] = item?.declaration?.checked
          ? "yes"
          : "no";
      }

      if (item?.view_only?.slug === "company_address" && item?.declaration) {
        setAddressChecked(item?.declaration?.checked);
      }
      if (
        item?.view_only?.slug === GENERAL_SETTINGS_SLUGS?.CORRESPONDENCE_ADDRESS
      ) {
        // let displayObject = getAddressDiplay(
        //   item?.view_only?.address?.address_type.toLowerCase(),
        //   item?.view_only?.address
        // );
        setAddressDisplayObject(
          getAddressDiplay(
            item?.view_only?.address?.address_type.toLowerCase(),
            item?.view_only?.address
          )
        );
      }
    });

    data?.left_side_fields?.forEach((item) => {
      if (item?.is_editable) {
        tempObject[item?.edit_only?.slug] =
          item?.edit_only?.default_value || "";
      }
    });
    setDisplayObject(tempObject);
    setAvatar(
      data?.profile_picture?.icon || data?.profile_picture?.fallback_icon
    );
    setDocId(data?.profile_picture?.document_id);
  }, [data]);

  const saveAvatar = async (croppedFile, croppedBase64Data) => {
    if (croppedFile && croppedBase64Data) {
      const formData = new FormData();
      const metadata = {
        flow: "general_settings",
      };

      formData.append("file", croppedFile);
      formData.append("base_64_data", croppedBase64Data);
      formData.append("doc_type", docType);
      formData.append("metadata", JSON.stringify(metadata));

      const dataRes = await dispatch(
        apiRequest(endpoints.uploadImage, formData, null, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      );

      if (dataRes?.success && dataRes?.data) {
        setAvatar(dataRes?.data?.base_64_data);
        setDocId(dataRes?.data?.document_id);
        setShowModal(false);
        docType === "USER_PROFILE_PICTURE" &&
          dispatch(
            updateUserBasicInfo({
              ...userInfoData,
              profile_icon: dataRes?.data?.base_64_data,
            })
          );
        setScreen("update");
      } else {
        // error toast message
        if (dataRes?.data?.toast) {
          dispatch(showToast(dataRes?.data?.toast));
        }
      }
    }
  };

  const removeAvatar = async () => {
    const dataRes = await dispatch(
      apiRequest(endpoints.removeImage, {
        action: "detach",
        document_id: docId,
        document_type: docType,
      })
    );
    if (dataRes?.success) {
      setAvatar(data?.profile_picture?.fallback_icon);
      docType === "USER_PROFILE_PICTURE" &&
        dispatch(
          updateUserBasicInfo({
            ...userInfoData,
            profile_icon: data?.profile_picture?.fallback_icon,
          })
        );
      setScreen("add");
    }
  };

  useEffect(() => {
    let temp = {};
    extraData?.directors?.forEach((item) => {
      temp[item?.director_id] = item?.is_consignee;
    });
    setConsigneeObj(temp);
  }, []);

  return (
    <>
      {showEditAddressModal && (
        <div className="manage-settings__add-user-modal-bg">
          <ModalV2 slug={"standard"}>
            <EditAddressPopup
              data={data?.edit_address_modal}
              renderEditOnlyFields={renderEditOnlyFields}
              renderViewOnlyFields={renderViewOnlyFields}
              handleButtonClick={handleButtonClick}
            />
          </ModalV2>
        </div>
      )}
      <div className="g-settings__cont">
        <p className="g-settings__title">{data?.title}</p>
        <div className="g-settings__card">
          <div className="g-settings__left">
            {data?.profile_picture && (
              <>
                <div
                  className="g-settings__avatar-cont"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <img
                    className="g-settings__avatar"
                    src={avatar}
                    alt="avatar"
                  />
                  {data?.profile_picture?.is_editable && (
                    <div className="g-settings__avatar-edit">
                      <BlunoTooltip
                        useChildren={true}
                        text={data?.profile_picture?.edit_icon_tooltip}
                      >
                        <img
                          className="g-settings__avatar-edit-image"
                          src={data?.profile_picture?.edit_icon}
                          alt="edit"
                        />
                      </BlunoTooltip>
                    </div>
                  )}
                </div>
                {showModal && (
                  <ModalV2 slug="standard">
                    <ImageUploader
                      avatar={avatar}
                      data={data?.profile_picture}
                      saveAvatar={saveAvatar}
                      removeAvatar={removeAvatar}
                      setShowModal={setShowModal}
                      screen={screen}
                      setScreen={setScreen}
                    />
                  </ModalV2>
                )}
              </>
            )}
            <div className="g-settings__additional">
              {data?.left_side_fields &&
                data?.left_side_fields?.map((item) => {
                  return item?.is_editable
                    ? renderEditOnlyFields(item, false)
                    : renderViewOnlyFields(item, false, item?.field_type);
                })}
            </div>
          </div>
          <div className="g-settings__right">
            <div
              className="g-settings__fields"
              style={{ gridTemplateColumns: data?.grid_template_columns }}
            >
              <>
                {data?.fields?.map((item) => {
                  return item?.field_type === "hr_line" ? (
                    <hr className="g-settings__horizontal" />
                  ) : item?.is_editable ? (
                    renderEditOnlyFields(item)
                  ) : (
                    renderViewOnlyFields(item)
                  );
                })}
              </>
              <div className="g-settings__fields-editable">
                {data?.edit_phone_number && (
                  <div className="g-settings__fields-input">
                    <EditIdentity
                      data={data?.edit_phone_number}
                      identityType={IDENTITY_TYPE?.PHONE}
                      editingPhone={editingPhone}
                      editingEmail={editingEmail}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      setPopupData={setPopupData}
                      setIsBlockerActive={setIsBlockerActive}
                      onUpdateBlockerAlertData={onUpdateBlockerAlertData}
                    />
                  </div>
                )}
                {data?.edit_email_id && (
                  <div className="g-settings__fields-input">
                    <EditIdentity
                      data={data?.edit_email_id}
                      identityType={IDENTITY_TYPE?.EMAIL}
                      editingPhone={editingPhone}
                      editingEmail={editingEmail}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      setPopupData={setPopupData}
                      setIsBlockerActive={setIsBlockerActive}
                      onUpdateBlockerAlertData={onUpdateBlockerAlertData}
                    />
                  </div>
                )}
              </div>
              <></>
            </div>
            {extraData && (
              <div className="g-settings__director">
                <div className="g-settings__consignee">
                  <div className="g-settings__consignee-detail">
                    <p className="g-settings__fields-label">
                      {extraData?.consignee?.director_text}
                    </p>
                    <p className="g-settings__fields-value">
                      {extraData?.consignee?.director_name}
                    </p>
                  </div>
                  <div>
                    <p className="g-settings__fields-label">
                      {extraData?.consignee?.pan_text}
                    </p>
                    <p className="g-settings__fields-value">
                      {extraData?.consignee?.pan_number}
                    </p>
                  </div>
                </div>
                <hr className="g-settings__horizontal" />
                <div className="g-settings__switch">
                  <div>
                    <p className="g-settings__fields-label">
                      {extraData?.title}
                    </p>
                    <div className="g-settings__director-list">
                      {extraData?.directors?.map((item) => {
                        return (
                          <p className="g-settings__fields-value">
                            {item?.director_name}
                            {!isEdit && item?.is_consignee && (
                              <img
                                src="https://static.bluno.in/miscl/green-star-outlined.png"
                                className="g-settings__fields-star"
                              />
                            )}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  {isEdit && (
                    <div>
                      <p className="g-settings__fields-label">
                        Is he authorized signatory?
                      </p>
                      <div className="g-settings__director-list">
                        {extraData?.directors?.map((item) => {
                          return (
                            <div className="g-settings__toggle">
                              <BlunoSwitch
                                isToggleActive={
                                  consigneeObj &&
                                  consigneeObj?.[item?.director_id] === true
                                }
                                handleToggleChange={() => {
                                  setConsigneeObj({
                                    ...consigneeObj,
                                    [item?.director_id]:
                                      !consigneeObj?.[item?.director_id],
                                  });
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {data?.edit_buttons && (
              <div className="g-settings__edit-btn">
                {data?.edit_buttons?.map((btn) => {
                  return (
                    <Button
                      buttonLabel={btn?.text}
                      buttonType={btn?.sub_type}
                      onClick={() => {
                        handleButtonClick(btn?.slug);
                      }}
                      icon={btn?.icon}
                      iconAlignment="left"
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && <div className="g-settings__modal-cont" />}
    </>
  );
};

export default CompanyDetailsCard;
