import React from "react";
import BlunoTableDropdown from "../BlunoTableDropdown";
import BlunoTableOptions from "../BlunoTableOptions";
import BlunoTooltip from "../BlunoTooltip";

export default function ActionColumn({
  data,
  row,
  OptionsComponent,
  onOptionsFunction,
  tableRef,
  tableHeadRef,
  showHoverBlueRow,
  specialColumnFunction,
  highlightRow,
  unHighlightRow,
}) {
  return (
    <div className="document-management__column">
      {data?.icons?.map((item, idx) => {
        return (
          <BlunoTooltip
            useChildren={true}
            text={item?.hover_text}
            alignment="center"
            position="fixed"
            left="4px"
          >
            <img
              src={item?.icon}
              className={`document-management__column-icon ${
                item?.disabled
                  ? "document-management__column-icon--disabled"
                  : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                if (!item?.disabled)
                  specialColumnFunction(
                    item?.slug,
                    row?.id,
                    item?.doc_id,
                    item?.sub_text
                  );
              }}
            />
          </BlunoTooltip>
        );
      })}
      <BlunoTableOptions
        row={row}
        OptionsComponent={OptionsComponent}
        onOptionsFunction={onOptionsFunction}
        header={{ slug: "options" }}
        tableRef={tableRef}
        tableHeadRef={tableHeadRef}
        showHoverBlueRow={showHoverBlueRow}
        highlightRow={highlightRow}
        unHighlightRow={unHighlightRow}
      />
    </div>
  );
}
