// react imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// redux imports
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";

// in-app imports
import Connections from "../../components/settings/security/Connections";
import LoginDevice from "../../components/settings/security/LoginDevice";
import { SECURITY_PASSWORD_SLUGS } from "../../utils/constants/SettingsConstants";
import { InputError } from "../../app/constants/InputError";
import { validatePassword } from "../../utils/Validation";
import SecurityCard from "../../modules/Settings/SecurityCard";

const Security = () => {
  // defined to access react based apis
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // local states
  const [data, setData] = useState(null);
  const [editDetails, setEditDetails] = useState(false);
  const [loginEditObject, setLoginEditObject] = useState({});
  const [mfaEditObject, setMfaEditObject] = useState({});
  const [loginDisplayObject, setLoginDisplayObject] = useState({});
  const [errorMessagesObject, setErrorMessagesObject] = useState({});
  const [isInputTypePassword, setIsInputTypePassword] = useState({});
  const [isBlockerActive, setIsBlockerActive] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const onLogoutDevice = (action, deviceId, isCurrentDevice) => {
    const onLogout = async () => {
      const apiRes = await dispatch(
        apiRequest(endpoints.logoutPreviousDevice, {}, `?device_id=${deviceId}`)
      );

      if (apiRes?.success && apiRes?.data) {
        if (isCurrentDevice) {
          localStorage.clear();
          navigate("/onboarding/login");
        }
        setData(apiRes?.data);
      }
    };
    onLogout();
  };

  const saveLoginDetails = async (body, action) => {
    const apiRes = await dispatch(
      apiRequest(endpoints.saveSecuritySettings, body)
    );

    if (apiRes?.success) {
      if (action === "password_action") {
        setEditDetails(false);
        setIsBlockerActive(false);
      }
    } else {
      if (apiRes?.data?.toast) {
        setErrorMessagesObject((prevErrorObj) => {
          return {
            ...prevErrorObj,
            [SECURITY_PASSWORD_SLUGS?.CURRENT_PASSWORD]:
              apiRes?.data?.toast?.data?.center?.text,
          };
        });
      } else if (apiRes?.data?.slug_message_map) {
        let error = {};
        const errorMessageMap = apiRes?.data?.slug_message_map;
        Object.keys(errorMessageMap)?.forEach((item) => {
          error[item] = errorMessageMap?.[item]?.text;
        });
        setErrorMessagesObject(error);
      }
    }
  };

  const validateSecurityPasswords = () => {
    // if (
    //   !loginEditObject?.[SECURITY_PASSWORD_SLUGS?.CURRENT_PASSWORD] &&
    //   !loginEditObject?.[SECURITY_PASSWORD_SLUGS?.NEW_PASSWORD] &&
    //   !loginEditObject?.[SECURITY_PASSWORD_SLUGS?.REENTERED_NEW_PASSWORD]
    // ) {
    //   return 1;
    // }
    if (!loginEditObject?.[SECURITY_PASSWORD_SLUGS?.CURRENT_PASSWORD]) {
      setErrorMessagesObject((prevErrorObj) => {
        return {
          ...prevErrorObj,
          [SECURITY_PASSWORD_SLUGS?.CURRENT_PASSWORD]:
            InputError?.EmptyCurrentPassword?.message,
        };
      });
      return 0;
    }

    if (!loginEditObject?.[SECURITY_PASSWORD_SLUGS?.NEW_PASSWORD]) {
      setErrorMessagesObject((prevErrorObj) => {
        return {
          ...prevErrorObj,
          [SECURITY_PASSWORD_SLUGS?.NEW_PASSWORD]:
            InputError?.EmptyNewPassword?.message,
        };
      });
      return 0;
    }

    if (!loginEditObject?.[SECURITY_PASSWORD_SLUGS?.REENTERED_NEW_PASSWORD]) {
      setErrorMessagesObject((prevErrorObj) => {
        return {
          ...prevErrorObj,
          [SECURITY_PASSWORD_SLUGS?.REENTERED_NEW_PASSWORD]:
            InputError?.EmptyReEnteredNewPassword?.message,
        };
      });
      return 0;
    }

    const validationObj = validatePassword(
      loginEditObject?.[SECURITY_PASSWORD_SLUGS?.NEW_PASSWORD],
      true
    );
    if (!validationObj?.success) {
      setErrorMessagesObject((prevErrorObj) => {
        return {
          ...prevErrorObj,
          [SECURITY_PASSWORD_SLUGS?.NEW_PASSWORD]: validationObj?.message,
        };
      });
      return 0;
    }

    if (
      loginEditObject?.[SECURITY_PASSWORD_SLUGS?.REENTERED_NEW_PASSWORD] !==
      loginEditObject?.[SECURITY_PASSWORD_SLUGS?.NEW_PASSWORD]
    ) {
      setErrorMessagesObject((prevErrorObj) => {
        return {
          ...prevErrorObj,
          [SECURITY_PASSWORD_SLUGS?.REENTERED_NEW_PASSWORD]:
            InputError?.PasswordMismatch?.message,
        };
      });
      return 0;
    }

    return 1;
  };

  const handleButtonClick = (theSlug) => {
    if (theSlug === "edit_state") {
      if (!validateSecurityPasswords()) {
        return;
      }

      saveLoginDetails(loginEditObject, "password_action");
    } else {
      // setEditDetails(true);
      setShowModal(true);
    }
  };

  useEffect(() => {
    const getSecuritySettingsData = async () => {
      const apiRes = await dispatch(
        apiRequest(
          endpoints.getSecuritySettings,
          `?state=${editDetails ? "edit_state" : "view_state"}`
        )
      );

      if (apiRes?.success && apiRes?.data) {
        setData(apiRes?.data);
      }
    };
    getSecuritySettingsData();
  }, [editDetails]);

  return (
    <>
      <div className="sec-settings">
        <div className="sec-settings__top-section">
          <SecurityCard
            data={data?.security_settings?.login_details}
            editObject={loginEditObject}
            setEditObject={setLoginEditObject}
            mfaEditObject={mfaEditObject}
            setMfaEditObject={setMfaEditObject}
            displayObject={loginDisplayObject}
            setDisplayObject={setLoginDisplayObject}
            saveLoginDetails={saveLoginDetails}
            handleButtonClick={handleButtonClick}
            isInputTypePassword={isInputTypePassword}
            setIsInputTypePassword={setIsInputTypePassword}
            errorMessagesObject={errorMessagesObject}
            setErrorMessagesObject={setErrorMessagesObject}
            isBlockerActive={isBlockerActive}
            setIsBlockerActive={setIsBlockerActive}
            setShowModal={setShowModal}
            showModal={showModal}
          />
        </div>
        <div className="g-settings__cont sec-settings__bottom-section">
          <p className="g-settings__title">
            {data?.security_settings?.past_logins?.title}
          </p>
          <div
            className="g-settings__card"
            style={{ overflowY: "auto", flex: "1 1" }}
          >
            <div className="sec-settings__pre-logins">
              <div className="sec-settings__devices">
                {data?.security_settings?.past_logins?.login_devices?.map(
                  (device) => {
                    return (
                      <LoginDevice
                        data={device}
                        onLogoutDevice={onLogoutDevice}
                      />
                    );
                  }
                )}
              </div>
              <Connections
                social={data?.security_settings?.past_logins?.social_links}
                policy={data?.security_settings?.past_logins?.policy}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Security;
