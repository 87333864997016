// react imports
import { createSlice } from "@reduxjs/toolkit";

// in-app imports

export const rbacSlice = createSlice({
  name: "rbac",
  initialState: {
    features: {},
  },
  reducers: {
    setFeatures: (state, action) => {
      state.features = action?.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFeatures } = rbacSlice.actions;

export default rbacSlice.reducer;
