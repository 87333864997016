import React, { useState } from "react";
import BlunoTooltip from "./BlunoTooltip";

const HomeHelpCard = ({ data }) => {
  return (
    <div className={`home-help`}>
      <div className="home-help__top">
        <div className="home-help__top-left">
          <h2 className="home-help__title">{data?.top?.title}</h2>
          {
            <p className="home-help__refresh-date">
              {data?.top?.last_refresh_date}
            </p>
          }
        </div>
      </div>

      <ul className="home-help__middle">
        {data?.middle?.news?.map((item, index) => {
          return (
            <div className="home-help__news">
              <p className="home-help__news-date">{item?.hz_text}</p>
              <p className="home-help__news-text">
                {item?.text}{" "}
                <span onClick={() => window.open(item?.deeplink, "_blank")}>
                  {item?.sub_text}
                </span>
              </p>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default HomeHelpCard;
