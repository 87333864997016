import React, { useEffect } from "react";
import Button from "./Button";
import CopyToClipboard from "./CopyToClipboard";
import { useDispatch, useSelector } from "react-redux";
import { showAlert } from "../redux/alert/slice";
import { showLoader } from "../redux/loader/slice";
import usePrevious from "../hooks/usePrevious";
import { updateShowSupportForm } from "../redux/common/slice";

export default function SupportModal({ data }) {
  const dispatch = useDispatch();
  const isShowLoader = useSelector((state) => state.loader.isShowLoader);
  const prevShowLoader = usePrevious(isShowLoader);

  const closeModal = () => {
    dispatch(updateShowSupportForm(false));
  };

  useEffect(() => {
    if (!isShowLoader && prevShowLoader) closeModal();
  }, [isShowLoader]);
  return (
    <div className="support-modal">
      <div className="support-modal__heading">
        <div className="support-modal__heading-top">
          <img
            className="support-modal__heading-logo"
            src={data?.heading?.icon}
          />
          <div className="support-modal__heading-top-right">
            <p className="support-modal__heading-top-right-title">
              {data?.heading?.text}
            </p>
            <p className="support-modal__heading-top-right-subtitle">
              {data?.heading?.sub_text}
            </p>
          </div>
        </div>
        <div className="support-modal__heading-contact-container">
          {data?.contacts?.map((item, idx) => {
            return (
              <div className="support-modal__heading-contact">
                <img
                  className="support-modal__heading-contact-icon"
                  src={item?.icon}
                />
                <p className="support-modal__heading-contact-text">
                  {item?.text}
                </p>
                <CopyToClipboard text={item?.text} textPosition="above" />
              </div>
            );
          })}
        </div>
        <img
          className="support-modal__close"
          src={data?.close_icon}
          onClick={(e) => closeModal()}
        />
      </div>
      <div className="support-modal__body">
        <p className="support-modal__body-text">{data?.body?.text}</p>
        <div className="support-modal__body-buttons">
          {data?.buttons?.map((item, idx) => {
            return (
              <Button
                buttonLabel={item?.text}
                buttonType={item?.sub_type}
                width="50%"
                fontSize="12px"
                onClick={
                  item?.slug === "raise_a_ticket"
                    ? () => {
                        closeModal();
                        setTimeout(() => {
                          if (
                            !document?.getElementsByClassName(
                              "zsiq-float zsiq-flexM zsiq-toggle"
                            )?.[0]
                          ) {
                            document
                              ?.getElementsByClassName(
                                "zsiq-float zsiq-flexM"
                              )?.[0]
                              ?.click();
                          }
                        }, [100]);
                      }
                    : () => {
                        dispatch(
                          showLoader({
                            loaderSuccess: true,
                            showLogo: true,
                            message: data?.success_popup?.text,
                          })
                        );
                      }
                }
              />
            );
          })}
        </div>
      </div>
      <div className="support-modal-partition"></div>
      <p className="support-modal__footer">{data?.footer?.text}</p>
    </div>
  );
}
