import React, { useEffect, useState } from "react";
import UploadFileCard from "../UploadFile/UploadFile";

// redux imports
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";

const UploadFormGroup = (props) => {
  const {
    item,
    value = null,
    setFormData,
    section,
    onDocAction,
    setUploadedDocumentIds,
  } = props;
  const { title = "", document = {} } = item;

  const [docId, setDocId] = useState(null);
  const [documents, setDocuments] = useState(null);

  const dispatch = useDispatch();

  const uploadSingleFile = (selectedFiles, doc_type) => {
    const uploadBoxData = new FormData();
    const metadata = {
      flow: "reconciliation",
    };
    uploadBoxData.append("file", selectedFiles[0]);
    uploadBoxData.append("doc_type", doc_type);
    uploadBoxData.append("metadata", JSON.stringify(metadata));

    const uploadApiCall = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.uploadFile, uploadBoxData, null, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      );
      if (dataRes?.success && dataRes?.data) {
        setUploadedDocumentIds((prev) => ({
          ...prev,
          [item?.element_id]: {
            ...item,
            document: dataRes?.data?.uploaded_document_info[0],
          },
        }));
        setFormData((prev) => ({
          ...prev,
          [item?.element_id]: dataRes?.data?.document_id,
        }));
        setDocId(dataRes?.data?.document_id);
        setDocuments(dataRes?.data?.uploaded_document_info[0]);
      }
    };
    uploadApiCall();
  };

  useEffect(() => {
    setDocuments(item?.document);
    setDocId(value);
  }, [item]);

  return (
    <>
      <div className="df-group">
        {title && <p>{title}</p>}
        <div className="df-group__input">
          <UploadFileCard
            upload_enabled={documents?.upload_enabled}
            documents={documents?.uploaded_documents}
            doc_type={documents?.slug}
            uploadSingleFile={uploadSingleFile}
            docId={docId}
            section={section}
            onDocAction={(section, action, fileid, filename) => {
              onDocAction(section, action, fileid, filename, item?.element_id);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default UploadFormGroup;
