// react imports
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "./redux/common/actions";
import endpoints from "./api/endpoints";
import { setCurrentStage } from "./redux/decision_task/slice";
import { setFeatures } from "./redux/rbac/slice";

// in-app imports
import { sanitizeHtml } from "./utils/sanitizeHtml";

import DOMPurify from "dompurify";
import { showToast } from "./redux/toast/slice";
import { STAGE } from "./utils/constants/UserConstants";

export default function App() {
  // defined to access react based apis
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // local states
  const [loadingState, setLoadingState] = useState(true);
  const userInfoData = useSelector((state) => state.common.userBasicInfo);

  const addScript = useRef(false);
  const orignalScript = useRef(false);

  const addChatBot = () => {
    const script = document.createElement("script");
    const chatBotUrl =
      "https://salesiq.zohopublic.in/widget?wc=siq60983d9fe4ec9cea73d0d2343266fc7511fdb1298fd75a41aa57398153d93a9f";

    const script2 = document.createElement("script");
    const script2InnerHtml = `window.$zoho = window.$zoho || {};
      $zoho.salesiq = $zoho.salesiq || { ready: function () {
      $zoho.salesiq.nonce="bfc30c92-04a3-4855-a567-b1c34c02fe2f";} };`;

    script.src = chatBotUrl;
    script2.innerHTML = script2InnerHtml;

    script.id = "zsiqscript";
    script.setAttribute("nonce", "bfc30c92-04a3-4855-a567-b1c34c02fe2f");

    script.defer = true;
    script2.setAttribute("nonce", "bfc30c92-04a3-4855-a567-b1c34c02fe2f");

    document.head.appendChild(script);

    document.head.appendChild(script2);
    orignalScript.current = true;
  };

  const removeChatBot = () => {
    document?.querySelectorAll("[data-id]")?.[0]?.remove();
  };

  useEffect(() => {
    const getDecision = async () => {
      const decisionRes = await dispatch(apiRequest(endpoints.decisionApi));
      if (decisionRes?.success && decisionRes?.data) {
        if (decisionRes?.data?.screen === STAGE?.ONBOARDING) {
          sessionStorage.setItem(
            "application_id",
            decisionRes?.data?.application_id
          );
        } else {
          localStorage.setItem(
            "application_id",
            decisionRes?.data?.application_id
          );
        }
        dispatch(
          setCurrentStage({
            applicationId: decisionRes?.data?.application_id,
            screen: decisionRes?.data?.screen,
            currentTask: decisionRes?.data?.current_task,
            currentSubTask: decisionRes?.data?.current_subtask,
          })
        );
        dispatch(setFeatures(decisionRes?.data?.features?.role_features));
        switch (decisionRes?.data?.screen) {
          case "dashboard":
            addChatBot();
            if (
              location.pathname.startsWith("/setup") ||
              location.pathname.startsWith("/onboarding")
            ) {
              navigate("/");
            }
            break;
          case "setup":
            addChatBot();
            if (location.pathname !== "/setup") {
              navigate("/setup");
            }
            break;
          case "onboarding":
            navigate("/onboarding/company-data");
            break;
        }
      } else {
        if (decisionRes?.data?.toast?.http_status_code === 401) {
          sessionStorage.clear();
          localStorage.clear();
          navigate("/onboarding/login");
          dispatch(showToast(decisionRes?.data?.toast));
        }
        if (decisionRes?.data?.toast?.http_status_code === 403) {
          let fromSession = false;
          let refresh = localStorage.getItem("refresh");
          if (!refresh) {
            refresh = sessionStorage.getItem("refresh");
            fromSession = true;
          }
          const refreshTokenRes = await dispatch(
            apiRequest(endpoints.refreshToken, {
              refresh_token: refresh,
            })
          );
          if (refreshTokenRes?.success && refreshTokenRes?.data) {
            if (fromSession) {
              sessionStorage.setItem(
                "access",
                refreshTokenRes?.data?.access_token
              );
              sessionStorage.setItem(
                "refresh",
                refreshTokenRes?.data?.refresh_token
              );
            } else {
              localStorage.setItem(
                "access",
                refreshTokenRes?.data?.access_token
              );
              localStorage.setItem(
                "refresh",
                refreshTokenRes?.data?.refresh_token
              );
            }
            navigate(0);
          } else {
            if (refreshTokenRes?.data?.toast?.http_status_code === 401) {
              localStorage.clear();
              sessionStorage.clear();
              navigate("/onboarding/login");
              dispatch(showToast(refreshTokenRes?.data?.toast));
            }
          }
        } else {
          dispatch(showToast(decisionRes?.data?.toast));
        }
      }
      setLoadingState(false);
    };

    setLoadingState(true);
    if (
      localStorage.getItem("access") === null &&
      sessionStorage.getItem("access") === null &&
      location.pathname === "/onboarding/login"
    ) {
      setLoadingState(false);
    } else if (
      localStorage.getItem("access") === null &&
      sessionStorage.getItem("access") === null
    ) {
      setLoadingState(false);
      navigate("/onboarding/login");
    } else {
      getDecision();
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (
      !addScript.current &&
      userInfoData?.identifier_2 &&
      userInfoData?.identifier_1
    ) {
      if (!orignalScript.current) addChatBot();
      const idScript = document.createElement("script");
      idScript.innerHTML =
        `$zoho?.salesiq?.chatbutton?.click(function(){
      $zoho?.salesiq?.visitor?.info({"identifierone":"` +
        userInfoData?.identifier_1 +
        `","identifiertwo":"` +
        userInfoData?.identifier_2 +
        `"});});`;
      idScript.setAttribute("nonce", "bfc30c92-04a3-4855-a567-b1c34c02fe2f");
      document.head.appendChild(idScript);

      const idScript2 = document.createElement("script");
      idScript2.innerHTML =
        `$zoho?.salesiq?.chat?.online(function(){
       $zoho?.salesiq?.visitor?.info({"identifierone":"` +
        userInfoData?.identifier_1 +
        `","identifiertwo":"` +
        userInfoData?.identifier_2 +
        `"});});`;
      idScript2.setAttribute("nonce", "bfc30c92-04a3-4855-a567-b1c34c02fe2f");
      document.head.appendChild(idScript2);

      addScript.current = true;
    }
  }, [userInfoData]);

  useEffect(() => {
    if (location?.pathname?.startsWith("/onboarding")) {
      removeChatBot();
    }
  }, [location]);

  useEffect(() => {
    const checkChatbotOutsideClick = (e) => {
      if (
        !document?.getElementById("embed_cont")?.contains(e?.target) &&
        !document
          ?.getElementsByClassName("zsiq-float zsiq-flexM zsiq-toggle")?.[0]
          ?.contains(e?.target)
      ) {
        if (
          document?.getElementsByClassName(
            "zsiq-float zsiq-flexM zsiq-toggle"
          )?.[0]
        ) {
          document
            ?.getElementsByClassName("zsiq-float zsiq-flexM zsiq-toggle")?.[0]
            ?.click();
        }
      }
    };
    document?.addEventListener("mousedown", checkChatbotOutsideClick);
    return () => {
      document?.removeEventListener("mousedown", checkChatbotOutsideClick);
    };
  }, []);

  return <>{!loadingState && <Outlet />}</>;
}
