import React, { useEffect, useRef, useState } from "react";
import { sanitizeHtml } from "../utils/sanitizeHtml";

const BlunoTooltip = (props) => {
  const {
    icon = "",
    hoverIcon = "",
    text = "",
    iconWidth = "",
    iconHeight = "",
    hoverIconWidth = "",
    hoverIconHeight = "",
    left = "",
    top = "",
    right = "",
    bottom = "",
    title = "",
    titleFontSize = "16px",
    width = "",
    height = "",
    useChildren = false,
    cursor = null,
    position = "absolute",
    alignment = "",
    tooltipDivWidth = "",
    internalToolTipDivWidth = "",
    textColor,
    textBgColor,
    showTextOnClick = false,
    setTextDangerously = false,
  } = props;

  const [isHovered, setIsHovered] = useState(true);
  const [isClicked, setIsClicked] = useState(false);
  const tooltipRef = useRef();
  const tooltipTextRef = useRef();

  const handleMouseEnter = () => {
    if (!showTextOnClick) {
      positionFixedToolTip();
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!showTextOnClick) {
      setIsHovered(true);
    }
  };

  const tooltipDivStyle = {
    width: tooltipDivWidth,
    pointerEvents: "auto",
  };

  const tooltipImgStyles = {
    width:
      isHovered && hoverIcon && hoverIconWidth ? hoverIconWidth : iconWidth,
    height:
      isHovered && hoverIcon && hoverIconHeight ? hoverIconHeight : iconHeight,
    cursor: cursor,
  };

  const tooltipTextStyles = {
    position: position,
    left: left,
    top: top,
    right: right,
    bottom: bottom,
    width: width,
    height: height,
    color: textColor,
    backgroundColor: textBgColor,
  };

  const tooltipTitleStyles = {
    fontSize: titleFontSize,
    color: isHovered ? "#046cb8" : "#000000",
    cursor: "pointer",
  };

  const positionFixedToolTip = () => {
    if (
      position === "fixed" &&
      text &&
      tooltipTextRef.current &&
      tooltipRef.current &&
      tooltipTextRef?.current?.style
    ) {
      tooltipTextRef.current.style.top =
        (top !== "" ? parseInt(top.replace("px", "")) : 0) +
        tooltipRef?.current?.getBoundingClientRect()?.y -
        tooltipTextRef?.current?.clientHeight -
        5 +
        "px";
      tooltipTextRef.current.style.left =
        (left !== "" ? parseInt(left.replace("px", "")) : 0) +
        tooltipRef?.current?.getBoundingClientRect()?.x +
        (alignment === "center"
          ? tooltipRef?.current?.getBoundingClientRect()?.width / 2
          : "") -
        (tooltipTextRef?.current?.clientWidth -
          (alignment === "center" ? 0 : 12)) /
          2 +
        "px";
    }
  };

  const handleClick = () => {
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 2000);
  };

  useEffect(() => {
    if (!showTextOnClick) {
      if (position === "fixed" && text) {
        tooltipTextRef.current.style.height = "max-content";
        tooltipTextRef.current.style.transform = "translateX(0px)";
        positionFixedToolTip();
      }
    } else {
      if (position === "fixed" && text && isClicked) {
        tooltipTextRef.current.style.height = "max-content";
        tooltipTextRef.current.style.transform = "translateX(0px)";
        positionFixedToolTip();
      }
    }
  }, [text, isClicked]);

  return (
    <div
      className={`s-tooltip`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={tooltipRef}
      style={tooltipDivStyle}
      // onClick={handleClick}
    >
      {useChildren ? (
        <div
          onClick={handleClick}
          style={{ pointerEvents: "auto", width: internalToolTipDivWidth }}
        >
          {props.children}
        </div>
      ) : (
        <img
          className={`s-tooltip__img`}
          src={isHovered && hoverIcon ? hoverIcon : icon}
          style={tooltipImgStyles}
          alt="bluno icon"
        />
      )}

      {(showTextOnClick && text && isClicked) || (!showTextOnClick && text) ? (
        <>
          {title && <p style={tooltipTitleStyles}>{title}</p>}
          {setTextDangerously ? (
            <>
              <p
                className="s-tooltip__text"
                style={tooltipTextStyles}
                ref={tooltipTextRef}
                dangerouslySetInnerHTML={sanitizeHtml(text)}
              ></p>
            </>
          ) : (
            <>
              <p
                className="s-tooltip__text"
                style={tooltipTextStyles}
                ref={tooltipTextRef}
              >
                {text}
              </p>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BlunoTooltip;
