// react imports
import React from "react";

// third-party imports
import { Snackbar } from "@mui/material";

// in-app imports
import Alert from "./Alert";
import redAlert from "../assets/media/alert/RedAlert.png";
import yellowAlert from "../assets/media/alert/YellowAlert.png";
import greenAlert from "../assets/media/alert/GreenAlert.png";
import Cross from "../assets/media/Toast/Icon-multiply.png";

const Toast = ({
  message = "Note archived",
  extraMessage = undefined,
  duration = 5000,
  isToast = false,
  closeToast,
  type = "success",
  title = undefined,
}) => {
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isToast}
        autoHideDuration={duration}
        onClose={closeToast}
        // action={action}
      >
        <div className={`s-toast s-toast--${type}`}>
          <div className="s-toast__content">
            <img
              src={
                type === "success"
                  ? greenAlert
                  : type === "warning"
                  ? yellowAlert
                  : redAlert
              }
              alt="Warning Icon"
            />
            <div className="s-toast__text">
              {title && <div className="s-toast__title">{title}</div>}
              <div className="s-toast__message">{message}</div>
              {extraMessage && (
                <div className="s-toast__message">{message}</div>
              )}
            </div>
          </div>
          <img src={Cross} onClick={closeToast} alt="Redirection Icon" />
        </div>
      </Snackbar>
    </>
  );
};

export default Toast;
