//react imports
import React, { useState } from "react";

// redux imports
import endpoints from "../../api/endpoints";
import { apiRequest } from "../../redux/common/actions";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/toast/slice";

//in-app imports
import Button from "../Button";
import { InputError } from "../../app/constants/InputError";
import {
  generalKeyDownValidation,
  validateEMAIL,
  validateNumber,
} from "../../utils/Validation";

//third party imports
import Textarea from "@mui/joy/Textarea";

//assets
import Logo from "../../assets/media/onboarding/Bluno Logo_S 1.png";
import MuiTextInput from "../blunoInputs/MuiTextInputs";

//contract
const data = {
  title: "Get help",
  close: "https://static.bluno.in/miscl/cross-thin.png",
  inputs: [
    {
      sub_type: "number",
      is_required: true,
      default_value: null,
      type: "text_box",
      slug: "phone_number",
      title: "Enter mobile number",
      limits: null,
      placeholder: "Enter mobile number",
      icon: null,
    },
    {
      sub_type: "email",
      is_required: true,
      default_value: null,
      type: "text_box",
      slug: "email_id",
      title: "Enter email id",
      limits: null,
      placeholder: "Enter email id",
      icon: null,
    },
    {
      sub_type: "text",
      is_required: false,
      default_value: null,
      type: "text_area",
      slug: "description",
      title: "Describe your issue",
      limits: null,
      placeholder: "Describe your issue",
      icon: null,
    },
  ],
  buttons: [
    {
      sub_type: "primary",
      disabled: false,
      show_loader: false,
      type: "button",
      text: "Request a callback",
      slug: "submit_get_help",
    },
  ],
};

const data2 = {
  // title: "We will get back to you",
  icon: Logo,
  text: "Thanks for providing the details.",
  text2: "Bluno expert will get back to you.",
};
export default function GetHelp({ setShowModal, getHelpFlow = "" }) {
  const dispatch = useDispatch();

  //local-states
  const [submitValues, setSubmitValues] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleOnChange = (value, slug) => {
    switch (slug) {
      case "phone_number":
        if (value?.length <= 10) {
          if (value[0] === "0") {
            setErrorMessage((prev) => ({
              ...prev,
              [slug]: InputError?.IncorrectMobileNumber?.message,
            }));
          } else {
            setErrorMessage((prev) => ({ ...prev, [slug]: null }));
            setSubmitValues((prev) => ({ ...prev, [slug]: value }));
          }
        }
        break;
      default:
        setErrorMessage((prev) => ({
          ...prev,
          [slug]: null,
        }));
        setSubmitValues((prev) => ({ ...prev, [slug]: value }));
    }
  };

  const handleKeyDown = (value, slug, e) => {
    switch (slug) {
      case "phone_number":
        if (value === "Enter") {
          handleSubmit(null);
        }
        if (generalKeyDownValidation(validateNumber, value, e)) {
          e.preventDefault();
          setErrorMessage((prev) => ({
            ...prev,
            [slug]: InputError?.IncorrectMobileNumber?.message,
          }));
        }
        break;
      case "email_id":
        if (value === "Enter") {
          handleSubmit(null);
        }
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (item) => {
    let error = false;
    //phone number validation
    if (
      !submitValues?.["phone_number"] ||
      submitValues?.phone_number?.length < 10
    ) {
      if (
        !submitValues?.["phone_number"] ||
        submitValues?.phone_number?.length === 0
      ) {
        setErrorMessage((prev) => ({
          ...prev,
          phone_number: InputError?.EmptyPhoneNumber?.message,
        }));
        error = true;
      } else
        setErrorMessage((prev) => ({
          ...prev,
          phone_number: InputError?.IncorrectMobileNumber?.message,
        }));
      error = true;
    }

    //email validation
    if (
      !submitValues?.["email_id"] ||
      submitValues?.["email_id"]?.length === 0
    ) {
      setErrorMessage((prev) => ({
        ...prev,
        email_id: InputError?.EmptyEmail?.message,
      }));
      return;
    } else {
      if (!validateEMAIL(submitValues?.["email_id"])) {
        setErrorMessage((prev) => ({
          ...prev,
          email_id: InputError?.InvalidEmailFormat?.message,
        }));
        return;
      }
    }
    if (error) return;

    const body = {
      ...submitValues,
      flow: getHelpFlow,
    };

    const submitFormRes = await dispatch(
      apiRequest(
        getHelpFlow === "login"
          ? endpoints.submitCommonGetHelpForm
          : endpoints.submitAuthGetHelpForm,
        body
      )
    );

    if (submitFormRes?.success && submitFormRes?.data) {
      setSubmitted(true);
    } else {
      // error toast message
      if (submitFormRes?.data?.toast) {
        dispatch(showToast(submitFormRes?.data?.toast));
      }
    }
  };

  const renderInputs = (theItem, index) => {
    switch (theItem?.type) {
      case "text_box":
        return (
          <MuiTextInput
            placeholder={theItem?.placeholder}
            type={theItem?.sub_type}
            value={submitValues?.[theItem?.slug]}
            onChange={(e) => handleOnChange(e.target.value, theItem?.slug)}
            onKeyDown={(e) => handleKeyDown(e.key, theItem?.slug, e)}
            id={theItem?.slug + index}
            icon={theItem?.icon}
            minWidth="280px"
            fixedText={theItem?.slug === "phone_number" && "+91"}
            paddingLeft={theItem?.slug === "phone_number" ? "20px" : "-5px"}
            fontSize="13px"
            fixedTextLeftStyle={
              theItem?.slug === "phone_number" ? "20px" : "7px"
            }
            fixedTextTopStyle={
              theItem?.slug === "phone_number" ? "20px" : "7px"
            }
            errorMessage={errorMessage?.[theItem?.slug]}
            height="40px"
          />
        );
      case "text_area":
        return (
          <MuiTextInput
            multiline={true}
            rows="3"
            height="100px"
            minWidth="280px"
            onChange={(e) => handleOnChange(e.target.value, theItem?.slug)}
            onKeyDown={(e) => handleKeyDown(e.key, theItem?.slug, e)}
            placeholder={theItem?.placeholder}
            value={submitValues?.[theItem?.slug]}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="o-gethelp">
        <img
          className="o-gethelp-close"
          src={data?.close}
          onClick={() => {
            setShowModal(false);
          }}
        ></img>
        {!submitted ? (
          <>
            <p className="o-gethelp-title">{data?.title} </p>
            <div className="o-gethelp__body">
              {data?.inputs?.map((item, index) => {
                return (
                  <div className="o-login__input">
                    {renderInputs(item, index)}
                  </div>
                );
              })}
              {data?.buttons?.map((item, index) => {
                return (
                  <Button
                    buttonType={item?.sub_type}
                    buttonLabel={item?.text}
                    onClick={() => handleSubmit(item)}
                    fontSize="13px"
                    height="40px"
                  />
                );
              })}
            </div>
          </>
        ) : (
          <div className="o-gethelp-submitted">
            <div>
              <span className="o-gethelp-submitted-title">{data2?.title}</span>
            </div>
            <img src={data2?.icon} className="o-gethelp-submitted__logo" />
            <p className="o-gethelp-submitted-text">
              <span>{data2?.text}</span>
              <span>{data2?.text2}</span>
            </p>
          </div>
        )}
      </div>
    </>
  );
}
