import React from "react";

const UploadDocHeaders = ({ headerData = null }) => {
  return (
    <div className="s-table__header">
      <p className="s-table__header-title">{headerData?.text}</p>

      <div className="s-table__header-bottom">
        {headerData?.sub_headers?.map((item) => {
          return (
            <p
              className={`s-table__header-text s-table__header--${item?.slug}`}
            >
              {item?.text}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default UploadDocHeaders;
