// react imports
import React, { useEffect, useState } from "react";

// redux imports
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";

// third-party imports

// in-app imports
import { PLATFORM_SETTINGS_RESPONSE } from "../../modules/landing/constants";
import PlatformCard from "../../components/settings/platform/PlatformCard";
import Button from "../../components/Button";
import SettingsCard from "../../modules/Settings/SettingsCard";
import useCallbackPrompt from "../../hooks/useCallBackPrompt";
import { BlockerAlert } from "../../app/constants/BlockerAlert";

const Platform = () => {
  const data = PLATFORM_SETTINGS_RESPONSE.data;

  const dispatch = useDispatch();

  // local states
  const [isEditable, setIsEditable] = useState(false);
  const [submitValues, setSubmitValues] = useState();
  const [displayValues, setDisplayValues] = useState();
  const [isBlockerActive, setIsBlockerActive] = useState(false);
  const [blockerAlertData, setBlockerAlertData] = useState(
    BlockerAlert?.PLATFORM_SETTINGS
  );

  // custom hooks variables
  useCallbackPrompt(isBlockerActive, blockerAlertData); // Navigation blocker hook

  const handleInputChange = (value, slug) => {
    setIsBlockerActive(true);
    setBlockerAlertData(BlockerAlert?.PLATFORM_SETTINGS);
    switch (slug) {
      case "currency_unit_rupee":
        setSubmitValues((prev) => ({
          ...prev,
          [slug]: value,
        }));
        setDisplayValues((prev) => ({
          ...prev,
          [slug]: value,
        }));
        break;
    }
  };

  const handleButtonClick = (slug) => {
    const updatePlatformSettings = async () => {
      const apiRes = await dispatch(
        apiRequest(endpoints.savePlatformSettings, submitValues)
      );
      if (apiRes?.success && apiRes?.data) {
        setDisplayValues((prev) => ({
          ...prev,
          [apiRes?.data?.currency_unit_rupee?.slug]:
            apiRes?.data?.currency_unit_rupee?.value,
        }));
      }
    };
    if (slug === "save") {
      updatePlatformSettings();
      setIsBlockerActive(false);
    }
    setIsEditable(!isEditable);
  };

  useEffect(() => {
    const getPlatformSettings = async () => {
      const apiRes = await dispatch(apiRequest(endpoints.getPlatformSettings));
      if (apiRes?.success && apiRes?.data) {
        setDisplayValues((prev) => ({
          ...prev,
          [apiRes?.data?.currency_unit_rupee?.slug]:
            apiRes?.data?.currency_unit_rupee?.value,
        }));
      }
    };
    getPlatformSettings();
  }, []);

  return (
    <>
      <div className="plat-settings">
        <div className="plat-settings__body">
          {data?.sections?.map((section, index) => {
            return (
              <SettingsCard
                title={
                  <>
                    {index === 0 && (
                      <div className="plat-settings__header">
                        <p>{data?.title}</p>
                        {data?.buttons && (
                          <div className="plat-settings__edit">
                            {data?.buttons?.map((action, index) => {
                              if (
                                (isEditable && index === 1) ||
                                (!isEditable && index === 0)
                              )
                                return (
                                  action?.type === "button" && (
                                    <Button
                                      buttonLabel={action?.text}
                                      buttonType={action?.sub_type}
                                      icon={action.icon}
                                      disabled={action?.disabled}
                                      iconAlignment={action?.icon_alignment}
                                      buttonSize="sm2"
                                      onClick={() =>
                                        handleButtonClick(action?.slug)
                                      }
                                    />
                                  )
                                );
                            })}
                          </div>
                        )}
                      </div>
                    )}
                  </>
                }
              >
                <PlatformCard
                  data={section}
                  isEditable={isEditable}
                  displayValues={displayValues}
                  handleInputChange={handleInputChange}
                  handleButtonClick={handleButtonClick}
                />
              </SettingsCard>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Platform;
