import React from "react";
import Button from "../../Button";
import { GENERAL_SETTINGS_SLUGS } from "../../../utils/constants/SettingsConstants";

const EditAddressPopup = ({
  data,
  renderEditOnlyFields,
  renderViewOnlyFields,
  handleButtonClick,
}) => {
  const onPopupClose = () => {
    handleButtonClick(GENERAL_SETTINGS_SLUGS?.CLOSE_EDIT_ADDRESS_POPUP);
  };

  return (
    <>
      <div className="g-settings__address-popup">
        <div className="g-settings__identity-popup-header">
          <h3 className="g-settings__identity-popup-title">{data?.title}</h3>
          <img
            className="g-settings__identity-popup-close"
            src={data?.close_icon}
            onClick={onPopupClose}
          />
        </div>
        <div
          className="g-settings__fields"
          style={{ gridTemplateColumns: "1fr 1fr" }}
        >
          {data?.fields?.map((item) => {
            return item?.field_type === "hr_line" ? (
              <hr className="g-settings__horizontal" />
            ) : item?.is_editable ? (
              renderEditOnlyFields(item)
            ) : (
              renderViewOnlyFields(item)
            );
          })}
        </div>
        <div className="g-settings__identity-popup-buttons">
          {data?.buttons?.map((item, idx) => {
            return (
              <Button
                key={idx}
                icon={item?.icon}
                buttonType={item?.sub_type}
                buttonLabel={item?.text}
                iconAlignment={item?.icon_alignment}
                onClick={() => {
                  handleButtonClick(item?.slug);
                }}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default EditAddressPopup;
