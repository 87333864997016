import React from "react";

// third-party imports
import { Skeleton } from "@mui/material";

const SkeletonBasic = ({
  children,
  showLoader = false,
  h1 = 16,
  h2 = 100,
  h3 = 100,
}) => {
  return (
    <>
      {showLoader ? (
        <div className="s-skeleton-basic">
          <Skeleton variant="rectangular" height={h1} animation="wave" />
          <Skeleton variant="rectangular" height={h2} animation="wave" />
          <Skeleton variant="rectangular" height={h3} animation="wave" />
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default SkeletonBasic;
