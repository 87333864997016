//react imports
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

//redux imports
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";

//custom-hook import
import usePrevious from "../../hooks/usePrevious";

//in app imports
import UserProfileCard from "../../modules/Settings/UserProfileCard";
import { GENERAL_SETTINGS_RESPONSE } from "../../modules/landing/constants";
import { GENERAL_SETTINGS_SLUGS } from "../../utils/constants/SettingsConstants";
import CompanyDetailsCard from "../../modules/Settings/CompanyDetailsCard";
import ModalV2 from "../../components/ModalV2";

const GeneralSettings = () => {
  // defined to acess react based apis
  const dispatch = useDispatch();
  const [showPopup, setShowPopup, setPopupData] = useOutletContext();

  // local states
  const [editUser, setEditUser] = useState("{}");
  const [editCompany, setEditCompany] = useState("{}");
  const [data, setData] = useState(null);
  const [userEditObject, setUserEditObject] = useState({});
  const [companyEditObject, setCompanyEditObject] = useState({});
  const [userDisplayObject, setUserDisplayObject] = useState({});
  const [companyDisplayObject, setCompanyDisplayObject] = useState({});
  const [isBlockerActive, setIsBlockerActive] = useState(false);
  const [addressDisplayObject, setAddressDisplayObject] = useState();
  const [showModal, setShowModal] = useState(false);

  const getGeneralSettingsData = async () => {
    const jsonEditUser = JSON.parse(editUser);
    const jsonEditCompany = JSON.parse(editCompany);
    let apiRes = await dispatch(
      apiRequest(
        endpoints.getGeneralSettings,
        `?edit_phone=${!!jsonEditUser?.editPhone}&edit_email=${!!jsonEditUser?.editEmail}&edit_website=${!!jsonEditCompany?.editWebsite}&edit_correspondence_address=${!!jsonEditCompany?.editCorrespondenceAddress}`
      )
    );
    if (apiRes?.success && apiRes?.data) {
      if (jsonEditCompany?.editWebsite) {
        apiRes.data.general_settings.company_settings.left_side_fields.forEach(
          (item) => {
            if (companyDisplayObject?.[item?.edit_only?.slug]) {
              item.edit_only.default_value =
                companyDisplayObject?.[item?.edit_only?.slug];
            }
          }
        );
      }
      if (jsonEditCompany?.editCorrespondenceAddress) {
        apiRes.data.general_settings.company_settings.fields.forEach((item) => {
          if (companyDisplayObject?.[item?.edit_only?.slug]) {
            item.edit_only.default_value =
              companyDisplayObject?.[item?.edit_only?.slug];
            item.edit_only.value =
              companyDisplayObject?.[item?.edit_only?.slug];
          }
          if (
            companyDisplayObject?.[item?.declaration?.slug] ||
            companyDisplayObject?.[item?.declaration?.slug] === false
          ) {
            item.declaration.checked =
              companyDisplayObject?.[item?.declaration?.slug] === "yes";
          }
        });
      }
      setData(apiRes?.data);
    }
  };

  const saveCompanyData = async (theSlug, doGetApiCall) => {
    if (Object.keys(companyEditObject).length === 0) {
      setEditCompany("{}");
      setCompanyEditObject({});
      setIsBlockerActive(false);
      return;
    }
    const requestBody = {
      company_data: {
        company_website:
          theSlug === GENERAL_SETTINGS_SLUGS?.SAVE_WEBSITE
            ? companyDisplayObject?.[GENERAL_SETTINGS_SLUGS?.COMPANY_WEBSITE]
            : null,
        is_correspondence_address_same:
          theSlug === GENERAL_SETTINGS_SLUGS?.SAVE_CORRESPONDENCE_ADDRESS
            ? companyDisplayObject?.[GENERAL_SETTINGS_SLUGS?.IS_ADDRESS_SAME]
            : null,
        correspondence_address:
          theSlug === GENERAL_SETTINGS_SLUGS?.SAVE_CORRESPONDENCE_ADDRESS
            ? {
                address_line1:
                  companyDisplayObject?.[
                    GENERAL_SETTINGS_SLUGS?.ADDRESS_LINE_1
                  ],
                address_line2:
                  companyDisplayObject?.[
                    GENERAL_SETTINGS_SLUGS?.ADDRESS_LINE_2
                  ],
                city: companyDisplayObject?.[GENERAL_SETTINGS_SLUGS?.CITY],
                state: companyDisplayObject?.[GENERAL_SETTINGS_SLUGS?.STATE],
                pin_code:
                  companyDisplayObject?.[GENERAL_SETTINGS_SLUGS?.PIN_CODE],
              }
            : null,
      },
    };
    const jsonEditCompany = JSON.parse(editCompany);
    const apiRes = await dispatch(
      apiRequest(
        endpoints.saveGeneralSettings,
        requestBody,
        `?edit_company=true`
      )
    );

    if (apiRes?.success) {
      if (doGetApiCall) {
        getGeneralSettingsData();
      }
      setCompanyEditObject((prev) => {
        const {
          [GENERAL_SETTINGS_SLUGS?.COMPANY_WEBSITE]: tempValue,
          ...rest
        } = prev;
        return theSlug === GENERAL_SETTINGS_SLUGS?.SAVE_WEBSITE
          ? { [GENERAL_SETTINGS_SLUGS?.COMPANY_WEBSITE]: tempValue }
          : rest;
      });
      setEditCompany((prev) => {
        const parsedPrev = JSON.parse(prev);
        if (theSlug === GENERAL_SETTINGS_SLUGS?.SAVE_WEBSITE) {
          const { editWebsite, ...rest } = parsedPrev;
          setIsBlockerActive(rest?.editCorrespondenceAddress);
          return JSON.stringify(rest);
        } else if (
          theSlug === GENERAL_SETTINGS_SLUGS?.SAVE_CORRESPONDENCE_ADDRESS
        ) {
          const { editCorrespondenceAddress, ...rest } = parsedPrev;
          setIsBlockerActive(rest?.editWebsite);
          return JSON.stringify(rest);
        }
      });
    }
  };

  const saveUserData = async () => {
    setEditUser(false);
    setIsBlockerActive(false);
  };

  useEffect(() => {
    getGeneralSettingsData();
  }, [editUser, editCompany]);

  return (
    <>
      <div className="g-settings__wrapper">
        <UserProfileCard
          data={data?.general_settings?.user_settings}
          type="user"
          docType="USER_PROFILE_PICTURE"
          isEdit={editUser}
          setIsEdit={setEditUser}
          editObject={userEditObject}
          setEditObject={setUserEditObject}
          displayObject={userDisplayObject}
          setDisplayObject={setUserDisplayObject}
          handleSave={saveUserData}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          setPopupData={setPopupData}
          isBlockerActive={isBlockerActive}
          setIsBlockerActive={setIsBlockerActive}
          getGeneralSettingsData={getGeneralSettingsData}
        />
        <CompanyDetailsCard
          data={data?.general_settings?.company_settings}
          type="company"
          docType="COMPANY_LOGO"
          isEdit={editCompany}
          setIsEdit={setEditCompany}
          editObject={companyEditObject}
          setEditObject={setCompanyEditObject}
          displayObject={companyDisplayObject}
          setDisplayObject={setCompanyDisplayObject}
          handleSave={saveCompanyData}
          addressDisplayObject={addressDisplayObject}
          setAddressDisplayObject={setAddressDisplayObject}
          isBlockerActive={isBlockerActive}
          setIsBlockerActive={setIsBlockerActive}
          getGeneralSettingsData={getGeneralSettingsData}
        />
      </div>
      {showModal && (
        <div className="a-buyers__modal-bg">
          <ModalV2 slug={"standard"} children={<p></p>} />
        </div>
      )}
    </>
  );
};

export default GeneralSettings;
