import React from "react";

const BankIcon = (props) => {
  const { color = "#000000" } = props;
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3333 9H11.6667H1V6.33333L11.6667 1L22.3333 6.33333V9Z"
        stroke={color}
        stroke-linejoin="round"
      />
      <path d="M4 9L4 18" stroke={color} stroke-linejoin="round" />
      <path d="M9 9V18" stroke={color} stroke-linejoin="round" />
      <path d="M14 9L14 18" stroke={color} stroke-linejoin="round" />
      <path d="M19 9V18" stroke={color} stroke-linejoin="round" />
      <path
        d="M1 18.3333L22.3333 18.3333"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 21L22.3333 21"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BankIcon;
