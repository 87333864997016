// react imports
import { createBrowserRouter } from "react-router-dom";

// third party imports

// in-app imports
import Root from "./pages/Root";
import Home from "./pages/Home";
import Bills from "./pages/Bills";
import ErrorPage from "./pages/ErrorPage";
import IRMDetailsPage from "./pages/IRMDetailsPage";
import Demo from "./pages/Demo";
import NewRecon from "./pages/NewRecon";
import AllBuyers from "./pages/Buyers/AllBuyers";
import SingleBuyer from "./pages/Buyers/SingleBuyer";
import AllBulkEbrc from "./pages/Buyers/AllBulkEbrc";
import Onboarding from "./pages/onboarding/Onboarding";
import Login from "./modules/Onboarding/Login";
import App from "./App";
import CompanyData from "./modules/Onboarding/CompanyData";
import GeneralSettings from "./pages/Settings/GeneralSettings";
import Security from "./pages/Settings/Security";
import SettingsWrapper from "./pages/Settings";
import BankDetailsSettings from "./pages/Settings/BankDetailsSettings";
import Platform from "./pages/Settings/Platform";
import ManageUsers from "./pages/Settings/ManageUsers";
import AadhaarRedirection from "./pages/Redirection/AadhaarRedirection";
import Tickets from "./pages/Tickets";
import Partners from "./pages/Settings/Partners";
import SetupV2 from "./pages/SetupV2";
import DocumentManagement from "./pages/DocumentManagement";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "/",
            element: <Home />,
            errorElement: <ErrorPage />,
          },
          {
            path: "/bills/:bill_type",
            element: <Bills />,
            errorElement: <ErrorPage />,
            children: [],
          },
          // {
          //   path: "/setup",
          //   element: <Setup />,
          //   errorElement: <ErrorPage />,
          //   children: [],
          // },
          {
            path: "/setup",
            element: <SetupV2 />,
            errorElement: <ErrorPage />,
            children: [],
          },
          // {
          //   path: "/document-management/:doc_type",
          //   element: <DocumentManagement />,
          //   errorElement: <ErrorPage />,
          //   children: [],
          // },
          {
            path: "/bills/irm/:irmId",
            element: <IRMDetailsPage />,
            errorElement: <ErrorPage />,
            children: [],
          },
          {
            path: "/bills/sb/:sbId",
            element: <NewRecon />,
            errorElement: <ErrorPage />,
            children: [],
          },
          {
            path: "/buyers",
            element: <AllBuyers />,
            errorElement: <ErrorPage />,
            children: [],
          },
          {
            path: "/sb/bulk-ebrc",
            element: <AllBulkEbrc />,
            errorElement: <ErrorPage />,
            children: [],
          },
          {
            path: "/buyers/:buyerId",
            element: <SingleBuyer />,
            errorElement: <ErrorPage />,
            children: [],
          },
          {
            path: "/settings",
            element: <SettingsWrapper />,
            errorElement: <ErrorPage />,
            children: [
              {
                path: "/settings/general",
                element: <GeneralSettings />,
                errorElement: <ErrorPage />,
                children: [],
              },
              {
                path: "/settings/security",
                element: <Security />,
                errorElement: <ErrorPage />,
                children: [],
              },
              {
                path: "/settings/bank-details",
                element: <BankDetailsSettings />,
                errorElement: <ErrorPage />,
                children: [],
              },
              // {
              //   path: "/settings/health-check",
              //   element: <HealthCheck />,
              //   errorElement: <ErrorPage />,
              //   children: [],
              // },
              {
                path: "/settings/manage/users",
                element: <ManageUsers />,
                errorElement: <ErrorPage />,
                children: [],
              },
              {
                path: "/settings/manage/access",
                element: <ManageUsers />,
                errorElement: <ErrorPage />,
                children: [],
              },
              {
                path: "/settings/partners",
                element: <Partners />,
                errorElement: <ErrorPage />,
                children: [],
              },
              {
                path: "/settings/platform",
                element: <Platform />,
                errorElement: <ErrorPage />,
                children: [],
              },
            ],
          },
          {
            path: "/tickets",
            element: <Tickets />,
            errorElement: <ErrorPage />,
            children: [],
          },
        ],
      },
      {
        path: "/demo",
        element: <Demo />,
        errorElement: <ErrorPage />,
        children: [],
      },
      {
        path: "/onboarding",
        element: <Onboarding />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "/onboarding/login",
            element: <Login />,
            errorElement: <ErrorPage />,
            children: [],
          },
          {
            path: "/onboarding/company-data",
            element: <CompanyData />,
            errorElement: <ErrorPage />,
            children: [],
          },
        ],
      },
    ],
  },
  // {
  //   path: "/health-check",
  //   element: <HealthCheck />,
  //   errorElement: <ErrorPage />,
  //   children: [],
  // },
  {
    path: "/bluno/hv-digilocker",
    element: <AadhaarRedirection />,
    errorElement: <ErrorPage />,
    children: [],
  },
]);
