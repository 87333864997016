import React, { useEffect, useState } from "react";
import BlunoTooltip from "./BlunoTooltip";
import Dropdown from "./Dropdown";
import MuiTextInput from "./blunoInputs/MuiTextInputs";
import UploadFileCard from "./UploadFile/UploadFile";
import Button from "./Button";
import { sanitizeHtml } from "../utils/sanitizeHtml";
import CopyToClipboard from "./CopyToClipboard";

const PostReconPendingDocs = ({
  data,
  invoiceData,
  onDocAction,
  uploadSingleFile,
  transportData,
  summaryErrorMessage,
  onActionClick,
}) => {
  const [transportMetaDeta, setTransportMetadata] = useState(null);
  const [transportDoc, setTransportDoc] = useState();

  const handleInputChange = (theValue, theSlug) => {
    setTransportMetadata({
      ...transportMetaDeta,
      [theSlug]: theValue,
    });
    if (transportDoc) {
      onDocAction(
        "sb_docs",
        { action: "detach" },
        transportDoc?.id,
        transportDoc?.name
      );
    }
  };

  const isTransportDocDisabled = () => {
    if (
      !transportMetaDeta?.document_type ||
      (transportMetaDeta?.document_type === "others" &&
        !transportMetaDeta?.document_type_other)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setTransportDoc(
      transportData?.data?.[0]?.document?.uploaded_documents?.[0]
    );
  }, [transportData]);

  useEffect(() => {
    setTransportMetadata({
      ...transportMetaDeta,
      document_type: transportData?.document_type?.default?.value,
      document_type_other: transportData?.document_type_other?.default_value,
    });
  }, []);

  return (
    <>
      <div className="post-recon-pending-docs">
        <div className="post-recon-pending-docs__title">
          <p className="post-recon-pending-docs__title-text">{data?.title}</p>
          <BlunoTooltip
            useChildren={true}
            text={data?.edit_info?.tooltip_text}
            position="fixed"
            left="125px"
            top="23px"
          >
            <img
              src={data?.edit_info?.icon}
              className="post-recon-pending-docs__title-icon"
            />
          </BlunoTooltip>
        </div>
        <div className="post-recon-pending-docs-partition" />
        <div
          className="s-sbDetails__invoice"
          style={{ borderTop: "none", padding: "none" }}
        >
          <div className="s-sbDetails__invoice-list">
            {invoiceData?.invoices?.map((item) => {
              return (
                <PostSBInvoiceCard
                  data={item}
                  onDocAction={onDocAction}
                  uploadSingleFile={uploadSingleFile}
                  summaryErrorMessage={summaryErrorMessage}
                />
              );
            })}
          </div>
        </div>
        {transportData && (
          <div
            className="s-sbDetails__transport-docs"
            style={{ width: "100%" }}
          >
            <h1 className="s-sbDetails__transport-docs-heading">
              {transportData?.title}
            </h1>
            {/* <p className="post-recon-pending-docs__transport-mode">
              {transportData?.transport_mode?.header}{" "}
              <span>{transportData?.transport_mode?.text}</span>
            </p> */}
            <div
              className="s-sbDetails__transport-docs-parent"
              style={{ width: "100%", border: "none", padding: "10px 0" }}
            >
              <div
                className={`s-sbDetails__transport-docs-fields ${
                  transportData?.e_commerce_data &&
                  "s-sbDetails__transport-docs-fields--border"
                }`}
              >
                <div className="s-sbDetails__transport-docs-container">
                  {transportData?.submitted_doc_text ? (
                    <div className="s-sbDetails__transport-docs-dropdown">
                      <div className="s-sbDetails__transport-doc-text">
                        <span>Document Type: </span>
                        <p> {transportData?.submitted_doc_text}</p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="s-sbDetails__transport-docs-dropdown">
                        <Dropdown
                          options={transportData?.document_type?.options}
                          defaultValue={transportMetaDeta?.document_type}
                          height="40px"
                          minWidth="225px"
                          fontSize="13px"
                          onSelectChange={(e) =>
                            handleInputChange(
                              e.value,
                              transportData?.document_type?.slug
                            )
                          }
                          isDisabled={transportData?.document_type?.disabled}
                          placeholder={
                            transportData?.document_type?.placeholder
                          }
                          placeholderFontSize="13px"
                          placeholderFontWeight="400"
                          fancyPlaceholder={true}
                          topSpacing="-5px"
                          iconWidth="10px"
                        />
                      </div>
                      {transportMetaDeta?.document_type === "others" && (
                        <div className="s-sbDetails__transport-docs-textfield">
                          <MuiTextInput
                            placeholder={
                              transportData?.document_type_other?.placeholder
                            }
                            type={"text"}
                            value={transportMetaDeta?.document_type_other}
                            onChange={(e) => {
                              handleInputChange(
                                e.target.value,
                                transportData?.document_type_other?.slug
                              );
                            }}
                            disabled={
                              transportData?.document_type_other?.disabled
                            }
                            height="40px"
                            minWidth="225px"
                            width={"225px"}
                            id={transportData?.document_type_other?.slug}
                            fontSize="13px"
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="s-sbDetails__transport-docs__upload">
                  <div className="s-sbDetails__transport-docs__upload-card">
                    <UploadFileCard
                      upload_enabled={
                        transportData?.data?.[0]?.document?.upload_enabled
                      }
                      documents={
                        transportData?.data?.[0]?.document?.uploaded_documents
                      }
                      doc_type={transportData?.data?.[0]?.document?.slug}
                      invoiceId={transportData?.data?.[0]?.id}
                      uploadSingleFile={(
                        selectedFiles,
                        doc_type,
                        invoiceId
                      ) => {
                        const metadata = {
                          document_type: transportMetaDeta?.document_type,
                          document_type_other:
                            transportMetaDeta?.document_type === "others"
                              ? transportMetaDeta?.document_type_other
                              : "",
                        };
                        uploadSingleFile(
                          selectedFiles,
                          doc_type,
                          invoiceId,
                          metadata
                        );
                      }}
                      section="sb_docs"
                      onDocAction={onDocAction}
                      disabled={isTransportDocDisabled()}
                    />
                    <p className="sb-invoice__col-error">
                      {
                        summaryErrorMessage?.[
                          transportData?.data?.[0]?.document?.slug
                        ]
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <p className="post-recon-completed-docs-footer">{data?.footer_text}</p>
        <div className="post-recon-completed-docs__buttons">
          {data?.buttons?.map((item, idx) => {
            return (
              <Button
                buttonType={item?.sub_type}
                buttonLabel={item?.text}
                icon={item?.icon}
                iconAlignment={item?.icon_alignment}
                disabled={item?.disabled}
                onClick={() => onActionClick(null, item)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

const PostSBInvoiceCard = ({
  data,
  uploadSingleFile,
  onDocAction,
  summaryErrorMessage,
}) => {
  const [expandedList, setExpandedlist] = useState(null);
  const [currency, setCurrency] = useState("");
  const [showExpanded, setShowExpanded] = useState(false);
  const [showCopyIndex, setShowCopyIndex] = useState(null);

  const handleExpandedData = (theData, theCurrency) => {
    setShowExpanded(!showExpanded);
    setExpandedlist(theData);
    setCurrency(theCurrency);
  };

  return (
    <div className="sb-invoice" style={{ border: "none" }}>
      <div className="sb-invoice__visible" style={{ padding: "0px 75px" }}>
        {data?.container_data?.map((item, idx) => {
          return (
            <>
              <div
                className={
                  item?.slug === "upload_invoice_text"
                    ? "sb-invoice__invoice-text"
                    : "sb-invoice__row"
                }
                key={idx}
                style={{
                  gridRow: `${item?.row}`,
                  gridColumn: `${item?.column}`,
                  ...(item?.slug === "upload_invoice_text" && {
                    display: "flex",
                    alignItems: "center",
                  }),
                  ...(item?.data && { justifyItems: "end" }),
                }}
              >
                {item?.data ? (
                  <>
                    <div
                      className="sb-invoice__col"
                      style={{ gridColumn: "1/4" }}
                    >
                      <UploadFileCard
                        upload_enabled={item?.data?.document?.upload_enabled}
                        documents={item?.data?.document?.uploaded_documents}
                        doc_type={item?.data?.document?.slug}
                        invoiceId={item?.data?.id}
                        uploadSingleFile={uploadSingleFile}
                        // docId={docId}
                        section="sb_docs"
                        // primaryText={"Upload a invoice doc"}
                        onDocAction={onDocAction}
                      />
                    </div>
                    <p
                      className="sb-invoice__col-error"
                      style={{ gridColumn: "1/4" }}
                    >
                      {summaryErrorMessage?.[item?.data?.id]}
                    </p>
                  </>
                ) : (
                  <>
                    <div
                      className="sb-invoice__col"
                      style={{ gridColumn: "1" }}
                    >
                      <p
                        className="sb-invoice__text"
                        dangerouslySetInnerHTML={sanitizeHtml(item?.title)}
                      />
                    </div>
                    <div
                      className="sb-invoice__col"
                      style={{ gridColumn: "2" }}
                      onMouseEnter={() => {
                        setShowCopyIndex(idx);
                      }}
                      onMouseLeave={() => {
                        setShowCopyIndex(null);
                      }}
                    >
                      <p
                        className={"sb-invoice__text"}
                        dangerouslySetInnerHTML={sanitizeHtml(item?.text)}
                      />

                      {item?.show_hover && (
                        <BlunoTooltip
                          icon={item?.hover_icon}
                          text={item?.hover_text}
                          iconWidth="16px"
                          iconHeight="16px"
                        />
                      )}
                      <div style={{ width: "20px", height: "16px" }}>
                        {item?.enable_copy && showCopyIndex === idx && (
                          <CopyToClipboard text={item?.copy_text} />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          );
        })}
        {expandedList && showExpanded && (
          <div className="sb-invoice__toggle">
            {expandedList?.map((item) => {
              return (
                <>
                  {item?.type === "icon" ? (
                    <div className="sb-invoice__box">
                      <img
                        className="sb-invoice__img"
                        src={item?.icon}
                        alt="addition"
                      />
                    </div>
                  ) : (
                    <div className="sb-invoice__box">
                      <h3 className="sb-invoice__title">{item?.title}</h3>
                      <p
                        className="sb-invoice__number"
                        dangerouslySetInnerHTML={sanitizeHtml(
                          item?.text +
                            " " +
                            `<span class="sb-invoice__currency">${currency}</span>`,
                          ["class"]
                        )}
                      ></p>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PostReconPendingDocs;
