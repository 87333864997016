export const formatUrl = (url) => {
  // Check if URL starts with "http://" or "https://"
  if (!/^https?:\/\//i.test(url)) {
    url = "https://" + url; // Add "https://" if not present
  }

  // Check if URL contains "www." after "https://"
  // if (!/^(https?:\/\/)?(www\.)/i.test(url)) {
  //   url = url.replace(/^(https?:\/\/)/i, "$1www."); // Add "www." if not present
  // }

  return url;
};
