import React, { useEffect, useState } from "react";
import Input from "../../components/Input";
import Dropdown from "../../components/Dropdown";

const NewIrmForm = (props) => {
  const { form_data, inputValues = [], setInputValues = () => {} } = props;

  useEffect(() => {
    const list = [];
    form_data?.map((objList) => {
      objList?.map((item) => {
        list.push({ name: item?.slug, value: "" });
      });
    });
    list.push({ name: "document_id", value: "" });
    setInputValues(list);
  }, []);

  const handleInputChange = (event, theSlug) => {
    const newValue = event.target.value;
    setInputValues((prevInputs) =>
      prevInputs.map((input) => {
        if (input.name === theSlug) {
          return { ...input, value: newValue };
        }
        return input;
      })
    );
  };

  // Function to update state based on slug for dropdowns
  const handleDropdownChange = (event, theSlug) => {
    const newValue = event.value;
    setInputValues((prevInputs) =>
      prevInputs.map((input) => {
        if (input.name === theSlug) {
          return { ...input, value: newValue };
        }
        return input;
      })
    );
  };

  const renderField = (theItem) => {
    return (
      <div className="d0-irm__col">
        <p
          className={`d0-irm__title ${
            theItem?.is_required ? `s-input--required` : ""
          }`}
        >
          {theItem?.title}
        </p>
        {theItem?.type === "dropdown" ? (
          <Dropdown
            options={theItem?.options}
            onSelectChange={(e) => {
              handleDropdownChange(e, theItem?.slug);
            }}
            defaultValue={
              inputValues.filter((item) => item.name === theItem?.slug)[0]
                ?.value
            }
            isRequired={theItem?.is_required}
            isSearchable={theItem?.is_searchable}
          />
        ) : (
          <Input
            title={theItem?.title}
            type={theItem?.sub_type}
            placeholder={theItem?.placeholder}
            onChange={(e) => {
              handleInputChange(e, theItem?.slug, null);
            }}
            value={
              inputValues.filter((item) => item.name === theItem?.slug)[0]
                ?.value
            }
            isRequired={theItem?.is_required}
            min={theItem?.limits?.min}
            max={theItem?.limits?.max}
          />
        )}
      </div>
    );
  };

  return (
    <div className="d0-irm">
      {form_data?.map((objList) => {
        return (
          <div className="d0-irm__row">
            {objList?.map((item) => {
              return renderField(item);
            })}
          </div>
        );
      })}
    </div>
  );
};

export default NewIrmForm;
