// react imports
import { createSlice } from "@reduxjs/toolkit";

// in-app imports

export const toastSlice = createSlice({
  name: "toast",
  initialState: {
    isToastShow: false,
    toastItems: {}
  },
  reducers: {
    showToast: (state, action) =>{
        state.isToastShow = true;
        state.toastItems = action?.payload;
    },
    closeToast: (state, action) =>{
        state.isToastShow = false;
        state.toastItems = {};
    }
  },
});

// Action creators are generated for each case reducer function
export const { showToast, closeToast } = toastSlice.actions;

export default toastSlice.reducer;
