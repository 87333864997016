// react imports
import React, { useEffect, useState, useRef } from "react";

// redux imports
import endpoints from "../../api/endpoints";
import { apiRequest } from "../../redux/common/actions";
import { useDispatch } from "react-redux";

// in-app imports
import blunoLogo from "../../assets/media/common/Bluno-Logo.png";
import { showToast } from "../../redux/toast/slice";
import { InputError } from "../../app/constants/InputError";
import {
  generalKeyDownValidation,
  validateNumber,
  validateNumeric,
  validatePassword,
} from "../../utils/Validation";
import GetHelp from "../../components/Onboarding/GetHelp";
import ModalV2 from "../../components/ModalV2";
import EnterMobile from "./Screens/EnterMobile";
import VerifyOtp from "./Screens/VerifyOtp";
import VerifyPassword from "./Screens/VerifyPassword";
import SetPassword from "./Screens/SetPassword";
import { LOGIN_SCREEN_SLUGS } from "../../utils/constants/OnboardingConstants";
import { useNavigate } from "react-router-dom";
import { GetQueryParamValue } from "../../utils";
import ExpiredLink from "../../components/Onboarding/ExpiredLink";
import { STAGE } from "../../utils/constants/UserConstants";
import { convertErrorMapToJson } from "../../utils/errorMapConversionHelper";
import DemoClone from "../../components/DemoClone";
import DemoCompleteClone from "../../components/DemoClone/DemoCompleteClone";

const Login = () => {
  // defined to access react based APIs
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // local states
  const [screenSlug, setScreenSlug] = useState(LOGIN_SCREEN_SLUGS?.enterMobile);
  const [editFlow, setEditFlow] = useState(null);
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [errorMessagesObject, setErrorMessagesObject] = useState({});
  const [isInputTypePassword, setIsInputTypePassword] = useState({
    password: true,
    confirm_password: true,
  });
  const [isPasswordValid, setIsPasswordValid] = useState({
    password: false,
    confirm_password: false,
  });

  const [startJourneyData, setStartJourneyData] = useState();
  const [reqId, setReqId] = useState();
  const [batchReqId, setBatchReqId] = useState();
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState(null);
  const [errorMessagePhone, setErrorMessagePhone] = useState();
  const [errorMessageOTP, setErrorMessageOTP] = useState();
  const [timerValue, setTimerValue] = useState(0);
  const [userBlocked, setUserBlocked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [getHelpFlow, setGetHelpFlow] = useState("");
  const [otpexpired, setOTPExpired] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showExpireLinkModal, setShowExpireLinkModal] = useState(false);
  const [expireLinkModalData, setExpireLinkModalData] = useState({});
  const [showDemoForm, setShowDemoForm] = useState(false);
  const [screen, setScreen] = useState(1);

  //local refs
  const otpInputRefs = useRef([]);
  const timer = useRef();

  // other states
  const inviteId = GetQueryParamValue("inviteId");

  useEffect(() => {
    clearTimeout(timer.current);
    timerValue > 0 &&
      (timer.current = setTimeout(() => {
        if (timerValue === 1) {
          setOTPExpired(true);
          setUserBlocked(true);
        }
        setTimerValue((prev) => Math.max(prev - 1, 0));
      }, 1000));
  }, [timerValue]);

  const converTimeToString = () => {
    return (
      (~~(timerValue / 60) >= 10
        ? ~~(timerValue / 60)
        : "0" + ~~(timerValue / 60)) +
      ":" +
      (timerValue % 60 >= 10 ? timerValue % 60 : "0" + (timerValue % 60))
    );
  };

  const handleInputChange = (value, slug) => {
    switch (slug) {
      case "phone_number":
        if (value?.length <= 10) {
          if (value[0] === "0") {
            setErrorMessagePhone(InputError?.IncorrectMobileNumber?.message);
          } else if (value && !validateNumber(value, false)) {
            setErrorMessagePhone(InputError?.IncorrectMobileNumber?.message);
          } else {
            setErrorMessagePhone(null);
            setPhone(value);
          }
        }
        if (value.length > 10) {
          setErrorMessagePhone(InputError?.FullMobileNumber?.message);
        }
        break;
      case "verify_otp":
        if (!validateNumeric(value)) {
          setErrorMessageOTP(InputError?.IncorrectOTP?.message);
          if (otpInputRefs?.current[value?.length - 1]) {
            otpInputRefs?.current[value?.length - 1].focus();
          }
        } else {
          if (otpInputRefs?.current[value?.length]) {
            otpInputRefs?.current[value?.length].focus();
            if (value?.length - 1 >= 0 && value?.length < otp?.length) {
              otpInputRefs?.current[value?.length - 1].focus();
            }
          }
          setErrorMessageOTP("");
          setOtp(value);
        }
        break;
      case "enter_password":
        setErrorMessagesObject({});

        const validationObj = validatePassword(value);
        if (value?.length > 0 && validationObj?.success) {
          setIsPasswordValid((prevErrorObj) => {
            return {
              ...prevErrorObj,
              password: true,
            };
          });
        } else if (value?.length > 20) {
          setIsPasswordValid((prevErrorObj) => {
            return {
              ...prevErrorObj,
              password: false,
            };
          });
          setErrorMessagesObject((prevErrorObj) => {
            return {
              ...prevErrorObj,
              password: InputError?.TooLongPassword?.message,
            };
          });
          return;
        } else {
          setIsPasswordValid((prevErrorObj) => {
            return {
              ...prevErrorObj,
              password: false,
            };
          });
        }

        setPassword(value);
        break;
      case "re_enter_password":
        setErrorMessagesObject({});

        if (value?.length > 0 && value === password) {
          setIsPasswordValid((prevErrorObj) => {
            return {
              ...prevErrorObj,
              confirm_password: true,
            };
          });
        } else if (value?.length > 20) {
          setIsPasswordValid((prevErrorObj) => {
            return {
              ...prevErrorObj,
              confirm_password: false,
            };
          });
          setErrorMessagesObject((prevErrorObj) => {
            return {
              ...prevErrorObj,
              confirm_password: InputError?.TooLongPassword?.message,
            };
          });
          return;
        } else {
          setIsPasswordValid((prevErrorObj) => {
            return {
              ...prevErrorObj,
              confirm_password: false,
            };
          });
        }

        setConfirmPassword(value);
        break;
      case "verify_password":
        setErrorMessagesObject({});
        setPassword(value);
        break;
    }
  };

  const handleKeyDown = (value, slug, e) => {
    switch (slug) {
      case "phone_number":
        if (generalKeyDownValidation(validateNumber, value, e)) {
          e.preventDefault();
          setErrorMessagePhone(InputError?.IncorrectMobileNumber?.message);
        }
        if (value === "Enter") {
          onPhoneEnter(null, editFlow);
        }
        break;
      case "verify_otp":
        if (value === "Backspace")
          if (otp?.length - 1 >= 0 && otpInputRefs?.current[otp?.length - 1])
            otpInputRefs?.current[otp?.length - 1].focus();
        if (value === "Enter") onVerifyOtp(null);
        break;
      case "enter_password":
        if (value === "Enter") {
          onSetPassword();
        }
        break;
      case "re_enter_password":
        if (value === "Enter") {
          onSetPassword();
        }
        break;
      case "verify_password":
        if (value === "Enter") {
          onVerifyPassword();
        }
        break;
    }
  };

  const onPhoneEnter = async (action, editFlow = LOGIN_SCREEN_SLUGS?.login) => {
    if (phone?.length < 10) {
      if (phone?.length === 0)
        setErrorMessagePhone(InputError?.EmptyPhoneNumber?.message);
      else setErrorMessagePhone(InputError?.IncorrectMobileNumber?.message);
      return;
    }
    setErrorMessagePhone("");
    const startJourneyData = async () => {
      const startJourneyRes = await dispatch(
        apiRequest(endpoints.startJourney, {
          edit_flow: editFlow,
          identity: {
            type: "PHONE",
            value: {
              country_code: "+91",
              phone_no: phone,
            },
          },
          invite_id: inviteId ?? "",
        })
      );
      if (startJourneyRes?.success && startJourneyRes?.data) {
        setReqId(startJourneyRes?.data?.request_id);
        setBatchReqId(startJourneyRes?.data?.batch_request_id);
        setTimerValue(startJourneyRes?.data?.duration_in_seconds);
        setScreenSlug(startJourneyRes?.data?.next_login_screen);
        setIsExistingUser(startJourneyRes?.data?.is_existing_user);
        setErrorMessagesObject({});
        return true;
      } else {
        // error toast message
        if (
          startJourneyRes?.data?.toast?.data?.center?.text ===
          "Phone number not allowed"
        ) {
          setShowDemoForm(true);
          return false;
        }
        if (startJourneyRes?.data?.toast) {
          dispatch(showToast(startJourneyRes?.data?.toast));
        } else if (startJourneyRes?.data?.slug_message_map) {
          return startJourneyRes?.data?.slug_message_map;
        }
        return false;
      }
    };
    return await startJourneyData();
  };

  const onResendOtp = () => {
    const resendOtp = async () => {
      const resendOtpRes = await dispatch(
        apiRequest(endpoints.resendPhoneOtp, {
          batch_request_id: batchReqId,
          request_id: reqId,
        })
      );
      if (resendOtpRes?.success && resendOtpRes?.data) {
        if (resendOtpRes?.data?.attempts_remaining === 0) {
          setTimerValue(0);
          setErrorMessageOTP(InputError?.TooManyAttemptsOTP?.message);
          setUserBlocked(true);
          return;
        }
        setReqId(resendOtpRes?.data?.request_id);
        setBatchReqId(resendOtpRes?.data?.batch_request_id);
        setUserBlocked(false);
        setOTPExpired(false);
        clearTimeout(timer.current);
        setTimerValue(resendOtpRes?.data?.duration_in_seconds);
        setErrorMessageOTP(
          InputError?.ResendOTP?.message?.replace(
            "%s",
            resendOtpRes?.data?.attempts_remaining
          )
        );
        setErrorMessagesObject({});
      } else {
        // error toast message
        if (resendOtpRes?.data?.toast) {
          dispatch(showToast(resendOtpRes?.data?.toast));
        }

        // error inline message
        if (resendOtpRes?.data?.message) {
          setErrorMessageOTP(resendOtpRes?.data?.message?.text);
        }
      }
    };
    resendOtp();
  };

  const onVerifyOtp = (action) => {
    if (!otp || otp?.length === 0) {
      setErrorMessageOTP(InputError?.EmptyOTP?.message);
      return;
    }
    if (otp?.length < 6) {
      setErrorMessageOTP(InputError?.IncorrectOTP?.message);
      return;
    }
    const verifyOtp = async () => {
      const verifyOtpRes = await dispatch(
        apiRequest(endpoints.verifyPhoneOtp, {
          edit_flow: editFlow,
          batch_request_id: batchReqId,
          request_id: reqId,
          otp: otp,
          invite_id: inviteId,
        })
      );
      if (verifyOtpRes?.success && verifyOtpRes?.data) {
        if (verifyOtpRes?.data?.verification_status === "verified") {
          //action after otp succesfully verified
          const tokens = verifyOtpRes?.data?.tokens;
          const deeplink = verifyOtpRes?.data?.deeplink;
          if (tokens && deeplink) {
            if (verifyOtpRes?.data?.screen === STAGE?.ONBOARDING) {
              sessionStorage.setItem("access", tokens?.access);
              sessionStorage.setItem("refresh", tokens?.refresh);
            } else {
              localStorage.setItem("access", tokens?.access);
              localStorage.setItem("refresh", tokens?.refresh);
            }
            window.location.href = verifyOtpRes?.data?.deeplink;
          } else {
            setScreenSlug(verifyOtpRes?.data?.next_login_screen);
          }

          if (editFlow === "forgot_password") {
            setReqId(verifyOtpRes?.data?.request_id);
            setBatchReqId(verifyOtpRes?.data?.batch_request_id);
          }
        } else {
          if (verifyOtpRes?.data?.attempts_remaining === 0) {
            setErrorMessageOTP(InputError?.TooManyAttemptsOTP?.message);
            setUserBlocked(true);
            setTimerValue(0);
          } else {
            setErrorMessageOTP(
              InputError?.IncorrectOTPAttempt?.message?.replace(
                "%s",
                verifyOtpRes?.data?.attempts_remaining
              )
            );
          }
        }

        setTimeout(() => {
          setPassword("");
          setConfirmPassword("");
          setOtp("");
          setErrorMessagesObject({});
        }, 200);
      } else {
        // error toast message
        if (verifyOtpRes?.data?.toast) {
          dispatch(showToast(verifyOtpRes?.data?.toast));
        }
      }
    };
    verifyOtp();
  };

  const onEditPhone = () => {
    setScreenSlug(LOGIN_SCREEN_SLUGS?.enterMobile);
    setEditFlow(null);
    setPassword("");
    setConfirmPassword("");
    setErrorMessagePhone(null);
    setTimerValue(0);
    setUserBlocked(false);
    setOTPExpired(false);
    setErrorMessageOTP(null);
    setOtp(null);
    setErrorMessagesObject({});
  };

  const onSetPassword = () => {
    setErrorMessagesObject({});
    if (password?.length === 0) {
      setErrorMessagesObject((prevErrorObj) => {
        return {
          ...prevErrorObj,
          password: InputError?.EmptyPassword?.message,
        };
      });
      return;
    }

    const validationObj = validatePassword(password);
    if (!validationObj?.success) {
      setErrorMessagesObject((prevErrorObj) => {
        return {
          ...prevErrorObj,
          password: validationObj?.message,
        };
      });
      return;
    }

    if (confirmPassword !== password) {
      setErrorMessagesObject((prevErrorObj) => {
        return {
          ...prevErrorObj,
          confirm_password: InputError?.PasswordMismatch?.message,
        };
      });
      return;
    }

    const setNewPassword = async () => {
      const reqBody = {
        entered_password: password,
        re_entered_password: confirmPassword,
        identity: {
          type: "PHONE",
          value: {
            country_code: "+91",
            phone_no: phone,
          },
        },
      };

      const apiRes = await dispatch(
        apiRequest(endpoints.addNewPassword, reqBody)
      );
      if (apiRes?.success && apiRes?.data) {
        if (editFlow === "forgot_password" && apiRes?.data?.toast) {
          dispatch(showToast(apiRes?.data?.toast?.toast));
        }

        const tokens = apiRes?.data?.tokens;
        const deeplink = apiRes?.data?.deeplink;
        if (tokens && deeplink) {
          if (apiRes?.data?.screen === STAGE?.ONBOARDING) {
            sessionStorage.setItem("access", tokens?.access);
            sessionStorage.setItem("refresh", tokens?.refresh);
          } else {
            localStorage.setItem("access", tokens?.access);
            localStorage.setItem("refresh", tokens?.refresh);
          }
          window.location.href = apiRes?.data?.deeplink;
        }
        setTimeout(() => {
          setPassword("");
          setConfirmPassword("");
          setErrorMessagesObject({});
        }, 200);
      } else {
        if (apiRes?.data?.toast) {
          setErrorMessagesObject((prevErrorObj) => {
            return {
              ...prevErrorObj,
              password: apiRes?.data?.toast?.data?.center?.text,
            };
          });
        }

        if (apiRes?.data?.slug_message_map) {
          if (apiRes?.data?.slug_message_map?.enter_password) {
            setErrorMessagesObject((prevErrorObj) => {
              return {
                ...prevErrorObj,
                password: apiRes?.data?.slug_message_map?.enter_password?.text,
              };
            });
          }

          if (apiRes?.data?.slug_message_map?.re_enter_password) {
            setErrorMessagesObject((prevErrorObj) => {
              return {
                ...prevErrorObj,
                confirm_password:
                  apiRes?.data?.slug_message_map?.re_enter_password?.text,
              };
            });
          }
        }
      }
    };

    const setUpdateForgottenPassword = async () => {
      const reqBody = {
        request_id: reqId,
        batch_request_id: batchReqId,
        credential_value: password,
        reenter_credential_value: confirmPassword,
        edit_flow: editFlow,
      };

      const apiRes = await dispatch(
        apiRequest(endpoints.forgotPassword, reqBody)
      );
      if (apiRes?.success && apiRes?.data) {
        //show success toast
        if (apiRes?.data?.toast) {
          dispatch(showToast(apiRes?.data?.toast?.toast));
        }

        setScreenSlug(apiRes?.data?.next_login_screen);
        setTimeout(() => {
          setPassword("");
          setConfirmPassword("");
          setEditFlow(null);
          setErrorMessagesObject({});
        }, 200);
      } else {
        if (apiRes?.data?.toast) {
          if (
            apiRes?.data?.toast?.data?.center?.text.includes("Session expired")
          ) {
            navigate(0);
          }
          dispatch(showToast(apiRes?.data?.toast));
        } else if (apiRes?.data?.slug_message_map) {
          setErrorMessagesObject((prev) => ({
            ...prev,
            ...convertErrorMapToJson(apiRes?.data?.slug_message_map),
          }));
        }
      }
    };

    if (editFlow === "forgot_password") {
      setUpdateForgottenPassword();
    } else {
      setNewPassword();
    }
  };

  const onVerifyPassword = () => {
    if (password?.length === 0) {
      setErrorMessagesObject((prevErrorObj) => {
        return {
          ...prevErrorObj,
          password: InputError?.EmptyPassword?.message,
        };
      });
      return;
    }
    const verifyPassword = async () => {
      setErrorMessagesObject({});
      const reqBody = {
        entered_password: password,
        identity: {
          type: "PHONE",
          value: {
            country_code: "+91",
            phone_no: phone,
          },
        },
      };

      const apiRes = await dispatch(
        apiRequest(endpoints.verifyPassword, reqBody)
      );

      if (apiRes?.success && apiRes?.data) {
        const tokens = apiRes?.data?.tokens;
        const deeplink = apiRes?.data?.deeplink;
        if (tokens && deeplink) {
          if (apiRes?.data?.screen === STAGE?.ONBOARDING) {
            sessionStorage.setItem("access", tokens?.access);
            sessionStorage.setItem("refresh", tokens?.refresh);
          } else {
            localStorage.setItem("access", tokens?.access);
            localStorage.setItem("refresh", tokens?.refresh);
          }
          window.location.href = apiRes?.data?.deeplink;
        } else {
          setReqId(apiRes?.data?.request_id);
          setBatchReqId(apiRes?.data?.batch_request_id);
          setTimerValue(apiRes?.data?.duration_in_seconds);
          setScreenSlug(apiRes?.data?.next_login_screen);
        }

        setTimeout(() => {
          setPassword("");
          setConfirmPassword("");
          setErrorMessagesObject({});
        }, 200);
      } else {
        if (apiRes?.data?.toast) {
          setErrorMessagesObject((prevErrorObj) => {
            return {
              ...prevErrorObj,
              password: apiRes?.data?.toast?.data?.center?.text,
            };
          });
        }

        if (apiRes?.data?.slug_message_map) {
          setErrorMessagesObject((prevErrorObj) => {
            return {
              ...prevErrorObj,
              password: apiRes?.data?.slug_message_map?.verify_password?.text,
            };
          });
        }
      }
    };

    verifyPassword();
  };

  useEffect(() => {
    const getStartJourneyData = async () => {
      const startJourneyRes = await dispatch(
        apiRequest(
          endpoints.getStartJourney,
          `${inviteId ? `?invite_id=${inviteId}` : ""}`
        )
      );
      if (startJourneyRes?.success && startJourneyRes?.data) {
        setStartJourneyData(startJourneyRes?.data);
        if (inviteId) {
          if (startJourneyRes?.data?.enter_mobile?.inputs?.[0]?.default_value) {
            setPhone(
              startJourneyRes?.data?.enter_mobile?.inputs?.[0]?.default_value
            );
          } else {
            navigate("/onboarding/login", { replace: true });
            if (startJourneyRes?.data?.enter_mobile?.expire_invite_popup) {
              setExpireLinkModalData(
                startJourneyRes?.data?.enter_mobile?.expire_invite_popup
              );
              setShowExpireLinkModal(true);
            }
          }
        }
      }
    };
    getStartJourneyData();
  }, []);

  const renderScreen = () => {
    switch (screenSlug) {
      case LOGIN_SCREEN_SLUGS?.enterMobile:
        return (
          <EnterMobile
            data={startJourneyData?.enter_mobile}
            phone={phone}
            handleInputChange={handleInputChange}
            errorMessagePhone={errorMessagePhone}
            handleKeyDown={handleKeyDown}
            onPhoneEnter={onPhoneEnter}
          />
        );
      case LOGIN_SCREEN_SLUGS?.otpVerification:
        return (
          <VerifyOtp
            data={startJourneyData?.otp_verification}
            phone={phone}
            setPhone={setPhone}
            onEditPhone={onEditPhone}
            otp={otp}
            errorMessagePhone={errorMessagePhone}
            setErrorMessagePhone={setErrorMessagePhone}
            handleInputChange={handleInputChange}
            otpInputRefs={otpInputRefs}
            handleKeyDown={handleKeyDown}
            otpexpired={otpexpired}
            converTimeToString={converTimeToString}
            timerValue={timerValue}
            onResendOtp={onResendOtp}
            errorMessageOTP={errorMessageOTP}
            userBlocked={userBlocked}
            onVerifyOtp={onVerifyOtp}
            screenSlug={screenSlug}
            isExistingUser={isExistingUser}
            editFlow={editFlow}
            onPhoneEnter={onPhoneEnter}
          />
        );
      case LOGIN_SCREEN_SLUGS?.verifyPassword:
        return (
          <VerifyPassword
            data={startJourneyData?.verify_password}
            phone={phone}
            setEditFlow={setEditFlow}
            setScreenSlug={setScreenSlug}
            onVerifyPassword={onVerifyPassword}
            password={password}
            setPassword={setPassword}
            handleInputChange={handleInputChange}
            onPhoneEnter={onPhoneEnter}
            onEditPhone={onEditPhone}
            errorMessagesObject={errorMessagesObject}
            handleKeyDown={handleKeyDown}
          />
        );
      case LOGIN_SCREEN_SLUGS?.setPassword:
        return (
          <SetPassword
            data={startJourneyData?.set_password}
            password={password}
            confirmPassword={confirmPassword}
            phone={phone}
            handleInputChange={handleInputChange}
            onSetPassword={onSetPassword}
            errorMessagesObject={errorMessagesObject}
            handleKeyDown={handleKeyDown}
            isInputTypePassword={isInputTypePassword}
            setIsInputTypePassword={setIsInputTypePassword}
            isPasswordValid={isPasswordValid}
            editFlow={editFlow}
          />
        );
      default:
        return (
          <EnterMobile
            data={startJourneyData?.enter_mobile}
            phone={phone}
            handleInputChange={handleInputChange}
            errorMessagePhone={errorMessagePhone}
            handleKeyDown={handleKeyDown}
            onPhoneEnter={onPhoneEnter}
          />
        );
    }
  };

  return (
    <>
      {showDemoForm && (
        <div className="demo-form-frame">
          {screen === 1 ? (
            <DemoClone
              setOpenModal={setShowDemoForm}
              setScreen={setScreen}
              phoneNumber={phone}
            />
          ) : (
            <DemoCompleteClone setOpenModal={setShowDemoForm} />
          )}
        </div>
      )}
      {showModal && (
        <div className="a-buyers__modal-bg">
          <ModalV2
            slug={"standard"}
            children={
              <GetHelp setShowModal={setShowModal} getHelpFlow={getHelpFlow} />
            }
          />
        </div>
      )}
      {showExpireLinkModal && (
        <div className="a-buyers__modal-bg">
          <ModalV2
            slug={"standard"}
            children={
              <ExpiredLink
                setShowModal={setShowExpireLinkModal}
                data={expireLinkModalData}
              />
            }
          />
        </div>
      )}
      <div className="o-company-data">
        <div className="o-static">
          <div className="o-static-content">
            <div>
              <img src={blunoLogo} alt="" className="o-login__static-logo" />
            </div>
            {editFlow !== "forgot_password" && (
              <div className="o-login__highlights">
                {startJourneyData?.static_response?.highlights?.map((item) => {
                  return (
                    <div className="o-login__highlights-item">
                      <img src={item?.icon} alt="" />
                      <div className="o-login__highlights-point">
                        {item?.text_list?.map((child) => {
                          switch (child?.text_type) {
                            case "plain":
                              return (
                                <span
                                  className="o-login__highlights-text"
                                  style={{
                                    color: child?.color ?? "",
                                    fontWeight: child?.font_weight ?? "",
                                  }}
                                >
                                  {child?.value}
                                </span>
                              );
                            case "hyperlink":
                              return (
                                <span
                                  className="o-login__highlights-text"
                                  style={{
                                    color: child?.color ?? "",
                                    fontWeight: child?.font_weight ?? "",
                                    cursor: "pointer",
                                  }}
                                >
                                  {child?.value}
                                </span>
                              );
                          }
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {editFlow === "forgot_password" &&
              startJourneyData?.static_response?.image_object && (
                <div className="o-login__resource">
                  <img
                    src={
                      startJourneyData?.static_response?.image_object?.hero_img
                    }
                    alt="hero image"
                  />
                </div>
              )}
          </div>
          <div className="o-login__powered">
            <h2 className="o-login__powered-title">
              {startJourneyData?.static_response?.powered_by?.title}
            </h2>
            <div className="o-login__powered-content">
              <img
                src={startJourneyData?.static_response?.powered_by?.image}
                alt=""
                className="o-login__powered-icon"
              />
            </div>
          </div>
        </div>
        <div className="o-login__flow">
          <>{renderScreen()}</>

          <div className="o-login__help">
            <p className="o-login__help-content">
              {startJourneyData?.enter_mobile?.help_text?.text_list?.map(
                (child) => {
                  switch (child?.text_type) {
                    case "plain":
                      return (
                        <span
                          className="o-login__help-text"
                          style={{
                            color: child?.color ?? "",
                            fontWeight: child?.font_weight ?? "",
                          }}
                        >
                          {child?.value}
                        </span>
                      );
                    case "hyperlink":
                      return (
                        <span
                          className="o-login__help-text"
                          style={{
                            color: child?.color ?? "",
                            fontWeight: child?.font_weight ?? "",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowModal(true);
                            setGetHelpFlow(child?.flow);
                          }}
                        >
                          {child?.value}
                        </span>
                      );
                  }
                }
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
