import React, { useState } from "react";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import { LOGIN_SCREEN_SLUGS } from "../../../utils/constants/OnboardingConstants";
import MuiTextInput from "../../../components/blunoInputs/MuiTextInputs";

const VerifyPassword = ({
  data,
  setEditFlow,
  setScreenSlug,
  phone = "",
  password,
  setPassword,
  handleInputChange,
  onVerifyPassword,
  onEditPhone,
  errorMessagesObject = {},
  handleKeyDown,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="o-login__enter">
      <div>
        <h2 className="o-login__title">{data?.title}</h2>
      </div>
      <div className="o-login__notification">
        <p className="o-login__notification-content">
          <span className="o-login__notification-text">
            {data?.mobile_text?.value}
          </span>
          <span className="o-login__notification-phone">
            {"+91-" + phone + "."}
          </span>
        </p>
        <div className="o-login__edit">
          <p onClick={onEditPhone}>{data?.edit_mobile?.value}</p>
        </div>
      </div>

      <div className="o-login__inputs">
        {data?.inputs?.map((item) => {
          return (
            <div className="o-login__input">
              <label htmlFor={item?.slug} className="o-login__input-label">
                {item?.title}
              </label>
              <div className="o-login__input__container">
                <MuiTextInput
                  placeholder={item?.placeholder}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  icon={
                    password &&
                    (showPassword ? item?.secondary_icon : item?.icon)
                  }
                  isIconClickable={true}
                  onIconClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  iconAlignment="right"
                  id={item?.slug}
                  errorMessage={errorMessagesObject?.password}
                  onChange={(e) => {
                    handleInputChange(e.target.value, item?.slug, e);
                  }}
                  onKeyDown={(e) => handleKeyDown(e.key, item?.slug, e)}
                  disabled={item?.disabled}
                  height="40px"
                  minWidth="360px"
                  fontSize="13px"
                  disableCopyPaste={true}
                />
              </div>
            </div>
          );
        })}
        {data?.forgot_password && (
          <p
            className="o-login__subaction"
            onClick={() => {
              setEditFlow(data?.forgot_password?.slug);
              setScreenSlug(LOGIN_SCREEN_SLUGS?.otpVerification);
              setPassword("");
            }}
          >
            {data?.forgot_password?.value}
          </p>
        )}
      </div>
      {data?.buttons && (
        <div className="o-login__continue">
          {data?.buttons?.map((action) => {
            return (
              action?.type === "button" && (
                <Button
                  buttonLabel={action?.text}
                  buttonType={action?.sub_type}
                  icon={action.icon}
                  disabled={action?.disabled}
                  iconAlignment={action?.icon_alignment}
                  extraClass={`o-login__continue-button`}
                  onClick={() => onVerifyPassword()}
                  height="40px"
                  fontSize="13px"
                />
              )
            );
          })}
        </div>
      )}
      <div className="o-login__terms">
        <p className="o-login__terms-content">
          {data?.terms?.text_list?.map((child) => {
            switch (child?.text_type) {
              case "plain":
                return (
                  <span
                    className="o-login__terms-text"
                    style={{
                      color: child?.color ?? "",
                      fontWeight: child?.font_weight ?? "",
                    }}
                  >
                    {child?.value}
                  </span>
                );
              case "hyperlink":
                return (
                  <a
                    target="_blank"
                    href={child?.deeplink}
                    style={{
                      color: child?.color ?? "",
                      fontWeight: child?.font_weight ?? "",
                      cursor: "pointer",
                    }}
                    className="o-login__terms-text"
                  >
                    {child?.value}
                  </a>
                );
            }
          })}
        </p>
      </div>
    </div>
  );
};

export default VerifyPassword;
