//react exports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom/dist";

//redux imports
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { closeAlert, resetAlertData, showAlert } from "../../redux/alert/slice";
import Button from "../../components/Button";

const SettingsSidebar = ({ data, currentPath = "" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAlertOk = useSelector((state) => state.alert.isAlertOk);
  const alertPropsList = useSelector((state) => state.alert.alertProps);

  const redirectTo = (theDeeplink, isDisabled) => {
    !isDisabled && navigate(theDeeplink[0]);
  };

  const handleSignout = async () => {
    const logout = await dispatch(apiRequest(endpoints.logout));
    if (logout?.success) {
      localStorage.clear();
      dispatch(resetAlertData());
      dispatch(closeAlert());
      navigate("/onboarding/login");
    }
  };

  const handleButtonClick = (theItem, theSlug) => {
    if (theSlug === "sign_out") {
      dispatch(
        showAlert({
          items: theItem?.popup_data,
          props: ["sign_out"],
        })
      );
    } else {
      theItem?.deeplink && redirectTo(theItem?.deeplink, theItem?.is_disabled);
    }
  };

  useEffect(() => {
    const action = alertPropsList[0];
    if (isAlertOk && action === "sign_out") {
      handleSignout();
      dispatch(resetAlertData());
      dispatch(closeAlert());
    }
  }, [isAlertOk]);
  return (
    <>
      {data?.map((item, index) => {
        if (!item) return;
        return (
          <>
            <div
              key={index}
              className={`g-settings__sidebar-item ${
                item?.deeplink?.includes(currentPath) &&
                "g-settings__sidebar-item--selected"
              } ${item?.is_disabled && "g-settings__sidebar-item--disabled"} 
              ${item?.slug === "sign_out" && "g-settings__sidebar-item--logout"}`}
              onClick={() => {
                handleButtonClick(item, item?.slug);
              }}
            >
              {item?.icon && (
                <img
                  src={item?.icon}
                  alt=""
                  className="g-settings__sidebar-item-icon"
                />
              )}
              <p className="g-settings__sidebar-item-text">
                {item?.display_name}
              </p>
            </div>
          </>
        );
      })}
    </>
  );
};

export default SettingsSidebar;
