// react imports
import React, { useState } from "react";

// in-app imports

const Connections = ({ social, policy }) => {
  // local states
  const [socialIcons, setSocialIcons] = useState({});

  const onLinkClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <>
      <div className={`sec-settings__connections ${social ? "sec-settings__connections--social" : ""}`}>
        {social && (
          <div className="sec-settings__social">
            <h2 className="sec-settings__social-title">{social?.title}</h2>
            <div className="sec-settings__social-apps">
              {social?.social_links?.map((item) => {
                return (
                  <div key={item?.slug}>
                    <img
                      src={item?.icon}
                      alt=""
                      // onMouseEnter={() => {
                      //   setSocialIcons((prev) => ({
                      //     ...prev,
                      //     [item?.slug]: item?.hover_icon,
                      //   }));
                      // }}
                      // onMouseLeave={() => {
                      //   setSocialIcons((prev) => ({
                      //     ...prev,
                      //     [item?.slug]: item?.icon,
                      //   }));
                      // }}
                      className="sec-settings__social-link"
                      onClick={() => {
                        item?.deeplink && onLinkClick(item?.deeplink);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="sec-settings__policy">
          <h2 className="sec-settings__policy-title">{policy?.title}</h2>
          <div
            className="sec-settings__policy-terms"
            onClick={() => onLinkClick(policy?.terms?.deeplink)}
          >
            {policy?.terms?.value}
          </div>
          <div
            className="sec-settings__policy-policy"
            onClick={() => onLinkClick(policy?.policy?.deeplink)}
          >
            {policy?.policy?.value}
          </div>
        </div>
      </div>
    </>
  );
};

export default Connections;
