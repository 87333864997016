import React from "react";

const Input = (props) => {
  const {
    type = "text",
    placeholder = "",
    width = "100%",
    height = "43px",
    name,
    value = "",
    onChange,
    isRequired = false,
    min = undefined,
    max = undefined,
    disabled,
    extraClass="",
    id=""
  } = props;

  return (
    <div className={`s-input`} style={{ width: width }}>
      <input
        type={type}
        value={value}
        placeholder={placeholder}
        className={`s-input__input s-input--${type} ${extraClass}`}
        style={{ height: height }}
        onChange={onChange}
        required={isRequired}
        min={min}
        max={max}
        name={name}
        minLength={type === "text" ? min : null}
        maxLength={type === "text" ? max : null}
        disabled={disabled}
        id={id}
      />
    </div>
  );
};

export default Input;
