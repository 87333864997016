//react imports
import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

//redux imports
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";

//in app imports
import SettingsSidebar from "../../modules/Settings/SettingsSidebar";
import ModalV2 from "../../components/ModalV2";
import Button from "../../components/Button";

const SettingsWrapper = () => {
  const [sidebarData, setSidebarData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({});

  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const getSidebartData = async () => {
      const apiRes = await dispatch(
        apiRequest(endpoints.getSidebarData, `?deeplink=${currentPath}`)
      );

      if (apiRes?.success && apiRes?.data) {
        setSidebarData(apiRes?.data);
      }
    };

    getSidebartData();
  }, []);

  return (
    <>
      {showPopup && (
        <div className="g-settings__popup-container">
          <ModalV2 slug={"standard"}>
            <div className="g-settings__popup">
              <img
                src={popupData?.close}
                className="g-settings__popup-close"
                onClick={() => {
                  setShowPopup(false);
                }}
              />
              <h2 className="g-settings__popup-heading">{popupData?.title}</h2>
              <p className="g-settings__popup-text">{popupData?.text}</p>
              <div className="g-settings__popup-button-container">
                <Button
                  buttonLabel={popupData?.reject_button?.text}
                  buttonType={popupData?.reject_button?.sub_type}
                  onClick={() => {
                    setShowPopup(false);
                  }}
                  extraClass="g-settings__popup-button"
                ></Button>
                <Button
                  buttonLabel={popupData?.accept_button?.text}
                  buttonType={popupData?.accept_button?.sub_type}
                  onClick={() => {
                    popupData?.accept_button?.action();
                    setShowPopup(false);
                  }}
                  extraClass="g-settings__popup-button"
                ></Button>
              </div>
            </div>
          </ModalV2>
        </div>
      )}
      <div
        className={`g-settings ${
          currentPath === "/settings/partners" ? "g-settings--dgftPartner" : ""
        }`}
      >
        <div className="g-settings__sidebar">
          <SettingsSidebar data={sidebarData} currentPath={currentPath} />
        </div>
        <div className="g-settings__body">
          <Outlet context={[showPopup, setShowPopup, setPopupData]} />
        </div>
      </div>
    </>
  );
};

export default SettingsWrapper;
