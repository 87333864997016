//in app imports
import React, { useState } from "react";

//third party imports
import { CopyToClipboard as ReactCopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyIcon from "../assets/svg/CopyIcon";

const CopyToClipboard = ({
  text = "",
  textPosition,
  variant = "highlight",
  tooltipText = "",
}) => {
  const [status, setStatus] = useState("");
  const [color, setColor] = useState(
    variant === "tooltip" ? "#1d1d1d" : "#888888"
  );
  const [showTooltip, setShowTooltip] = useState(false);

  const [backgroundColor, setBackgroundColor] = useState("transparent");

  const handleCopy = (e) => {
    if (!text) {
      setStatus("error");
    } else {
      setStatus("success");
    }
    setTimeout(() => setStatus(""), 3000);
  };

  const onMouseHoverEnter = () => {
    if (variant === "tooltip") {
      setBackgroundColor("#d2e7ff");
      setShowTooltip(true);
      setStatus("");
    } else {
      setColor("#046cb8");
    }
  };

  const onMouseHoverLeave = () => {
    if (variant === "tooltip") {
      setBackgroundColor("#ffffff");
      setShowTooltip(false);
      setStatus("");
    } else {
      setColor("#888888");
    }
  };

  const copyContainerStyles = {
    width: variant === "tooltip" && "32px",
    height: variant === "tooltip" && "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: backgroundColor,
    borderRadius: "50%",
  };

  const copyIconStyles = {
    height: "16px",
    cursor: "pointer",
    color: color,
  };

  return (
    <div
      className="s-copy-clipboard"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ReactCopyToClipboard
        text={text}
        onCopy={(e) => {
          handleCopy(e);
        }}
        onError={() => setStatus("error")}
      >
        <div style={copyContainerStyles}>
          <CopyIcon
            onMouseEnter={onMouseHoverEnter}
            onMouseLeave={onMouseHoverLeave}
            style={copyIconStyles}
            color={color}
          />
        </div>
      </ReactCopyToClipboard>
      {status && variant === "highlight" && (
        <p
          className={`s-copy-clipboard__text s-copy-clipboard__text--${textPosition} ${
            status === "success"
              ? "s-copy-clipboard__text--success"
              : "s-copy-clipboard__text--failure"
          }`}
          style={{ color: status === "success" ? "green" : "red" }}
        >
          {status === "success" ? "Copied!" : "Failed to copy!"}
        </p>
      )}
      {variant === "tooltip" && showTooltip && (
        <p className="s-copy-clipboard__tooltip-text">
          {status
            ? status === "success"
              ? "Copied!"
              : "Failed to copy!"
            : tooltipText}
        </p>
      )}
    </div>
  );
};

export default CopyToClipboard;
