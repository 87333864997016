// react imports
import React, { useState } from "react";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "../redux/common/actions";
import endpoints from "../api/endpoints";
import { updateSidebarItems } from "../redux/common/slice";

// third-party imports
import Textarea from "@mui/joy/Textarea";

//in-app imports
import Button from "./Button";
import BlunoRadio from "./BlunoRadio";
import Logo from "../assets/media/onboarding/Bluno Logo_S 1.png";
import { showToast } from "../redux/toast/slice";
import InputErrorMsg from "./InputErrorMsg";
import MuiTextInput from "./blunoInputs/MuiTextInputs";
import { REQUEST_FEATURE } from "../utils/constants/HomePageConstants";
import { validateCompanyName } from "../utils/Validation";
import { InputError } from "../app/constants/InputError";
import { convertErrorMapToJson } from "../utils/errorMapConversionHelper";

const RequestFeature = ({ data, setShowModal }) => {
  const dispatch = useDispatch();

  // local states
  const [submitValues, setSubmitValues] = useState();
  const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});

  // redux state variables
  const sidebarData = useSelector((state) => state.common.sidebarItems);

  const popUpData = {
    icon: Logo,
    text: "Thank you for your valuable feedback! We appreciate ",
    text2: "your insights and will use them to improve.",
  };

  const onHandleSubmit = (submitValues) => {
    const requestFeature = async () => {
      const requestFeatureRes = await dispatch(
        apiRequest(endpoints.requestFeatures, {
          submit_values: submitValues,
        })
      );
      if (requestFeatureRes?.success && requestFeatureRes?.data) {
        setIsRequestSubmitted(true);
        const updatedItems = {
          ...sidebarData,
          navigation: sidebarData?.navigation?.map((mapItem) => {
            if (mapItem?.slug === "request_feature") {
              return {
                ...mapItem,
                is_disabled: true,
              };
            }
            return mapItem;
          }),
        };
        dispatch(updateSidebarItems(updatedItems));
      } else if (requestFeatureRes?.data?.slug_message_map) {
        setErrorMessage(
          convertErrorMapToJson(requestFeatureRes?.data?.slug_message_map)
        );
      } else {
        if (requestFeatureRes?.data?.toast) {
          dispatch(showToast(requestFeatureRes?.data?.toast));
        }

        if (requestFeatureRes?.data?.message) {
          setErrorMessage((prev) => ({
            ...prev,
            empty: requestFeatureRes?.data?.message?.text,
          }));
        }
      }
    };
    requestFeature();
  };

  const onButtonClick = (slug) => {
    if (slug === "finish_later") {
      setShowModal(false);
    } else if (slug === "submit") {
      onHandleSubmit(submitValues);
    }
  };

  const handleInputChange = (theValue, theSlug) => {
    if (
      theSlug === REQUEST_FEATURE?.REQUEST_FEATURES &&
      !validateCompanyName(theValue)
    ) {
      setErrorMessage((prev) => ({
        ...prev,
        [theSlug]: InputError?.InvalidCharacterInput?.message,
      }));
      return;
    }
    setErrorMessage({});
    setSubmitValues((prev) => {
      if (theValue === "") {
        const { [theSlug]: _, ...rest } = prev;
        return rest;
      } else {
        return {
          ...submitValues,
          [theSlug]: theValue,
        };
      }
    });
  };

  const renderUiComponent = (theItem) => {
    switch (theItem?.type) {
      case "text_area":
        return (
          <>
            <div className="o-verify-iec__input">
              <MuiTextInput
                multiline={true}
                height="80px"
                rows="3"
                maxLength={theItem?.limits?.max}
                placeholder={theItem?.placeholder}
                value={submitValues?.[theItem?.slug]}
                onChange={(e) => {
                  handleInputChange(e.target.value, theItem?.slug);
                }}
                errorMessage={errorMessage?.[theItem?.slug]}
                borderColor="#000"
              />
            </div>
          </>
        );
      case "radio":
        return (
          <>
            <BlunoRadio
              options={theItem?.options}
              name={theItem?.name}
              title={theItem?.header}
              value={submitValues?.[theItem?.slug]}
              onChange={(e) => {
                handleInputChange(e.target.value, theItem?.slug);
              }}
              orientationTitle="horizontal"
              extraClassTitle="request-feature__feature-header"
              extraClass="request-feature__feature"
              extraClassRadioGroup="request-feature__feature-options"
              radioGap="16px"
            />
          </>
        );
    }
  };

  return (
    <>
      {!isRequestSubmitted ? (
        <div className="request-feature">
          <h2 className="request-feature__title">{data?.title}</h2>
          {/* <img src={data?.top?.icon} alt="" className="request-feature__icon" /> */}
          <div className="request-feature__top">
            <p className="request-feature__top-header">{data?.top?.header}</p>
            {data?.top?.inputs?.map((item) => {
              return renderUiComponent(item);
            })}
          </div>
          <div className="request-feature__bottom">
            <h2 className="request-feature__bottom-header">
              {data?.bottom?.title}
            </h2>
            <div className="request-feature__features">
              <div className="request-feature__features-emojis">
                {data?.bottom?.heading?.map((item, idx) => {
                  return (
                    <div
                      className="request-feature__emoji"
                      style={{
                        marginRight:
                          idx === 0 ? "-7px" : idx === 1 ? "5px" : "",
                      }}
                    >
                      <img src={item?.icon} alt="" />
                      <p className="request-feature__emoji-text">
                        {item?.text}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="request-feature__features-list">
                {data?.bottom?.feature_list?.map((item) => {
                  return renderUiComponent(item);
                })}
              </div>
            </div>
          </div>
          {errorMessage && (
            <InputErrorMsg
              message={errorMessage?.empty}
              textAlignment="center"
            />
          )}
          {data?.buttons && (
            <div className="request-feature__action">
              {data?.buttons?.map((action) => {
                return (
                  action?.type === "button" && (
                    <Button
                      buttonLabel={action?.text}
                      buttonType={action?.sub_type}
                      icon={action.icon}
                      disabled={action?.disabled}
                      iconAlignment={action?.icon_alignment}
                      buttonSize={action?.size}
                      width={action?.width}
                      onClick={() => onButtonClick(action?.slug)}
                    />
                  )
                );
              })}
            </div>
          )}
          <img
            className="request-feature__close"
            src="https://static.bluno.in/miscl/cross-thin.png"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </div>
      ) : (
        <div className="o-gethelp" style={{ maxWidth: "360px" }}>
          <img
            className="o-gethelp-close"
            src="https://static.bluno.in/miscl/cross-thin.png"
            onClick={() => {
              setShowModal(false);
            }}
          ></img>
          <div
            className="o-gethelp-submitted"
            style={{
              minWidth: "400px",
              gap: "10px",
              padding: "10px 10px 20px 10px",
            }}
          >
            <div>
              <span className="o-gethelp-submitted-title">
                {popUpData?.title}
              </span>
            </div>
            <div
              style={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
                border: "2px solid #00D28A",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={popUpData?.icon}
                className="o-gethelp-submitted__logo"
                style={{ position: "relative", left: "-3px", top: "2px" }}
              />
            </div>
            <p className="o-gethelp-submitted-text">
              <span>{popUpData?.text}</span>
              <span>{popUpData?.text2}</span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default RequestFeature;
