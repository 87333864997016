// react imports
import React, { useEffect, useState } from "react";

// redux imports
import { useDispatch } from "react-redux";
import { apiRequest } from "../redux/common/actions";
import endpoints from "../api/endpoints";

// third party imports

// in-app imports
import BlunoBanner from "../components/blunoBanners/BlunoBanner";
import BlunoKycBanner from "../components/blunoBanners/BlunoKycBanner";
import BlunoPendingDocsBanner from "../components/blunoBanners/BlunoPendingDocsBanner";

const SetupV2 = () => {
  // defined to access react based APIs
  const dispatch = useDispatch();
  const [bannerData, setBannerData] = useState(null);

  const hideNotificationBanner = async () => {
    const dataRes = await dispatch(
      apiRequest(endpoints.hideNotificationBannner, {})
    );
    if (dataRes?.success) {
      setBannerData(null);
    }
  };

  const getHomeData = async () => {
    // Notification Banner
    const bannerRes = await dispatch(
      apiRequest(endpoints.fetchNotificationBanner)
    );
    if (bannerRes?.success && bannerRes?.data) {
      setBannerData(bannerRes?.data);
    }
  };

  useEffect(() => {
    getHomeData();
  }, []);

  return (
    <>
      <div className="home">
        {bannerData?.kyc_pending_doc_banner && (
          <div className="home_banner">
            <BlunoPendingDocsBanner
              data={bannerData?.kyc_pending_doc_banner}
              getHomeData={getHomeData}
            />
          </div>
        )}
        {bannerData?.kyc_status_banner && (
          <div className="home_banner">
            <BlunoKycBanner data={bannerData?.kyc_status_banner} />
          </div>
        )}
        {bannerData?.dgft_link_banner && (
          <div className="home__banner">
            <BlunoBanner
              data={bannerData?.dgft_link_banner}
              hideNotificationBanner={hideNotificationBanner}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SetupV2;
