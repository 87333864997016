// third party imports
import { configureStore } from "@reduxjs/toolkit";

// in-app imports
import homeReducer from "../redux/home/slice";
import commonReducer from "../redux/common/slice";
import toastReducer from "../redux/toast/slice";
import alertReducer from "../redux/alert/slice";
import loaderReducer from "../redux/loader/slice";
import decisionTaskReducer from "../redux/decision_task/slice";
import rbacReducer from "../redux/rbac/slice";

export default configureStore({
  reducer: {
    home: homeReducer,
    common: commonReducer,
    toast: toastReducer,
    alert: alertReducer,
    loader: loaderReducer,
    decisionTask: decisionTaskReducer,
    rbac: rbacReducer,
  },
});
