import React, { useEffect, useRef, useState } from "react";
import OptionDots from "../assets/svg/OptionDots";
import BlunoTooltip from "./BlunoTooltip";

export default function OptionDotComponent({
  modalData,
  onOptionsFunction,
  OptionsComponent,
  positionVertical = "below",
  positionHorizontal = "left",
  hoverBlue,
}) {
  const component = useRef();
  const parent = useRef();

  const [showComponent, setShowComponent] = useState(false);
  const [hover, setHover] = useState(false);

  const checkOutsideClick = (event) => {
    if (component?.current && !component?.current?.contains(event.target)) {
      setShowComponent(false);
    }
  };

  const positionLeft = () => {
    if (!component?.current?.style) return undefined;
    component.current.style.left =
      parent?.current?.getBoundingClientRect()?.x -
      component?.current?.clientWidth +
      30 +
      "px";
  };
  const positionAbove = () => {
    if (!component?.current?.style) return undefined;
    component.current.style.top =
      parent?.current?.getBoundingClientRect()?.y -
      component?.current?.clientHeight +
      10 +
      "px";
  };
  const positionBelow = () => {
    if (!component?.current?.style) return undefined;
    component.current.style.top =
      parent?.current?.getBoundingClientRect()?.y + 10 + "px";
  };

  const changePosition = () => {
    if (positionVertical === "below") positionBelow();
    else positionAbove();
    if (positionHorizontal === "left") positionLeft();
  };
  const positionModal = () => {
    if (positionVertical === "below") positionBelow();
    else positionAbove();
    if (positionHorizontal === "left") positionLeft();
  };

  useEffect(() => {
    document.addEventListener("mousedown", checkOutsideClick);
    return () => {
      document.removeEventListener("mousedown", checkOutsideClick);
    };
  }, []);
  return (
    <>
      <div className="s-table__options">
        <BlunoTooltip
          useChildren={true}
          position="fixed"
          text={"More"}
          left="12px"
        >
          <div
            onClick={() => {
              setShowComponent(true);
            }}
            ref={parent}
            className={`s-table__options-dots ${
              !hoverBlue
                ? "s-table__options-dots--hover-effect"
                : "s-table__options-dots--hover-effect--blue"
            } `}
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
          >
            <OptionDots
              hoverColor={!hoverBlue ? "#000000" : "#046cb8"}
              color={hover ? (!hoverBlue ? "#000000" : "#046cb8") : "#000000"}
            />
          </div>
        </BlunoTooltip>

        <div className="s-table__options-modal" ref={component}>
          {showComponent && (
            <OptionsComponent
              data={modalData}
              onOptionsFunction={onOptionsFunction}
              positionModal={positionModal}
              changePosition={changePosition}
            />
          )}
        </div>
      </div>
    </>
  );
}
