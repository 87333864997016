//react im ports
import React, { useEffect, useState } from "react";

// third-party imports
import OtpInput from "react-otp-input";

//in app imports
import Button from "../../../components/Button";
import InputErrorMsg from "../../../components/InputErrorMsg";
import { InputError } from "../../../app/constants/InputError";
import { LOGIN_SCREEN_SLUGS } from "../../../utils/constants/OnboardingConstants";
import MuiTextInput from "../../../components/blunoInputs/MuiTextInputs";

const VerifyOtp = ({
  data,
  phone = "",
  setPhone,
  onEditPhone,
  otp = "",
  errorMessagePhone,
  setErrorMessagePhone,
  handleInputChange,
  otpInputRefs = null,
  handleKeyDown,
  otpexpired,
  converTimeToString,
  timerValue,
  onResendOtp,
  errorMessageOTP,
  userBlocked,
  onVerifyOtp,
  screenSlug = "",
  isExistingUser = false,
  editFlow = "",
  onPhoneEnter,
}) => {
  const [showOtp, setShowOtp] = useState(true);

  const handleButtonClick = async () => {
    const result = await onPhoneEnter(null, editFlow);
    if (result === true) {
      setShowOtp(true);
    } else {
      setErrorMessagePhone(result?.phone_number?.text);
    }
  };

  const handleMobileNumberKeyDown = (e) => {
    if (e.key === "Enter") {
      handleButtonClick();
    }
  };

  useEffect(() => {
    if (editFlow === "forgot_password") {
      setShowOtp(false);
    }
  }, [editFlow]);

  return (
    <div
      className={`o-login__enter ${
        screenSlug === LOGIN_SCREEN_SLUGS?.otpVerification
          ? "o-login__enter--otp"
          : ""
      }`}
    >
      <div>
        <h2 className="o-login__title">{data?.title}</h2>
      </div>

      {showOtp ? (
        <div className="o-login__notification">
          <p className="o-login__notification-content">
            <span className="o-login__notification-text">
              We have sent a 6-digit OTP to
            </span>
            <span className="o-login__notification-phone">
              {"+91-" + phone}
            </span>
          </p>
          {!isExistingUser && (
            <div>
              <p className="o-login__edit" onClick={onEditPhone}>
                {data?.edit_mobile?.value}
              </p>
            </div>
          )}
        </div>
      ) : (
        // <div className="o-login__notification">
        //   <p className="o-login__notification-content">
        //     <span className="o-login__notification-text">
        //       An OTP would be shared on your 10 digit mobile number to reset
        //       password. Click continue to send OTP.
        //     </span>
        //   </p>
        // </div>
        <></>
      )}

      {showOtp ? (
        <div className="o-login__otp">
          {data?.inputs?.map((item) => {
            return (
              item?.type === "otp_input" && (
                <div className="o-login__otp-item">
                  <OtpInput
                    value={otp}
                    onChange={(value) =>
                      handleInputChange(value, item?.slug, value)
                    }
                    numInputs={6}
                    containerStyle={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    inputStyle={{
                      width: "44px",
                      height: "44px",
                      borderRadius: "8px",
                      backgroundColor: "#EEEEEE",
                      fontFamily: "inherit",
                      fontSize: "16px",
                      fontWeight: "400",
                      textAlign: "center",
                      border: "none",
                    }}
                    focusStyle={{
                      outline: "none",
                      border: "1px solid #046cb8",
                      backgroundColor: "black",
                    }}
                    renderInput={(props, index) => (
                      <input
                        {...props}
                        ref={(el) => (otpInputRefs.current[index] = el)}
                        onKeyUp={(e) => {
                          handleKeyDown(e.key, item?.slug, e);
                        }}
                        autoFocus={index === 0 ? true : false}
                      />
                    )}
                    shouldAutoFocus={true}
                  />
                  <div className="o-login__otp-error-container">
                    <div className="o-login__otp-container">
                      <div className="o-login__otp-container-closer">
                        {
                          <p
                            className={`o-login__countdown--small ${
                              otpexpired && "o-login__countdown--red"
                            }`}
                          >
                            {otpexpired
                              ? InputError?.OtpTimerExpire?.message
                              : "Remaining time:"}
                          </p>
                        }
                        {!otpexpired && (
                          <p className={`o-login__countdown `}>
                            {converTimeToString(timerValue)}
                          </p>
                        )}
                      </div>
                      <p className="o-login__resend" onClick={onResendOtp}>
                        {item?.action?.text}
                      </p>
                    </div>
                    {
                      <InputErrorMsg
                        message={errorMessageOTP}
                        extraClass="o-login__error"
                        textAlignment="center"
                      />
                    }
                  </div>
                </div>
              )
            );
          })}
        </div>
      ) : (
        <>
          <div className="o-login__inputs">
            <div className="o-login__input">
              <div className="o-login__input__container">
                <MuiTextInput
                  placeholder="Mobile number"
                  type="text"
                  value={phone}
                  id="disabled_phone_number"
                  fixedText="+91"
                  paddingLeft="20px"
                  fixedTextLeftStyle={"20px"}
                  // subtext=""
                  onChange={(e) => {
                    handleInputChange(e.target.value, "phone_number", e);
                  }}
                  onKeyDown={(e) => handleMobileNumberKeyDown(e)}
                  // disabled
                  height="40px"
                  minWidth="360px"
                  fontSize="13px"
                  errorMsgPostionType="absolute"
                  errorMessage={errorMessagePhone}
                  errorMsgBottomPosition="-17px"
                />
              </div>
            </div>
          </div>
          <div>
            <p className="o-login__forgot-text">
              An OTP would be shared on your 10 digit mobile number to reset
              password. Click continue to send OTP.
            </p>
          </div>
        </>
      )}
      {showOtp ? (
        <>
          {data?.buttons && (
            <div className="o-login__continue">
              {data?.buttons?.map((action) => {
                return (
                  action?.type === "button" && (
                    <Button
                      buttonLabel={action?.text}
                      buttonType={action?.sub_type}
                      icon={action.icon}
                      disabled={userBlocked}
                      iconAlignment={action?.icon_alignment}
                      extraClass={`o-login__continue-button`}
                      onClick={() => onVerifyOtp(action)}
                      height="40px"
                      fontSize="13px"
                    />
                  )
                );
              })}
            </div>
          )}
        </>
      ) : (
        <>
          <div className="o-login__continue o-login__continue--space">
            <Button
              buttonLabel="Continue"
              buttonType="primary"
              extraClass={`o-login__continue-button`}
              onClick={async () => {
                handleButtonClick();
              }}
              height="40px"
              fontSize="13px"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default VerifyOtp;
