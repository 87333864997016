// react  imports
import { useCallback, useEffect, useState } from "react";
import { useBlocker } from "react-router";
import { useLocation } from "react-router-dom";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { closeAlert, resetAlertData, showAlert } from "../redux/alert/slice";
import { updateSidebarItems } from "../redux/common/slice";

// in-app imports
import { getSelectedSlug, updateSelectedState } from "../utils/sidebarHelper";

const useCallbackPrompt = (shouldBlockNavigation, blockerAlertData) => {
  // defined to access react based apis
  const dispatch = useDispatch();
  const location = useLocation();

  // redux state variables
  const { isAlertOk, alertProps, isAlertCanceled } = useSelector(
    (state) => state.alert
  );
  const sidebarData = useSelector((state) => state.common.sidebarItems);

  // local states variables
  const [blockNavigator, setBlockNavigator] = useState();
  const [showPrompt, setShowPrompt] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isProceed, setIsProceed] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (shouldBlockNavigation) {
      setShowPrompt(true);
    }
    return (
      shouldBlockNavigation &&
      currentLocation.pathname !== nextLocation.pathname
    );
  });

  //1. callback fn if user doesn't proceed
  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
    setIsCanceled(true);
  }, []);

  //2. callback fn if user proceed
  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setIsProceed(true);
  }, []);

  useEffect(() => {
    if (blockNavigator && blockNavigator.state === "blocked" && isProceed) {
      setIsProceed(false);
      blockNavigator.proceed();
    }
  }, [isProceed]);

  useEffect(() => {
    if (blockNavigator && blockNavigator.state === "blocked" && isCanceled) {
      setIsCanceled(false);
      blockNavigator.reset();
    }
  }, [isCanceled]);

  useEffect(() => {
    if (blocker) {
      setBlockNavigator(blocker);
    }
  }, [blocker]);

  useEffect(() => {
        if (showPrompt && blockerAlertData) {
      dispatch(showAlert(blockerAlertData));
    }
  }, [showPrompt, blockerAlertData]);

  useEffect(() => {
    const action = alertProps[0];
    if (isAlertOk && action === "blocker") {
      confirmNavigation();
      dispatch(closeAlert());
      setTimeout(() => {
        dispatch(resetAlertData());
      }, [100]);
    }
    if (isAlertCanceled && action === "blocker") {
      cancelNavigation();
      const selectedSlug = getSelectedSlug(location.pathname);
      const updatedItems = updateSelectedState(
        sidebarData,
        selectedSlug,
        location
      );
      dispatch(updateSidebarItems(updatedItems));
    }
  }, [isAlertOk, isAlertCanceled]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Required for Chrome to show the dialog
    };

    if (shouldBlockNavigation) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [shouldBlockNavigation]);

  return [showPrompt, confirmNavigation, cancelNavigation];
};

export default useCallbackPrompt;
