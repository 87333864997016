// react imports
import React, { useEffect, useRef, useState } from "react";

const useDebouncing = (value, delay) => {
  const [debounceValue, setDebounceValue] = useState();
  const initiate = useRef(true);
  useEffect(() => {
    if (initiate.current) {
      initiate.current = false;
    } else {
      const delayHandler = setTimeout(() => {
        setDebounceValue(value);
      }, delay);

      return () => {
        clearTimeout(delayHandler);
      };
    }
  }, [value, delay]);

  return debounceValue;
};

export default useDebouncing;
