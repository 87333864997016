export const loaderConfig = {
  fetchIRM: {
    type: "primary",
    timeoutLimit: 0,
    message: "",
    delayedDataLoader: {
      delayDataTime: 3000,
      messageList: ["Securely mapping your Inward Remittance, Please wait..."],
    },
  },
  submitToBank: {
    type: "primary",
    timeoutLimit: 0,
    message: "",
    delayedDataLoader: {
      delayDataTime: 3000,
      messageList: ["Securely submitting to the bank"],
    },
  },
};
