import React from "react";
import { sanitizeHtml } from "../../utils/sanitizeHtml";

export default function RejectSummary({ data }) {
  return (
    <div className="s-reject">
      <h2
        className="s-reject__title"
        dangerouslySetInnerHTML={sanitizeHtml(data?.title)}
      ></h2>
      <div className="s-reject__break"></div>
      <div className="s-reject__body">
        {data?.fields?.map((item, idx) => {
          return (
            <p
              key={idx}
              style={{ gridRow: item?.grid_row, gridColumn: item?.grid_column }}
              className={`s-reject__item ${
                item?.sub_type === "label" ? "s-reject__item--label" : ""
              }`}
            >
              {item?.text}
              {item?.sub_type === "label" && <span>&nbsp;:&nbsp;</span>}
            </p>
          );
        })}
      </div>
    </div>
  );
}
