//react imports
import React, { useRef, useState } from "react";

//in app party imports
import UploadFileCard from "../../components/UploadFile/UploadFile";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/toast/slice";
import { DOCUMENT_ACTION } from "../../utils/constants/DocumentConstants";
import { downloadDocumentUtility } from "../../utils/downloadDocument";

const NewUploadCard = (props) => {
  const {
    upload_enabled = false,
    uploaded_documents = [],
    slug = "",
    setApiData = () => {},
    setInputValues = () => {},
    flow = "setup",
    secondaryText,
    padding,
    backgroundColor,
    showPreviousFiles = false,
  } = props;

  const [docId, setDocId] = useState();
  const [documents, setDocuments] = useState(uploaded_documents);
  const previousDocuments = useRef([]);
  const [isErrorOccurred, setIsErrorOccurred] = useState(false);
  const [error, setError] = useState("");
  const [errorFileNames, setErrorFileNames] = useState([]);
  // const [sessionFileList, setSessionFileList] = useState([]);
  const sessionFileList = [
    {
      icon: "https://static.bluno.in/miscl/file_icon.png",
      id: "b61f6923-708d-4c82-b98e-354e2ad2e4ff",
      name: "SB6.pdf",
      size: null,
      actions: [
        {
          alert_id: null,
          action: "view",
          icon: "https://static.bluno.in/miscl/eye_black.png",
        },
        {
          alert_id: null,
          action: "download",
          icon: "https://static.bluno.in/miscl/download_black.png",
        },
      ],
    },
    {
      icon: "https://static.bluno.in/miscl/file_icon.png",
      id: "b61f6923-708d-4c82-b98e-354e2ad2e4ff",
      name: "SB6.pdf",
      size: null,
      actions: [
        {
          alert_id: null,
          action: "view",
          icon: "https://static.bluno.in/miscl/eye_black.png",
        },
        {
          alert_id: null,
          action: "download",
          icon: "https://static.bluno.in/miscl/download_black.png",
        },
      ],
    },
    {
      icon: "https://static.bluno.in/miscl/file_icon.png",
      id: "b61f6923-708d-4c82-b98e-354e2ad2e4ff",
      name: "SB6.pdf",
      size: null,
      actions: [
        {
          alert_id: null,
          action: "view",
          icon: "https://static.bluno.in/miscl/eye_black.png",
        },
        {
          alert_id: null,
          action: "download",
          icon: "https://static.bluno.in/miscl/download_black.png",
        },
      ],
    },
  ];
  const dispatch = useDispatch();

  const handleInputChange = (theDocId, theSlug) => {
    const newValue = theDocId;
    setInputValues((prevInputs) =>
      prevInputs.map((input) => {
        if (input.name === theSlug) {
          return { ...input, value: newValue };
        }
        return input;
      })
    );
  };

  const onDocAction = (section, actionBtn, documentId, fileName) => {
    const docAction = async (section, actionBtn, documentId, fileName) => {
      const dataRes = await dispatch(
        apiRequest(endpoints.docAction, {
          section: section,
          action: actionBtn?.action,
          document_id: documentId,
        })
      );

      if (dataRes?.success && dataRes?.data) {
        if (dataRes?.data?.document_link) {
          if (actionBtn?.action === DOCUMENT_ACTION?.["VIEW"]) {
            window.open(dataRes?.data?.document_link, "_blank");
          } else if (actionBtn?.action === DOCUMENT_ACTION?.["DOWNLOAD"]) {
            downloadDocumentUtility(dataRes?.data?.document_link, fileName);
          }
        }
      }
    };

    docAction(section, actionBtn, documentId, fileName);
  };

  const uploadSingleFile = (selectedFiles, doc_type, invoiceId, files) => {
    const formData = new FormData();
    const metadata = {
      flow: flow,
    };
    formData.append("file", selectedFiles[0]);
    formData.append("doc_type", doc_type);
    formData.append("metadata", JSON.stringify(metadata));
    previousDocuments.current = documents;
    const uploadApiCall = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.uploadFile, formData, null, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      );
      if (dataRes?.success && dataRes?.data) {
        let newDocuments =
          dataRes?.data?.uploaded_document_info[0]?.uploaded_documents;
        if (showPreviousFiles)
          newDocuments = [...previousDocuments.current, ...newDocuments];
        setDocuments(newDocuments);
        // setSessionFileList((prev) => [...prev, ...newDocuments]);

        setDocId(dataRes?.data?.document_id);
        handleInputChange(dataRes?.data?.document_id, "document_id");
        slug === "SHIPPING_BILL" && setApiData(dataRes?.data?.summary_data);
        setIsErrorOccurred(false);
      } else {
        // dispatch(showToast(dataRes?.data?.toast));
        setError(dataRes?.data?.toast?.data?.center?.text);
        setDocuments(previousDocuments.current);
        setErrorFileNames((prev) => [...prev, selectedFiles[0]?.name]);
        setIsErrorOccurred(true);
      }
    };
    uploadApiCall();
  };

  return (
    <div>
      <UploadFileCard
        upload_enabled={upload_enabled}
        documents={documents}
        doc_type={slug}
        uploadSingleFile={uploadSingleFile}
        docId={docId}
        onDocAction={onDocAction}
        isErrorOccurred={isErrorOccurred}
        setIsErrorOccurred={setIsErrorOccurred}
        setError={setError}
        sessionFileList={sessionFileList}
        screen="sb_dashboard"
        secondaryText={secondaryText}
        padding={padding}
        backgroundColor={backgroundColor}
        showPreviousFiles={showPreviousFiles}
        errorFileNames={errorFileNames}
        setErrorFileNames={setErrorFileNames}
      />
      {isErrorOccurred ? (
        <p className="s-upload-card__error">{error}</p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NewUploadCard;
