// react imports
import React from "react";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  okAlert,
  closeAlert,
  resetAlertData,
  executeButtonFunction,
} from "../redux/alert/slice";

// third-party imports
import { Snackbar } from "@mui/material";

// in-app imports
import Button from "./Button";
// import cross from "../assets/media/Toast/Icon-multiply.png";
import circularCross from "./../assets/media/cross-circular.png";
import {
  ALERT_POPUP_ACTIONS,
  ALERT_POPUP_BUTTON_TYPE,
} from "../utils/constants/PopupConstants";

const AlertPopup = () => {
  // defined to access react based APIs
  const dispatch = useDispatch();

  // redux state variables
  const alertData = useSelector((state) => state.alert.alertItems);
  const isAlertShow = useSelector((state) => state.alert.isAlertShow);
  const cross = "https://static.bluno.in/miscl/cross-thin.png";

  const onAlertButtonClick = (action) => {
    if (action === ALERT_POPUP_ACTIONS?.["PROCEED"]) {
      dispatch(okAlert());
    } else {
      onAlertClose();
    }
  };
  const onAlertClose = () => {
    dispatch(closeAlert({ isAlertCanceled: true }));
    setTimeout(() => {
      dispatch(resetAlertData());
    }, [100]);
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={isAlertShow}
        onClose={onAlertClose}
        TransitionProps={{
          appear: false,
        }}
      >
        {alertData && (
          <div
            className={`s-alert-popup--${alertData?.position} s-alert-popup__parent `}
          >
            <div className={`s-alert-popup `} onClick={() => {}}>
              <div
                className={`s-alert-popup__header s-alert-popup--${alertData?.type}`}
              >
                {alertData?.icon && (
                  <img src={alertData?.icon} alt="status-icon" />
                )}
                <h2 className="s-alert-popup__title">{alertData?.title}</h2>
              </div>
              <div className="s-alert-popup__content">
                <p className="s-alert-popup__text">{alertData?.text}</p>
              </div>
              {alertData?.buttons && (
                <div className="s-alert-popup__action">
                  {alertData?.buttons?.map((action) => {
                    return (
                      action?.type === ALERT_POPUP_BUTTON_TYPE?.["BUTTON"] && (
                        <Button
                          buttonLabel={action?.text}
                          buttonType={action?.sub_type}
                          icon={action.icon}
                          disabled={action?.disabled}
                          iconAlignment={action?.icon_alignment}
                          buttonSize={action?.size ?? "sm2"}
                          width={action?.width}
                          onClick={() => onAlertButtonClick(action?.slug)}
                        />
                      )
                    );
                  })}
                </div>
              )}
              <div className="s-alert-popup__cancel">
                <img src={cross} alt="" onClick={() => onAlertClose()} />
              </div>
            </div>
          </div>
        )}
      </Snackbar>
    </>
  );
};

export default AlertPopup;
