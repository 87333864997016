import React, { useEffect, useRef, useState } from "react";
import { sanitizeHtml } from "../utils/sanitizeHtml";
import Button from "./Button";
import BlunoTooltip from "./BlunoTooltip";

const Task = ({
  id,
  icon,
  text,
  modalIcon,
  buttons,
  duration,
  modalData,
  deleteTask,
  flow,
  isTaskBorder = true,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef();

  const onActionClick = (e, action) => {
    switch (action) {
      case "cancel":
        e.stopPropagation();
        setIsModalOpen(false);
        break;
      case "delete":
        e.stopPropagation();
        deleteTask([id]);
        setIsModalOpen(false);
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef?.current && !modalRef?.current?.contains(event.target)) {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <>
      <div
        className={`s-task ${isModalOpen ? "s-task--modal-open" : ""} ${
          isTaskBorder ? "s-task--border" : ""
        }`}
      >
        {icon && (
          <div className="s-task__left">
            <img src={icon} alt="" />
          </div>
        )}
        <div className="s-task__center">
          <p
            className="s-task__text"
            dangerouslySetInnerHTML={sanitizeHtml(text)}
          />
          {buttons && (
            <div className="s-task__button">
              {buttons?.map((action) => {
                return (
                  action?.type === "button" && (
                    <Button
                      buttonLabel={action?.text}
                      buttonType={action?.sub_type}
                      icon={action.icon}
                      disabled={action?.disabled}
                      iconAlignment={action?.icon_alignment}
                      width="80px"
                      height="24px"
                      variant="task"
                      onClick={() =>
                        action?.deeplink &&
                        window.location.replace(action?.deeplink)
                      }
                    />
                  )
                );
              })}
            </div>
          )}
        </div>
        <div className="s-task__right">
          <BlunoTooltip
            text="More"
            useChildren={true}
            position="fixed"
            left={`${flow === "notification" ? "-1023px" : "5px"}`}
            top={`${flow === "notification" ? "-85px" : "0px"}`}
          >
            <div className="s-task__right-more-bg">
              <img
                className="s-task__right-more"
                src={modalIcon}
                alt=""
                onClick={() => {
                  setIsModalOpen(true);
                }}
              />
            </div>
          </BlunoTooltip>
          <p className="s-task__duration">{duration}</p>
        </div>
        {isModalOpen && (
          <div className="s-task__modal-bg" ref={modalRef}>
            <div className="s-task__modal">
              {modalData?.buttons && (
                <div className="s-task__modal-buttons">
                  {modalData?.buttons?.map((action) => {
                    return (
                      action?.type === "button" && (
                        <Button
                          buttonLabel={action?.text}
                          buttonType={action?.sub_type}
                          icon={action.icon}
                          disabled={action?.disabled}
                          iconAlignment={action?.icon_alignment}
                          onClick={(e) => onActionClick(e, action)}
                          iconExtraClass={`${
                            action?.slug === "close"
                              ? "s-task__modal-buttons--close"
                              : ""
                          }`}
                        />
                      )
                    );
                  })}
                </div>
              )}
              {modalData?.icon_list && (
                <div className="s-task__modal-icons">
                  {modalData?.icon_list?.map((item) => {
                    return (
                      <BlunoTooltip
                        text={item?.hover_text}
                        useChildren={true}
                        position={item?.hover_text_position}
                        top={
                          item?.hover_text_position === "fixed"
                            ? "60px"
                            : "100%"
                        }
                        left={
                          item?.hover_text_position === "fixed" ? "10px" : "50%"
                        }
                        textColor="#000"
                        textBgColor="transparent"
                      >
                        <div
                          className="s-task__modal-icon-bg"
                          onClick={(e) => onActionClick(e, item?.slug)}
                        >
                          <img
                            src={item?.icon}
                            alt=""
                            className={`s-task__modal-icon ${
                              item?.slug === "cancel"
                                ? "s-task__modal-icon--cancel"
                                : ""
                            }`}
                          />
                        </div>
                      </BlunoTooltip>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Task;
