//react imports
import React, { act, useEffect, useRef, useState } from "react";

//redux imports
import { apiRequest } from "../../redux/common/actions";
import { useDispatch } from "react-redux";
import endpoints from "../../api/endpoints";

//in app imports
import InputWithOtp from "../../components/InputWithOtp";
import { InputError } from "../../app/constants/InputError";
import { validateEMAIL, validateNumber } from "../../utils/Validation";
import { showToast } from "../../redux/toast/slice";
import {
  GENERAL_SETTINGS_SLUGS,
  IDENTITY_SLUGS,
  IDENTITY_TYPE,
  SETTING_STATE,
} from "../../utils/constants/SettingsConstants";
import { getBlockerAlertBySection } from "../../app/constants/BlockerAlert";

const EditIdentity = ({
  data,
  identityType = "PHONE",
  editingPhone,
  editingEmail,
  setShowPopup,
  setPopupData,
  setIsBlockerActive,
  onUpdateBlockerAlertData,
  handleButtonClick,
  setShowEditIdentityModal,
}) => {
  // defined to access react based apis
  const dispatch = useDispatch();

  // local states
  const [editData, setEditData] = useState(null);

  const [inputValue, setInputValue] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isOtpDisbaled, setIsOtpDisabled] = useState(true);
  const [actionButtons, setActionButtons] = useState(
    data?.edit_identity_flow_data?.buttons1
  );
  const [actionTitle, setActionTitle] = useState(
    data?.edit_identity_flow_data?.title1
  );

  const [verificationId, setVerificationId] = useState(null);
  const [batchVerificationId, setBatchVerificationId] = useState(null);
  const [timerValue, setTimerValue] = useState(0);
  const [otpexpired, setOTPExpired] = useState(false);
  const [userBlocked, setUserBlocked] = useState(false);
  const [editFlow, setEditFlow] = useState(null);
  const [editFlowId, setEditFlowId] = useState(null);
  const [nextFlow, setNextFlow] = useState(null);

  // error message states
  const [inputErrorMsg, setInputErrorMsg] = useState("");
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const timer = useRef();

  useEffect(() => {
    clearTimeout(timer.current);
    timerValue > 0 &&
      (timer.current = setTimeout(() => {
        if (timerValue === 1) {
          setOTPExpired(true);
          setUserBlocked(true);
        }
        setTimerValue((prev) => Math.max(prev - 1, 0));
      }, 1000));
  }, [timerValue]);

  const onPopupClose = () => {
    handleButtonClick(GENERAL_SETTINGS_SLUGS?.SAVE_EDIT_IDENTITY_POPUP);
  };

  const validateInput = () => {
    if (identityType === IDENTITY_TYPE?.PHONE) {
      if (
        inputValue?.length === 0 ||
        inputValue?.length !== 10 ||
        !validateNumber(inputValue)
      ) {
        setInputErrorMsg(InputError?.IncorrectMobileNumber?.message);
        return false;
      } else {
        return true;
      }
    } else if (identityType === IDENTITY_TYPE?.EMAIL) {
      if (inputValue?.length === 0) {
        setInputErrorMsg(InputError?.EmptyEmail?.message);
        return false;
      } else if (!validateEMAIL(inputValue)) {
        setInputErrorMsg(InputError?.InvalidEmailFormat?.message);
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const intitateIdentityUpdate = async () => {
    if (!validateInput()) {
      return;
    }
    let identityValue = {};

    if (identityType === IDENTITY_TYPE?.PHONE) {
      identityValue = {
        country_code: "+91",
        phone_no: inputValue,
      };
    } else if (identityType === IDENTITY_TYPE?.EMAIL) {
      identityValue = {
        email_id: inputValue,
      };
    }

    const requestBody = {
      identity_type: identityType,
      identity_value: identityValue,
    };

    const apiRes = await dispatch(
      apiRequest(endpoints.updateIdentity, requestBody)
    );

    if (apiRes?.success && apiRes?.data) {
      setVerificationId(apiRes?.data?.request_id);
      setBatchVerificationId(apiRes?.data?.batch_request_id);
      setEditFlow(apiRes?.data?.edit_flow);
      setEditFlowId(apiRes?.data?.edit_flow_id);
      setTimerValue(apiRes?.data?.duration_in_seconds);
      setIsOtpDisabled(false);
      setOtpErrorMsg("");
      if (identityType === "PHONE") {
        editingPhone.current = true;
      } else if (identityType === "EMAIL") {
        editingEmail.current = true;
      }
      setIsBlockerActive(true);
      onUpdateBlockerAlertData(
        getBlockerAlertBySection(
          identityType === "PHONE" ? "phone verification" : "email verification"
        )
      );
      setActionTitle(data?.edit_identity_flow_data?.title2);
      setActionButtons(data?.edit_identity_flow_data?.buttons2);
    } else if (apiRes?.data?.toast) {
      dispatch(showToast(apiRes?.data?.toast));
    }
  };

  const initiateNewIdentityVerification = async () => {
    if (!validateInput()) {
      return;
    }

    if (nextFlow === IDENTITY_SLUGS?.VERIFY_NEW) {
      let identityValue = {};

      if (identityType === IDENTITY_TYPE?.PHONE) {
        identityValue = {
          country_code: "+91",
          phone_no: inputValue,
        };
      } else {
        identityValue = {
          email_id: inputValue,
        };
      }

      const requestBody = {
        identity_type: identityType,
        identity_value: identityValue,
        edit_flow: editFlow,
        edit_flow_id: editFlowId,
      };

      const apiRes = await dispatch(
        apiRequest(endpoints.insertIdentity, requestBody)
      );

      if (apiRes?.success && apiRes?.data) {
        setVerificationId(apiRes?.data?.request_id);
        setBatchVerificationId(apiRes?.data?.batch_request_id);
        setEditFlow(apiRes?.data?.edit_flow);
        setEditFlowId(apiRes?.data?.edit_flow_id);
        setTimerValue(apiRes?.data?.duration_in_seconds);
        setIsOtpDisabled(false);
        setIsOtpDisabled(false);
        setOtpErrorMsg("");
        setActionTitle(data?.edit_identity_flow_data?.title4);
        setActionButtons(data?.edit_identity_flow_data?.buttons4);
      } else if (apiRes?.data?.toast) {
        dispatch(showToast(apiRes?.data?.toast));
      }
    }
  };

  const verifyIdentity = async (slug) => {
    const requestBody = {
      verification_id: verificationId,
      batch_verification_id: batchVerificationId,
      edit_flow: editFlow,
      edit_flow_id: editFlowId,
      otp: otpValue,
    };

    const apiRes = await dispatch(
      apiRequest(endpoints.verifyIdentity, requestBody, `?type=${identityType}`)
    );

    if (apiRes?.success && apiRes?.data) {
      if (
        apiRes?.data?.verification_response?.verification_status ===
        "verification_expired"
      ) {
        setOtpErrorMsg(InputError?.OtpTimerExpire?.message);
        setOtpValue("");
      } else if (
        apiRes?.data?.verification_response?.verification_status ===
        "verification_failed"
      ) {
        setOtpErrorMsg(InputError?.IncorrectOTP?.message);
        setOtpValue("");
      } else {
        if (slug === IDENTITY_SLUGS?.VERIFY_NEW_IDENTITY) {
          //close popup and get call back to parent
          handleButtonClick(GENERAL_SETTINGS_SLUGS?.SAVE_EDIT_IDENTITY_POPUP);
        }
        setEditData(apiRes?.data);
        if (slug === IDENTITY_SLUGS?.VERIY_OLD_IDENTITY) {
          setActionTitle(data?.edit_identity_flow_data?.title3);
          setActionButtons(data?.edit_identity_flow_data?.buttons3);
        }
        setNextFlow(apiRes?.data?.verification_response?.next_flow);
        setIsInputDisabled(apiRes?.data?.text_box?.disabled);
        setIsOtpDisabled(true);
        setOtpValue("");
      }
    } else {
      if (apiRes?.data?.toast) {
        dispatch(showToast(apiRes?.data?.toast));
        setOtpValue("");
      }
    }
  };

  const handleResendOtp = async () => {
    const requestBody = {
      request_id: verificationId,
      batch_request_id: batchVerificationId,
      edit_flow: editFlow,
      edit_flow_id: editFlowId,
      otp: otpValue,
    };

    const apiRes = await dispatch(
      apiRequest(endpoints.resendOtpToVerify, requestBody)
    );

    if (apiRes?.success && apiRes?.data) {
      if (apiRes?.data?.attempts_remaining === 0) {
        setTimerValue(0);
        setOtpErrorMsg(InputError?.TooManyAttemptsOTP?.message);
        setUserBlocked(true);
        return;
      }
      setVerificationId(apiRes?.data?.request_id);
      setBatchVerificationId(apiRes?.data?.batch_request_id);
      setUserBlocked(false);
      setOTPExpired(false);
      clearTimeout(timer.current);
      setTimerValue(apiRes?.data?.duration_in_seconds);
      setOtpErrorMsg(
        `You have ${apiRes?.data?.attempts_remaining} attempts remaining`
      );
    }
  };

  const onActionButtonClick = (theSlug) => {
    switch (theSlug) {
      case IDENTITY_SLUGS?.EDIT_OLD_IDENTITY:
        intitateIdentityUpdate();
        break;
      case IDENTITY_SLUGS?.VERIY_OLD_IDENTITY:
        verifyIdentity(theSlug);
        break;
      case IDENTITY_SLUGS?.UPDATE_NEW_IDENTITY:
        initiateNewIdentityVerification();
        break;
      case IDENTITY_SLUGS?.VERIFY_NEW_IDENTITY:
        verifyIdentity(theSlug);
        break;
      case IDENTITY_SLUGS?.RESEND_OTP:
        handleResendOtp();
        break;
      case SETTING_STATE?.EDIT_STATE:
        handleButtonClick(theSlug);
        setIsBlockerActive(false);
        break;
      default:
        return;
    }
  };

  const onInputChange = (theValue) => {
    if (identityType === IDENTITY_TYPE?.PHONE && theValue.length > 10) {
      setInputErrorMsg(InputError?.FullMobileNumber?.message);
    } else {
      setInputValue(theValue);
      setInputErrorMsg("");
    }
  };

  useEffect(() => {
    if (!editingPhone.current && identityType === IDENTITY_TYPE?.PHONE) {
      setEditData(data);
      setInputValue(data?.text_box?.default_value);
      setIsInputDisabled(data?.text_box?.disabled);
      setIsOtpDisabled(true);
    }
    if (!editingEmail.current && identityType === IDENTITY_TYPE?.EMAIL) {
      setEditData(data);
      setInputValue(data?.text_box?.default_value);
      setIsInputDisabled(data?.text_box?.disabled);
      setIsOtpDisabled(true);
    }
  }, [editingEmail.current, editingPhone.current]);

  useEffect(() => {
    setEditData(data);
    setInputValue(data?.text_box?.default_value);
    setIsInputDisabled(data?.text_box?.disabled);
  }, []);

  return (
    <>
      <div className="g-settings__identity-popup">
        <div className="g-settings__identity-popup-header">
          <h3 className="g-settings__identity-popup-title">{actionTitle}</h3>
          <img
            className="g-settings__identity-popup-close"
            src={data?.close_icon}
            onClick={onPopupClose}
          />
        </div>
        {/* <div className="g-settings__identity-popup-inputs">
          {data?.inputs?.map((item, idx) => {
            return getPopupInputElement(item);
          })}
        </div> */}
        {/* <p className="g-settings__fields-label">{editData?.text_box?.title}</p> */}
        <div className="g-settings__identity-popup-inputs">
          <InputWithOtp
            data={editData}
            actionButtons={actionButtons}
            onActionButtonClick={onActionButtonClick}
            onInputChange={onInputChange}
            inputValue={inputValue}
            otp={otpValue}
            setOtp={setOtpValue}
            isInputDisabled={isInputDisabled}
            setIsInputDisabled={setIsInputDisabled}
            isOtpDisbaled={isOtpDisbaled}
            otpErrorMsg={otpErrorMsg}
            inputErrorMsg={inputErrorMsg}
            timerValue={timerValue}
            otpexpired={otpexpired}
          />
        </div>
      </div>
    </>
  );
};

export default EditIdentity;
