import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import { updateShowBrForm } from "../../redux/common/slice";

// third-party imports
import { Checkbox } from "@mui/material";

// in-app imports
import BlunoTableV2 from "../../components/BlunoTableV2/BlunoTableV2";
import ModalV2 from "../../components/ModalV2";
import PostBulkInfo from "../../components/buyersPage/PostBulkInfo";
import Button from "../../components/Button";

// utils
import { checkIfAllValuesFalse } from "../../utils";
import BlunoTooltip from "../../components/BlunoTooltip";
import { sanitizeHtml } from "../../utils/sanitizeHtml";

const AllBulkEbrc = () => {
  // defined to access react based apis
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // local states
  const [bulkData, setBulkData] = useState(null);
  const [headerData, setHeaderData] = useState(null);
  const [buttonsData, setButtonsData] = useState([]);
  const [declaration, setDeclaration] = useState(null);
  const [declarationValue, setDeclarationValue] = useState("no");
  const [activeId, setActiveId] = useState(null);
  const [expandedData, setExpandedData] = useState(null);
  const [rowSelectionState, setRowSelectionState] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [postBulkData, setpostBulkData] = useState();
  const [showDocModal, setShowDocModal] = useState(false);

  // modal states variables
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({});

  const isShowLoader = useSelector((state) => state.loader.isShowLoader);

  // other variables
  const configData = {
    headers: bulkData?.headers,
    data: bulkData?.data,
    expanded_data: expandedData,
    selection_supported: bulkData?.selection_supported,
    select_all_supported: bulkData?.select_all_supported,
    default_view: bulkData?.default_view,
  };

  const closeModal = () => {
    setShowModal(false);
    setModalInfo({});
  };

  const getTableData = async () => {
    const dataRes = await dispatch(apiRequest(endpoints.getAllBulkEbrcData));
    if (dataRes?.success && dataRes?.data) {
      setHeaderData(dataRes?.data?.header);
      setBulkData(dataRes?.data?.bulk_recon_data);
      setButtonsData(dataRes?.data?.buttons);
      setDeclaration(dataRes?.data?.declaration);
    }
  };

  const changeDeclaration = () => {
    if (declarationValue === "yes") {
      setDeclarationValue("no");
    } else {
      setDeclarationValue("yes");
    }
  };

  const onHoverClick = async (theId) => {
    if (activeId && activeId === theId) {
      setActiveId(null);
    } else {
      const dataRes = await dispatch(
        apiRequest(endpoints.getAllBulkEbrcBreakup, {
          parent_bulk_recon_id: bulkData?.parent_bulk_recon_id,
          single_bulk_group_id: theId,
        })
      );
      if (dataRes?.success && dataRes?.data) {
        setExpandedData(dataRes?.data);
        setActiveId(theId);
      }
    }
  };

  const onTableRowSelection = (rowId) => {
    setRowSelectionState((prev) => ({
      ...prev,
      [rowId]: prev[rowId] ? undefined : true,
    }));
  };

  const onAllTableRowSelection = (prevState, allRow) => {
    let allCheckedState = allRow?.reduce((acc, obj) => {
      acc[obj?.id] = true;
      return acc;
    }, {});
    if (prevState) {
      if (checkIfAllValuesFalse(prevState)) {
        setRowSelectionState(allCheckedState);
      } else {
        setRowSelectionState({});
      }
    } else {
      setRowSelectionState(allCheckedState);
    }
  };

  const generateBulkEbrc = async () => {
    const reqBody = {
      parent_bulk_recon_id: bulkData?.parent_bulk_recon_id,
      single_bulk_group_ids: selectedRows,
      declaration_input: {
        element_id: declaration?.element_id,
        value: declarationValue,
      },
    };
    const dataRes = await dispatch(
      apiRequest(endpoints.generateBulkEbrc, reqBody)
    );
    if (dataRes?.success && dataRes?.data) {
      if (dataRes?.data?.is_kyc_pending) {
        // if (!isShowLoader) {
        //   dispatch(updateShowBrForm(true));
        // }
        setShowDocModal(true);
      } else {
        setpostBulkData(dataRes?.data);
        setShowModal(true);
      }
    }
  };

  const handleButtonClick = async (theSlug, theDeeplink) => {
    if (theSlug === "generateAllBulkEbrc") {
      generateBulkEbrc(theSlug);
    } else {
      navigate(theDeeplink);
    }
  };

  const checkButtonDisabled = (theSlug) => {
    if (theSlug === "generateAllBulkEbrc") {
      if (declarationValue === "yes" && selectedRows?.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const redirectTo = (e, theLink) => {
    e.stopPropagation();
    navigate(theLink);
  };

  useEffect(() => {
    const selectedData = bulkData?.data
      ?.filter((row) => rowSelectionState?.[row?.id])
      .map((item) => item?.id);
    setSelectedRows(selectedData ? selectedData : []);
  }, [rowSelectionState]);

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    if (!isShowLoader && showDocModal) {
      dispatch(updateShowBrForm(true));
    }
  }, [isShowLoader]);

  return (
    <div className="a-buyers">
      <div className="a-buyers__top">
        <div className="a-buyers__top-left">
          {headerData?.back_info && (
            <BlunoTooltip
              icon={headerData?.back_info?.icon}
              text={location?.state?.text ?? headerData?.back_info?.hover_text}
              iconWidth="16px"
              iconHeight="16px"
              left="28px"
              useChildren={true}
            >
              <div
                href={headerData?.back_info?.deeplink}
                onClick={(e) => {
                  if (location?.state?.link)
                    redirectTo(e, location?.state?.link);
                  else redirectTo(e, headerData?.back_info?.deeplink);
                }}
                className="s-sbHeader__back s-sbHeader__back-background"
              >
                <img
                  src={headerData?.back_info?.icon}
                  className="s-sbHeader__back-img"
                />
              </div>
            </BlunoTooltip>
          )}

          <h2 className="a-buyers__top-heading">{headerData?.title}</h2>
        </div>
        <div className="a-buyers__top-right">
          {headerData?.buttons?.map((tab) => {
            return (
              <Button
                buttonLabel={tab?.text}
                buttonType={tab?.type}
                icon={tab?.icon}
              />
            );
          })}
        </div>
      </div>
      <div className="a-buyers__ebrc-cont">
        <div
          className={`b-ebrc__table ${
            configData?.default_view ? "b-ebrc__table--short" : ""
          }`}
        >
          <BlunoTableV2
            data={configData}
            activeId={activeId}
            setActiveId={setActiveId}
            onHoverClick={onHoverClick}
            rowSelectionState={rowSelectionState}
            onTableRowSelection={onTableRowSelection}
            onAllTableRowSelection={onAllTableRowSelection}
          />
        </div>
        {declaration && (
          <div className="b-ebrc__declaration">
            <Checkbox
              size="small"
              checked={declarationValue === "yes" ? true : false}
              inputProps={{ "aria-label": "controlled" }}
              id={declaration?.element_id}
              sx={{
                color: "black",
                "&.Mui-checked": {
                  color: "black",
                },
                marginLeft: "-17px",
              }}
              onChange={() => changeDeclaration()}
              disabled={declaration?.disabled}
            />
            <p>
              <label
                style={{
                  cursor: `${!declaration?.disabled ? "pointer" : "default"}`,
                  opacity: `${!declaration?.disabled ? "1" : "0.6"}`,
                }}
                htmlFor={declaration?.element_id}
                dangerouslySetInnerHTML={sanitizeHtml(declaration?.title, [
                  "target",
                ])}
              />
            </p>
          </div>
        )}
        <div className="b-ebrc__buttons">
          {buttonsData?.map((item) => {
            return (
              <Button
                buttonLabel={item?.text}
                buttonType={item?.sub_type}
                onClick={() => {
                  handleButtonClick(item?.slug, item?.deeplink);
                }}
                disabled={checkButtonDisabled(item?.slug)}
              />
            );
          })}
        </div>
      </div>
      {showModal && !isShowLoader && (
        <ModalV2 slug="standard">
          <PostBulkInfo data={postBulkData} closeModal={closeModal} />
        </ModalV2>
      )}
      {showModal && !isShowLoader && (
        <div className="a-buyers__modal-bg" onClick={closeModal}></div>
      )}
    </div>
  );
};

export default AllBulkEbrc;
