//react-imports
import React, { useEffect, useRef, useState } from "react";

//third-party-imports
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

//in-app-imports
import Button from "./Button";
import {
  formatDate,
  formatDateToDDMMYYString,
  formatDateToDDMMYYYYString,
} from "../utils/formatDate";

import calenderActive from "../assets/media/exportBills/calender-active.png";
import TextInput from "./TextInput";
import { subMonths } from "date-fns";

const BlunoCalender = (props) => {
  const {
    standardDurations = [],
    placeholder = "",
    defaultSelection = {},
    isHighlighted = false,
    isDateCleared = false,
    icon = "",
    height = "30px",
    minWidth = "245px",
    onDateRangeChange,
    fancyPlaceholder = false,
    isModalOpen = false,
  } = props;

  const modalRef = useRef(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [customRangeText, setCustomRangeText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [internalStartDate, setInternalStartDate] = useState("");
  const [internalEndDate, setInternalEndDate] = useState("");
  const [internalDateDisplay, setInternalDateDisplay] = useState("");
  const [externalDateDisplay, setExternalDateDisplay] = useState("");
  const [inputWidth, setInputWidth] = useState(minWidth);
  const [useMonth, setUseMonth] = useState(subMonths(new Date(), 1));

  const inputStyles = {
    height: height,
    minWidth: inputWidth,
  };

  const modifiers = {
    startOfRow: (date) => {
      const dayOfWeek = date.getDay();
      const dayOfMonth = date.getDate();
      return dayOfWeek === 0 || dayOfMonth === 1; // Sunday (start of the row)
    },
    endOfRow: (date) => {
      const dayOfWeek = date.getDay();
      const nextDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1
      );
      return dayOfWeek === 6 || nextDay.getDate() === 1; // Saturday (end of the row)
    },
  };

  const handleSelection = (theItem) => {
    setCustomRangeText(theItem?.value);
    setInternalStartDate(theItem?.start_date);
    setInternalEndDate(theItem?.end_date);
    setSelectedDate({
      from: new Date(theItem?.start_date),
      to: new Date(theItem?.end_date),
    });
    setUseMonth(subMonths(new Date(theItem?.end_date), 1));
  };

  const handleDateSelection = (event) => {
    // if (!event?.from || !event?.to) {
    setCustomRangeText("");
    // }
    setSelectedDate(event);
  };

  const renderCustomDateRange = () => {
    if (!standardDurations.length) {
      return <></>;
    }
    return (
      <div className="b-calender__sidebar">
        {standardDurations?.map((item) => {
          return (
            <button
              key={item?.value}
              className={`b-calender__button ${
                customRangeText === item?.value && "b-calender__button--active"
              }`}
              onClick={() => handleSelection(item)}
            >
              {item?.key}
            </button>
          );
        })}
      </div>
    );
  };

  const handleExternalDisplay = () => {
    if (customRangeText) {
      standardDurations?.map((item) => {
        if (item?.value === customRangeText) {
          setExternalDateDisplay(item?.key);
          return;
        }
      });
      setInputWidth(205);
    } else {
      let formattedString = "";
      if (internalStartDate) {
        formattedString += formatDateToDDMMYYString(internalStartDate);
      }
      if (internalEndDate) {
        formattedString += ` - ${formatDateToDDMMYYString(internalEndDate)}`;
      }
      setInputWidth(minWidth);
      setExternalDateDisplay(formattedString);
    }
  };

  const handleAppylyClick = () => {
    onDateRangeChange(internalStartDate, internalEndDate);
    handleExternalDisplay();
    setModalOpen(false);
    setSelectedDate({
      from: new Date(internalStartDate),
      to: new Date(internalEndDate),
    });
  };

  const formatAndSetInternalDate = () => {
    setInternalStartDate(formatDate(selectedDate?.from));
    setInternalEndDate(formatDate(selectedDate?.to ?? new Date()));
  };

  useEffect(() => {
    selectedDate && formatAndSetInternalDate();
  }, [selectedDate]);

  useEffect(() => {
    if (isModalOpen) {
      setModalOpen(isModalOpen);
    }
  }, [isModalOpen]);

  useEffect(() => {
    setInternalStartDate(defaultSelection?.start_date);
    setInternalEndDate(defaultSelection?.end_date);
    setCustomRangeText(defaultSelection?.value);
    setExternalDateDisplay(defaultSelection?.key);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef?.current && !modalRef?.current?.contains(event.target)) {
        setModalOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  useEffect(() => {
    if (isDateCleared) {
      setInternalStartDate(defaultSelection?.start_date);
      setInternalEndDate(defaultSelection?.end_date);
      setCustomRangeText(defaultSelection?.value);
      setExternalDateDisplay(defaultSelection?.key ?? "");
      setSelectedDate(null);
    }
  }, [isDateCleared]);

  return (
    <div className="b-calender-container">
      <div ref={modalRef} className="b-calender">
        <div
          // onFocus={(e) => {
          //   setModalOpen(true);
          // }}
          onClick={() => {
            setModalOpen(!modalOpen);
          }}
          className="b-calender__input-cont"
          style={inputStyles}
        >
          {fancyPlaceholder ? (
            <TextInput
              height="30px"
              minWidth="50px"
              iconClass={`b-calender__icon ${
                isHighlighted || modalOpen ? "b-calender__icon--blue" : ""
              }`}
              icon={icon}
              showMuiInput="true"
              searchIcon={false}
              iconAlignment="right"
              value={externalDateDisplay}
              readonly={true}
              placeholder={placeholder}
              isHighlighted={isHighlighted}
              isFocusedExternal={modalOpen}
              isIconClickable={true}
              onIconClick={() => {
                setModalOpen(true);
              }}
              cursor="pointer"
            />
          ) : (
            <>
              <input
                type="text"
                placeholder={fancyPlaceholder ? "" : placeholder}
                className={`b-calender__input ${
                  isHighlighted ? "b-calender__input--highlighted" : ""
                }`}
                value={externalDateDisplay}
                readOnly
              />
              {icon && (
                <img
                  className="b-calender__icon"
                  src={isHighlighted ? calenderActive : icon}
                  alt="calender"
                />
              )}
            </>
          )}
        </div>
        {modalOpen && (
          <div
            className="b-calender__modal"
            onBlur={(e) => {
              if (!e.currentTarget.contains(e.relatedTarget))
                setModalOpen(false);
            }}
          >
            <>{renderCustomDateRange()}</>
            <div className="b-calender__right">
              <div className="b-calender__dates">
                <p className="b-calender__date">
                  {formatDateToDDMMYYYYString(internalStartDate)}
                </p>
                <span>{`->`}</span>
                <p className="b-calender__date">
                  {formatDateToDDMMYYYYString(internalEndDate)}
                </p>
              </div>
              <div className="b-calender__calender">
                <DayPicker
                  numberOfMonths={2}
                  mode="range"
                  selected={selectedDate}
                  onSelect={(e) => {
                    handleDateSelection(e);
                  }}
                  captionLayout="dropdown-buttons"
                  disabled={{ before: "", after: new Date() }}
                  fromYear={2014}
                  toDate={new Date()}
                  toMonth={new Date()}
                  toYear={new Date().getFullYear()}
                  month={useMonth}
                  onMonthChange={(e) => setUseMonth(e)}
                  modifiers={modifiers}
                  modifiersClassNames={{
                    startOfRow: "b-calender__start-of-row",
                    endOfRow: "b-calender__end-of-row",
                  }}
                />
              </div>
              <div className="b-calender__actions">
                <Button
                  buttonLabel="Cancel"
                  buttonType="secondary"
                  buttonSize="sm2"
                  onClick={() => {
                    setModalOpen(false);
                  }}
                />
                <Button
                  buttonLabel="Apply"
                  buttonSize="sm2"
                  onClick={handleAppylyClick}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlunoCalender;
