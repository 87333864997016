// react imports
import React from "react";
import { useNavigate } from "react-router-dom";

//third-party imports

// in-app imports
import redAlert from "../assets/media/alert/RedAlert.png";
import yellowAlert from "../assets/media/alert/YellowAlert.png";
import nextIcon from "../assets/media/alert/Next.png";
import { updateShowBrForm } from "../redux/common/slice";
import { useDispatch } from "react-redux";
import { ALERT_POPUP_SUBTYPE } from "../utils/constants/PopupConstants";

const Alert = ({ type = "warning", text = "", deeplink, subType = "link" }) => {
  // defined to access react based APIs
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const redirect = () => {
    switch (subType) {
      case ALERT_POPUP_SUBTYPE.link:
        navigate(deeplink);
        break;
      case ALERT_POPUP_SUBTYPE.brFormModal:
        dispatch(updateShowBrForm(true));
        break;
    }
  };
  return (
    <>
      <div className={`s-alert s-alert--${type}`} onClick={redirect}>
        <div className="s-alert__content">
          <img
            src={type === "warning" ? yellowAlert : redAlert}
            alt="Warning Icon"
          />
          <div className="s-alert__text">{text}</div>
        </div>
        {/* <img src={nextIcon} alt="Redirection Icon" /> */}
      </div>
    </>
  );
};

export default Alert;
