// react imports
import React, { useEffect, useState } from "react";

//in app imports
import TextInput from "../TextInput";
import Button from "../Button";
import OtpBox from "./OtpBox";
import BlunoTooltip from "../BlunoTooltip";
import MuiTextInput from "../blunoInputs/MuiTextInputs";

const InputWithOtp = ({
  data,
  actionButtons,
  onActionButtonClick,
  onInputChange,
  inputValue = "",
  otp = "",
  setOtp,
  isInputDisabled = true,
  isOtpDisbaled = true,
  otpErrorMsg = "",
  inputErrorMsg = "",
  timerValue,
  otpexpired,
}) => {

  return (
    <div className="s-ipotp">
      <div className="s-ipotp__inputs">
        <div className="s-ipotp__input">
          {/* <BlunoTooltip
          text={
            isInputDisabled &&
            data?.slug !== "updated_identity" &&
            (data?.text_box?.slug === "user_email_id"
              ? "To update new email id, verify your current email id by clicking Request OTP."
              : "To update new number, verify your current number by clicking Request OTP.")
          }
          useChildren={true}
          position="fixed"
          top="58px"
          left="85px"
        > */}
          <MuiTextInput
            placeholder={data?.text_box?.placeholder}
            type={data?.text_box?.sub_type}
            value={inputValue}
            disabled={isInputDisabled}
            errorMessage={inputErrorMsg}
            onChange={(e) => {
              onInputChange(e.target.value);
            }}
            onKeyDown={(e) => {
              if (
                (data?.text_box?.sub_type === "number" && e.which === 38) ||
                e.which === 40
              ) {
                e.preventDefault();
              }
            }}
            fixedText={data?.text_box?.fixed_text}
            fixedTextColor="#888888"
            paddingLeft={data?.text_box?.left_padding}
            fixedTextLeftStyle={"20px"}
            height="40px"
            minWidth="360px"
            fontSize="13px"
            errorMsgPostionType="absolute"
            errorMsgBottomPosition="-17px"
          />
          {/* </BlunoTooltip> */}
          {data?.text_box?.icon && (
            <img src={data?.text_box?.icon} className="s-ipotp__input-icon" />
          )}
        </div>
        {!isOtpDisbaled && (
          <div className="s-ipotp__otp">
            <p className="s-ipotp__info">{data?.otp_box?.title}</p>
            <OtpBox
              otp={otp}
              setOtp={setOtp}
              width="36px"
              height="36px"
              actionText={data?.otp_box?.action?.text}
              timerValue={timerValue}
              otpexpired={otpexpired}
              errorMsg={otpErrorMsg}
              handleActionClick={() => {
                onActionButtonClick(data?.otp_box?.action?.slug);
              }}
            />
          </div>
        )}
      </div>

      {actionButtons && (
        <div className="s-ipotp__btn">
          {actionButtons.map((btn) => {
            return (
              <Button
                buttonLabel={btn?.text}
                buttonType={btn?.sub_type}
                onClick={() => {
                  onActionButtonClick(btn?.slug);
                }}
                icon={btn?.icon}
                iconAlignment={btn?.icon_alignment}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default InputWithOtp;
