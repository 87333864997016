export const STAGE = {
  SETUP: "setup",
  ONBOARDING: "onboarding",
  DASHBOARD: "dashboard",
};

export const IDENTITY = {
  EMAIL: "EMAIL",
};

