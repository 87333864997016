import React from "react";

const AllBuyersSummaryCard = ({
  icon,
  title,
  text1,
  text2,
  subText,
  currency,
  buttons,
}) => {
  return (
    <>
      <div className="a-buyers__summary-info">
        {icon && <img src={icon} alt="" className="a-buyers__summary-icon" />}
        <div className="a-buyers__summary-content">
          {title && <h2 className="a-buyers__summary-title">{title}</h2>}
          {(text1 || currency || text2) && (
            <div className="a-buyers__summary-text">
              {text1 && <p>{text1}</p>}
              {(currency || text2) && (
                <span
                  className={`${currency ? "a-buyers__summary-currency" : ""}`}
                >
                  {currency ?? text2}
                </span>
              )}
            </div>
          )}
          {subText && <p className="a-buyers__summary-sub-text">{subText}</p>}
          <div className="a-buyers__summary-buttons">{buttons}</div>
        </div>
      </div>
    </>
  );
};

export default AllBuyersSummaryCard;
