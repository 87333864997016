// react imports
import React from "react";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { closeToast } from "../redux/toast/slice";

// third-party imports
import { Snackbar } from "@mui/material";

// in-app imports

const ToastV2 = () => {
  // defined to access react based APIs
  const dispatch = useDispatch();

  // redux state variables
  const toastData = useSelector((state) => state.toast.toastItems);
  const isToastShow = useSelector((state) => state.toast.isToastShow);

  const onToastClose = () => {
    dispatch(closeToast());
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: toastData?.position?.vertical || "top",
          horizontal: toastData?.position?.horizontal || "right",
        }}
        open={isToastShow}
        autoHideDuration={toastData?.duration_in_ms}
        onClose={onToastClose}
        // action={action}
      >
        <div className={`s-toastV2`}>
          <div className={`s-toastV2__left s-toastV2--${toastData?.type}`}>
            <img
              src={toastData?.data?.left?.status_icon}
              alt=""
              className="s-toastV2__status-icon"
            />
          </div>
          <div className="s-toastV2__center">
            <p className="s-toastV2__text">{toastData?.data?.center?.text}</p>
          </div>
          <div className="s-toastV2__right">
            <img
              src={toastData?.data?.right?.close_icon}
              alt=""
              className="s-toastV2__close"
              onClick={onToastClose}
            />
          </div>
        </div>
      </Snackbar>
    </>
  );
};

export default ToastV2;
