import React from "react";
import Button from "./Button";
import BlunoTooltip from "./BlunoTooltip";

const PostReconCompletedDocs = ({ data, onEditSection }) => {
  return (
    <>
      <div className="post-recon-completed-docs">
        <div className="post-recon-completed-docs__title">
          <p className="post-recon-completed-docs__title-text">{data?.title}</p>
          <BlunoTooltip
            useChildren={true}
            text={data?.edit_info?.tooltip_text}
            position="fixed"
          >
            <img
              src={data?.edit_info?.icon}
              className="post-recon-completed-docs__title-icon"
              onClick={(e) => {
                e.stopPropagation();
                onEditSection(null, { slug: data?.edit_info?.action });
              }}
            />
          </BlunoTooltip>
        </div>
        <div className="post-recon-completed-docs-partition" />
        <p className="post-recon-completed-docs-body">{data?.text}</p>
        {data?.doc_list?.map((item, idx) => {
          return (
            <div className="post-recon-completed-docs__item" key={idx}>
              <img
                src={item?.icon}
                className="post-recon-completed-docs__item-icon"
              />
              <p className="post-recon-completed-docs__item-text">
                {item?.text}
              </p>
            </div>
          );
        })}
        <p className="post-recon-completed-docs-footer">{data?.footer_text}</p>
        <div className="post-recon-completed-docs-partition" />
        <div className="post-recon-completed-docs__buttons">
          {data?.buttons?.map((item, idx) => {
            return (
              <Button
                buttonType={item?.sub_type}
                buttonLabel={item?.text}
                icon={item?.icon}
                iconAlignment={item?.icon_alignment}
                disabled={item?.disabled}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PostReconCompletedDocs;
