// react imports
import React from "react";
import { Outlet } from "react-router-dom";

// third-party imports

// in-app imports
import AlertPopup from "../../components/AlertPopup";
import ToastV2 from "../../components/ToastV2";
import Loader from "../../components/Loader";

const Onboarding = () => {
  return (
    <>
      <div className="onboarding">
        <div className="onboarding__card">
          <Outlet />
        </div>
      </div>
      <AlertPopup />
      <ToastV2 />
      <Loader />
    </>
  );
};

export default Onboarding;
