const API_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
};

const endpoints = {
  misc: {
    url: "https://api.sampleapis.com/wines/reds",
    method: API_METHODS.GET,
    redux: "initData",
  },
  getDocumentManagementDetails: {
    url: "/v1/bluno/document-management/view/details",
    method: API_METHODS.GET,
  },
  downloadExcelSummary: {
    url: "/v1/bluno/document-management/excel/summary/download",
    method: API_METHODS.PUT,
  },
  downloadRelatedDocuments: {
    url: "/v1/bluno/document-management/related/document/download",
    method: API_METHODS.PUT,
  },
  bulkDownloadDocManagement: {
    url: "/v1/bluno/document-management/bulk/download",
    method: API_METHODS.PUT,
  },
  getDocumentManagementData: {
    url: "/v1/bluno/document-management/fetch",
    method: API_METHODS.GET,
    loaderObject: {
      isEnabled: true,
      timeoutLimit: 1000,
      message: "Loading ...",
    },
  },
  updateIRMCharges: {
    url: "/v1/bluno/irm/update/charge",
    method: API_METHODS.PUT,
  },
  remindSB: {
    url: "/v1/bluno/recon/sb/remind",
    method: API_METHODS.PUT,
  },
  getSBRequestData: {
    url: "/v1/bluno/sb/fetch/requests",
    method: API_METHODS.GET,
  },
  submitSbToBank: {
    url: "/v1/bluno/recon/sb/submit",
    method: API_METHODS.PUT,
    loaderObject: {
      isEnabled: true,
      type: "primary",
      message: "Loading ...",
    },
  },
  returnSbToMaker: {
    url: "/v1/bluno/recon/sb/return",
    method: API_METHODS.PUT,
    loaderObject: {
      isEnabled: true,
      type: "primary",
      timeoutLimit: 1000,
      message: "Loading ...",
    },
  },
  editSb: {
    url: "/v1/bluno/recon/sb/",
    method: API_METHODS.PUT,
    loaderObject: {
      isEnabled: true,
      type: "primary",
      timeoutLimit: 1000,
      message: "Loading ...",
    },
  },
  reconManualAddIrm: {
    url: "/v1/bluno/recon/sb",
    method: API_METHODS.POST,
  },
  fetchSBReconStatus: {
    url: "/v1/bluno/recon/sb",
    method: API_METHODS.GET,
  },
  saveManageAccess: {
    url: "/v1/bluno/settings/manage-user/access/save",
    method: API_METHODS.POST,
  },
  editManageAccess: {
    url: "/v1/bluno/settings/manage-user/access/edit",
    method: API_METHODS.GET,
  },
  notifyAdminSbClosure: {
    url: "/v1/bluno/recon/sb",
    method: API_METHODS.PUT,
    loaderObject: {
      isEnabled: true,
      type: "primary",
      message: "Notifying ...",
    },
  },
  regenerateInvite: {
    url: "/v1/bluno/settings/manage-user/regenerate",
    method: API_METHODS.PUT,
  },
  checkPincode: {
    url: "/v1/bluno/settings/general/pincode",
    method: API_METHODS.GET,
  },
  deleteNotifications: {
    url: "/v1/bluno/notification/delete/notifications",
    method: API_METHODS.POST,
  },
  notifications: {
    url: "/v1/bluno/notification/list/notifications",
    method: API_METHODS.GET,
  },
  deleteTasks: {
    url: "/v1/bluno/notification/delete/tasks",
    method: API_METHODS.POST,
  },
  bulkOCR: {
    url: "/v1/bluno/document/sb/ocr",
    method: API_METHODS.POST,
  },
  getHomeSummary: {
    url: "/v1/bluno/home/summary",
    method: API_METHODS.GET,
  },
  getRecentShippingBills: {
    url: "/v1/bluno/home/sb/list/recent",
    method: API_METHODS.GET,
  },
  getMiscInfo: {
    url: "/v1/bluno/home/info-section",
    method: API_METHODS.GET,
  },
  getSidebarConfigs: {
    url: "/v1/bluno/config/sidebar",
    method: API_METHODS.GET,
  },
  getUserBasicInfo: {
    url: "v1/bluno/user/basic-info",
    method: API_METHODS.GET,
  },
  getAllSbData: {
    url: "/v1/bluno/sb/fetch",
    method: API_METHODS.GET,
    loaderObject: {
      isEnabled: true,
      timeoutLimit: 1000,
      message: "Loading ...",
    },
  },
  getSbSingleRowData: {
    url: "/v1/bluno/sb/fetch/single",
    method: API_METHODS.GET,
  },
  getAllIrmData: {
    url: "/v1/bluno/irm/fetch",
    method: API_METHODS.GET,
    loaderObject: {
      isEnabled: true,
      timeoutLimit: 1000,
      message: "Loading ...",
    },
  },
  getReconData: {
    url: "/v1/bluno/recon/sb",
    method: API_METHODS.GET,
  },
  getIRMData: {
    url: "/v1/bluno/irm",
    method: API_METHODS.GET,
  },
  refreshIRMData: {
    url: "/v1/bluno/irm/refresh",
    method: API_METHODS.GET,
  },
  docAction: {
    url: "/v1/bluno/document/action",
    method: API_METHODS.POST,
  },
  uploadFile: {
    url: "/v1/bluno/document/upload",
    method: API_METHODS.POST,
  },
  getDayZeroData: {
    url: "/v1/bluno/dashboard/setup",
    method: API_METHODS.GET,
  },
  actionSubmit: {
    url: "/v1/bluno/recon/sb",
    method: API_METHODS.POST,
  },
  uploadFirstIrm: {
    url: "/v1/bluno/irm/add",
    method: API_METHODS.POST,
  },
  updateCompleteSetup: {
    url: "/v1/bluno/dashboard/setup/complete",
    method: API_METHODS.POST,
  },

  // Version 2 API Data
  getNewReconData: {
    url: "/v1/bluno/recon/sb",
    method: API_METHODS.GET,
  },
  submitAction: {
    url: "/v1/bluno/recon/sb",
    method: API_METHODS.POST,
  },
  editReconSection: {
    url: "/v1/bluno/recon/sb",
    method: API_METHODS.PUT,
  },
  editPostReconDocs: {
    url: "/v1/bluno/recon/sb",
    method: API_METHODS.PUT,
  },
  singleBuyerSbSummary: {
    url: "/v1/bluno/buyer/sb",
    method: API_METHODS.GET,
    loaderObject: {
      isEnabled: true,
      type: "primary",
      timeoutLimit: 1000,
      message: "Loading ...",
    },
  },
  singleBuyerIrmSummary: {
    url: "/v1/bluno/buyer/irm",
    method: API_METHODS.GET,
    loaderObject: {
      isEnabled: true,
      type: "primary",
      timeoutLimit: 1000,
      message: "Loading ...",
    },
  },
  getAllBuyersData: {
    url: "/v1/bluno/buyers/fetch",
    method: API_METHODS.GET,
    loaderObject: {
      isEnabled: true,
      type: "primary",
      timeoutLimit: 1000,
      message: "Loading ...",
    },
  },
  logout: {
    url: "/v1/bluno/logout",
    method: API_METHODS.GET,
  },
  // Buyers Page APIs
  getBuyerInfoSec: {
    url: "/v1/bluno/buyer/info-section",
    method: API_METHODS.GET,
  },
  getRemindBuyerData: {
    url: "/v1/bluno/buyer/remind",
    method: API_METHODS.GET,
  },
  remindBuyers: {
    url: "/v1/bluno/buyer/remind",
    method: API_METHODS.POST,
  },
  getAllBulkEbrcData: {
    url: "/v1/bluno/recon/bulk-info",
    method: API_METHODS.GET,
  },
  getAllBulkEbrcBreakup: {
    url: "/v1/bluno/recon/bulk-group-info",
    method: API_METHODS.POST,
  },
  generateBulkEbrc: {
    url: "/v1/bluno/recon/bulk/initiate",
    method: API_METHODS.POST,
    loaderObject: {
      isEnabled: true,
      type: "primary",
      timeoutLimit: 1000,
      message: "Loading ...",
      delayedDataLoader: {
        delayDataTime: 3000,
        messageList: ["Securely submitting eBRCs generation request.... "],
      },
    },
  },

  // onboarding flow
  getStartJourney: {
    url: "/v1/bluno/start-journey",
    method: API_METHODS.GET,
  },
  startJourney: {
    url: "/v1/bluno/start-journey",
    method: API_METHODS.POST,
  },
  resendPhoneOtp: {
    url: "/v1/bluno/otp/resend",
    method: API_METHODS.POST,
  },
  verifyPhoneOtp: {
    url: "/v1/bluno/otp/verify",
    method: API_METHODS.POST,
  },
  fetchOnboardingTask: {
    url: "/v1/bluno/onboarding/task/fetch",
    method: API_METHODS.GET,
  },
  fetchOnboardingPendingTask: {
    url: "/v1/bluno/onboarding/pending-docs",
    method: API_METHODS.GET,
  },
  submitOnboardingTask: {
    url: "/v1/bluno/onboarding/task/submit",
    method: API_METHODS.POST,
    loaderObject: {
      isEnabled: true,
      type: "primary",
      timeoutLimit: 1000,
      message: "Loading ...",
    },
  },
  fetchBankBranchAddress: {
    url: "/v1/bluno/config/ifsc/search",
    method: API_METHODS.GET,
  },
  decisionApi: {
    url: "/v1/bluno/user/application-info",
    method: API_METHODS.GET,
  },
  refreshToken: {
    url: "/v1/bluno/token/refresh",
    method: API_METHODS.PUT,
  },

  //settings
  getSidebarData: {
    url: "/v1/bluno/settings/sidebar",
    method: API_METHODS.GET,
  },
  getGeneralSettings: {
    url: "/v1/bluno/settings/general/get",
    method: API_METHODS.GET,
  },
  saveGeneralSettings: {
    url: "/v1/bluno/settings/general/save",
    method: API_METHODS.POST,
  },
  getSecuritySettings: {
    url: "v1/bluno/settings/security",
    method: API_METHODS.GET,
  },
  saveSecuritySettings: {
    url: "/v1/bluno/settings/security/save",
    method: API_METHODS.POST,
  },
  logoutPreviousDevice: {
    url: "v1/bluno/settings/device/logout",
    method: API_METHODS.POST,
  },
  updateIdentity: {
    url: "/v1/bluno/settings/general/update",
    method: API_METHODS.POST,
  },
  insertIdentity: {
    url: "/v1/bluno/settings/general/insert",
    method: API_METHODS.POST,
  },
  verifyIdentity: {
    url: "/v1/bluno/settings/general/otp/verify",
    method: API_METHODS.POST,
  },
  resendOtpToVerify: {
    url: "/v1/capi/user/identity/resend-verification",
    method: API_METHODS.POST,
  },
  uploadImage: {
    url: "/v1/bluno/document/image/upload",
    method: API_METHODS.POST,
  },
  removeImage: {
    url: "/v1/bluno/document/image/remove",
    method: API_METHODS.POST,
  },
  getPlatformSettings: {
    url: "/v1/bluno/settings/platform",
    method: API_METHODS.GET,
  },
  savePlatformSettings: {
    url: "/v1/bluno/settings/platform/save",
    method: API_METHODS.POST,
  },
  getCompanyBankDetails: {
    url: "/v1/bluno/settings/bank-details",
    method: API_METHODS.GET,
  },
  addNewPassword: {
    url: "/v1/bluno/password/add",
    method: API_METHODS.POST,
  },
  verifyPassword: {
    url: "/v1/bluno/password/verify",
    method: API_METHODS.POST,
  },
  forgotPassword: {
    url: "/v1/bluno/password/forgot/update",
    method: API_METHODS.POST,
  },
  getManageAccessSettings: {
    url: "/v1/bluno/settings/manage-user/access",
    method: API_METHODS.GET,
  },
  getManageGroupsSettings: {
    url: "/v1/bluno/settings/manage-user/groups",
    method: API_METHODS.GET,
  },
  inviteUser: {
    url: "/v1/bluno/user/invite",
    method: API_METHODS.POST,
  },
  deleteUser: {
    url: "/v1/bluno/settings/manage-user/remove",
    method: API_METHODS.PUT,
  },
  changeUserRoles: {
    url: "/v1/bluno/settings/manage-user/change",
    method: API_METHODS.POST,
  },
  checkRedirection: {
    url: "/v1/bluno/process/redirection",
    method: API_METHODS.POST,
  },
  submitCommonGetHelpForm: {
    url: "/v1/bluno/common/help-submit",
    method: API_METHODS.POST,
  },
  submitAuthGetHelpForm: {
    url: "/v1/bluno/auth/help-submit",
    method: API_METHODS.POST,
  },
  requestFeatures: {
    url: "/v1/bluno/home/feature/request",
    method: API_METHODS.POST,
  },
  submitUploadLater: {
    url: "/v1/bluno/onboarding/upload-later",
    method: API_METHODS.POST,
  },
  onboardingDocAction: {
    url: "/v1/bluno/document/onboarding/action",
    method: API_METHODS.POST,
  },
  fetchUserTickets: {
    url: "/v1/bluno/ticket/fetch",
    method: API_METHODS.GET,
  },
  createUserTicker: {
    url: "/v1/bluno/ticket/create",
    method: API_METHODS.POST,
  },
  userTicketAction: {
    url: "/v1/bluno/ticket/action",
    method: API_METHODS.POST,
  },
  rateUserTicket: {
    url: "/v1/bluno/ticket/review/rate",
    method: API_METHODS.POST,
  },
  fetchNotificationBanner: {
    url: "/v1/bluno/home/banner/fetch",
    method: API_METHODS.GET,
  },
  hideNotificationBannner: {
    url: "/v1/bluno/home/banner/hide",
    method: API_METHODS.POST,
  },
  fetchBlunoPartnersData: {
    url: "/v1/bluno/settings/partner-platform",
    method: API_METHODS.GET,
  },
  registerDgftCred: {
    url: "/v1/bluno/settings/dgft/register",
    method: API_METHODS.POST,
  },
  handleNeedAssistanceRequest: {
    url: "/v1/bluno/settings/request/help",
    method: API_METHODS.GET,
  },
  initiateBillLodgement: {
    url: "/v1/bluno/recon/sb",
    method: API_METHODS.POST,
  },
};

export default endpoints;
