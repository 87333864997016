//react imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//in app imports
import Button from "../Button";
import ModalV2 from "../ModalV2";
import UploadDocCard from "./UploadDocCard";
import { SUB_MODAL_TYPE } from "../../utils/constants/TableConstants";
import PendingModal from "./PendingModal";
import SuccessModal from "./SuccessModal";
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import { showToast } from "../../redux/toast/slice";
import BlunoTooltip from "../BlunoTooltip";
import { showLoader } from "../../redux/loader/slice";
import { downloadDocumentUtility } from "../../utils/downloadDocument";

const UploadDocColumn = ({
  rowData = null,
  headerData = null,
  tableData = {},
  setTableData,
}) => {
  const columnData = rowData?.[headerData?.slug];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [sbId, setSbId] = useState("");
  const [subModalType, setSubModalType] = useState(SUB_MODAL_TYPE?.invisible);
  const [subModalData, setSubModalData] = useState(null);
  const [isInvoiceHovered, setIsInvoiceHovered] = useState(false);
  const [isTransportHovered, setIsTransportHovered] = useState(false);

  const redirectToLink = (theSlug) => {
    navigate(theSlug);
  };

  const handleModalClick = (e, theStatus, theData, theSbId) => {
    e.stopPropagation();
    setShowModal(true);
    setModalData(theData);
    setSbId(theSbId);
  };

  const handleSubmitToBankClick = async (id) => {
    const apiRes = await dispatch(
      apiRequest(endpoints.submitSbToBank, {
        ids: id,
      })
    );
    if (apiRes?.success) {
      updatedSingleRowData(id[0]);
    }
  };

  const updatedSingleRowData = async (id) => {
    const apiData = await dispatch(
      apiRequest(endpoints.getSbSingleRowData, `/${id}`)
    );

    if (apiData?.success && apiData?.data) {
      const dataObj = apiData?.data?.[0];

      const updatedData = tableData?.data?.map((item) =>
        item?.id === dataObj?.id ? dataObj : item
      );
      setTableData({
        ...tableData,
        data: updatedData,
      });
      return dataObj;
    } else {
      // error toast message
      if (apiData?.data?.toast) {
        dispatch(showToast(apiData?.data?.toast));
      }
      return null;
    }
  };

  const bulkDownloadEbrc = async (ids) => {
    const apiRes = await dispatch(
      apiRequest(endpoints.bulkDownloadDocManagement, {
        ids: ids,
        document_management_type: "ebrc",
        processing: "sync",
      })
    );
    if (apiRes?.success) {
      downloadDocumentUtility(
        apiRes?.data?.document_link,
        apiRes?.data?.filename
      );
    } else {
      dispatch(showToast(apiRes?.data?.toast));
    }
  };

  return (
    <>
      <div className="s-table__column">
        <div
          className={`s-table__upload s-table__header--${columnData?.invoice_docs?.slug} `}
          onClick={(e) => {
            handleModalClick(
              e,
              columnData?.invoice_docs?.status,
              columnData?.modal,
              rowData?.id
            );
          }}
        >
          <BlunoTooltip
            useChildren={true}
            text={
              columnData?.invoice_docs?.status === "pending"
                ? columnData?.invoice_docs?.tooltip_text
                : null
            }
          >
            <div
              className={`${
                columnData?.invoice_docs?.status === "pending"
                  ? "s-table__upload-background"
                  : ""
              }`}
            >
              <img
                className={`s-table__upload-icon`}
                src={columnData?.invoice_docs?.icon}
                alt={columnData?.invoice_docs?.status}
              />
            </div>
          </BlunoTooltip>
          {columnData?.invoice_docs?.text && (
            <p className="s-table__upload-text">
              {columnData?.invoice_docs?.text}
            </p>
          )}
        </div>
        <div
          className={`s-table__upload s-table__header--${columnData?.transport_docs?.slug} `}
          onClick={(e) => {
            handleModalClick(
              e,
              columnData?.transport_docs?.status,
              columnData?.modal,
              rowData?.id
            );
          }}
        >
          <BlunoTooltip
            useChildren={true}
            text={
              columnData?.transport_docs?.status === "pending"
                ? columnData?.transport_docs?.tooltip_text
                : null
            }
          >
            <div
              className={`${
                columnData?.transport_docs?.status === "pending"
                  ? "s-table__upload-background"
                  : ""
              }`}
            >
              <img
                className={`s-table__upload-icon`}
                src={columnData?.transport_docs?.icon}
                alt={columnData?.transport_docs?.status}
              />
            </div>
          </BlunoTooltip>
          {columnData?.transport_docs?.text && (
            <p className="s-table__upload-text">
              {columnData?.transport_docs?.text}
            </p>
          )}
        </div>
        <div className={`s-table__table-action s-table__header--action`}>
          {columnData?.action?.sub_type !== "download" ? (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                if (columnData?.action?.slug === "send_to_bank") {
                  handleSubmitToBankClick([rowData?.id]);
                } else if (
                  columnData?.action?.slug === "upload_transport_doc" ||
                  columnData?.action?.slug === "upload_invoice_bill"
                ) {
                  handleModalClick(
                    e,
                    columnData?.transport_docs?.status,
                    columnData?.modal,
                    rowData?.id
                  );
                } else {
                  redirectToLink(columnData?.action?.deeplink);
                }
              }}
              buttonSize="xs"
              buttonLabel={columnData?.action?.text}
              extraClass={`s-button--${columnData?.action?.sub_type}`}
              fontSize="11px"
            />
          ) : (
            <div className="s-table__upload-action-cont">
              <div
                className="s-table__upload-action"
                onClick={(e) => {
                  e.stopPropagation();
                  if (columnData?.action?.slug === "download_ebrc")
                    bulkDownloadEbrc(rowData?.ebrc_ids);
                }}
              >
                <img
                  className="s-table__upload-action-icon"
                  src={columnData?.action?.icon}
                />
                <p className="s-table__ellipsis s-table__ellipsis--pending">
                  {columnData?.action?.text}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <>
          <div
            className="s-table__column-modal-bg"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ModalV2 slug="standard">
              <UploadDocCard
                data={modalData}
                setShowModal={setShowModal}
                sbId={sbId}
                tableData={tableData}
                setTableData={setTableData}
                subModalType={subModalType}
                setSubModalType={setSubModalType}
                subModalData={subModalData}
                setSubModalData={setSubModalData}
                maxFileNameWidth="150px"
              />
            </ModalV2>
          </div>
        </>
      )}
    </>
  );
};

export default UploadDocColumn;
