import React from "react";

// in app imports
import Button from "../Button";
import { SUB_MODAL_TYPE } from "../../utils/constants/TableConstants";

const PendingModal = ({ data, setShowModal, setSubModalType }) => {
  const handleButtonClick = (theSlug) => {
    switch (theSlug) {
      case "upload_later":
        setShowModal(false);
        setSubModalType(SUB_MODAL_TYPE?.invisible);
        break;
      case "complete_now":
        setShowModal(true);
        setSubModalType(SUB_MODAL_TYPE?.invisible);
        break;
      default:
        break;
    }
  };

  return (
    <div className="s-table__modal-pending">
      <div className="s-table__pending">
        <div>
          <div className={`s-table__pending-header`}>
            {data?.icon && <img src={data?.icon} alt="status-icon" />}
            <h2 className="s-alert-popup__title">{data?.title}</h2>
          </div>
          <div className="s-table__pending-content">
            <p className="s-table__success-text">{data?.text}</p>
          </div>

          <div className="s-alert-popup__action">
            {data?.buttons?.map((action) => {
              return (
                <Button
                  buttonLabel={action?.text}
                  buttonType={action?.sub_type}
                  icon={action.icon}
                  disabled={action?.disabled}
                  iconAlignment={action?.icon_alignment}
                  buttonSize={action?.size ?? "sm2"}
                  width={action?.width}
                  onClick={() => handleButtonClick(action?.slug)}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="s-table__modal-tprt">
        <img
          src={data?.close_icon}
          alt="close"
          className="s-table__modal-icon-close"
          onClick={() => {
            handleButtonClick("upload_later");
          }}
        />
      </div>
    </div>
  );
};

export default PendingModal;
