// imports
import { homeSlice } from "../home/slice";
import api from "../../api";

// redux imports
import { showLoader, removeLoader } from "../loader/slice";

const { actions } = homeSlice;

const makeAPIRequest = (requestObj, payload, postQuery, dispatch) => {
  if (requestObj?.method === "POST") {
    if (postQuery) return api.post(`${requestObj?.url}${postQuery}`, payload);
    else return api.post(requestObj?.url, payload);
  } else if (requestObj?.method === "PUT") {
    if (postQuery) return api.put(`${requestObj?.url}${postQuery}`, payload);
    else return api.put(requestObj?.url, payload);
  } else if (requestObj?.method === "GET") {
    return api.get(payload ? `${requestObj?.url}${payload}` : requestObj?.url);
  }
};

export const apiRequest =
  (requestObj, payload, postQuery = null) =>
  (dispatch) => {
    if (requestObj?.loaderObject && requestObj?.loaderObject?.isEnabled) {
      dispatch(
        showLoader({
          type: requestObj?.loaderObject?.type,
          timeoutLimit: requestObj?.loaderObject?.timeoutLimit,
          message: requestObj?.loaderObject?.message,
          delayedDataLoader: requestObj?.loaderObject?.delayedDataLoader,
        })
      );
    }
    return makeAPIRequest(requestObj, payload, postQuery, dispatch)
      .then((response) => {
        if (requestObj?.redux) {
          dispatch(actions[requestObj?.redux]?.(response?.data));
        }
        return response?.data;
      })
      .catch((error) => {
        return error?.response?.data;
      })
      .finally(() => {
        if (
          requestObj?.loaderObject?.isEnabled &&
          !requestObj?.loaderObject?.delayedDataLoader?.delayDataTime
        ) {
          dispatch(removeLoader());
        }
      });
  };
