import React from "react";
import TimePeriodPanel from "../../components/TimePeriodPanel";

import SkeletonBasic from "../../components/SkeletonLoaders/SkeletonBasic";
import AllBuyersSummaryCard from "./AllBuyersSummaryCard";
import ModalV2 from "../../components/ModalV2";
import RemindAlert from "../../components/buyersPage/RemindAlert";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import comingSoon from "../../assets/media/Coming Soon.png";

const BuyersSummary = (props) => {
  const {
    data,
    btns,
    onGetRemindClick,
    remindBuyersData,
    showModal,
    modalInfo,
    closeModal,
    sendReminder,
    bulkBtn,
  } = props;

  const navigate = useNavigate();
  return (
    <div className="a-buyers__summary">
      <SkeletonBasic showLoader={data ? false : true}>
        <AllBuyersSummaryCard
          icon={data?.icon}
          title={data?.title}
          text1={data?.active_buyers}
          text2={data?.total_buyers}
          subText={data?.sub_text}
        />
        {data?.bills?.map((item) => {
          return (
            <AllBuyersSummaryCard
              icon={item?.icon}
              title={item?.text}
              text1={item?.amount}
              subText={item?.total_text}
              currency={data?.currency}
            />
          );
        })}
        <AllBuyersSummaryCard
          buttons={
            <>
              <Button
                buttonLabel={bulkBtn?.text}
                buttonType={bulkBtn?.sub_type}
                icon={bulkBtn?.icon}
                disabled={bulkBtn?.disabled}
                iconAlignment={bulkBtn?.icon_alignment}
                tooltipText={bulkBtn?.tooltip_text}
                onClick={() => {
                  bulkBtn?.slug === "generate_bulk_ebrc_info" &&
                    navigate("/sb/bulk-ebrc");
                }}
              />
              <div
                className={`a-buyers__alert-remind ${
                  showModal && modalInfo?.slug === "remind"
                    ? "a-buyers__alert--show"
                    : null
                }`}
              >
                <img
                  src={comingSoon}
                  className="a-buyers__alert-remind-coming-soon"
                />
                <Button
                  buttonLabel={btns?.remind?.text}
                  buttonType={btns?.remind?.sub_type}
                  icon={btns?.remind?.icon}
                  disabled={btns?.remind?.disabled}
                  iconAlignment={btns?.remind?.icon_alignment}
                  onClick={() =>
                    onGetRemindClick(
                      undefined,
                      undefined,
                      btns?.slug,
                      btns?.remind?.slug
                    )
                  }
                />
                {showModal && modalInfo?.slug === "remind" && (
                  <ModalV2 slug={modalInfo?.slug}>
                    <RemindAlert
                      data={remindBuyersData?.data}
                      alertName={remindBuyersData?.alertName}
                      onGetRemindClick={onGetRemindClick}
                      sendReminder={sendReminder}
                      closeModal={closeModal}
                    />
                  </ModalV2>
                )}
              </div>
            </>
          }
        />
      </SkeletonBasic>
    </div>
  );
};

export default BuyersSummary;
