export const ALERT_POPUP_ACTIONS = {
  PROCEED: "proceed",
  CLOSE: "close",
};

export const ALERT_POPUP_BUTTON_TYPE = {
  BUTTON: "button",
};

export const ALERT_POPUP_SUBTYPE = {
  link: "link",
  brFormModal: "br_form_modal",
};
