import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import UploadedFile from "./UploadedFile";
import endpoints from "../../api/endpoints";
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import { showToast } from "../../redux/toast/slice";
import { downloadDocumentUtility } from "../../utils/downloadDocument";
import { DOCUMENT_ACTION } from "../../utils/constants/DocumentConstants";

export default function UploadFileV2({
  disabled,
  upload_enabled = true,
  allowed_doc_types,
  multiple_files,
  icon,
  file_icon,
  primary_text,
  sub_title,
  slug,
  flow,
  uploaded_documents,
  extraMetadata = {},
  extraClass = "",
  getApi,
  callGetApi = false,
  singleUploadAllowed = false,
  onFileChange = () => {},
  showUploadedFiles = true,
}) {
  const dispatch = useDispatch();

  const [acceptedExtensionsList, setAcceptedExtensionsList] = useState("");
  const id = uuidv4();
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [files, setFiles] = useState(
    uploaded_documents
      ? uploaded_documents?.map((item) => {
          return [item];
        })
      : []
  );
  const [showUpload, setShowUpload] = useState(upload_enabled);
  const uploadSingleFile = (selectedFile, frontendId) => {
    const formData = new FormData();
    const metadata = {
      flow: flow,
      ...extraMetadata,
    };
    formData.append("file", selectedFile);
    formData.append("doc_type", slug);
    formData.append("metadata", JSON.stringify(metadata));
    const uploadApiCall = async () => {
      if (singleUploadAllowed) {
        setShowUpload(false);
      }
      const dataRes = await dispatch(
        apiRequest(endpoints.uploadFile, formData, null, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      );
      if (dataRes?.success && dataRes?.data) {
        setShowUpload(
          dataRes?.data?.uploaded_document_info?.[0]?.upload_enabled
        );
        setFiles((prev) =>
          prev.map((item, idx) => {
            if (item?.[0]?.frontendId === frontendId) {
              return dataRes?.data?.uploaded_document_info?.[0]?.uploaded_documents?.map(
                (item_file) => {
                  return { ...item_file, frontendId: uuidv4() };
                }
              );
            } else {
              return item;
            }
          })
        );
      } else {
        // dispatch(showToast(dataRes?.data?.toast));
        setFiles((prev) =>
          prev.map((item, idx) => {
            if (item?.[0]?.frontendId === frontendId) {
              return [
                {
                  ...item?.[0],
                  info_icon:
                    "https://static.bluno.in/miscl/query-raised-icon.png",
                  error_message: dataRes?.data?.toast?.data?.center?.text,
                },
              ];
            } else {
              return item;
            }
          })
        );
      }
    };
    uploadApiCall();
  };

  const removeFileFrontend = (frontendId) => {
    setFiles((prev) => {
      return prev.reduce((itemAccumulator, item) => {
        const temp_item = item?.filter((item_file) => {
          return item_file?.frontendId !== frontendId;
        });
        if (temp_item.length > 0) itemAccumulator.push(temp_item);
        return itemAccumulator;
      }, []);
    });
  };

  const removeFileBackend = (document_id) => {
    setFiles((prev) => {
      return prev?.reduce((itemAccumulator, item) => {
        const temp_item = item?.filter((item_file) => {
          return (
            item_file?.id !== document_id &&
            item_file?.zip_file_parent !== document_id
          );
        });
        if (temp_item?.length > 0) itemAccumulator.push(temp_item);
        return itemAccumulator;
      }, []);
    });
  };

  const handleFileInputChange = (e) => {
    if (e.target.files.length > 0) {
      const selectedFiles = Array.from(e.target.files);
      selectedFiles.map((item, idx) => {
        const frontendId = uuidv4();
        let newFile = {
          frontendId: frontendId,
          name: item?.name,
          size: item?.size,
          icon: file_icon,
        };
        if (!("." + item?.name?.split(".").pop() in allowed_doc_types)) {
          newFile.error_message = "Invalid File Format";
        } else if (
          item?.size >
          allowed_doc_types?.["." + item?.name?.split(".").pop()] * 1024
        ) {
          newFile.error_message = "File size too large";
        }
        if (newFile.error_message !== undefined) {
          newFile.info_icon =
            "https://static.bluno.in/miscl/query-raised-icon.png";
        }
        setFiles((prev) => [...prev, [newFile]]);
        if (newFile.error_message === undefined) {
          uploadSingleFile(item, frontendId);
        }
      });
    }
    e.target.value = "";
  };

  const onDocAction = (actionBtn, documentId, fileName, fileMetaData) => {
    const docAction = async (actionBtn, documentId, fileName, fileMetaData) => {
      const metadata = {
        flow: flow,
        ...extraMetadata,
      };

      const dataRes = await dispatch(
        apiRequest(
          flow === "onboarding"
            ? endpoints.onboardingDocAction
            : endpoints.docAction,
          {
            action: actionBtn,
            document_id: documentId,
            document_type: slug,
            metadata: JSON.stringify(metadata),
            sb_id: fileMetaData?.sbId,
          }
        )
      );

      if (dataRes?.success && dataRes?.data) {
        if (actionBtn === DOCUMENT_ACTION?.["DETACH"]) {
          removeFileBackend(documentId);

          if (flow === "onboarding" && callGetApi) {
            getApi();
            setShowUpload(true);
          }

          if (singleUploadAllowed) {
            setShowUpload(true);
          }

          if (
            dataRes?.data?.summary_data?.upload_enabled === true ||
            dataRes?.data?.summary_data?.upload_enabled === false
          ) {
            setShowUpload(dataRes?.data?.summary_data?.upload_enabled);
          }
        }

        if (dataRes?.data?.document_link) {
          if (actionBtn === DOCUMENT_ACTION?.["VIEW"]) {
            window.open(dataRes?.data?.document_link, "_blank");
          } else if (actionBtn === DOCUMENT_ACTION?.["DOWNLOAD"]) {
            downloadDocumentUtility(dataRes?.data?.document_link, fileName);
          }
        }
      }
    };
    if (actionBtn === DOCUMENT_ACTION?.["DETACH"]) {
      files?.forEach((item) => {
        item?.forEach((file) => {
          if (file?.zip_file_parent === documentId && !file?.error_message) {
            docAction(actionBtn, file?.id, file?.name, file?.metadata);
          }
        });
      });
    }
    docAction(actionBtn, documentId, fileName, fileMetaData);
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    handleFileInputChange({ target: { files: e.dataTransfer.files } });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
  };

  useEffect(() => {
    let count = 0;
    let acceptedExtensionsListTemp = "";
    for (let item in allowed_doc_types) {
      acceptedExtensionsListTemp += (count === 0 ? "" : ",") + item;
      count++;
    }
    setAcceptedExtensionsList(acceptedExtensionsListTemp);
  }, []);

  useEffect(() => {
    let found = false;
    files.forEach((item) => {
      if (
        item?.length === 1 &&
        item?.[0]?.name?.split(".")?.pop() === "zip" &&
        item?.[0]?.id
      ) {
        onDocAction("detach", item?.[0]?.id, item?.name);
        found = true;
        return;
      }
    });
    if (found) return;
    onFileChange(files);
  }, [files]);

  return (
    <div className={`s-upload-card-v2 ${extraClass}`}>
      {showUpload && (
        <label className="s-upload-card-v2__label" htmlFor={id}>
          <section
            className={`s-upload-card-v2__input 
        ${isDraggingOver && "s-upload-card-v2__input--drag"}`}
            onDrop={handleFileDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            <input
              id={id}
              className="s-upload-card-v2__upload"
              type="file"
              accept={acceptedExtensionsList}
              multiple={multiple_files}
              onChange={handleFileInputChange}
              disabled={disabled}
            />
            <img
              className={`s-upload-card-v2__label-img ${
                disabled && "s-upload-card-v2--disabled"
              }`}
              src={icon}
              alt=""
            />
            <div className="s-upload-card-v2__text-cont">
              <p
                className={`s-upload-card-v2__text ${
                  disabled && "s-upload-card-v2--disabled"
                }`}
              >
                <span
                  className={`s-upload-card-v2__label-text ${
                    disabled && "s-upload-card-v2--disabled"
                  }`}
                >
                  {primary_text}
                </span>
                or drag and drop here
              </p>
              {sub_title && (
                <p className="s-upload-card-v2__secondary-text">{sub_title}</p>
              )}
            </div>
          </section>
        </label>
      )}
      {showUploadedFiles && (
        <div
          className={`s-upload-card-v2__list ${
            singleUploadAllowed ? "s-upload-card-v2__list--one" : ""
          }`}
        >
          {files?.map((item_files, mainIdx) => {
            return item_files?.map((item, idx) => {
              return (
                <div
                  className={`s-upload-list-v2-${
                    item?.zip_file_parent ? "zip" : ""
                  } ${
                    item?.zip_file_parent && idx !== item_files.length - 1
                      ? "s-upload-list-v2-left"
                      : ""
                  }`}
                  key={idx}
                  style={{
                    paddingTop:
                      mainIdx === 0 && idx === 0 && !singleUploadAllowed
                        ? "15px"
                        : "0px",
                  }}
                >
                  {item?.zip_file_parent && idx === item_files.length - 1 && (
                    <div className="s-upload-list-v2-line-vertical" />
                  )}
                  {item?.zip_file_parent && (
                    <div className="s-upload-list-v2-line-horizontal" />
                  )}
                  <UploadedFile
                    file={item}
                    removeFile={removeFileFrontend}
                    onDocAction={onDocAction}
                  />
                </div>
              );
            });
          })}
        </div>
      )}
    </div>
  );
}
