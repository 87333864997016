import React from "react";

const UploadDocIcon = (props) => {
  const { color = "#000000" } = props;

  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6022 18.6668L4.19472 21L0.910524 5.58214C0.582105 4.04035 1.8112 3.38673 2.46681 3.25264L9.54731 1.8045L14.302 4.85443L16.8472 16.8033C17.11 18.0367 16.1267 18.5596 15.6022 18.6668Z"
        stroke={color}
        stroke-linecap="round"
      />
      <path
        d="M12.4389 20.3423V1.46912H7.51174L6.16797 2.78585V20.3423H12.4389Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M12.4375 20.3423V1.46912H15.573L20.0522 5.85822V20.3423H12.4375Z"
        fill="white"
      />
      <path
        d="M16.0195 1.03015V4.22223C16.0195 4.62124 16.2639 5.41926 17.2411 5.41926C18.2184 5.41926 19.8201 5.41926 20.4988 5.41926"
        stroke={color}
        stroke-linecap="round"
      />
      <path
        d="M18.7086 20.7811H5.71875V3.22471C5.71875 1.46906 7.21183 1.03015 7.95837 1.03015H16.021L20.5003 5.41926V19.0255C20.5003 20.43 19.3058 20.7811 18.7086 20.7811Z"
        stroke={color}
        stroke-linecap="round"
      />
      <path
        d="M17.3903 11.4437L12.9452 6.99854M12.9452 6.99854L8.5 11.4437M12.9452 6.99854V17.0002"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.3903 11.4437L12.9452 6.99854L8.5 11.4437"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default UploadDocIcon;
