import React from 'react'

const ProfileDetails = (props) => {
  const { color = "#000000" } = props;

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="10.5" stroke={color} />
      <path
        d="M14.6724 5.86355C14.6724 7.79022 13.1105 9.3521 11.1839 9.3521C9.25719 9.3521 7.69531 7.79022 7.69531 5.86355C7.69531 3.93688 9.25719 2.375 11.1839 2.375C13.1105 2.375 14.6724 3.93688 14.6724 5.86355Z"
        stroke={color}
        stroke-width="0.75"
      />
      <rect
        x="5.375"
        y="10.1025"
        width="11.614"
        height="8.52251"
        rx="2.625"
        stroke={color}
        stroke-width="0.75"
      />
    </svg>
  );
}

export default ProfileDetails
