// react imports
import React from "react";

// in-app imports
import blunoLogo from "../../assets/media/common/Bluno-Logo.png";

export default function Static({ data }) {
  return (
    <div className="o-static">
      <div className="o-static-content">
        <div>
          <img src={blunoLogo} alt="" className="o-static-logo" />
        </div>
        <div className="o-static__security">
          <img
            src={data?.image_object?.hero_img}
            alt=""
            className="o-static__security-ilu"
          />
          <div className="o-static__security-content">
            <img src={data?.image_object?.icon} alt="" />
            <p className="o-static__security-text ">
              {data?.image_object?.text}
            </p>
          </div>
        </div>
      </div>
      <div className="o-static__powered">
        <h2 className="o-static__powered-title">{data?.powered_by?.title}</h2>
        <div className="o-static__powered-content">
          <img
            src={data?.powered_by?.image}
            alt=""
            className="o-static__powered-icon"
          />
        </div>
      </div>
    </div>
  );
}
