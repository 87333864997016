//react imports
import React, { useEffect, useState } from "react";

//in app imports
import CopyToClipboard from "../../components/CopyToClipboard";
import BlunoTooltip from "../../components/BlunoTooltip";

const BankDetailsCard = ({ data }) => {
  const [currentHover, setCurrentHover] = useState(null);
  const renderViewOnlyFields = (theItem) => {
    return (
      <>
        {theItem?.view_only?.value && (
          <div
            className={`g-settings__fields-item ${
              theItem?.view_only?.slug === "bank_branch"
                ? "b-settings__fields--branch"
                : ""
            }`}
            key={theItem?.view_only?.slug}
            style={{ gridColumn: theItem?.colspan }}
          >
            <p className="g-settings__fields-label">
              {theItem?.view_only?.label || theItem?.view_only?.info_text}
            </p>
            <p
              className="g-settings__fields-value"
              onMouseEnter={() => {
                setCurrentHover(theItem?.view_only?.slug);
              }}
              onMouseLeave={() => {
                setCurrentHover(null);
              }}
            >
              <span>{theItem?.view_only?.value}</span>
              {theItem?.view_only?.enable_copy &&
                currentHover === theItem?.view_only?.slug && (
                  <div style={{ marginLeft: "-10px" }}>
                    <CopyToClipboard text={theItem?.view_only?.value} />
                  </div>
                )}
              {theItem?.view_only?.icon && (
                <img
                  className="g-settings__fields-status"
                  src={theItem?.view_only?.icon}
                  alt="status"
                />
              )}
            </p>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="g-settings__cont">
      <p className="g-settings__title">
        {data?.bank_name}
      </p>
      <div className="g-settings__card">
        <div className="b-settings__left">
          {data?.bank_icon && (
            <img src={data?.bank_icon} alt={data?.bank_name} />
          )}
        </div>
        <div className="g-settings__right">
          <div
            className="g-settings__fields"
            style={{ gridTemplateColumns: data?.grid_template_columns }}
          >
            <>
              {data?.fields?.map((item) => {
                return item?.is_editable ? (
                  <>Edit View</>
                ) : (
                  renderViewOnlyFields(item)
                );
              })}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetailsCard;
