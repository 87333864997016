// react imports
import React, { useState } from "react";

// third-party imports
import { useDispatch } from "react-redux";
import endpoints from "../../api/endpoints";
import { apiRequest } from "../../redux/common/actions";

// in-app imports
import Button from "../../components/Button";
import BlunoRadio from "../../components/BlunoRadio";
import { sanitizeHtml } from "../../utils/sanitizeHtml";
import { DGFT_PLATFORM_SLUGS } from "../../utils/constants/SettingsConstants";
import { DGFT_LINK_STATUS } from "../../utils/constants/DgftConstants";
import CopyToClipboard from "../../components/CopyToClipboard";
import BlunoBanner from "../../components/blunoBanners/BlunoBanner";

const DgftPartner = ({
  data,
  setShowModal,
  showConfirmationCard = false,
  setShowConfirmationCard,
  dgftLinkStatus = null,
  setDgftLinkStatus,
  showDgftGuide = false,
  setShowDgftGuide,
  submitValues = null,
  setSubmitValues,
}) => {
  const [showReqCallbackBtn, setShowReqCallbackBtn] = useState(true);
  const [showNeedAssistBtn, setShowNeedAssistBtn] = useState(true);
  const [showNeedAssistBtn2, setShowNeedAssistBtn2] = useState(true);
  const dispatch = useDispatch();

  let videoIndex = 0;

  const registerDgftCredential = async (theStatus) => {
    const apiRes = await dispatch(
      apiRequest(endpoints.registerDgftCred, {
        status: theStatus,
      })
    );
    if (apiRes?.success && apiRes?.data) {
      setDgftLinkStatus(theStatus);
      setShowConfirmationCard(false);
    }
  };

  const handleNeedAssistanceRequest = async (theSlug) => {
    const apiRes = await dispatch(
      apiRequest(endpoints.handleNeedAssistanceRequest)
    );
    if (apiRes?.success && apiRes?.data) {
      if (theSlug === DGFT_PLATFORM_SLUGS?.requestCallback) {
        setShowReqCallbackBtn(false);
      }
      if (theSlug === DGFT_PLATFORM_SLUGS?.needAssistance) {
        setShowNeedAssistBtn(false);
      }
      if (theSlug == DGFT_PLATFORM_SLUGS?.needAssistance2) {
        setShowNeedAssistBtn2(false);
      }
    }
  };

  const onActionClick = (action) => {
    switch (action?.slug) {
      case "securely_connect":
        setShowModal(true);
        break;
      case DGFT_PLATFORM_SLUGS?.showDgftGuideButton:
        setShowDgftGuide(true);
        break;
      case DGFT_PLATFORM_SLUGS?.yes:
        registerDgftCredential(DGFT_LINK_STATUS?.IN_PROGRESS);
        break;
      case DGFT_PLATFORM_SLUGS?.requestCallback:
      case DGFT_PLATFORM_SLUGS?.needAssistance:
      case DGFT_PLATFORM_SLUGS?.needAssistance2:
        handleNeedAssistanceRequest(action?.slug);
        break;
    }
  };

  const isNextButtonDisabled =
    !submitValues?.[DGFT_PLATFORM_SLUGS?.registeredDgftAccountRadio] ||
    (submitValues?.[DGFT_PLATFORM_SLUGS?.registeredDgftAccountRadio] ===
      "yes" &&
      !submitValues?.[DGFT_PLATFORM_SLUGS?.iecDgftLinkedRadio]);

  const getVisibleVideos = () => {
    const firstOption =
      submitValues?.[DGFT_PLATFORM_SLUGS?.registeredDgftAccountRadio];
    const secondOption =
      submitValues?.[DGFT_PLATFORM_SLUGS?.iecDgftLinkedRadio];

    if (firstOption === "no") return [0, 1, 2];
    if (firstOption === "yes" && secondOption === "no") return [1, 2];
    if (firstOption === "yes" && secondOption === "yes") return [2];
    return [];
  };

  const handleInputChange = (theValue, theSlug) => {
    setSubmitValues((prev) => {
      if (theValue === "") {
        const { [theSlug]: _, ...rest } = prev;
        return rest;
      } else {
        return {
          ...submitValues,
          [theSlug]: theValue,
        };
      }
    });
    setShowDgftGuide(false);
  };

  const statustext = () => {
    switch (dgftLinkStatus) {
      case DGFT_LINK_STATUS?.ACTIVE:
        return { text: "Active", status: dgftLinkStatus };
      case DGFT_LINK_STATUS?.INACTIVE:
        return { text: "Inactive", status: dgftLinkStatus };
      case DGFT_LINK_STATUS?.IN_PROGRESS:
        return { text: "Under Review", status: dgftLinkStatus };
    }
  };

  const renderUiComponent = (item, index) => {
    switch (item?.type) {
      case "radio":
        let showRadioOption =
          item?.slug === DGFT_PLATFORM_SLUGS?.iecDgftLinkedRadio
            ? submitValues?.[
                DGFT_PLATFORM_SLUGS?.registeredDgftAccountRadio
              ] === "yes"
            : true;
        return (
          showRadioOption && (
            <>
              <BlunoRadio
                options={item?.options}
                name={item?.name}
                title={item?.header}
                value={submitValues?.[item?.slug]}
                onChange={(e) => {
                  handleInputChange(e.target.value, item?.slug);
                }}
                orientationTitle="horizontal"
                extraClassTitle="dgft-partner__iec-inputs-header"
                // extraClass="request-feature__feature"
                extraClassRadioGroup="dgft-partner__iec-inputs-radio"
                radioGap="24px"
              />
            </>
          )
        );
    }
  };
  return (
    <>
      <div className="dgft-partner">
        <BlunoBanner data={data?.banner_data?.dgft_link_banner} />

        {showConfirmationCard && data?.confirmation_card && (
          <div className="dgft-partner__confirmation">
            <div className="dgft-partner__confirmation-content">
              <p className="dgft-partner__confirmation-title">
                {data?.confirmation_card?.title}
              </p>
              <p className="dgft-partner__confirmation-text">
                {data?.confirmation_card?.text}
              </p>
            </div>

            <div className="dgft-partner__buttons">
              {data?.confirmation_card?.buttons?.map((action) => {
                if (
                  action?.slug === DGFT_PLATFORM_SLUGS?.needAssistance2 &&
                  showNeedAssistBtn2
                ) {
                  return (
                    action?.type === "button" && (
                      <Button
                        buttonLabel={action?.text}
                        buttonType={action?.sub_type}
                        icon={action.icon}
                        disabled={action?.disabled}
                        iconAlignment={action?.icon_alignment}
                        onClick={() => onActionClick(action)}
                        // iconExtraClass="bluno-banner__buttons-icon"
                      />
                    )
                  );
                } else if (
                  action?.slug !== DGFT_PLATFORM_SLUGS?.needAssistance2
                ) {
                  return (
                    action?.type === "button" && (
                      <Button
                        buttonLabel={action?.text}
                        buttonType={action?.sub_type}
                        icon={action.icon}
                        disabled={action?.disabled}
                        iconAlignment={action?.icon_alignment}
                        onClick={() => onActionClick(action)}
                        // iconExtraClass="bluno-banner__buttons-icon"
                      />
                    )
                  );
                }
              })}
              {!showNeedAssistBtn2 && (
                <div className="dgft-partner__assistance">
                  <p>Assistance requested</p>
                  <img src="https://static.bluno.in/miscl/green_tick_20_icon.png" />
                </div>
              )}
            </div>
          </div>
        )}
        {dgftLinkStatus &&
          dgftLinkStatus !== DGFT_LINK_STATUS?.INACTIVE &&
          data?.status_card && (
            <div className="dgft-partner__status">
              <div className="dgft-partner__status-header">
                <p className="dgft-partner__status-title">
                  {data?.status_card?.title}
                </p>
                <div className="dgft-partner__status-box">
                  <p
                    className={`dgft-partner__status-status dgft-partner__status-status--${
                      statustext().status
                    }`}
                  >
                    {statustext().text}
                  </p>
                  <p className="dgft-partner__status-text">
                    {data?.status_card?.text}
                  </p>
                </div>
              </div>
            </div>
          )}

        {dgftLinkStatus === DGFT_LINK_STATUS?.INACTIVE && (
          <div className="dgft-partner__iec">
            <p
              className="dgft-partner__iec-title"
              dangerouslySetInnerHTML={sanitizeHtml(
                data?.iec_connect_card?.title
              )}
            />
            <div className="dgft-partner__iec-inputs">
              {data?.iec_connect_card?.inputs?.map((item, index) => {
                return (
                  <div className="dgft-partner__iec-inputs">
                    {renderUiComponent(item, index)}
                  </div>
                );
              })}
            </div>
            <div className="dgft-partner__buttons">
              {!showDgftGuide &&
                data?.iec_connect_card?.buttons?.map((action) => {
                  return (
                    action?.type === "button" && (
                      <Button
                        buttonLabel={action?.text}
                        buttonType={action?.sub_type}
                        icon={action.icon}
                        disabled={isNextButtonDisabled}
                        iconAlignment={action?.icon_alignment}
                        onClick={() => onActionClick(action)}
                      />
                    )
                  );
                })}
            </div>
          </div>
        )}

        {dgftLinkStatus === DGFT_LINK_STATUS?.INACTIVE &&
          showDgftGuide &&
          data?.guide_data && (
            <div className="dgft-partner__guide">
              <div className="dgft-partner__guide-title">
                <img src={data?.guide_data?.icon} alt="dgft connect steps" />
                <p>{data?.guide_data?.title}</p>
              </div>

              <div className="dgft-partner__guide-header">
                <p className="dgft-partner__guide-text">
                  {data?.guide_data?.text}
                </p>
                <>
                  {data?.guide_data?.call_back_button?.map((action) => {
                    if (
                      action?.slug === DGFT_PLATFORM_SLUGS?.requestCallback &&
                      showReqCallbackBtn
                    ) {
                      return (
                        action?.type === "button" && (
                          <Button
                            buttonLabel={action?.text}
                            buttonType={action?.sub_type}
                            icon={action.icon}
                            disabled={action?.disabled}
                            iconAlignment={action?.icon_alignment}
                            onClick={() => onActionClick(action)}
                            // iconExtraClass="bluno-banner__buttons-icon"
                          />
                        )
                      );
                    } else if (
                      action?.slug !== DGFT_PLATFORM_SLUGS?.requestCallback
                    ) {
                      return (
                        action?.type === "button" && (
                          <Button
                            buttonLabel={action?.text}
                            buttonType={action?.sub_type}
                            icon={action.icon}
                            disabled={action?.disabled}
                            iconAlignment={action?.icon_alignment}
                            onClick={() => onActionClick(action)}
                            // iconExtraClass="bluno-banner__buttons-icon"
                          />
                        )
                      );
                    }
                  })}
                  {!showReqCallbackBtn && (
                    <div className="dgft-partner__assistance">
                      <p>Assistance requested</p>
                      <img src="https://static.bluno.in/miscl/green_tick_20_icon.png" />
                    </div>
                  )}
                </>
              </div>
              <div className="dgft-partner__guide-list">
                {data?.guide_data?.guide_steps?.map((item, index) => {
                  let visibleVideosList = getVisibleVideos();
                  let showVideo = visibleVideosList?.includes(index);
                  videoIndex = showVideo ? videoIndex + 1 : videoIndex;

                  return (
                    showVideo && (
                      <div
                        className={`dgft-partner__guide-item ${
                          videoIndex !== visibleVideosList?.length &&
                          "dgft-partner__guide-item--border"
                        }`}
                      >
                        <p>
                          <span>{`Step ${videoIndex}/${visibleVideosList?.length} :`}</span>
                          {item?.text}
                        </p>

                        {/* <video
                          src="https://videos.pexels.com/video-files/7565438/7565438-sd_360_640_25fps.mp4"
                          style={{
                            width: "362px",
                            height: "215px",
                            objectFit: "cover",
                          }}
                          autoPlay={true}
                        /> */}
                        <iframe
                          width="400"
                          height="250"
                          src={item?.video_link}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                          referrerpolicy="strict-origin-when-cross-origin"
                          allowfullscreen
                        ></iframe>
                      </div>
                    )
                  );
                })}
              </div>
              <div className="dgft-partner__buttons">
                {data?.guide_data?.buttons?.map((action) => {
                  if (
                    action?.slug === DGFT_PLATFORM_SLUGS?.needAssistance &&
                    showNeedAssistBtn
                  ) {
                    return (
                      action?.type === "button" && (
                        <Button
                          buttonLabel={action?.text}
                          buttonType={action?.sub_type}
                          icon={action.icon}
                          disabled={action?.disabled}
                          iconAlignment={action?.icon_alignment}
                          onClick={() => onActionClick(action)}
                          // iconExtraClass="bluno-banner__buttons-icon"
                        />
                      )
                    );
                  } else if (
                    action?.slug !== DGFT_PLATFORM_SLUGS?.needAssistance
                  ) {
                    return (
                      action?.type === "button" && (
                        <Button
                          buttonLabel={action?.text}
                          buttonType={action?.sub_type}
                          icon={action.icon}
                          disabled={action?.disabled}
                          iconAlignment={action?.icon_alignment}
                          onClick={() => onActionClick(action)}
                          // iconExtraClass="bluno-banner__buttons-icon"
                        />
                      )
                    );
                  }
                })}
                {!showNeedAssistBtn && (
                  <div className="dgft-partner__assistance">
                    <p>Assistance requested</p>
                    <img src="https://static.bluno.in/miscl/green_tick_20_icon.png" />
                  </div>
                )}
              </div>
            </div>
          )}
        <div className="dgft-partner__email">
          <div className="dgft-partner__email-header">
            <p className="dgft-partner__email-title">
              {data?.linked_account_card?.title}
            </p>
            <p className="dgft-partner__email-email">
              {data?.linked_account_card?.status}
              <CopyToClipboard
                text={data?.linked_account_card?.status}
                textPosition={"above"}
              />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DgftPartner;
