import React, { useEffect, useState } from "react";
import Button from "../Button";
import { DGFT_PLATFORM_SLUGS } from "../../utils/constants/SettingsConstants";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import BlunoTooltip from "../BlunoTooltip";
import UploadFileV2 from "../UploadFileV2/UploadFileV2";
import { downloadDocumentUtility } from "../../utils/downloadDocument";

const BlunoPendingDocsBanner = ({ data, getHomeData }) => {
  // redux state variables
  const currentStage = useSelector((state) => state.decisionTask.currentStage);
  const [files, setFiles] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showNeedAssistBtn, setShowNeedAssistBtn] = useState(true);
  const [showRequestCallbackBtn, setShowRequestCallbackBtn] = useState(true);

  const handleNeedAssistanceRequest = async (theSlug) => {
    const apiRes = await dispatch(
      apiRequest(endpoints.handleNeedAssistanceRequest)
    );
    if (apiRes?.success && apiRes?.data) {
      // setShowNeedAssistBtn(false);

      if (theSlug === DGFT_PLATFORM_SLUGS?.requestCallback2) {
        setShowRequestCallbackBtn(false);
      }
      if (theSlug === DGFT_PLATFORM_SLUGS?.needAssistance) {
        setShowNeedAssistBtn(false);
      }
    }
  };

  useEffect(() => {
    getHomeData();
  }, [files]);

  // const submitPendingDocs = async () => {
  //   const submitTaskRes = await dispatch(
  //     apiRequest(
  //       endpoints.submitOnboardingTask,
  //       null,
  //       `?application_id=${currentStage?.applicationId}&task=${
  //         currentStage.currentTask
  //       }${
  //         currentStage?.currentSubTask
  //           ? `&sub_task=${currentStage?.currentSubTask}`
  //           : ""
  //       }`
  //     )
  //   );
  //   if (submitTaskRes?.success) {
  //     getHomeData();
  //   }
  // };

  const onActionClick = (action) => {
    switch (action?.slug) {
      case "submit_kyc_docs":
        getHomeData();
        break;
      case DGFT_PLATFORM_SLUGS?.needAssistance:
      case DGFT_PLATFORM_SLUGS?.requestCallback2:
        handleNeedAssistanceRequest(action?.slug);
        break;
    }
  };

  return (
    <>
      <div className="doc-banner">
        <div className="doc-banner__middle">
          <div className="doc-banner__header">
            <h2 className="doc-banner__title">{data?.header}</h2>
            {data?.header_buttons && (
              <div className="doc-banner__header-buttons">
                {data?.header_buttons?.map((action) => {
                  if (
                    action?.slug === DGFT_PLATFORM_SLUGS?.needAssistance &&
                    showNeedAssistBtn
                  ) {
                    return (
                      action?.type === "button" && (
                        <Button
                          buttonLabel={action?.text}
                          buttonType={action?.sub_type}
                          icon={action.icon}
                          disabled={action?.disabled}
                          iconAlignment={action?.icon_alignment}
                          onClick={() => onActionClick(action)}
                        />
                      )
                    );
                  } else if (
                    action?.slug !== DGFT_PLATFORM_SLUGS?.needAssistance
                  ) {
                    return (
                      action?.type === "button" && (
                        <Button
                          buttonLabel={action?.text}
                          buttonType={action?.sub_type}
                          icon={action.icon}
                          disabled={action?.disabled}
                          iconAlignment={action?.icon_alignment}
                          onClick={() => onActionClick(action)}
                        />
                      )
                    );
                  }
                })}
                {!showNeedAssistBtn && (
                  <div className="dgft-partner__assistance">
                    <p>Assistance requested</p>
                    <img src="https://static.bluno.in/miscl/green_tick_20_icon.png" />
                  </div>
                )}
              </div>
            )}
          </div>
          <p className="doc-banner__message">
            {data?.message}
            <img
              className="doc-banner__card-icon"
              src={data?.card_icon}
              alt=""
            />
          </p>
          <p className="doc-banner__text">{data?.text}</p>
          <div className="doc-banner__docs">
            {data?.documents && (
              <div className="o-completed__list" style={{ width: "100%" }}>
                {data?.documents?.map((item, idx) => {
                  return (
                    <div
                      className={`o-completed__document ${
                        idx !== data?.documents?.length - 1 &&
                        "o-completed__document--underline"
                      }`}
                      style={{ justifyContent: "flex-start" }}
                    >
                      <p
                        className="o-completed__document-title"
                        style={{
                          flexGrow: "0",
                          maxWidth: "223px",
                          minWidth: "223px",
                        }}
                      >
                        <span>{item?.title?.title}</span>
                        <BlunoTooltip
                          icon={item?.title?.back_info?.icon}
                          text={item?.title?.back_info?.hover_text}
                          iconWidth="16px"
                          iconHeight="16px"
                          top="-50px"
                          width="400px"
                          height="45px"
                        />
                      </p>
                      <div
                        className="o-completed__document-content"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <UploadFileV2
                          upload_enabled={item?.document_info?.upload_enabled}
                          multiple_files={item?.document_info?.multiple_files}
                          icon={item?.document_info?.icon}
                          primary_text={item?.document_info?.primary_text}
                          extraClass="o-completed__document-box-v2"
                          flow={"onboarding"}
                          slug={item?.document_info?.slug}
                          onFileChange={setFiles}
                          allowed_doc_types={
                            item?.document_info?.allowed_doc_types
                          }
                          uploaded_documents={
                            item?.document_info?.uploaded_documents
                          }
                          extraMetadata={{
                            application_id: currentStage?.applicationId,
                            task: currentStage?.currentTask,
                            sub_task: item?.document_info?.sub_title,
                          }}
                          getApi={getHomeData}
                          callGetApi={true}
                          singleUploadAllowed={true}
                        />

                        <Button
                          buttonType={item?.sample?.sub_type}
                          buttonLabel={item?.sample?.text}
                          icon={item?.sample?.icon}
                          iconAlignment={item?.sample?.icon_alignment}
                          // iconExtraClass="o-completed__blueicon"
                          // iconParDiv={true}
                          // iconParDivClass="o-completed__blueicon-par"
                          extraClass="o-completed__hyperlink"
                          textWidth="max-content"
                          onClick={() => {
                            downloadDocumentUtility(
                              item?.sample?.deeplink,
                              item?.sample?.slug
                            );
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="doc-banner__buttons">
              {data?.buttons?.map((action) => {
                if (
                  action?.slug === DGFT_PLATFORM_SLUGS?.requestCallback2 &&
                  showRequestCallbackBtn
                ) {
                  return (
                    action?.type === "button" && (
                      <Button
                        buttonLabel={action?.text}
                        buttonType={action?.sub_type}
                        icon={action.icon}
                        disabled={action?.disabled}
                        iconAlignment={action?.icon_alignment}
                        onClick={() => onActionClick(action)}
                        tooltipText={action?.tooltip_text}
                        tooltipPosition={{ bottom: "40px", left: "50px" }}
                      />
                    )
                  );
                } else if (
                  action?.slug !== DGFT_PLATFORM_SLUGS?.requestCallback2
                ) {
                  return (
                    action?.type === "button" && (
                      <Button
                        buttonLabel={action?.text}
                        buttonType={action?.sub_type}
                        icon={action.icon}
                        disabled={action?.disabled}
                        iconAlignment={action?.icon_alignment}
                        onClick={() => onActionClick(action)}
                        tooltipText={action?.tooltip_text}
                        tooltipPosition={{ bottom: "40px", left: "50px" }}
                      />
                    )
                  );
                }
              })}
            </div>
          </div>
          <div className="doc-banner__footer">
            <p className="doc-banner__footer-text">{data?.footer_text}</p>
            {data?.footer_buttons?.map((action) => {
              if (
                action?.slug === DGFT_PLATFORM_SLUGS?.requestCallback2 &&
                showRequestCallbackBtn
              ) {
                return (
                  action?.type === "button" && (
                    <Button
                      buttonLabel={action?.text}
                      buttonType={action?.sub_type}
                      icon={action.icon}
                      disabled={action?.disabled}
                      iconAlignment={action?.icon_alignment}
                      onClick={() => onActionClick(action)}
                    />
                  )
                );
              } else if (
                action?.slug !== DGFT_PLATFORM_SLUGS?.requestCallback2
              ) {
                return (
                  action?.type === "button" && (
                    <Button
                      buttonLabel={action?.text}
                      buttonType={action?.sub_type}
                      icon={action.icon}
                      disabled={action?.disabled}
                      iconAlignment={action?.icon_alignment}
                      onClick={() => onActionClick(action)}
                    />
                  )
                );
              }
            })}
            {!showRequestCallbackBtn && (
              <div className="dgft-partner__assistance">
                <p>Assistance requested</p>
                <img src="https://static.bluno.in/miscl/green_tick_20_icon.png" />
              </div>
            )}
          </div>
        </div>
        <div className="doc-banner__right">
          <img src={data?.balloon_icon} alt="" />
        </div>
      </div>
    </>
  );
};

export default BlunoPendingDocsBanner;
