// react imports
import React, { useEffect, useState } from "react";

// third-party imports
import { Checkbox, FormControlLabel } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// in-app imports
import TextInput from "../../components/TextInput";
import BlunoSwitch from "../../components/BlunoSwitch";
import Button from "../../components/Button";
import {
  BlockerAlert,
  getBlockerAlertBySection,
} from "../../app/constants/BlockerAlert";
import useCallbackPrompt from "../../hooks/useCallBackPrompt";
import BlunoTooltip from "../../components/BlunoTooltip";
import { InputError } from "../../app/constants/InputError";
import ChangePasswordPopup from "../../components/settings/security/ChangePasswordPopup";
import ModalV2 from "../../components/ModalV2";
import MuiTextInput from "../../components/blunoInputs/MuiTextInputs";
import Dropdown from "../../components/Dropdown";

const SecurityCard = ({
  data,
  editObject = null,
  setEditObject,
  setMfaEditObject,
  displayObject = null,
  setDisplayObject,
  saveLoginDetails,
  handleButtonClick,
  isInputTypePassword,
  setIsInputTypePassword,
  errorMessagesObject,
  setErrorMessagesObject,
  isBlockerActive,
  setIsBlockerActive,
  showModal,
  setShowModal,
}) => {
  // local states
  const [isToggleActive, setIsToggleActive] = useState();
  const [blockerAlertData, setBlockerAlertData] = useState(
    BlockerAlert?.SECURITY_SETTINGS
  );
  // custom hooks variables
  useCallbackPrompt(isBlockerActive, blockerAlertData); // Navigation blocker hook

  const getPopupInputElement = (item) => {
    switch (item?.type) {
      case "text_box":
        return (
          <>
            <div className="o-login__input">
              <MuiTextInput
                placeholder={item?.placeholder}
                type={isInputTypePassword?.[item?.slug] ? "password" : "text"}
                disableCopyPaste={true}
                height="40px"
                value={displayObject?.[item?.slug]}
                minWidth="220px"
                iconAlignment="right"
                id={item?.slug}
                onChange={(e) => {
                  handleInputChange(e.target.value, item?.slug);
                }}
                icon={
                  displayObject?.[item?.slug] &&
                  (isInputTypePassword?.[item?.slug]
                    ? item?.icon
                    : item?.secondary_icon)
                }
                isIconClickable={true}
                onIconClick={() => {
                  setIsInputTypePassword((prevState) => {
                    return {
                      ...prevState,
                      [item?.slug]: !prevState?.[item?.slug],
                    };
                  });
                }}
                // onKeyDown={(e) => handleKeyDown(e.key, item?.slug, e)}
                disabled={item?.disabled}
                fixedText={item?.fixed_text}
                paddingLeft={item?.left_padding}
                fixedTextLeftStyle={"20px"}
                fontSize="13px"
                subtext={item?.subtext}
                errorMessage={errorMessagesObject?.[item?.slug]}
              />
            </div>
          </>
        );
      // case "dropdown":
      //   return (
      //     <>
      //       <Dropdown
      //         options={item?.options}
      //         height="35px"
      //         placeholderFontSize="13px"
      //         fontSize="13px"
      //         fontWeight="600"
      //         minWidth="220px"
      //         onSelectChange={(e) => {
      //           setUserData((prev) => ({ ...prev, [item?.slug]: e.value }));
      //         }}
      //         fancyPlaceholder={true}
      //         topSpacing="-8px"
      //         iconWidth="10px"
      //         useValue={true}
      //         placeholder={item?.placeholder}
      //         errorMessage={errorMessage?.[item?.slug]}
      //         errorMessageFontSize="11px"
      //       />
      //     </>
      //   );
      default:
        break;
    }
  };

  const handleToggleChange = (inputSlug, inputValue) => {
    setIsToggleActive(inputValue);
    setMfaEditObject((prev) => {
      const mfaObj = {
        ...prev,
        [inputSlug]: inputValue,
      };
      saveLoginDetails(mfaObj, "mfa_action");

      return mfaObj;
    });
  };

  const renderViewOnlyFields = (theItem) => {
    return (
      <>
        {theItem?.view_only?.value && (
          <div
            className={`g-settings__fields-item`}
            key={theItem?.view_only?.slug}
            style={{ gridColumn: theItem?.colspan }}
          >
            <p className="g-settings__fields-label">
              {theItem?.view_only?.label || theItem?.view_only?.info_text}
              {theItem?.view_only?.label_edit_icon && (
                <BlunoTooltip
                  useChildren={true}
                  text={theItem?.view_only?.label_edit_icon_tooltip}
                >
                  <div
                    className="g-settings__fields-edit"
                    onClick={() => {
                      handleButtonClick(theItem?.view_only?.slug);
                    }}
                  >
                    <img
                      src={theItem?.view_only?.label_edit_icon}
                      className="g-settings__fields-edit-img"
                    />
                  </div>
                </BlunoTooltip>
              )}
            </p>
            <p className="g-settings__fields-value">
              <span>{theItem?.view_only?.value}</span>
            </p>
          </div>
        )}
      </>
    );
  };

  const renderEditOnlyFields = (theItem) => {
    const inputType = theItem?.edit_only.type;
    switch (inputType) {
      case "button":
        return (
          <div className="sec-settings__login-details-button">
            <Button
              buttonLabel={theItem?.edit_only?.text}
              buttonType={theItem?.edit_only?.sub_type}
              icon={theItem?.edit_only.icon}
              disabled={theItem?.edit_only?.disabled}
              iconAlignment={theItem?.edit_only?.icon_alignment}
              onClick={() => handleButtonClick(theItem?.edit_only?.slug)}
              extraClass="sec-settings__login-details-button"
            />
          </div>
        );
      case "text_box":
        return (
          <div
            className="g-settings__fields-item"
            key={theItem?.edit_only?.slug}
            style={{ gridColumn: theItem?.colspan }}
          >
            <p className="g-settings__fields-label">
              {theItem?.edit_only?.title}
            </p>
            <div className="g-settings__fields-input">
              <TextInput
                placeholder={theItem?.edit_only?.placeholder}
                type={
                  isInputTypePassword?.[theItem?.edit_only?.slug]
                    ? "password"
                    : "text"
                }
                disableCopyPaste={true}
                height="30px"
                value={displayObject?.[theItem?.edit_only?.slug]}
                onChange={(e) => {
                  handleInputChange(e.target.value, theItem?.edit_only?.slug);
                }}
                icon={
                  displayObject?.[theItem?.edit_only?.slug] &&
                  (isInputTypePassword?.[theItem?.edit_only?.slug]
                    ? theItem?.edit_only?.icon
                    : theItem?.edit_only?.secondary_icon)
                }
                isIconClickable={true}
                onIconClick={() => {
                  setIsInputTypePassword((prevState) => {
                    return {
                      ...prevState,
                      [theItem?.edit_only?.slug]:
                        !prevState?.[theItem?.edit_only?.slug],
                    };
                  });
                }}
                iconAlignment="right"
                errorMessage={errorMessagesObject?.[theItem?.edit_only?.slug]}
              />
            </div>
          </div>
        );
      case "checkbox_list":
        return isToggleActive ? (
          <div
            className="g-settings__fields-item"
            key={theItem?.edit_only?.slug}
            style={{ gridColumn: theItem?.colspan }}
          >
            <p className="g-settings__fields-label">
              {theItem?.edit_only?.label}
            </p>
            <p className="g-settings__fields-value sec-settings__2fa">
              {theItem?.edit_only?.check_list?.map((item) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checkedIcon={<CheckBoxIcon />}
                        onChange={(e) => {
                          handleMFAChange(e.target.checked, item?.slug);
                        }}
                        checked={displayObject?.[item?.slug]}
                        style={{
                          padding: 0,
                        }}
                      />
                    }
                    disabled={item?.disabled}
                    label={
                      <p className="sec-settings__2fa--label">{item?.text}</p>
                    }
                    style={{ marginLeft: 0 }}
                  />
                );
              })}
            </p>
          </div>
        ) : (
          <div></div>
        );
      case "toggle":
        return (
          <div
            className="g-settings__fields-item"
            key={theItem?.edit_only?.slug}
            style={{ gridColumn: theItem?.colspan }}
          >
            <p className="g-settings__fields-label">
              {theItem?.edit_only?.title}
            </p>
            <p className="g-settings__fields-value">
              <BlunoSwitch
                isToggleActive={!!isToggleActive}
                handleToggleChange={(e) => {
                  handleToggleChange(
                    theItem?.edit_only?.slug,
                    e.target.checked
                  );
                }}
                onLabel={theItem?.edit_only?.on_label}
                offLabel={theItem?.edit_only?.off_label}
              />
            </p>
          </div>
        );
      default:
        return <></>;
    }
  };

  const handleMFAChange = (inputValue, inputSlug) => {
    setErrorMessagesObject({});
    setDisplayObject({
      ...displayObject,
      [inputSlug]: inputValue,
    });
    setMfaEditObject((prev) => {
      const mfaObj = {
        ...prev,
        [inputSlug]: inputValue,
      };
      saveLoginDetails(mfaObj, "mfa_action");
      return mfaObj;
    });
  };

  const handleInputChange = (inputValue, inputSlug) => {
    setIsBlockerActive(true);
    setBlockerAlertData(BlockerAlert?.SECURITY_SETTINGS);

    if (inputValue?.length > 20) {
      setErrorMessagesObject({
        [inputSlug]: InputError?.TooLongPassword?.message,
      });
      return;
    }
    setErrorMessagesObject({});
    setEditObject({
      ...editObject,
      [inputSlug]: inputValue,
    });
    setDisplayObject({
      ...displayObject,
      [inputSlug]: inputValue,
    });
  };

  useEffect(() => {
    const tempObject = {};
    const tempEditObject = {};
    data?.left_fields?.forEach((item) => {
      if (item?.is_editable) {
        tempObject[item?.edit_only?.slug] =
          item?.edit_only?.default_value || "";

        if (item?.edit_only?.type === "text_box") {
          setIsInputTypePassword((prev) => {
            return {
              ...prev,
              [item?.edit_only?.slug]: true,
            };
          });
        }
      }
    });
    data?.right_fields?.forEach((item) => {
      if (item?.is_editable) {
        if (item?.edit_only?.slug === "mfa_checkbox") {
          item?.edit_only?.check_list?.forEach((checkItem) => {
            tempObject[checkItem?.slug] = checkItem?.checked;
            tempEditObject[checkItem?.slug] = checkItem?.checked;
          });
        } else if (item?.edit_only?.slug === "mfa_toggle") {
          setIsToggleActive(item?.edit_only?.default_value);
          tempEditObject[item?.edit_only?.slug] =
            item?.edit_only?.default_value;
        }
      }
    });
    setDisplayObject(tempObject);
    setMfaEditObject(tempEditObject);
  }, [data]);

  return (
    <>
      {showModal && (
        <div className="manage-settings__add-user-modal-bg">
          <ModalV2 slug={"standard"}>
            <ChangePasswordPopup
              data={data?.change_password_modal}
              setShowModal={setShowModal}
              getPopupInputElement={getPopupInputElement}
              onChangePassword={handleButtonClick}
              setEditObject={setEditObject}
              setDisplayObject={setDisplayObject}
              setErrorMessagesObject={setErrorMessagesObject}
              setIsBlockerActive={setIsBlockerActive}
            />
          </ModalV2>
        </div>
      )}
      <div className="g-settings__cont">
        <p className="g-settings__title">{data?.title}</p>
        <div className="g-settings__card">
          <div className="sec-settings__login-details-left">
            {data?.profile_picture?.icon && (
              <img src={data?.profile_picture?.icon} alt={data?.bank_name} />
            )}
          </div>
          <div className="g-settings__right sec-settings__login-details-right">
            <div className="g-settings__right-left">
              <div
                className="g-settings__fields"
                style={{ gridTemplateColumns: data?.grid_template_columns }}
              >
                <>
                  {data?.left_fields?.map((item) => {
                    return item?.is_editable
                      ? renderEditOnlyFields(item)
                      : renderViewOnlyFields(item);
                  })}
                </>
              </div>
            </div>
            <div className="g-settings__right-right">
              <div
                className="g-settings__fields"
                style={{ gridTemplateColumns: data?.grid_template_columns }}
              >
                <>
                  {data?.right_fields?.map((item) => {
                    return item?.is_editable
                      ? renderEditOnlyFields(item)
                      : renderViewOnlyFields(item);
                  })}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecurityCard;
