// react imports
import React, { useState, useEffect, useRef } from "react";

// third-party imports

// in-app imports
import TimelineCard from "./Timeline";
import ReconSummary from "../modules/ReconSummary/ReconSummary";
import BankReview from "./BankReview";
import TimelineCardV2 from "./TimelineV2";
import ModalV2 from "./ModalV2";
import PreReconActionsCard from "../modules/ReconSummary/PreReconActionsCard";
import PostReconCompletedDocs from "./PostReconCompletedDocs";
import PostReconPendingDocs from "./PostReconPendingDocs";

const PostRecon = ({
  data,
  onActionClick,
  onEditSection,
  onDocAction,
  uploadSingleFile,
}) => {
  const [showReconSummary, setShowReconSummary] = useState(false);
  const [showBankResponse, setShowBankResponse] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef?.current && !modalRef?.current?.contains(event.target)) {
        setShowReconSummary(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);


  return (
    <>
      <div className="post-recon">
        {/* {data?.timeline_card && (
          <div className="post-recon__timeline">
            <TimelineCard data={data?.timeline_card} />
          </div>
        )} */}
        {data?.timeline_card && (
          <div className="post-recon__timeline">
            <TimelineCardV2
              data={data?.timeline_card}
              showReconSummary={showReconSummary}
              setShowReconSummary={setShowReconSummary}
              showBankResponse={showBankResponse}
              setShowBankResponse={setShowBankResponse}
              currentStatus={data?.current_status}
              onActionClick={onActionClick}
            />
            {showBankResponse && data?.query_raised && (
              <div className="post-recon__query">
                <BankReview data={data?.query_raised} />
              </div>
            )}
          </div>
        )}

        {!data?.is_recon_completed && (
          <div className="post-recon__summary">
            <PreReconActionsCard
              data={data?.summary_response}
              onActionClick={onActionClick}
              showReconSummary={showReconSummary}
              setShowReconSummary={setShowReconSummary}
            />
          </div>
        )}

        {/* {(!data?.is_recon_completed || showReconSummary) && (
          <div className="post-recon__summary">
            <ReconSummary
              data={data?.summary_response}
              onActionClick={onActionClick}
              showReconSummary={showReconSummary}
              setShowReconSummary={setShowReconSummary}
            />
          </div>
        )} */}

        {/* {data?.query_raised && (
          <div className="post-recon__summary">
            <BankReview data={data?.query_raised} />
          </div>
        )} */}
        {data?.post_recon_pending_docs && (
          <PostReconPendingDocs
            data={data?.post_recon_pending_docs}
            transportData={data?.post_recon_pending_docs?.transport_details}
            invoiceData={data?.post_recon_pending_docs?.invoice_details}
            onActionClick={onActionClick}
            onEditSection={onEditSection}
            onDocAction={onDocAction}
            uploadSingleFile={uploadSingleFile}
          />
        )}
        {data?.post_recon_completed_docs && (
          <PostReconCompletedDocs
            data={data?.post_recon_completed_docs}
            onEditSection={onEditSection}
          />
        )}
      </div>
      {showReconSummary && (
        <div className="a-buyers__modal-bg" style={{}}>
          <ModalV2
            slug={"standard"}
            children={
              <div
                className="post-recon__summary post-recon__summary--modal"
                ref={modalRef}
              >
                <ReconSummary
                  data={data?.summary_response}
                  onActionClick={onActionClick}
                  showReconSummary={true}
                  setShowReconSummary={setShowReconSummary}
                  isReconCompleted={data?.is_recon_completed}
                />
              </div>
            }
          />
        </div>
      )}
    </>
  );
};

export default PostRecon;
