//react imports
import React, { useState } from "react";

//in app imports
import BlunoTooltip from "../BlunoTooltip";
import { sanitizeHtml } from "../../utils/sanitizeHtml";
import UploadFileCard from "../UploadFile/UploadFile";
import { useDispatch } from "react-redux";
import CopyToClipboard from "../CopyToClipboard";

const SBInvoiceCard = ({
  data,
  uploadSingleFile,
  onDocAction,
  summaryErrorMessage,
}) => {
  const [expandedList, setExpandedlist] = useState(null);
  const [currency, setCurrency] = useState("");
  const [showExpanded, setShowExpanded] = useState(false);
  const [showCopyIndex, setShowCopyIndex] = useState(null);

  const handleExpandedData = (theData, theCurrency) => {
    setShowExpanded(!showExpanded);
    setExpandedlist(theData);
    setCurrency(theCurrency);
  };

  return (
    <div className="sb-invoice">
      <div className="sb-invoice__visible">
        {data?.container_data?.map((item, idx) => {
          return (
            <>
              <div
                className={
                  item?.slug === "upload_invoice_text"
                    ? "sb-invoice__invoice-text"
                    : "sb-invoice__row"
                }
                key={idx}
                style={{
                  gridRow: `${item?.row}`,
                  gridColumn: `${item?.column}`,
                  ...(item?.slug === "upload_invoice_text" && {
                    display: "flex",
                    alignItems: "center",
                  }),
                  ...(item?.data && { justifyItems: "end" }),
                }}
              >
                {item?.data ? (
                  <>
                    <div
                      className="sb-invoice__col"
                      style={{ gridColumn: "1/4" }}
                    >
                      <UploadFileCard
                        upload_enabled={item?.data?.document?.upload_enabled}
                        documents={item?.data?.document?.uploaded_documents}
                        doc_type={item?.data?.document?.slug}
                        invoiceId={item?.data?.id}
                        uploadSingleFile={uploadSingleFile}
                        // docId={docId}
                        section="sb_docs"
                        // primaryText={"Upload a invoice doc"}
                        onDocAction={onDocAction}
                      />
                    </div>
                    <p
                      className="sb-invoice__col-error"
                      style={{ gridColumn: "1/4" }}
                    >
                      {summaryErrorMessage?.[item?.data?.id]}
                    </p>
                  </>
                ) : (
                  <>
                    <div
                      className="sb-invoice__col"
                      style={{ gridColumn: "1" }}
                    >
                      <p
                        className="sb-invoice__text"
                        dangerouslySetInnerHTML={sanitizeHtml(item?.title)}
                      />
                    </div>
                    <div
                      className="sb-invoice__col"
                      style={{ gridColumn: "2" }}
                      onMouseEnter={() => {
                        setShowCopyIndex(idx);
                      }}
                      onMouseLeave={() => {
                        setShowCopyIndex(null);
                      }}
                    >
                      <p
                        className={"sb-invoice__text"}
                        dangerouslySetInnerHTML={sanitizeHtml(item?.text)}
                      />
                      {item?.expanded_data && (
                        <div
                          className="sb-invoice__hyperlink"
                          onClick={() =>
                            handleExpandedData(
                              item?.expanded_data?.highlighted_data,
                              item?.expanded_data?.currency
                            )
                          }
                        >
                          <p>{item?.expanded_data?.text}</p>
                        </div>
                      )}

                      {item?.show_hover && (
                        <BlunoTooltip
                          icon={item?.hover_icon}
                          text={item?.hover_text}
                          iconWidth="16px"
                          iconHeight="16px"
                        />
                      )}
                      <div style={{ width: "20px", height: "16px" }}>
                        {item?.enable_copy && showCopyIndex === idx && (
                          <CopyToClipboard text={item?.copy_text} />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          );
        })}
        {expandedList && showExpanded && (
          <div className="sb-invoice__toggle">
            {expandedList?.map((item) => {
              return (
                <>
                  {item?.type === "icon" ? (
                    <div className="sb-invoice__box">
                      <img
                        className="sb-invoice__img"
                        src={item?.icon}
                        alt="addition"
                      />
                    </div>
                  ) : (
                    <div className="sb-invoice__box">
                      <h3 className="sb-invoice__title">{item?.title}</h3>
                      <p
                        className="sb-invoice__number"
                        dangerouslySetInnerHTML={sanitizeHtml(
                          item?.text +
                            " " +
                            `<span class="sb-invoice__currency">${currency}</span>`,
                          ["class"]
                        )}
                      ></p>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SBInvoiceCard;
