import React, { useEffect, useRef } from "react";
import Dropdown from "./Dropdown";

export default function BlunoTableDropdown({
  options = [
    {
      label: "Option 1",
      value: "option_1",
    },
    {
      label: "Option 2",
      value: "option_2",
    },
  ],
  useValue = false,
  onSelectChange,
  name,
  isRequired = false,
  isClearable = false,
  isSearchable = false,
  isDisabled = false,
  isLoading = false,
  isRtl = false,
  defaultValue = null,
  height = "48px",
  minWidth = "100px",
  fontSize = "15px",
  fontWeight = "500",
  placeholderFontSize = "14px",
  placeholderFontWeight = "400",
  placeholder = "Select...",
  extraClass = null,
  id,
  isHighlighted = false,
  menuPlacement = "auto",
  menuPosition = "absolute",
  minHeight,
  iconWidth = "13px",
  errorMessage = "",
  fancyPlaceholder = false,
  topSpacing,
  tableRef,
}) {
  const isScrollable = useRef();

  useEffect(() => {
    isScrollable.current =
      tableRef?.current?.firstElementChild?.clientHeight <
      tableRef?.current?.firstElementChild?.scrollHeight;
  }, []);

  return (
    <>
      <Dropdown
        options={options}
        useValue={useValue}
        onSelectChange={onSelectChange}
        name={name}
        isRequired={isRequired}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isRtl={isRtl}
        defaultValue={defaultValue}
        height={height}
        minWidth={minWidth}
        fontSize={fontSize}
        fontWeight={fontWeight}
        placeholderFontSize={placeholderFontSize}
        placeholderFontWeight={placeholderFontWeight}
        placeholder={placeholder}
        extraClass={extraClass}
        id={id}
        isHighlighted={isHighlighted}
        menuPlacement={menuPlacement}
        menuPosition={isScrollable.current ? "absolute" : "fixed"}
        minHeight={minHeight}
        iconWidth={iconWidth}
        errorMessage={errorMessage}
        fancyPlaceholder={fancyPlaceholder}
        topSpacing={topSpacing}
      />
    </>
  );
}
