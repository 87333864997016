import React from "react";

export default function CopyIcon(props) {
  const { color = "#000000", style, onMouseEnter, onMouseLeave } = props;

  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <g clip-path="url(#clip0_16962_184204)">
        <path
          d="M13.3333 6.02246H7.33333C6.59695 6.02246 6 6.61941 6 7.35579V13.3558C6 14.0922 6.59695 14.6891 7.33333 14.6891H13.3333C14.0697 14.6891 14.6667 14.0922 14.6667 13.3558V7.35579C14.6667 6.61941 14.0697 6.02246 13.3333 6.02246Z"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.33301 10.0221H2.66634C2.31272 10.0221 1.97358 9.88166 1.72353 9.63161C1.47348 9.38156 1.33301 9.04242 1.33301 8.6888V2.6888C1.33301 2.33518 1.47348 1.99604 1.72353 1.74599C1.97358 1.49594 2.31272 1.35547 2.66634 1.35547H8.66634C9.01996 1.35547 9.3591 1.49594 9.60915 1.74599C9.8592 1.99604 9.99967 2.33518 9.99967 2.6888V3.35547"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16962_184204">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.0224609)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
