import React from "react";

export default function BlunoRadio({
  title,
  name,
  options,
  disabled,
  id,
  value,
  onChange,
  orientationTitle = "vertical",
  orientationButtons = "horizontal",
  disabledefault = false,
  extraClassTitle = "",
  extraClassRadioButton = "",
  extraClassRadioGroup = "",
  extraClassRadioButtonLabel = "",
  extraClassRadioInput = "",
  extraClass = "",
  radioGap = "20px",
}) {
  const radioInputStyles = {
    gap: radioGap,
    marginTop: orientationTitle === "vertical" ? "10px" : "",
  };
  return (
    <div
      className={`${
        disabledefault ? "" : `s-blunoradio s-blunoradio--${orientationTitle}`
      } ${extraClass}`}
    >
      {title && (
        <span
          className={`${
            disabledefault ? "" : "s-blunoradio--title"
          } ${extraClassTitle}`}
        >
          {title}
        </span>
      )}
      <div
        className={`${
          disabledefault
            ? ""
            : `s-blunoradio__radio s-blunoradio--${orientationButtons}`
        } ${extraClassRadioGroup}`}
        style={radioInputStyles}
      >
        {options?.map((item, index) => {
          return (
            <div
              className={`${
                disabledefault ? "" : "s-blunoradio__radio__button"
              } ${extraClassRadioButton}`}
            >
              <input
                type="radio"
                name={name}
                id={`${id}__${index}`}
                value={item?.slug}
                checked={item?.slug === value}
                disabled={disabled}
                onChange={onChange}
                className={`s-blunoradio__input ${extraClassRadioInput}`}
              />
              <label
                htmlFor={`${id}__${index}`}
                className={`${
                  disabledefault ? "" : "s-blunoradio__radio__label"
                } ${extraClassRadioButtonLabel}`}
              >
                {item?.text}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
