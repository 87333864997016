import React, { useState } from "react";
import TextInput from "../../TextInput";
import Dropdown from "../../Dropdown";
import Button from "../../Button";
import { ADD_USER_SLUGS } from "../../../utils/constants/SettingsConstants";
import {
  generalKeyDownValidation,
  validateEMAIL,
  validateNumber,
} from "../../../utils/Validation";
import { InputError } from "../../../app/constants/InputError";
import { useDispatch } from "react-redux";
import { apiRequest } from "../../../redux/common/actions";
import endpoints from "../../../api/endpoints";
import MuiTextInput from "../../blunoInputs/MuiTextInputs";

export default function AddUserPopup({
  data,
  setShowAddUserModal,
  updateData,
}) {
  const [userData, setUserData] = useState({});
  const [errorMessage, setErrorMessage] = useState({});

  const dispatch = useDispatch();

  const addUser = async () => {
    const tempErrorMessage = {};
    data?.inputs?.forEach((item, idx) => {
      if (!userData?.[item?.slug]) {
        tempErrorMessage[item?.slug] = InputError?.RequiredField?.message;
      } else if (
        item?.slug === ADD_USER_SLUGS?.EMAIL &&
        !validateEMAIL(userData?.[item?.slug])
      ) {
        tempErrorMessage[item?.slug] = InputError?.InvalidEmailFormat?.message;
      } else if (
        item?.slug === ADD_USER_SLUGS?.MOBILE &&
        userData?.[item?.slug]?.length !== 10
      ) {
        tempErrorMessage[item?.slug] =
          InputError?.IncorrectMobileNumber?.message;
      }
    });

    if (Object.keys(tempErrorMessage)?.length !== 0) {
      setErrorMessage(tempErrorMessage);
      return;
    }
    setErrorMessage({});
    let apiData = JSON.parse(JSON.stringify(userData));
    for (const [key, value] of Object.entries(apiData)) {
      if (key === ADD_USER_SLUGS?.EMAIL) {
        apiData[key] = { type: "EMAIL", value: { email_id: value } };
      } else if (key === ADD_USER_SLUGS?.MOBILE)
        apiData[key] = {
          type: "PHONE",
          value: { country_code: "+91", phone_no: value },
        };
    }
    const apiRes = await dispatch(apiRequest(endpoints?.inviteUser, apiData));
    if (apiRes?.success) {
      setShowAddUserModal(false);
      updateData();
    } else {
      setErrorMessage({ buttonError: apiRes?.data?.toast?.data?.center?.text });
    }
  };

  const handleKeyDown = (value, slug, e) => {
    if (
      slug === ADD_USER_SLUGS?.MOBILE &&
      generalKeyDownValidation(validateNumber, value, e)
    ) {
      e.preventDefault();
      setErrorMessage((prev) => ({
        ...prev,
        [slug]: InputError?.IncorrectMobileNumber?.message,
      }));
    }
  };

  const handleInputChange = (e, slug) => {
    switch (slug) {
      case ADD_USER_SLUGS?.MOBILE:
        if (e.target.value?.length <= 10) {
          if (e.target.value[0] === "0") {
            setErrorMessage((prev) => ({
              ...prev,
              [slug]: InputError?.IncorrectMobileNumber?.message,
            }));
          } else {
            setErrorMessage((prev) => ({ ...prev, [slug]: null }));
            setUserData((prev) => ({
              ...prev,
              [slug]: e.target.value,
            }));
          }
        }
        if (e.target.value.length > 10) {
          setErrorMessage((prev) => ({
            ...prev,
            [slug]: InputError?.FullMobileNumber?.message,
          }));
        }
        break;

      default:
        setUserData((prev) => ({
          ...prev,
          [slug]: e.target.value,
        }));
        break;
    }
  };

  const getInput = (item) => {
    switch (item?.type) {
      case "text_box":
        return (
          <>
            <div className="o-login__input">
              <MuiTextInput
                placeholder={item?.placeholder}
                type={item?.sub_type}
                value={userData?.[item?.slug]}
                height="40px"
                minWidth="220px"
                icon={item?.icon}
                iconAlignment="right"
                id={item?.slug}
                errorMessage={errorMessage?.[item?.slug]}
                onChange={(e) => {
                  handleInputChange(e, item?.slug);
                }}
                onKeyDown={(e) => handleKeyDown(e.key, item?.slug, e)}
                disabled={item?.disabled}
                fixedText={item?.fixed_text}
                paddingLeft={item?.left_padding}
                fixedTextLeftStyle={"20px"}
                fontSize="13px"
                subtext={item?.subtext}
              />
            </div>
          </>
        );
      case "dropdown":
        return (
          <>
            <Dropdown
              options={item?.options}
              height="35px"
              placeholderFontSize="13px"
              fontSize="13px"
              fontWeight="600"
              minWidth="220px"
              onSelectChange={(e) => {
                setUserData((prev) => ({ ...prev, [item?.slug]: e.value }));
              }}
              fancyPlaceholder={true}
              topSpacing="-8px"
              iconWidth="10px"
              useValue={true}
              placeholder={item?.placeholder}
              errorMessage={errorMessage?.[item?.slug]}
              errorMessageFontSize="11px"
            />
          </>
        );
      default:
        break;
    }
  };
  return (
    <>
      <div className="manage-settings__add-user-modal">
        <h3 className="manage-settings__add-user-modal-heading">
          {data?.title}
        </h3>
        <img
          className="manage-settings__add-user-modal-close"
          src={data?.close_icon}
          onClick={() => {
            setShowAddUserModal(false);
          }}
        />
        <div className="manage-settings__add-user-modal__inputs">
          {data?.inputs?.map((item, idx) => {
            return getInput(item);
          })}
        </div>
        {data?.buttons?.map((item, idx) => {
          return (
            <Button
              key={idx}
              icon={item?.icon}
              buttonType={item?.sub_type}
              buttonLabel={item?.text}
              onClick={() => {
                addUser();
              }}
              errorMessage={errorMessage?.buttonError}
            />
          );
        })}
      </div>
    </>
  );
}
