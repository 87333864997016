// react imports
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// redux imports
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";

// in-app imports
import { DGFT_PLATFORM_SLUGS } from "../../utils/constants/SettingsConstants";
import Button from "../Button";

const BlunoBanner = ({ data, hideNotificationBanner }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showNeedAssistBtn, setShowNeedAssistBtn] = useState(true);

  const handleNeedAssistanceRequest = async () => {
    const apiRes = await dispatch(
      apiRequest(endpoints.handleNeedAssistanceRequest)
    );
    if (apiRes?.success && apiRes?.data) {
      setShowNeedAssistBtn(false);
    }
  };

  const onActionClick = (action) => {
    switch (action?.slug) {
      case "do_later":
        hideNotificationBanner();
        break;
      case "securely_connect":
        navigate("/settings/partners", {
          state: { showBannerButton: false },
        });
        break;
      case DGFT_PLATFORM_SLUGS?.needAssistance:
        handleNeedAssistanceRequest();
        break;
    }
  };

  return (
    <>
      <div className="bluno-banner">
        <div className="bluno-banner__container">
          <div className="bluno-banner__left">
            <img src={data?.icon} alt="" />
          </div>
          <div className="bluno-banner__right">
            <div className="bluno-banner__header">
              <h2 className="bluno-banner__title">{data?.title}</h2>
              {data?.buttons && (
                <div className="bluno-banner__header-buttons">
                  {data?.header_buttons?.map((action) => {
                    if (
                      action?.slug === DGFT_PLATFORM_SLUGS?.needAssistance &&
                      showNeedAssistBtn
                    ) {
                      return (
                        action?.type === "button" && (
                          <Button
                            buttonLabel={action?.text}
                            buttonType={action?.sub_type}
                            icon={action.icon}
                            disabled={action?.disabled}
                            iconAlignment={action?.icon_alignment}
                            onClick={() => onActionClick(action)}
                          />
                        )
                      );
                    } else if (
                      action?.slug !== DGFT_PLATFORM_SLUGS?.needAssistance
                    ) {
                      return (
                        action?.type === "button" && (
                          <Button
                            buttonLabel={action?.text}
                            buttonType={action?.sub_type}
                            icon={action.icon}
                            disabled={action?.disabled}
                            iconAlignment={action?.icon_alignment}
                            onClick={() => onActionClick(action)}
                          />
                        )
                      );
                    }
                  })}
                  {!showNeedAssistBtn && (
                    <div className="dgft-partner__assistance">
                      <p>Assistance requested</p>
                      <img src="https://static.bluno.in/miscl/green_tick_20_icon.png" />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="bluno-banner__content">
              <p className="bluno-banner__text">{data?.text}</p>
            </div>
            <div className="bluno-banner__footer">
              <div className="bluno-banner__list">
                {data?.list?.map((item) => {
                  return (
                    <div className="bluno-banner__list-item">
                      <img src={item?.icon} alt="" />
                      <p>{item?.text}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="bluno-banner__buttons">
          {data?.buttons?.map((action) => {
            return (
              action?.type === "button" && (
                <Button
                  buttonLabel={action?.text}
                  buttonType={action?.sub_type}
                  icon={action.icon}
                  disabled={action?.disabled}
                  iconAlignment={action?.icon_alignment}
                  tooltipText={action?.tooltip_text}
                  tooltipPosition={{ bottom: "40px", left: "50px" }}
                  onClick={() => onActionClick(action)}
                  iconExtraClass="bluno-banner__buttons-icon"
                />
              )
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BlunoBanner;
