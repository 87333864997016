// react imports
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// third-party imports
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import { showToast } from "../../redux/toast/slice";
import { updateShowSbForm } from "../../redux/common/slice";

//in-app imports
import AllBuyersSummary from "../../modules/AllBuyers/AllBuyersSummary";
import Button from "../../components/Button";
import AllBuyersTable from "../../modules/AllBuyers/AllBuyersTable";
import ModalV2 from "../../components/ModalV2";
import BuyerDetails from "../../components/buyersPage/BuyerDetails";
import BlunoTooltip from "../../components/BlunoTooltip";
import TimePeriodPanel from "../../components/TimePeriodPanel";
import useDebouncing from "../../hooks/useDebouncing";

// utils
import { DEBOUNCE } from "../../app/constants/common";

const AllBuyers = () => {
  // defined to acces react based APIs
  const dispatch = useDispatch();

  // local states
  const [buyersInfoSec, setBuyersInfoSec] = useState();
  const [remindBuyersData, setRemindBuyersData] = useState();

  // modal states
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [summaryData, setSummaryData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [filterObj, setFilterObj] = useState({});

  // custom hooks variables
  const debouncedFilterObj = useDebouncing(filterObj, DEBOUNCE.delay);

  const handleDateRangeChange = (startDate, endDate) => {
    setFilterObj((prev) => ({
      ...prev,
      start_date: startDate,
      end_date: endDate,
    }));
  };
  const onGetRemindClick = (
    sortfield = "overdue_sb_amount",
    order = "desc",
    alert_name,
    btnSlug = modalInfo?.slug
  ) => {
    const getRemind = async () => {
      // Remind Buyer Data Resoponse
      const remindData = await dispatch(
        apiRequest(
          endpoints.getRemindBuyerData,
          `?sort_field=${sortfield}&order=${order}`
        )
      );
      if (remindData?.success && remindData?.data) {
        setRemindBuyersData({ alertName: alert_name, data: remindData?.data });
        onModalClick(btnSlug);
      }
    };
    getRemind();
  };

  const sendReminder = (buyerData) => {
    const sendRemind = async () => {
      // Remind Buyer Data Resoponse
      const remindData = await dispatch(
        apiRequest(endpoints.remindBuyers, {
          alert_name: buyerData?.alertName,
          buyer_ids: buyerData?.idList,
        })
      );
      if (remindData?.success && remindData?.data) {
        closeModal();
        dispatch(showToast(remindData?.data?.toast));
      }
    };
    sendRemind();
  };

  const onModalClick = (slug) => {
    setShowModal(true);
    setModalInfo({ slug: slug });
  };

  const getAllBuyersData = async (sortField = "buyer_name", order = "asc") => {
    const filters = ["buyer_name"];
    let params = filters
      .filter((key) => filterObj?.[key])
      .map((key) => `${key}=${filterObj[key]}`)
      .join("&");
    const queryString = `?sort_field=${sortField}&order=${order}&page_no=${pageNo}&${
      filterObj?.start_date ? `&start_date=${filterObj.start_date}` : ""
    }${filterObj?.end_date ? `&end_date=${filterObj.end_date}` : ""}&${params}`;

    const dataRes = await dispatch(
      apiRequest(endpoints.getAllBuyersData, queryString)
    );
    if (dataRes?.success && dataRes?.data) {
      setSummaryData(dataRes?.data?.all_buyers_summary);
      setTableData(dataRes?.data?.table_data);
    }
  };

  const onSortOrderClick = (sortCategory, sortOrder) => {
    const order = sortOrder ? (sortOrder === "asc" ? "desc" : "asc") : "desc";
    getAllBuyersData(sortCategory, order);
  };
  const handleInputChange = (theValue, theSlug) => {
    switch (theSlug) {
      default:
        setFilterObj((prev) => {
          if (theValue === "") {
            const { [theSlug]: _, ...rest } = prev;
            return rest;
          } else {
            return {
              ...filterObj,
              [theSlug]: theValue,
            };
          }
        });
    }
    setPageNo(1);
  };

  const onClearFilter = () => {
    setFilterObj({});
  };

  const closeModal = () => {
    setShowModal(false);
    setModalInfo({});
  };

  const navigate = useNavigate();

  const redirectTo = (e, theLink) => {
    e.stopPropagation();
    navigate(theLink);
  };

  useEffect(() => {
    getAllBuyersData();
  }, [pageNo, debouncedFilterObj]);

  useEffect(() => {
    const getBuyersData = async () => {
      // All Buyers Info Resoponse
      const buyersInfoRes = await dispatch(
        apiRequest(endpoints.getBuyerInfoSec)
      );
      if (buyersInfoRes?.success && buyersInfoRes?.data) {
        setBuyersInfoSec(buyersInfoRes?.data);
      }
    };
    getBuyersData();
  }, []);

  return (
    <>
      <div className="a-buyers">
        <div className="a-buyers__top">
          <div className="a-buyers__top-left">
            {buyersInfoSec?.top_bar?.header?.back_info && (
              <BlunoTooltip
                icon={buyersInfoSec?.top_bar?.header?.back_info?.icon}
                // hoverIcon={
                //   buyersInfoSec?.top_bar?.header?.back_info?.hover_icon
                // }
                text={buyersInfoSec?.top_bar?.header?.back_info?.hover_text}
                iconWidth="16px"
                iconHeight="16px"
                left="28px"
                useChildren={true}
              >
                <div
                  href={buyersInfoSec?.top_bar?.header?.back_info?.deeplink}
                  onClick={(e) => {
                    redirectTo(
                      e,
                      buyersInfoSec?.top_bar?.header?.back_info?.deeplink
                    );
                  }}
                  className="s-sbHeader__back s-sbHeader__back-background"
                >
                  <img
                    src={buyersInfoSec?.top_bar?.header?.back_info?.icon}
                    className="s-sbHeader__back-img"
                  />
                </div>
              </BlunoTooltip>
            )}
            <h2 className="a-buyers__top-heading">
              {buyersInfoSec?.top_bar?.header?.title}
            </h2>
          </div>
          {/* <TimePeriodPanel
            setSummaryDate={setSummaryDate}
            data={{
              time_period_list: summaryData?.time_period_list,
              custom_time: summaryData?.custom_time_period,
            }}
          /> */}
          <div className="a-buyers__top-right">
            {buyersInfoSec?.top_bar?.tabs?.map((tab, index) => {
              return tab?.type === "button" ? (
                tab?.slug === "add_sb" && (
                  <Button
                    buttonLabel={tab?.text}
                    buttonType={tab?.sub_type}
                    icon={tab?.icon}
                    disabled={tab?.disabled}
                    iconAlignment={tab?.icon_alignment}
                    onClick={() => dispatch(updateShowSbForm(true))}
                  />
                )
              ) : (
                <div
                  className={`a-buyers__top-tab ${
                    showModal && modalInfo?.slug === "buyer_details"
                      ? "a-buyers__top--show"
                      : null
                  }`}
                >
                  {tab?.icon && <img src={tab?.icon} alt="" />}
                  <p
                    className={`a-buyers__top-tabtext`}
                    onClick={() =>
                      tab?.slug === "buyer_details" && onModalClick(tab?.slug)
                    }
                  >
                    {tab?.text}
                  </p>
                  {showModal && modalInfo?.slug === "buyer_details" && (
                    <ModalV2 slug={modalInfo?.slug}>
                      <BuyerDetails closeModal={closeModal} />
                    </ModalV2>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <AllBuyersSummary
          data={summaryData}
          btns={buyersInfoSec?.imp_alerts?.alerts[0]}
          onGetRemindClick={onGetRemindClick}
          remindBuyersData={remindBuyersData}
          showModal={showModal}
          modalInfo={modalInfo}
          closeModal={closeModal}
          sendReminder={sendReminder}
          bulkBtn={buyersInfoSec?.imp_alerts?.button}
        />
        <AllBuyersTable
          data={tableData}
          pageNo={pageNo}
          setPageNo={setPageNo}
          onSortOrderClick={onSortOrderClick}
          filterObj={filterObj}
          handleInputChange={handleInputChange}
          onClearFilter={onClearFilter}
          handleDateRangeChange={handleDateRangeChange}
        />
      </div>
      {showModal && (
        <div className="a-buyers__modal-bg" onClick={closeModal}></div>
      )}
    </>
  );
};

export default AllBuyers;
