//react imports
import React from "react";

//in app imports
import { sanitizeHtml } from "../../utils/sanitizeHtml";
import { SUB_MODAL_TYPE } from "../../utils/constants/TableConstants";
import { useNavigate } from "react-router-dom";
import Button from "../Button";

const SuccessModal = ({ data, setShowModal, setSubModalType }) => {
  const navigate = useNavigate();

  const handleCloseClick = () => {
    setShowModal(false);
    setSubModalType(SUB_MODAL_TYPE?.invisible);
  };

  return (
    <div className="s-table__modal-success">
      <div className="s-table__success">
        <div className="s-table__success-logo">
          <img src={data?.icon} alt="bluno-logo" />
        </div>
        <p
          className="s-table__success-text"
          dangerouslySetInnerHTML={sanitizeHtml(data?.text)}
        />
      </div>

      <div className="s-table__success-tprt">
        <img
          src={data?.close_icon}
          alt="close"
          className="s-table__success-icon"
          onClick={handleCloseClick}
        />
      </div>
      {data?.buttons && (
        <div className="s-table__success-action">
          {data?.buttons?.map((action) => {
            return (
              action?.type === "button" && (
                <Button
                  buttonLabel={action?.text}
                  buttonType={action?.sub_type}
                  icon={action.icon}
                  disabled={action?.disabled}
                  iconAlignment={action?.icon_alignment}
                  buttonSize="sm2"
                  onClick={() => action?.deeplink && navigate(action?.deeplink)}
                />
              )
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SuccessModal;
