// react imports
import React, { useEffect, useRef, useState } from "react";

// third party imports
import Select, { components } from "react-select";

// in-app imports
import MuiTextInput from "./blunoInputs/MuiTextInputs";

const Dropdown = ({
  options = [
    {
      label: "Option 1",
      value: "option_1",
    },
    {
      label: "Option 2",
      value: "option_2",
    },
  ],
  useValue = false,
  onSelectChange,
  name,
  isRequired = false,
  isClearable = false,
  isSearchable = false,
  isDisabled = false,
  isLoading = false,
  isRtl = false,
  defaultValue = null,
  height = "48px",
  minWidth = "100px",
  width,
  fontSize = "15px",
  fontWeight = "500",
  placeholderFontSize = "14px",
  placeholderFontWeight = "400",
  placeholder = "Select...",
  extraClass = null,
  id,
  isHighlighted = false,
  menuPlacement = "auto",
  menuPosition = "absolute",
  minHeight,
  iconWidth = "13px",
  errorMessage = "",
  errorMessageFontSize = "13px",
  makeErrorTakeHeight = true,
  fancyPlaceholder = false,
  topSpacing,
  isSeparator = false,
  separatorIndex = null,
  menuHeight = "max-content",
  ellipsis = false,
  menuWidth = "100%",
}) => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(
    options?.filter((option) => option?.value === defaultValue)[0]
  );
  const [searchValue, setSearchValue] = useState("");
  const selectRef = useRef(null);
  const dropdownRef = useRef();

  const enhancedOptions = React.useMemo(() => {
    if (
      isSeparator &&
      separatorIndex !== null &&
      separatorIndex < options?.length
    ) {
      const newOptions = [...options];
      newOptions?.splice(separatorIndex, 0, { isSeparator: true });
      return newOptions;
    }
    return options;
  }, [isSeparator, separatorIndex, options]);

  const customOption = (props) => {
    const { data, innerRef, innerProps } = props;

    if (data?.isSeparator) {
      return (
        <div style={{ borderBottom: "1px solid #ddd", margin: "2px 0" }} />
      );
    }

    return (
      <components.Option {...props} ref={innerRef} {...innerProps}>
        {data.label}
      </components.Option>
    );
  };

  const style = {
    control: (base) => ({
      ...base,
      border: isHighlighted ? "1px solid #046cb8" : "1px solid #E7EAEE",
      // This line disable the blue border
      boxShadow: "none",
      borderRadius: "8px",
      fontSize: "15px",
      fontWeight: "500",
      padding: "5px 10px",
      minHeight: "30px",
      height: height,
      minWidth: minWidth,
      width: width,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && "rotate(180deg)",
      padding: "0px",
      display: "flex",
      alignItems: "center",
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#EBF4FF" : "#FFFFFF",
        color: isFocused ? "#1F1F1F" : "#888888",
        fontSize: fontSize,
        fontWeight: fontWeight,
        maxHeight: menuHeight,
      };
    },
    menu: (base) => {
      return {
        ...base,
        padding: "10px 5px",
        borderRadius: "8px",
        border: "1px #E7EAEE solid",
        zIndex: 9999,
        overflowY: "auto",
        width: menuWidth,
      };
    },
    menuList: (base) => ({
      ...base,
      maxHeight: menuHeight,
      overflowY: "auto",
    }),
    singleValue: (base) => {
      return {
        ...base,
        color: isHighlighted ? "#046cb8" : "#191D23",
        fontSize: fontSize,
        fontWeight: fontWeight,
      };
    },
    valueContainer: (base) => ({
      ...base,
      padding: "0px 8px",
    }),
    placeholder: (base) => {
      return {
        ...base,
        fontSize: placeholderFontSize,
        fontWeight: placeholderFontWeight,
      };
    },
    ...extraClass,
  };

  const handleOnChange = (e) => {
    setFocused(false);
    selectRef.current.blur();
    setValue(options?.filter((option) => option?.value === e.value)[0]);
    onSelectChange(e);
    setSearchValue("");
  };

  const handleInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "input-change") {
      setSearchValue(inputValue);
    }
  };

  useEffect(() => {
    setValue(options?.filter((option) => option?.value === defaultValue)[0]);
  }, [defaultValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef?.current &&
        !dropdownRef?.current?.contains(event.target)
      ) {
        setFocused(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      className="dropdown__conatiner"
      onClick={() => {
        if (focused) {
          selectRef.current.blur();
          setFocused(false);
        } else {
          selectRef.current.focus();
          setFocused(true);
        }
      }}
      ref={dropdownRef}
    >
      {fancyPlaceholder && (
        <>
          <div className="dropdown__label">
            <MuiTextInput
              height={height}
              minWidth={minWidth}
              searchIcon={false}
              icon={
                focused && !errorMessage
                  ? "https://static.bluno.in/miscl/dropdown_icon_blue.png"
                  : "https://static.bluno.in/miscl/dropdown_icon_black.png"
              }
              iconWidth={iconWidth}
              iconClass={`dropdown__icon ${
                errorMessage ? "dropdown__icon--error" : ""
              } ${focused ? "dropdown__icon--rotate" : ""}`}
              value={searchValue || value?.label || ""}
              iconAlignment="right"
              readonly={true}
              placeholder={placeholder}
              isHighlighted={isHighlighted}
              isFocusedExternal={focused}
              cursor={"pointer"}
              disabled={isDisabled}
              fontSize={fontSize}
              topSpacing={topSpacing}
              errorMessage={errorMessage}
              ellipsis={ellipsis}
            />
          </div>
        </>
      )}
      <Select
        ref={selectRef}
        className={`basic-single ${
          isHighlighted ? "basic-single--highlighted" : ""
        }`}
        classNamePrefix="select"
        value={
          useValue
            ? value
            : options?.filter((option) => option?.value === defaultValue)
        }
        isDisabled={isDisabled}
        menuPosition={menuPosition}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name={name}
        required={isRequired}
        options={enhancedOptions}
        onChange={(e) => handleOnChange(e)}
        onInputChange={handleInputChange}
        menuIsOpen={focused}
        components={{
          IndicatorSeparator: () => null,
          Option: customOption,
        }}
        styles={style}
        placeholder={fancyPlaceholder ? null : placeholder}
        id={id}
        menuPlacement={menuPlacement}
        minMenuHeight={minHeight}
      />
      {errorMessage && makeErrorTakeHeight && (
        <p
          className="basic-single__error"
          style={{ visibility: `${fancyPlaceholder ? "hidden" : ""}` }}
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default Dropdown;
