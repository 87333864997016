import React from "react";
import { sanitizeHtml } from "../../utils/sanitizeHtml";
import BlunoTooltip from "../BlunoTooltip";

const CheckboxGroup = (props) => {
  const { item, value = "", onChange, disabled } = props;
  const { title = "", element_id = "", name } = item;

  return (
    <>
      <div className="df-group">
        <div className="df-group__checkbox">
          <BlunoTooltip useChildren={true} text={disabled ? "" : "Select"}>
            <div
              className={
                disabled
                  ? "df-group__checkbox-div"
                  : "s-sbHeader__sb-download-background"
              }
              onClick={(e) => {
                e?.target?.children[0]?.click();
              }}
            >
              <input
                type="checkbox"
                id={element_id}
                checked={value === "yes" ? true : false}
                name={name}
                onChange={onChange}
                disabled={disabled}
              />
            </div>
          </BlunoTooltip>
          <label
            htmlFor={element_id}
            className="df-group__label"
            dangerouslySetInnerHTML={sanitizeHtml(title, ["target"])}
          />
        </div>
      </div>
    </>
  );
};

export default CheckboxGroup;
