// USE CAPS TO NAME THE CONSTANTS TO
// differentiate them from the regular variables

export const M_SINGLE_SB = {
  navbar: [
    { content: "Status", deeplink: "" },
    { content: "Mapped IRMs", deeplink: "" },
    { content: "Documents", deeplink: "" },
  ],
};

export const SETTINGS_API_RESPONSE = {
  success: "true",
  data: {
    toggle_details: {
      selected: "Primary Bank",
      unselected: "Set as primary bank",
    },
    popup_data: {
      title: "Change primary bank",
      text1: "Change ",
      text2: " to primary bank?",
      type: "info",
      buttons: [
        {
          text: "Cancel",
          sub_type: "secondary",
          type: "button",
          width: "105px",
        },
        {
          text: "Ok",
          sub_type: "primary",
          type: "button",
          slug: "proceed",
          width: "105px",
        },
      ],
    },
    buttons: [
      {
        sub_type: "primary",
        disabled: false,
        show_loader: false,
        type: "button",
        icon: "https://static.bluno.in/banks/bank-icon.png",
        text: "Add another bank",
        slug: "add_new_bank",
      },
    ],
    bank_list: [
      {
        grid_template_columns: "1fr 1fr",
        account_id: "123",
        state: "view_only",
        bank_name: "HDFC Bank",
        primary_bank: true,
        bank_icon: "https://static.bluno.in/banks/hdfc-bank-logo.png",
        edit_buttons: [
          {
            sub_type: "tertiary",
            disabled: false,
            show_loader: false,
            type: "button",
            icon: "https://static.bluno.in/miscl/black_edit.png",
            text: "Edit",
            slug: "view_state",
          },
        ],
        fields: [
          {
            is_editable: false,
            view_only: {
              label: "Account Holder Name:",
              value: "Pratyush Yuvraj",
              slug: "account_holder_name",
            },
            colspan: "span 2",
          },
          {
            is_editable: false,
            view_only: {
              label: "Account Number",
              value: "XXXXXXXXXX8782",
              slug: "account_number",
              icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            },
            colspan: "span 1",
          },
          {
            is_editable: false,
            view_only: {
              label: "IFSC Code",
              value: "HDFC000209",
              slug: "ifsc_code",
            },
            colspan: "span 1",
          },
          {
            is_editable: false,
            view_only: {
              label: "Branch",
              value: "Mahatma Gandhi Road, Surat",
              slug: "bank_branch",
            },
            colspan: "span 2",
          },
          {
            is_editable: false,
            view_only: {
              enable_copy: true,
              label: "AD Code",
              value: "57525272572511",
              slug: "bank_ad_code",
            },
            colspan: "span 2",
          },
        ],
      },
      {
        grid_template_columns: "1fr 1fr",
        account_id: "156",
        state: "view_only",
        bank_name: "YES Bank",
        primary_bank: false,
        bank_icon:
          "https://i.pinimg.com/736x/50/4d/03/504d035f4ec492eb90052bc4b42b3a26.jpg",
        edit_buttons: [
          {
            sub_type: "tertiary",
            disabled: false,
            show_loader: false,
            type: "button",
            icon: "https://static.bluno.in/miscl/black_edit.png",
            text: "Edit",
            slug: "view_state",
          },
        ],
        fields: [
          {
            is_editable: false,
            view_only: {
              label: "Account Holder Name:",
              value: "Arijit Singh",
              slug: "account_holder_name",
            },
            colspan: "span 2",
          },
          {
            is_editable: false,
            view_only: {
              label: "Account Number",
              value: "XXXXXXXXXX3029",
              slug: "account_number",
              icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            },
            colspan: "span 1",
          },
          {
            is_editable: false,
            view_only: {
              label: "IFSC Code",
              value: "YES000007",
              slug: "ifsc_code",
            },
            colspan: "span 1",
          },
          {
            is_editable: false,
            view_only: {
              label: "Branch",
              value: "Birsa Munda Chowk, Ranchi",
              slug: "bank_branch",
            },
            colspan: "span 2",
          },
          {
            is_editable: false,
            view_only: {
              enable_copy: true,
              label: "AD Code",
              value: "99525452892511",
              slug: "bank_ad_code",
            },
            colspan: "span 2",
          },
        ],
      },
    ],
    add_bank: {
      title: "Search for Bank IFSC code",
      text_box: {
        type: "text_box",
        slug: "search_ifsc_code",
        title: "Enter IFSC code",
        placeholder: "Enter Bank IFSC code",
        icon: "https://static.bluno.in/miscl/search.png",
      },
    },
  },
  message: "",
};

export const SETTINGS_API_RESPONSE_2 = {
  success: "true",
  data: {
    health_checks: {
      title: "Bank Ecosystem Healthcheck",
      last_refresh: "Last refreshed at 7:00 PM IST",
      info: {
        status: "warning",
        text: "UCO Bank, Bank of Baroda servers are down. Other all bank servers are operational",
      },
      status_list: [
        {
          account_id: "123",
          bank_name: "HDFC BANK",
          bank_icon: "https://static.bluno.in/banks/hdfc-bank-logo.png",
          status: "success",
        },
        {
          account_id: "124",
          bank_name: "Bank of Baroda",
          bank_icon:
            "https://i.pinimg.com/736x/2a/f8/14/2af814fc55040230fc6708002fb23493.jpg",
          status: "alert",
        },
        {
          account_id: "127",
          bank_name: "YES Bank",
          bank_icon:
            "https://i.pinimg.com/736x/50/4d/03/504d035f4ec492eb90052bc4b42b3a26.jpg",
          status: "success",
        },
        {
          account_id: "134",
          bank_name: "ICICI Bank",
          bank_icon:
            "https://i.pinimg.com/736x/ff/d5/31/ffd531a6a78464512a97848e14506738.jpg",
          status: "success",
        },
        {
          account_id: "138",
          bank_name: "State Bank of India",
          bank_icon:
            "https://i.pinimg.com/736x/c4/d2/57/c4d257877a52603cc7c438226e7cf4ad.jpg",
          status: "success",
        },
        {
          account_id: "156",
          bank_name: "Axis Bank",
          bank_icon: "https://static.bluno.in/banks/hdfc-bank-logo.png",
          status: "success",
        },
        {
          account_id: "157",
          bank_name: "UCO Bank",
          bank_icon: "https://static.bluno.in/banks/hdfc-bank-logo.png",
          status: "alert",
        },
        {
          account_id: "158",
          bank_name: "Federal Bank",
          bank_icon: "https://static.bluno.in/banks/hdfc-bank-logo.png",
          status: "success",
        },
      ],
    },
  },
  message: "",
};

export const GENERAL_SETTINGS_RESPONSE = {
  title: "Director Name",
  text: "(Selected as authorized signatory of the company)",
  directors: [
    {
      director_id: "12345",
      director_name: "Pratyush Yuvraj",
      is_consignee: false,
    },
    {
      director_id: "54321",
      director_name: "Pranav Singh",
      is_consignee: true,
    },
    {
      director_id: "656478",
      director_name: "Smit Patel",
      is_consignee: false,
    },
  ],
  consignee: {
    director_id: "54321",
    director_text: "Authorized Signatory's Name",
    director_name: "Pranav Singh ",
    pan_text: "Authorized Signatory's PAN Number",
    pan_number: "ADSG822YU",
  },
  edit_consignee: {
    consignee_declaration: {
      name: null,
      text: "Authorised Signatory and company director are same",
      slug: "is_consignee_director",
      checked: true,
      editable: false,
    },
    consignee_name: {
      type: "text_box",
      slug: "consignee_name",
      title: "Authorised Signatory",
      limits: null,
      placeholder: "Enter name",
      icon: null,
      disabled: false,
      isVerified: false,
      sub_type: "text",
      is_required: false,
      default_value: null,
      fixed_text: null,
      left_padding: null,
    },
    consignee_pan: {
      type: "text_box",
      slug: "consignee_pan",
      title: "Authorised Signatory's PAN Number",
      limits: null,
      placeholder: "Enter PAN",
      icon: null,
      disabled: false,
      isVerified: false,
      sub_type: "text",
      is_required: false,
      default_value: null,
      fixed_text: null,
      left_padding: null,
    },
  },
};

export const UPLOAD_DOC_TABLE_DATA = {
  success: true,
  data: {
    bill_types: [
      {
        total_count: " of 14",
        sub_text: ")",
        selected: true,
        text: "Shipping Bills (14",
        slug: "sb",
        deeplink: "/bills/sb",
      },
      {
        total_count: "",
        sub_text: ")",
        selected: false,
        text: "Inward Remittance (17",
        slug: "irm",
        deeplink: "/bills/irm",
      },
    ],
    border_supported: false,
    select_all_supported: false,
    selection_supported: false,
    selection_with_api: false,
    headers: [
      {
        sort_supported: true,
        text: "Buyer",
        slug: "buyer_name",
        alignment: "left",
      },
      {
        sort_supported: false,
        text: "Shipping Bill No",
        slug: "sb_no",
        alignment: "left",
      },
      {
        sort_supported: true,
        sort_order: "desc",
        text: "Shipping Bill Date",
        slug: "sb_date",
        alignment: "center",
      },
      {
        sort_supported: true,
        text: "Invoice Amount",
        slug: "invoice_value",
        alignment: "center",
      },
      {
        sort_supported: false,
        sub_headers: [
          {
            sort_supported: false,
            text: "Invoice",
            slug: "invoice_docs",
            alignment: "center",
          },
          {
            sort_supported: false,
            text: "Transport Docs",
            slug: "transport_docs",
            alignment: "center",
          },
          {
            sort_supported: false,
            text: "Action",
            slug: "action",
            alignment: "center",
          },
        ],
        text: "Action",
        slug: "bank_flow",
        alignment: "center",
      },
    ],
    data: [
      {
        id: 181,
        deeplink: "/bills/sbb/181",
        buyer_name: {
          disabled: false,
          text: "DEF PRIVATE LIMITED",
          type: "plain",
          alignment: "left",
        },
        sb_no: {
          disabled: false,
          text: "9065629",
          type: "plain",
          alignment: "left",
        },
        sb_date: {
          disabled: false,
          text: "04 Apr, 2024",
          type: "plain",
          alignment: "center",
        },
        invoice_value: {
          hz_text: "USD",
          disabled: false,
          text: "6,888",
          type: "plain",
          alignment: "center",
        },
        bank_flow: {
          invoice_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "invoice_docs",
          },
          transport_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "transport_docs",
          },
          text: "action",
          type: "bank_flow",
          alignment: "center",
          action: {
            sub_type: "info",
            disabled: false,
            text: "Track",
            alignment: "center",
            deeplink: "/bills/sbb/181",
            slug: "action",
          },
          modal: null,
        },
      },
      {
        id: 178,
        deeplink: "/bills/sbb/178",
        buyer_name: {
          disabled: false,
          text: "QRS PRIVATE LIMITED",
          type: "plain",
          alignment: "left",
        },
        sb_no: {
          disabled: false,
          text: "4872630",
          type: "plain",
          alignment: "left",
        },
        sb_date: {
          disabled: false,
          text: "17 Oct, 2023",
          type: "plain",
          alignment: "center",
        },
        invoice_value: {
          hz_text: "NZD",
          disabled: false,
          text: "11,473",
          type: "plain",
          alignment: "center",
        },
        bank_flow: {
          invoice_docs: {
            text: "0 of 1 pending",
            icon: "https://static.bluno.in/miscl/upload-icon.png",
            status: "pending",
            alignment: "center",
            disabled: false,
            slug: "invoice_docs",
          },
          transport_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "transport_docs",
          },
          text: "action",
          type: "bank_flow",
          alignment: "center",
          action: {
            sub_type: "neutral",
            disabled: false,
            text: "Upload invoice bill",
            alignment: "center",
            deeplink: "/bills/sbb/178",
            slug: "action",
          },
          modal: {
            all_documents_status: false,
            deeplink_icon: "https://static.bluno.in/miscl/redirect-icon.png",
            close_icon: "https://static.bluno.in/miscl/cross_black.png",
            invoice_docs: {
              title: "Bill of invoice",
              status: "pending",
              data: [
                {
                  sr_no: "01",
                  document: {
                    document_count_limits: {
                      max: 1,
                      min: 1,
                      current: 0,
                    },
                    sub_title: "PDF file supported | Max file size 5 MB",
                    uploaded_documents: [],
                    upload_enabled: true,
                    dropdown: false,
                    required: false,
                    slug: "COMMERCIAL_INVOICE",
                  },
                  id: 169,
                  text: "Invoice 28/FD",
                },
              ],
            },
            transport_docs: {
              transport_mode: {
                name: "mode_of_transport",
                header: "Mode of transport",
                options: [
                  {
                    text: "Road",
                    slug: "road",
                  },
                  {
                    text: "Ship",
                    slug: "ship",
                  },
                  {
                    text: "Rail",
                    slug: "rail",
                  },
                  {
                    text: "Air",
                    slug: "air",
                  },
                  {
                    text: "Multi Mode",
                    slug: "multi_mode",
                  },
                ],
                slug: "mode_of_transport",
                type: "radio",
              },
              document_type: {
                is_required: false,
                is_searchable: false,
                type: "dropdown",
                slug: "document_type",
                header: "Select document type",
                options: [
                  {
                    label: "Bill of lading",
                    value: "bill_of_lading",
                  },
                  {
                    label: "Others",
                    value: "others",
                  },
                ],
              },
              document_type_other: {
                sub_type: null,
                is_required: false,
                default_value: "",
                fixed_text: null,
                left_padding: null,
                type: "text_box",
                slug: "document_type_other",
                title: "Please mention the other document type",
                limits: null,
                placeholder: "Enter other document type",
                icon: null,
                disabled: false,
                isVerified: false,
              },
              title: "Transport Details",
              status: "completed",
              data: [
                {
                  sr_no: "01",
                  document: {
                    document_count_limits: {
                      max: 1,
                      min: 1,
                      current: 0,
                    },
                    sub_title: "PDF file supported | Max file size 5 MB",
                    uploaded_documents: [],
                    upload_enabled: false,
                    dropdown: false,
                    required: false,
                    slug: "TRANSPORT_DOC",
                  },
                  text: "Bill of lading",
                },
              ],
            },
            slug: "shipping_bill_attachments",
            deeplink: "/bills/sbb/178",
            buttons: [
              {
                sub_type: "primary",
                disabled: false,
                show_loader: false,
                text: "Submit",
                slug: "submit",
              },
            ],
          },
        },
      },
      {
        id: 177,
        deeplink: "/bills/sbb/177",
        buyer_name: {
          disabled: false,
          text: "QRS PRIVATE LIMITED",
          type: "plain",
          alignment: "left",
        },
        sb_no: {
          disabled: false,
          text: "4713850",
          type: "plain",
          alignment: "left",
        },
        sb_date: {
          disabled: false,
          text: "10 Oct, 2023",
          type: "plain",
          alignment: "center",
        },
        invoice_value: {
          hz_text: "NZD",
          disabled: false,
          text: "11,473",
          type: "plain",
          alignment: "center",
        },
        bank_flow: {
          invoice_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "invoice_docs",
          },
          transport_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "transport_docs",
          },
          text: "action",
          type: "bank_flow",
          alignment: "center",
          action: {
            sub_type: "info",
            disabled: false,
            text: "Track",
            alignment: "center",
            deeplink: "/bills/sbb/177",
            slug: "action",
          },
          modal: null,
        },
      },
      {
        id: 179,
        deeplink: "/bills/sbb/179",
        buyer_name: {
          disabled: false,
          text: "GHI PRIVATE LIMITED",
          type: "plain",
          alignment: "left",
        },
        sb_no: {
          disabled: false,
          text: "4720929",
          type: "plain",
          alignment: "left",
        },
        sb_date: {
          disabled: false,
          text: "10 Oct, 2023",
          type: "plain",
          alignment: "center",
        },
        invoice_value: {
          hz_text: "USD",
          disabled: false,
          text: "113.76",
          type: "plain",
          alignment: "center",
        },
        bank_flow: {
          invoice_docs: {
            text: "0 of 1 pending",
            icon: "https://static.bluno.in/miscl/upload-icon.png",
            status: "pending",
            alignment: "center",
            disabled: false,
            slug: "invoice_docs",
          },
          transport_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "transport_docs",
          },
          text: "action",
          type: "bank_flow",
          alignment: "center",
          action: {
            sub_type: "neutral",
            disabled: false,
            text: "Upload invoice bill",
            alignment: "center",
            deeplink: "/bills/sbb/179",
            slug: "action",
          },
          modal: {
            all_documents_status: false,
            deeplink_icon: "https://static.bluno.in/miscl/redirect-icon.png",
            close_icon: "https://static.bluno.in/miscl/cross_black.png",
            invoice_docs: {
              title: "Bill of invoice",
              status: "pending",
              data: [
                {
                  sr_no: "01",
                  document: {
                    document_count_limits: {
                      max: 1,
                      min: 1,
                      current: 0,
                    },
                    sub_title: "PDF file supported | Max file size 5 MB",
                    uploaded_documents: [],
                    upload_enabled: true,
                    dropdown: false,
                    required: false,
                    slug: "COMMERCIAL_INVOICE",
                  },
                  id: 170,
                  text: "Invoice BSIM23-22003343",
                },
              ],
            },
            transport_docs: {
              transport_mode: {
                name: "mode_of_transport",
                header: "Mode of transport",
                options: [
                  {
                    text: "Road",
                    slug: "road",
                  },
                  {
                    text: "Ship",
                    slug: "ship",
                  },
                  {
                    text: "Rail",
                    slug: "rail",
                  },
                  {
                    text: "Air",
                    slug: "air",
                  },
                  {
                    text: "Multi Mode",
                    slug: "multi_mode",
                  },
                ],
                slug: "mode_of_transport",
                type: "radio",
              },
              document_type: {
                is_required: false,
                is_searchable: false,
                type: "dropdown",
                slug: "document_type",
                header: "Select document type",
                options: [
                  {
                    label: "Bill of lading",
                    value: "bill_of_lading",
                  },
                  {
                    label: "Others",
                    value: "others",
                  },
                ],
              },
              document_type_other: {
                sub_type: null,
                is_required: false,
                default_value: "",
                fixed_text: null,
                left_padding: null,
                type: "text_box",
                slug: "document_type_other",
                title: "Please mention the other document type",
                limits: null,
                placeholder: "Enter other document type",
                icon: null,
                disabled: false,
                isVerified: false,
              },
              title: "Transport Details",
              status: "completed",
              data: [
                {
                  sr_no: "01",
                  document: {
                    document_count_limits: {
                      max: 1,
                      min: 1,
                      current: 1,
                    },
                    sub_title: "PDF file supported | Max file size 5 MB",
                    uploaded_documents: [
                      {
                        icon: null,
                        id: "0b4f9cc3-a213-4fd9-b513-760d81e5499c",
                        name: "blank.pdf",
                        size: null,
                        actions: [
                          {
                            alert_id: "detach_alert_id",
                            action: "detach",
                            icon: "https://static.bluno.in/miscl/cross_black.png",
                          },
                          {
                            alert_id: null,
                            action: "download",
                            icon: "https://static.bluno.in/miscl/download_black.png",
                          },
                          {
                            alert_id: null,
                            action: "view",
                            icon: "https://static.bluno.in/miscl/eye_black.png",
                          },
                        ],
                      },
                    ],
                    upload_enabled: false,
                    dropdown: false,
                    required: false,
                    slug: "TRANSPORT_DOC",
                  },
                  text: "Bill of lading",
                },
              ],
            },
            slug: "shipping_bill_attachments",
            deeplink: "/bills/sbb/179",
            buttons: [
              {
                sub_type: "primary",
                disabled: false,
                show_loader: false,
                text: "Submit",
                slug: "submit",
              },
            ],
          },
        },
      },
      {
        id: 176,
        deeplink: "/bills/sbb/176",
        buyer_name: {
          disabled: false,
          text: "QRS PRIVATE LIMITED",
          type: "plain",
          alignment: "left",
        },
        sb_no: {
          small_tag: {
            sub_type: "negative",
            text: "Overdue",
          },
          disabled: false,
          text: "4427758",
          type: "plain",
          alignment: "left",
        },
        sb_date: {
          disabled: false,
          text: "26 Sept, 2023",
          type: "plain",
          alignment: "center",
        },
        invoice_value: {
          hz_text: "NZD",
          disabled: false,
          text: "11,175",
          type: "plain",
          alignment: "center",
        },
        bank_flow: {
          invoice_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "invoice_docs",
          },
          transport_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "transport_docs",
          },
          text: "action",
          type: "bank_flow",
          alignment: "center",
          action: {
            sub_type: "positive",
            disabled: false,
            text: "Generate eBRC",
            alignment: "center",
            deeplink: "/bills/sbb/176",
            slug: "action",
          },
          modal: null,
        },
      },
      {
        id: 175,
        deeplink: "/bills/sbb/175",
        buyer_name: {
          disabled: false,
          text: "QRS PRIVATE LIMITED",
          type: "plain",
          alignment: "left",
        },
        sb_no: {
          small_tag: {
            sub_type: "negative",
            text: "Overdue",
          },
          disabled: false,
          text: "4272081",
          type: "plain",
          alignment: "left",
        },
        sb_date: {
          disabled: false,
          text: "19 Sept, 2023",
          type: "plain",
          alignment: "center",
        },
        invoice_value: {
          hz_text: "NZD",
          disabled: false,
          text: "11,175",
          type: "plain",
          alignment: "center",
        },
        bank_flow: {
          invoice_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "invoice_docs",
          },
          transport_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "transport_docs",
          },
          text: "action",
          type: "bank_flow",
          alignment: "center",
          action: {
            sub_type: "positive",
            disabled: false,
            text: "Generate eBRC",
            alignment: "center",
            deeplink: "/bills/sbb/175",
            slug: "action",
          },
          modal: null,
        },
      },
      {
        id: 174,
        deeplink: "/bills/sbb/174",
        buyer_name: {
          disabled: false,
          text: "QRS PRIVATE LIMITED",
          type: "plain",
          alignment: "left",
        },
        sb_no: {
          small_tag: {
            sub_type: "negative",
            text: "Overdue",
          },
          disabled: false,
          text: "4112655",
          type: "plain",
          alignment: "left",
        },
        sb_date: {
          disabled: false,
          text: "12 Sept, 2023",
          type: "plain",
          alignment: "center",
        },
        invoice_value: {
          hz_text: "NZD",
          disabled: false,
          text: "10,875",
          type: "plain",
          alignment: "center",
        },
        bank_flow: {
          invoice_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "invoice_docs",
          },
          transport_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "transport_docs",
          },
          text: "action",
          type: "bank_flow",
          alignment: "center",
          action: {
            sub_type: "positive",
            disabled: false,
            text: "Generate eBRC",
            alignment: "center",
            deeplink: "/bills/sbb/174",
            slug: "action",
          },
          modal: null,
        },
      },
      {
        id: 173,
        deeplink: "/bills/sbb/173",
        buyer_name: {
          disabled: false,
          text: "QRS PRIVATE LIMITED",
          type: "plain",
          alignment: "left",
        },
        sb_no: {
          small_tag: {
            sub_type: "negative",
            text: "Overdue",
          },
          disabled: false,
          text: "3817970",
          type: "plain",
          alignment: "left",
        },
        sb_date: {
          disabled: false,
          text: "29 Aug, 2023",
          type: "plain",
          alignment: "center",
        },
        invoice_value: {
          hz_text: "NZD",
          disabled: false,
          text: "10,875",
          type: "plain",
          alignment: "center",
        },
        bank_flow: {
          invoice_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "invoice_docs",
          },
          transport_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "transport_docs",
          },
          text: "action",
          type: "bank_flow",
          alignment: "center",
          action: {
            sub_type: "positive",
            disabled: false,
            text: "Generate eBRC",
            alignment: "center",
            deeplink: "/bills/sbb/173",
            slug: "action",
          },
          modal: null,
        },
      },
      {
        id: 172,
        deeplink: "/bills/sbb/172",
        buyer_name: {
          disabled: false,
          text: "QRS PRIVATE LIMITED",
          type: "plain",
          alignment: "left",
        },
        sb_no: {
          small_tag: {
            sub_type: "negative",
            text: "Overdue",
          },
          disabled: false,
          text: "3364081",
          type: "plain",
          alignment: "left",
        },
        sb_date: {
          disabled: false,
          text: "08 Aug, 2023",
          type: "plain",
          alignment: "center",
        },
        invoice_value: {
          hz_text: "NZD",
          disabled: false,
          text: "10,875",
          type: "plain",
          alignment: "center",
        },
        bank_flow: {
          invoice_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "invoice_docs",
          },
          transport_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "transport_docs",
          },
          text: "action",
          type: "bank_flow",
          alignment: "center",
          action: {
            sub_type: "positive",
            disabled: false,
            text: "Generate eBRC",
            alignment: "center",
            deeplink: "/bills/sbb/172",
            slug: "action",
          },
          modal: null,
        },
      },
      {
        id: 185,
        deeplink: "/bills/sbb/185",
        buyer_name: {
          disabled: false,
          text: "ABC PRIVATE LIMITED",
          type: "plain",
          alignment: "left",
        },
        sb_no: {
          small_tag: {
            sub_type: "negative",
            text: "Overdue",
          },
          disabled: false,
          text: "9253682",
          type: "plain",
          alignment: "left",
        },
        sb_date: {
          disabled: false,
          text: "13 Apr, 2023",
          type: "plain",
          alignment: "center",
        },
        invoice_value: {
          hz_text: "USD",
          disabled: false,
          text: "20,985",
          type: "plain",
          alignment: "center",
        },
        bank_flow: {
          invoice_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "invoice_docs",
          },
          transport_docs: {
            icon: "https://static.bluno.in/miscl/Step-Done-Green.png",
            status: "completed",
            alignment: "center",
            disabled: true,
            slug: "transport_docs",
          },
          text: "action",
          type: "bank_flow",
          alignment: "center",
          action: {
            sub_type: "positive",
            disabled: false,
            text: "Generate eBRC",
            alignment: "center",
            deeplink: "/bills/sbb/185",
            slug: "action",
          },
          modal: null,
        },
      },
    ],
    right_panel: {
      close_cta: {
        disabled: false,
        type: "cross",
      },
      button: {
        sub_type: "primary",
        icon_alignment: "right",
        disabled: false,
        show_loader: false,
        type: "button",
        icon: "https://static.bluno.in/miscl/next.png",
        text: "Submit",
        action: "close_right_panel",
      },
      document_data: [
        {
          document_count_limits: {
            max: 10,
            min: 1,
            current: 14,
          },
          sub_title: "PDF file supported | Max file size 5 MB",
          upload_enabled: true,
          dropdown: true,
          required: true,
          status: {
            slug: "uploaded",
            text: "Uploaded",
          },
          title: "Shipping Bill",
          slug: "SHIPPING_BILL",
        },
      ],
      view: "add_new_sb",
      title: "Add New Shipping Bill",
    },
    buttons: [
      {
        sub_type: "primary",
        icon_alignment: "left",
        disabled: false,
        show_loader: false,
        type: "button",
        icon: "https://static.bluno.in/miscl/add_icon.png",
        text: "Add Shipping Bill",
        action: "right_panel",
        slug: "add_sb",
      },
    ],
    filters: [
      {
        sub_type: null,
        is_required: false,
        default_value: null,
        fixed_text: null,
        left_padding: null,
        type: "text_box",
        slug: "buyer_name",
        title: "Buyer Name",
        limits: null,
        placeholder: "Buyer Name",
        icon: "https://static.bluno.in/miscl/search.png",
        disabled: false,
        isVerified: false,
      },
      {
        sub_type: null,
        is_required: false,
        default_value: null,
        fixed_text: null,
        left_padding: null,
        type: "text_box",
        slug: "sb_no",
        title: "Shipping Bill No.",
        limits: null,
        placeholder: "Shipping Bill No.",
        icon: "https://static.bluno.in/miscl/search.png",
        disabled: false,
        isVerified: false,
      },
      {
        sub_type: "date",
        is_required: true,
        standard_durations: [
          {
            start_date: "2024-06-12",
            end_date: "2024-07-12",
            is_active: false,
            key: "Last 30 Days",
            value: "thirty_days",
          },
          {
            start_date: "2024-01-12",
            end_date: "2024-07-12",
            is_active: false,
            key: "Last 6 Months",
            value: "six_months",
          },
          {
            start_date: "2023-07-12",
            end_date: "2024-07-12",
            is_active: false,
            key: "Last 1 Year",
            value: "one_year",
          },
          {
            start_date: "2014-07-12",
            end_date: "2024-07-12",
            is_active: true,
            key: "All",
            value: "inception",
          },
        ],
        default: {
          start_date: "2014-07-12",
          end_date: "2024-07-12",
          is_active: true,
          key: "All",
          value: "inception",
        },
        type: "date_picker",
        slug: "duration",
        name: null,
        title: "Duration",
        limits: {
          min: "2015-01-01",
          max: "2024-07-12",
        },
        placeholder: "Enter",
        icon: "https://static.bluno.in/miscl/calendar_icon.png",
      },
      {
        is_required: false,
        is_searchable: true,
        default: {
          label: "All",
          value: "all",
        },
        type: "dropdown",
        title: "Status",
        slug: "filter_category",
        options: [
          {
            label: "All Categories",
            value: "all",
          },
          {
            label: "Outstanding",
            value: "outstanding",
          },
          {
            label: "Standard",
            value: "standard",
          },
          {
            label: "Overdue",
            value: "overdue",
          },
          {
            label: "In Process",
            value: "in_process",
          },
          {
            label: "Closed",
            value: "closed",
          },
        ],
        label: "Status",
      },
      {
        is_required: false,
        is_searchable: true,
        default: {
          label: "All Currency",
          value: "ALL",
        },
        type: "dropdown",
        title: "Currency",
        slug: "currency",
        options: [
          {
            label: "All Currency",
            value: "ALL",
          },
          {
            label: "NZD",
            value: "NZD",
          },
          {
            label: "USD",
            value: "USD",
          },
        ],
        label: "Currency",
      },
    ],
    pagination: {
      current_page: 1,
      total_pages: 2,
      total_results: 14,
    },
  },
  message: "",
};

export const PLATFORM_SETTINGS_RESPONSE = {
  success: true,
  data: {
    title: "Platform Preference",
    sections: [
      {
        slug: "currency",
        title: "Currency",
        description:
          "Select default currency format and its unit for dashboard.",
        fields: [
          {
            type: "dropdown",
            title: "Currency Unit (in Rupee)",
            slug: "currency_unit_rupee",
            selected_value: "L (Lakhs)",
            default_value: "lakh",
            label_edit_icon: "https://static.bluno.in/miscl/edit-icon-grey.png",
            label_edit_icon_tooltip: "Edit",
            options: [
              {
                label: "K (Thousands)",
                value: "thousand",
              },
              {
                label: "L (Lakhs)",
                value: "lakh",
              },
              {
                label: "Cr (Crores)",
                value: "crore",
              },
            ],
            // placeholder: "Status",
            // label: "Status",
            disabled: false,
            is_required: false,
            is_searchable: true,
          },
          {
            disabled: false,
            show_loader: false,
            slug: "save",
            sub_type: "primary",
            icon_alignment: "left",
            text: "Save change",
            icon: "https://static.bluno.in/miscl/white_tick.png",
            type: "button",
          },
          // {
          //   type: "dropdown",
          //   title: "Currency Unit (in Dollars)",
          //   slug: "currency_unit_dollers",
          //   selected_value: "M (Millions)",
          //   default_value: "million",
          //   options: [
          //     {
          //       label: "K (Thousands)",
          //       value: "thousand",
          //     },
          //     {
          //       label: "M (Millions)",
          //       value: "million",
          //     },
          //     {
          //       label: "B (Billions)",
          //       value: "billion",
          //     },
          //     {
          //       label: "T (Trillions)",
          //       value: "trillion",
          //     },
          //   ],
          //   // placeholder: "Status",
          //   // label: "Status",
          //   disabled: false,
          //   is_required: false,
          //   is_searchable: true,
          // },
          // {
          //   type: "dropdown",
          //   title: "FX Rate Update Duration",
          //   slug: "fx_rate_update_duration",
          //   selected_value: "Every 12 hours",
          //   default_value: "12_hours",
          //   options: [
          //     {
          //       label: "Every 6 hours",
          //       value: "6_hours",
          //     },
          //     {
          //       label: "Every 12 hours",
          //       value: "12_hours",
          //     },
          //     {
          //       label: "Every 24 hours",
          //       value: "24_hours",
          //     },
          //     {
          //       label: "Every 48 hours",
          //       value: "48_hours",
          //     },
          //     {
          //       label: "Every 78 hours",
          //       value: "78_hours",
          //     },
          //   ],
          //   // placeholder: "Status",
          //   // label: "Status",
          //   disabled: false,
          //   is_required: false,
          //   is_searchable: true,
          // },
        ],
      },
      // {
      //   slug: "notification",
      //   title: "Notification",
      //   description: "Select type of notification and its duration",
      //   fields: [
      //     {
      //       type: "dropdown",
      //       title: "Notification Checklist",
      //       slug: "notification_type",
      //       selected_value: "All Notifications",
      //       default_value: "all",
      //       options: [
      //         {
      //           label: "All Notifications",
      //           value: "all",
      //         },
      //         {
      //           label: "Emails",
      //           value: "emails",
      //         },
      //         {
      //           label: "SMS",
      //           value: "sms",
      //         },
      //         {
      //           label: "Call/Push",
      //           value: "call",
      //         },
      //       ],
      //       // placeholder: "Status",
      //       // label: "Status",
      //       disabled: false,
      //       is_required: false,
      //       is_searchable: true,
      //     },
      //     {
      //       type: "dropdown",
      //       title: "Notification Frequency",
      //       slug: "notification_frequency",
      //       selected_value: "Real Time",
      //       default_value: "real_time",
      //       options: [
      //         {
      //           label: "Real Time",
      //           value: "real_time",
      //         },
      //         {
      //           label: "Daily",
      //           value: "daily",
      //         },
      //         {
      //           label: "Weekly",
      //           value: "weekly",
      //         },
      //         {
      //           label: "Monthly",
      //           value: "monthly",
      //         },
      //       ],
      //       // placeholder: "Status",
      //       // label: "Status",
      //       disabled: false,
      //       is_required: false,
      //       is_searchable: true,
      //     },
      //   ],
      // },
      // {
      //   slug: "date_time",
      //   title: "Date & Time",
      //   description: "Select default date formate and time line duration",

      //   fields: [
      //     {
      //       type: "dropdown",
      //       title: "Date Format",
      //       slug: "date_format",
      //       selected_value: "12 Jan 2024",
      //       default_value: "dd-MMM-yyyy",
      //       options: [
      //         {
      //           label: "12 Jan 2024",
      //           value: "dd-MMM-yyyy",
      //         },
      //         {
      //           label: "12 / 01 / 2024",
      //           value: "dd-MM-yyyy",
      //         },
      //         {
      //           label: "01 / 12 / 2024",
      //           value: "MM-dd-yyyy",
      //         },
      //         {
      //           label: "2024 / 01 / 12",
      //           value: "yyyy-MM-dd",
      //         },
      //         {
      //           label: "Jan, 12, 2024",
      //           value: "MMM-",
      //         },
      //       ],
      //       // placeholder: "Status",
      //       // label: "Status",
      //       disabled: false,
      //       is_required: false,
      //       is_searchable: true,
      //     },
      //     {
      //       type: "dropdown",
      //       title: "Timeline Format",
      //       slug: "timeline_format",
      //       selected_value: "Last 6 months",
      //       default_value: "six_months",
      //       options: [
      //         {
      //           label: "Last 30 days",
      //           value: "one_month",
      //         },
      //         {
      //           label: "Last 6 months",
      //           value: "six_months",
      //         },
      //         {
      //           label: "Last 1 year",
      //           value: "one_year",
      //         },
      //       ],
      //       // placeholder: "Status",
      //       // label: "Status",
      //       disabled: false,
      //       is_required: false,
      //       is_searchable: true,
      //     },
      //   ],
      // },
    ],
  },
};

export const DEMO_FORM = {
  title: "To start your journey, <span>request a free demo</span>",
  form_data: [
    {
      id: 1,
      slug: "company_name",
      type: "input",
      sub_type: "text",
      label: "Company Name <span>*</span>",
      placeholder: "Company name",
      column: "1/3",
      row: "1",
    },
    {
      id: 2,
      slug: "mobile_number",
      type: "input",
      sub_type: "tel",
      label: "Mobile Number <span>*</span>",
      placeholder: "Mobile number",
      column: "1/3",
      row: "2",
    },
    {
      id: 3,
      slug: "email_address",
      type: "input",
      sub_type: "email",
      label: "Email ID (as per IEC) <span>*</span>",
      placeholder: "Email ID",
      column: "1/3",
      row: "3",
    },
    {
      id: 4,
      slug: "client_type",
      type: "checkbox",
      label: "Are you into <span>(Optional)</span>",
      column: "1/3",
      row: "4",
      options: [
        {
          text: "Export",
          slug: "export",
          name: "client_type",
        },
        {
          text: "Import",
          slug: "import",
          name: "client_type",
        },
      ],
    },
    // {
    //   id: 5,
    //   slug: "export_turnover_currency",
    //   type: "dropdown",
    //   label: "Annual export turnover range<span> (optional)</span>",
    //   column: "1/3",
    //   row: "4",
    //   column_mobile: "1/3",
    //   row_mobile: "5",
    //   options: [
    //     { label: "INR", value: "inr" },
    //     { label: "USD", value: "usd" },
    //     ,
    //   ],
    // },
    // {
    //   id: 6,
    //   slug: "export_turnover_range",
    //   type: "slider",
    //   column: "1/3",
    //   row: "5",
    //   column_mobile: "1/3",
    //   row_mobile: "6",
    //   step: 1,
    //   min: 0,
    //   max: 500,
    //   marks: [
    //     {
    //       value: 2,
    //       text1: "Upto 1",
    //       text2: "crore",
    //     },
    //     {
    //       value: 100,
    //       text1: "5",
    //       text2: "crore",
    //     },
    //     {
    //       value: 200,
    //       text1: "25",
    //       text2: "crore",
    //     },
    //     {
    //       value: 300,
    //       text1: "50",
    //       text2: "crore",
    //     },
    //     {
    //       value: 400,
    //       text1: "100",
    //       text2: "crore",
    //     },
    //     {
    //       value: 493,
    //       text1: "Above",
    //       text2: "500 crore",
    //     },
    //   ],
    //   marksUSD: [
    //     {
    //       value: 2,
    //       text1: "Upto 100",
    //       text2: "thousand",
    //     },
    //     {
    //       value: 102,
    //       text1: "500",
    //       text2: "thousand",
    //     },
    //     {
    //       value: 200,
    //       text1: "1",
    //       text2: "million",
    //     },
    //     {
    //       value: 300,
    //       text1: "5",
    //       text2: "million",
    //     },
    //     {
    //       value: 400,
    //       text1: "25",
    //       text2: "million",
    //     },
    //     {
    //       value: 493,
    //       text1: "Above 50",
    //       text2: "million",
    //     },
    //   ],
    // },
    {
      id: 5,
      slug: "agreement",
      column: "1/3",
      row: "5",
      type: "text",
      text: 'By continuing you agree to our <a href="https://bluno.in/privacy-policy" target="_parent">privacy policy</a> and <a href="https://bluno.in/terms-of-service" target="_parent">terms of service</a>',
    },
    {
      id: 7,
      slug: "request_demo",
      column: "1/3",
      row: "6",
      type: "button",
      button_label: "Book Demo",
      button_type: "primary",
      icon: "https://static.bluno.in/miscl/request-demo.png",
      iconAlignment: "left",
    },
  ],
};

export const RECON_MAP_IRM_DUMMY = {
  success: true,
  data: {
    sb_type: "type_1",
    highlighted_data_v2: {
      header: {
        text1: "SB #",
        text2: "4713850",
        text3: "<span>Buyer: </span> QRS PRIVATE LIMITED",
        summary: {
          left: [
            {
              title: "Invoice Amount",
              text: "11,473 NZD",
              type: "plain",
              disabled: false,
              show_hover: false,
            },
            {
              title: "Attached Amount",
              text: "11,473 NZD",
              type: "plain",
              disabled: false,
              show_hover: false,
            },
          ],
          right: [
            {
              title: "Balance Amount",
              text: "0 NZD",
              type: "plain",
              color: "#00963D",
              disabled: false,
              show_hover: false,
            },
          ],
        },
        tag_info: {
          text: "Standard",
          type: "tag",
          sub_type: "standard",
        },
        dropdown: true,
        is_dropdown_open: false,
        back_info: {
          icon: "https://static.bluno.in/miscl/left_arrow.png",
          deeplink: "/bills/sb",
          hover_icon: "https://static.bluno.in/miscl/left_arrow_bghover.png",
          hover_text: "Export Bills",
          show_hover: true,
        },
        sb_doc: {
          action: "download",
          doc_id: "f426256d-208b-40e0-ae94-3b774872df19",
          doc_name: "Shipping_Bill_4713850.pdf",
          download_icon: "https://static.bluno.in/miscl/thin-download.png",
          download_hover_icon:
            "https://static.bluno.in/miscl/download_bghover.png",
          download_tooltip_text: "Download",
        },
        copy_doc_number: {
          text: "4713850",
          disabled: false,
          show_hover: false,
          sub_text: "Copy",
        },
      },
      middle: {
        source: "INBLR4 (Bangalore)",
        destination: "AKL (AUCKLAND)",
        source_text: "Port of Export : ",
        destination_text: "Port of Final Destination : ",
        mode_icon: {
          icon: "https://static.bluno.in/miscl/mode-air.png",
          hover_icon: "https://static.bluno.in/miscl/mode-air.png",
          hover_text: "Mode: Air",
          show_hover: true,
        },
        mode_info: "",
        arrow_icon: "https://static.bluno.in/miscl/arrow-active.png",
      },
      invoice_details: {
        title: "Number of invoices",
        invoices: [
          {
            container_data: [
              {
                title: "<span>Invoice Number 1</span>",
                text: "<span> :</span> 27/FD",
                row: "1",
                column: "1/3",
                disabled: false,
                show_hover: false,
                enable_copy: true,
                copy_text: "27/FD",
              },
              {
                title: "<span>Invoice Date</span>",
                text: "<span> :</span> 07 October, 2022",
                row: "1",
                column: "4/6",
                disabled: false,
                show_hover: false,
                enable_copy: false,
              },
              {
                title: "<span>Invoice Terms</span>",
                text: "<span> :</span> CF",
                row: "2",
                column: "1/3",
                disabled: false,
                show_hover: true,
                hover_icon: "https://static.bluno.in/miscl/tooltip_icon.png",
                hover_text: "COST FREIGHT",
                enable_copy: false,
              },
              {
                title: "<span>Invoice Currency</span>",
                text: "<span> :</span> NZD",
                row: "2",
                column: "4/6",
                disabled: false,
                show_hover: false,
                enable_copy: false,
              },
              {
                title: "<span>Invoice Amount</span>",
                text: "<span> :</span> 11,473 NZD",
                row: "3",
                column: "1/3",
                disabled: false,
                show_hover: false,
                expanded_data: {
                  text: "View breakup",
                  type: "hyperlink",
                  currency: "NZD",
                  highlighted_data: [
                    {
                      title: "FOB Amount",
                      text: "4,133.58",
                      type: "text",
                      disabled: false,
                      show_hover: false,
                    },
                    {
                      type: "icon",
                      icon: "https://static.bluno.in/miscl/plus.png",
                      disabled: false,
                      show_hover: false,
                    },
                    {
                      title: "Freight",
                      text: "7,339.42",
                      type: "text",
                      disabled: false,
                      show_hover: false,
                    },
                    {
                      type: "icon",
                      icon: "https://static.bluno.in/miscl/plus.png",
                      disabled: false,
                      show_hover: false,
                    },
                    {
                      title: "Insurance Amount",
                      text: "0",
                      type: "text",
                      disabled: false,
                      show_hover: false,
                    },
                    {
                      type: "icon",
                      icon: "https://static.bluno.in/miscl/equals_to.png",
                      disabled: false,
                      show_hover: false,
                    },
                    {
                      title: "Invoice Amount",
                      text: "11,473",
                      type: "text",
                      disabled: false,
                      show_hover: false,
                    },
                  ],
                },
                enable_copy: false,
              },
              {
                title: "<span>FX Rate</span>",
                text: "<span> :</span> 46.45 NZDINR ",
                row: "3",
                column: "4/6",
                disabled: false,
                show_hover: false,
                enable_copy: false,
              },
              {
                text: "Upload this invoice copy for verification",
                slug: "upload_invoice_text",
                row: "6",
                column: "1/3",
                disabled: false,
                show_hover: false,
                enable_copy: false,
              },
              {
                row: "6",
                column: "4/7",
                data: {
                  id: 168,
                  text: "Invoice 27/FD",
                  sr_no: "01",
                  document: {
                    slug: "COMMERCIAL_INVOICE",
                    document_count_limits: {
                      max: 1,
                      min: 1,
                      current: 1,
                    },
                    sub_title: "PDF file supported | Max file size 5 MB",
                    uploaded_documents: [
                      {
                        icon: null,
                        id: "9b04f352-4ecd-4a4b-b28a-6f49aaeae674",
                        name: "blank.pdf",
                        size: 4911,
                        actions: [
                          {
                            action: "detach",
                            icon: "https://static.bluno.in/miscl/dustbin-icon.png",
                            alert_id: "detach_alert_id",
                            disabled: false,
                            tooltip_text: "Delete",
                          },
                          {
                            action: "download",
                            icon: "https://static.bluno.in/miscl/thin-download.png",
                            alert_id: null,
                            disabled: false,
                            tooltip_text: "Download",
                          },
                        ],
                        metadata: {
                          flow: "sb_table",
                          sb_id: 177,
                          invoice_id: 168,
                        },
                        zip_file_parent: null,
                        error_message: null,
                        info_icon:
                          "https://static.bluno.in/miscl/Step-Done-Green.png",
                      },
                    ],
                    upload_enabled: false,
                    dropdown: false,
                    required: false,
                    multiple_files: false,
                  },
                },
                disabled: false,
                show_hover: false,
                enable_copy: false,
              },
            ],
            sb_id: 177,
          },
        ],
        total_invoices: 1,
        ad_code: {
          label: "AD Code: ",
          value: "6210325",
          slug: "sb_ad_code",
          icon: "https://static.bluno.in/banks/yes-bank-logo.png",
          enable_copy: true,
        },
      },
      transport_details: {
        title: "Transport Details",
        status: "completed",
        data: [
          {
            text: "",
            sr_no: "01",
            document: {
              slug: "TRANSPORT_DOC",
              document_count_limits: {
                max: 1,
                min: 1,
                current: 1,
              },
              sub_title: "PDF file supported | Max file size 5 MB",
              uploaded_documents: [
                {
                  icon: null,
                  id: "5702b594-ddfc-4a5f-a60f-e2b2bf4dd5d0",
                  name: "blank.pdf",
                  size: 4911,
                  actions: [
                    {
                      action: "detach",
                      icon: "https://static.bluno.in/miscl/dustbin-icon.png",
                      alert_id: "detach_alert_id",
                      disabled: false,
                      tooltip_text: "Delete",
                    },
                    {
                      action: "download",
                      icon: "https://static.bluno.in/miscl/thin-download.png",
                      alert_id: null,
                      disabled: false,
                      tooltip_text: "Download",
                    },
                  ],
                  metadata: {
                    flow: "sb_table",
                    sb_id: 177,
                    document_type: "others",
                    document_type_other: "Air Cargo",
                  },
                  zip_file_parent: null,
                  error_message: null,
                  info_icon:
                    "https://static.bluno.in/miscl/Step-Done-Green.png",
                },
              ],
              upload_enabled: false,
              dropdown: false,
              required: false,
              multiple_files: false,
            },
          },
        ],
        sb_type: "type_1",
        transport_mode: {
          header: "Mode of transport: ",
          text: "Air",
        },
        document_type: {
          type: "dropdown",
          title: "Select document type",
          slug: "document_type",
          options: [
            {
              label: "Bill of lading",
              value: "bill_of_lading",
            },
            {
              label: "Others",
              value: "others",
            },
          ],
          placeholder: "Select document type",
          disabled: false,
          is_required: false,
          is_searchable: false,
          default: {
            label: null,
            value: "others",
          },
          is_separator: false,
        },
        document_type_other: {
          type: "text_box",
          slug: "document_type_other",
          title: "Enter document type",
          limits: null,
          placeholder: "Enter document type",
          icon: null,
          disabled: false,
          isVerified: false,
          subtext: null,
          multiline: false,
          colspan: null,
          sub_type: null,
          is_required: false,
          default_value: "Air Cargo",
          fixed_text: null,
          secondary_icon: null,
          left_padding: null,
        },
        submitted_doc_text: "",
        e_commerce_data: null,
      },
    },
    reject_data: null,
    highlighted_data: null,
    recon_stages: null,
    recon_section: {
      sb_docs: {
        title: "Invoice and Transport Details",
        slug: "sb_docs",
        is_editable: false,
        status_icon: "https://static.bluno.in/miscl/blue_tick.png",
        is_dropdown: true,
        is_dropdown_open: false,
        sub_sections: [
          {
            title: "1 Invoice",
            data: [
              {
                id: 168,
                text: "Invoice 27/FD",
                sr_no: "01",
                document: {
                  slug: "COMMERCIAL_INVOICE",
                  document_count_limits: {
                    max: 1,
                    min: 1,
                    current: 1,
                  },
                  sub_title: "PDF file supported | Max file size 5 MB",
                  uploaded_documents: [
                    {
                      icon: null,
                      id: "9b04f352-4ecd-4a4b-b28a-6f49aaeae674",
                      name: "blank.pdf",
                      size: 4911,
                      actions: [
                        {
                          action: "detach",
                          icon: "https://static.bluno.in/miscl/dustbin-icon.png",
                          alert_id: "detach_alert_id",
                          disabled: false,
                          tooltip_text: "Delete",
                        },
                        {
                          action: "download",
                          icon: "https://static.bluno.in/miscl/thin-download.png",
                          alert_id: null,
                          disabled: false,
                          tooltip_text: "Download",
                        },
                      ],
                      metadata: {
                        flow: "sb_table",
                        sb_id: 177,
                        invoice_id: 168,
                      },
                      zip_file_parent: null,
                      error_message: null,
                      info_icon:
                        "https://static.bluno.in/miscl/Step-Done-Green.png",
                    },
                  ],
                  upload_enabled: false,
                  dropdown: false,
                  required: false,
                  multiple_files: false,
                },
              },
            ],
          },
          {
            title: "Transport Documents",
            data: [
              {
                text: "Bill Of Lading",
                document: {
                  slug: "TRANSPORT_DOC",
                  document_count_limits: {
                    max: 1,
                    min: 1,
                    current: 1,
                  },
                  sub_title: "PDF file supported | Max file size 5 MB",
                  uploaded_documents: [
                    {
                      icon: null,
                      id: "5702b594-ddfc-4a5f-a60f-e2b2bf4dd5d0",
                      name: "blank.pdf",
                      size: 4911,
                      actions: [
                        {
                          action: "detach",
                          icon: "https://static.bluno.in/miscl/dustbin-icon.png",
                          alert_id: "detach_alert_id",
                          disabled: false,
                          tooltip_text: "Delete",
                        },
                        {
                          action: "download",
                          icon: "https://static.bluno.in/miscl/thin-download.png",
                          alert_id: null,
                          disabled: false,
                          tooltip_text: "Download",
                        },
                      ],
                      metadata: {
                        flow: "sb_table",
                        sb_id: 177,
                        document_type: "others",
                        document_type_other: "Air Cargo",
                      },
                      zip_file_parent: null,
                      error_message: null,
                      info_icon:
                        "https://static.bluno.in/miscl/Step-Done-Green.png",
                    },
                  ],
                  upload_enabled: false,
                  dropdown: false,
                  required: false,
                  multiple_files: false,
                },
              },
            ],
          },
        ],
      },
      map_irms: {
        slug: "map_irms",
        title: "Map Inward Remittance",
        irms: {
          select_all_supported: false,
          selection_supported: true,
          selection_with_api: true,
          border_supported: false,
          has_full_height: false,
          enable_long_text: false,
          sub_title1: "10 Eligible Inward Remittance(s)",
          sub_title2: {
            text: "6 selected",
            type: "plain",
          },
          headers: [
            {
              text: "Reference No",
              slug: "irm_ref_no",
              alignment: "left",
              sort_supported: false,
            },
            {
              text: "Credit Date",
              slug: "value_date",
              alignment: "center",
              sort_supported: false,
            },
            {
              text: "Remittance Amount",
              slug: "fcy_amount",
              alignment: "center",
              sort_supported: false,
            },
            {
              text: "Remaining Amount",
              slug: "fcy_remaining_amount",
              alignment: "right",
              sort_supported: false,
            },
            {
              text: "Attached Amount",
              slug: "fcy_attached_amount",
              alignment: "right",
              sort_supported: false,
            },
          ],
          data: [
            {
              id: 80,
              is_selected: true,
              is_disabled: false,
              is_recommended: true,
              irm_ref_no: {
                text: "IRM_REF_Q_11",
                type: "plain",
                alignment: "left",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              value_date: {
                text: "10 Apr, 2024",
                type: "plain",
                alignment: "center",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_amount: {
                text: "7,000",
                type: "plain",
                alignment: "center",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_remaining_amount: {
                text: "0",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_attached_amount: {
                text: "7,000",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
            },
            {
              id: 71,
              is_selected: true,
              is_disabled: false,
              is_recommended: true,
              irm_ref_no: {
                text: "IRM_REF_Q_1",
                type: "plain",
                alignment: "left",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              value_date: {
                text: "10 Apr, 2024",
                type: "plain",
                alignment: "center",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_amount: {
                text: "1,000",
                type: "plain",
                alignment: "center",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_remaining_amount: {
                text: "0",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_attached_amount: {
                text: "1,000",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              attach_irm_modal_data: {
                variant: "modal",
                buttons: [
                  {
                    type: "button",
                    text: "Add",
                    action: "add_irm",
                    sub_type: "primary",
                    disabled: false,
                    show_loader: false,
                  },
                ],
                input_list: [
                  {
                    type: "dropdown",
                    title: "Select reason for IRM addition",
                    placeholder: "Select reason for IRM addition",
                    slug: "reason_for_irm_addition",
                    options: [
                      {
                        label: "Payment received from sister concern",
                        value: "payment",
                      },
                      {
                        label: "Wrong purpose code",
                        value: "wrong",
                      },
                      {
                        label: "Remitter name different",
                        value: "remitter",
                      },
                      {
                        label: "Other",
                        value: "other",
                      },
                    ],
                    disabled: false,
                    is_required: true,
                    is_searchable: true,
                    is_separator: false,
                  },
                  {
                    type: "text_box",
                    slug: "other_reson",
                    title: "Add Comment",
                    placeholder: "Add Comment",
                    icon: null,
                    disabled: false,
                    isVerified: false,
                    subtext: null,
                    multiline: false,
                    colspan: null,
                    sub_type: "string",
                    is_required: true,
                    default_value: null,
                    fixed_text: null,
                    secondary_icon: null,
                    left_padding: null,
                  },
                ],
              },
            },
            {
              id: 72,
              is_selected: true,
              is_disabled: false,
              is_recommended: true,
              irm_ref_no: {
                text: "IRM_REF_Q_2",
                type: "plain",
                alignment: "left",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              value_date: {
                text: "10 Apr, 2024",
                type: "plain",
                alignment: "center",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_amount: {
                text: "1,000",
                type: "plain",
                alignment: "center",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_remaining_amount: {
                text: "0",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_attached_amount: {
                text: "1,000",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
            },
            {
              id: 73,
              is_selected: true,
              is_disabled: false,
              is_recommended: true,
              irm_ref_no: {
                text: "IRM_REF_Q_3",
                type: "plain",
                alignment: "left",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              value_date: {
                text: "10 Apr, 2024",
                type: "plain",
                alignment: "center",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_amount: {
                text: "1,000",
                type: "plain",
                alignment: "center",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_remaining_amount: {
                text: "0",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_attached_amount: {
                text: "1,000",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
            },
            {
              id: 74,
              is_selected: true,
              is_disabled: false,
              is_recommended: true,
              irm_ref_no: {
                text: "IRM_REF_Q_4",
                type: "plain",
                alignment: "left",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              value_date: {
                text: "10 Apr, 2024",
                type: "plain",
                alignment: "center",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_amount: {
                text: "10,000",
                type: "plain",
                alignment: "center",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_remaining_amount: {
                text: "0",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_attached_amount: {
                text: "1,175",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
            },
            {
              id: 75,
              is_selected: true,
              is_disabled: false,
              is_recommended: true,
              irm_ref_no: {
                text: "IRM_REF_Q_6",
                type: "plain",
                alignment: "left",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              value_date: {
                text: "10 Apr, 2024",
                type: "plain",
                alignment: "center",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_amount: {
                text: "10,000",
                type: "plain",
                alignment: "center",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_remaining_amount: {
                text: "7,054",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_attached_amount: {
                text: "298",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
            },
            {
              id: 76,
              is_selected: false,
              is_disabled: false,
              is_recommended: false,
              irm_ref_no: {
                text: "IRM_REF_Q_7",
                type: "plain",
                alignment: "left",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              value_date: {
                text: "10 Apr, 2024",
                type: "plain",
                alignment: "center",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_amount: {
                text: "10,000",
                type: "plain",
                alignment: "center",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_remaining_amount: {
                text: "10,000",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_attached_amount: {
                text: "0",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
            },
            {
              id: 77,
              is_selected: false,
              is_disabled: false,
              is_recommended: false,
              irm_ref_no: {
                text: "IRM_REF_Q_8",
                type: "plain",
                alignment: "left",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              value_date: {
                text: "10 Apr, 2024",
                type: "plain",
                alignment: "center",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_amount: {
                text: "10,000",
                type: "plain",
                alignment: "center",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_remaining_amount: {
                text: "10,000",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_attached_amount: {
                text: "0",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
            },
            {
              id: 78,
              is_selected: false,
              is_disabled: false,
              is_recommended: false,
              irm_ref_no: {
                text: "IRM_REF_Q_9",
                type: "plain",
                alignment: "left",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              value_date: {
                text: "10 Apr, 2024",
                type: "plain",
                alignment: "center",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_amount: {
                text: "5,000",
                type: "plain",
                alignment: "center",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_remaining_amount: {
                text: "5,000",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_attached_amount: {
                text: "0",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
            },
            {
              id: 79,
              is_selected: false,
              is_disabled: false,
              is_recommended: false,
              irm_ref_no: {
                text: "IRM_REF_Q_19",
                type: "plain",
                alignment: "left",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              value_date: {
                text: "10 Apr, 2024",
                type: "plain",
                alignment: "center",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_amount: {
                text: "6,000",
                type: "plain",
                alignment: "center",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_remaining_amount: {
                text: "6,000",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
              fcy_attached_amount: {
                text: "0",
                type: "plain",
                alignment: "right",
                hz_text: "NZD",
                enable_copy: false,
                disabled: false,
                max_char_limit: 0,
              },
            },
          ],
        },
        buttons: [
          {
            type: "button",
            icon: "https://static.bluno.in/miscl/white_i.png",
            text: "Generate eBRC",
            slug: "1",
            sub_type: "primary",
            icon_alignment: "left",
            disabled: false,
            show_loader: false,
          },
        ],
        is_editable: false,
        is_dropdown: true,
        is_dropdown_open: true,
      },
    },
    alert_info: {
      detach_alert_id: {
        icon: null,
        type: "info",
        title: "Confirmation",
        text: "Are you sure to remove this file?",
        buttons: [
          {
            type: "button",
            text: "Yes",
            slug: "proceed",
            sub_type: "secondary",
            disabled: false,
            show_loader: false,
          },
          {
            type: "button",
            text: "No",
            slug: "ignore_and_close",
            sub_type: "primary",
            disabled: false,
            show_loader: false,
          },
        ],
        position: null,
        close_icon: null,
      },
      map_irm_alert_id: {
        icon: null,
        type: "info",
        title: "Heads up!",
        text: "This will reset your progress and require you to restart reconciliation. Do you want to proceed?",
        buttons: [
          {
            type: "button",
            text: "Yes",
            slug: "proceed",
            sub_type: "secondary",
            disabled: false,
            show_loader: false,
          },
          {
            type: "button",
            text: "No",
            slug: "ignore_and_close",
            sub_type: "primary",
            disabled: false,
            show_loader: false,
          },
        ],
        position: null,
        close_icon: null,
      },
      sb_docs_alert_id: {
        icon: "https://static.bluno.in/alert/blue.png",
        type: "info",
        title: "Confirmation",
        text: "Do you want to edit this section?",
        buttons: [
          {
            type: "button",
            text: "Yes",
            slug: "proceed",
            sub_type: "secondary",
            disabled: false,
            show_loader: false,
          },
          {
            type: "button",
            text: "No",
            slug: "ignore_and_close",
            sub_type: "primary",
            disabled: false,
            show_loader: false,
          },
        ],
        position: null,
        close_icon: null,
      },
    },
    post_reconciliation_data: null,
  },
  message: "",
};

export const BILL_LODGEMENT_DUMMY = {
  status: "pending",
  slug: "bill_lodgement",
  title: "Submission",
  is_editable: false,
  is_dropdown: true,
  is_dropdown_open: true,
  alert_container: {
    text: "Bill lodgement delayed for more than 21 days",
    icon: "https://static.bluno.in/miscl/red-warning-icon.png",
  },
  inputs: [
    {
      type: "dropdown",
      title: "Select reason for delay",
      slug: "bill_lodgemnet_delay_reason",
      options: [
        {
          label: "Delay in receiving transport documents",
          value: "delay_in_receiving_transport_documents",
        },
        {
          label: "Delay in shipping bill correction",
          value: "delay_in_shipping_bill_correction",
        },
        {
          label: "Other reason",
          value: "otther_reason",
        },
      ],
      placeholder: "Select reason for delay",
      disabled: false,
      is_required: true,
      is_searchable: true,
      is_separator: true,
      separator_index: 5,
      in_compact: false,
    },
    {
      type: "text_box",
      slug: "other_bill_lodgement_delay_reason",
      title: "Delay Reason (Other)",
      limits: null,
      placeholder: "Delay Reason (Other)",
      sub_type: "text",
      is_required: true,
    },
  ],
  declration: [
    {
      type: "checkbox",
      slug: "bill_lodgement_fema_declaration",
      is_checked: false,
      title:
        "I have thoroughly read and comprehended the standard FEMA declarations and hereby acknowledge my acceptance of them.",
    },
  ],
  buttons: [
    {
      text: "Submit To The Bank",
      slug: "submit_bill_lodgement",
      sub_type: "primary",
      disabled: false,
      show_loader: false,
    },
  ],
};

export const BILL_LODGEMENT_IN_PROGRESS_DUMMY = {
  status: "in_progress",
  status_text: "In Progress",
  slug: "bill_lodgement",
  title: "Bill lodgement",
  is_editable: false,
  is_dropdown: true,
  is_dropdown_open: true,
  in_progress_img: "https://static.bluno.in/miscl/default_asset.gif",
  text: "The bill lodgement for this SB has been successfully submitted. We will notify you once the lodgement ID is generated via <span>email and notification</span>.",
  date_text: "Date of submission :",
  date_submitted: "12 June, 2024",
};
