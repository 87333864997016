// react imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// third-party imports

// in-app imports
import Button from "../Button";
import CopyToClipboard from "../CopyToClipboard";

const PostBulkInfo = ({ data, closeModal }) => {
  const navigate = useNavigate();
  const [showCopyIcon, setShowCopyIcon] = useState(false);

  // const onMouseHoverEnter = (theSlug) => {
  //   setShowCopyIcon(true);
  // };

  // const onMouseHoverLeave = (theSlug) => {
  //   setShowCopyIcon(false);
  // };
  return (
    <>
      <div className="post-bulk">
        <div className="post-bulk__info">
          <div>
            <p className="post-bulk__ebrc">
              {data?.ebrc_generated_heading}
              <span> {data?.ebrc_generated_count}</span>
            </p>
          </div>
          <div className="post-bulk__bulkid-par">
            <p
              className="post-bulk__bulkid"
              onMouseEnter={() => setShowCopyIcon(true)}
              onMouseLeave={() => setShowCopyIcon(false)}
            >
              {data?.bulk_recon_heading} <span>{data?.bulk_recon_txn_id}</span>{" "}
              {showCopyIcon && (
                <CopyToClipboard text={data?.bulk_recon_txn_id} />
              )}
            </p>
          </div>
        </div>
        {data?.buttons && (
          <div className="post-bulk__action">
            {data?.buttons?.map((action) => {
              return (
                action?.type === "button" && (
                  <Button
                    buttonLabel={action?.text}
                    buttonType={action?.sub_type}
                    icon={action?.icon}
                    disabled={action?.disabled}
                    iconAlignment={action?.icon_alignment}
                    onClick={() => {
                      if (action?.deeplink) {
                        closeModal();
                        navigate(action?.deeplink);
                      }
                    }}
                  />
                )
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default PostBulkInfo;
