import React from "react";

import Dropdown from "../Dropdown";

const DropdownGroup = (props) => {
  const { item, value = "", onChange, disabled } = props;
  const { title = "", options = [], min_height } = item;

  return (
    <>
      <div className="df-group">
        {title && <p>{title}</p>}
        <div className="df-group__input">
          <Dropdown
            className="df-group__input"
            options={options}
            onSelectChange={onChange}
            defaultValue={value}
            isDisabled={disabled}
            fontSize="13px"
            placeholderFontSize="13px"
            minHeight={"200px"}
            height="38px"
          />
        </div>
      </div>
    </>
  );
};

export default DropdownGroup;
