import React, { useEffect, useState } from "react";

import { formatFileSize } from "../../utils/formatFileSize";
import BlunoTooltip from "../BlunoTooltip";

const UploadedList = ({
  files = [],
  file,
  idx = 0,
  onDocAction,
  section,
  maxFileNameWidth = "180px",
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let timer;
    if (file?.id) {
      setProgress(100);
    } else {
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 80) {
            clearInterval(timer);
            return 80;
          }
          return prevProgress + 5;
        });
      }, 100);
    }

    return () => {
      clearInterval(timer);
    };
  }, [file?.id, file]);

  return (
    <div
      className={`s-upload-card__file-card ${
        files.length - 1 !== idx && "s-upload-card__file-card--border"
      }`}
      key={idx}
    >
      <div className="s-uploaded-file-v2">
        <img
          src={
            file?.icon
              ? file.icon
              : "https://static.bluno.in/miscl/file_icon.png"
          }
          onError="this.onerror=null;this.src='https://static.bluno.in/miscl/file_icon.png';"
          alt="filename"
          className="s-uploaded-file-v2__file-icon"
        />
        <div className="s-upload-card__file-cont">
          <div className="s-upload-card__file-info">
            <div className="s-uploaded-file-v2__file-container">
              <p
                className="s-uploaded-file-v2__file-name"
                style={{ maxWidth: maxFileNameWidth }}
              >
                {file?.name?.split(".")[0]}
              </p>
              <p className="s-uploaded-file-v2__file-extension">
                .{file?.name?.split(".").pop()}
              </p>
            </div>
            <span className="s-upload-card__file-span">
              {file?.size && (
                <p className="s-upload-card__file-text">
                  {formatFileSize(file?.size)}
                </p>
              )}
              <div className="s-uploaded-file-v2-break" />
              {!file?.id && (
                <>
                  <p className="s-uploaded-file-v2__progress-info">{`${progress}% Uploaded`}</p>
                </>
              )}
            </span>
          </div>
          {!file?.id && (
            <div className="s-upload-card__bar">
              <div
                className="s-upload-card__progress"
                style={{
                  width: `${progress}%`,
                }}
              ></div>
            </div>
          )}
        </div>
        {file?.id && file?.info_icon && (
          <div className="s-uploaded-file-v2__action-container">
            <img
              className="s-uploaded-file-v2__action"
              src={file?.info_icon}
              alt="completed"
            />
          </div>
        )}
        <div className="s-upload-card__file-actions">
          {file?.actions?.map((action) => {
            return (
              <>
                {action?.action !== "detach" && (
                  <BlunoTooltip useChildren={true} text={action?.tooltip_text}>
                    <img
                      src={action?.icon}
                      alt={action?.action}
                      className={`s-uploaded-file-v2__action`}
                      onClick={() =>
                        onDocAction(section, action, file?.id, file?.name)
                      }
                    />
                  </BlunoTooltip>
                )}
              </>
            );
          })}
        </div>

        {file?.actions?.map((action) => {
          return (
            <>
              {action?.action === "detach" && (
                <BlunoTooltip useChildren={true} text={action?.tooltip_text}>
                  <img
                    src={action?.icon}
                    alt={action?.action}
                    className={`s-uploaded-file-v2__action`}
                    onClick={() => onDocAction(section, action, file?.id)}
                  />
                </BlunoTooltip>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default UploadedList;
