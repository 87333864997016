// react imports
import { createSlice } from "@reduxjs/toolkit";

// in-app imports

export const alertSlice = createSlice({
  name: "alert",
  initialState: {
    isAlertShow: false,
    isAlertOk: false,
    isAlertCanceled: false,
    alertProps: [],
    alertItems: {},
  },
  reducers: {
    showAlert: (state, action) => {
      state.isAlertShow = true;
      state.alertProps = action?.payload?.props;
      state.alertItems = action?.payload?.items;
    },
    closeAlert: (state, action) => {
      state.isAlertShow = false;
      state.isAlertCanceled = action?.payload?.isAlertCanceled ?? false;
    },
    okAlert: (state, action) => {
      state.isAlertOk = true;
    },
    resetAlertData: (state, action) => {
      state.isAlertOk = false;
      state.alertItems = {};
      state.alertProps = [];
      state.isAlertCanceled = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showAlert, okAlert, closeAlert, resetAlertData } =
  alertSlice.actions;

export default alertSlice.reducer;
