import React, { useEffect } from "react";
import Button from "./Button";
import BlunoTooltip from "./BlunoTooltip";
import { downloadDocumentUtility } from "../utils/downloadDocument";
import { sanitizeHtml } from "../utils/sanitizeHtml";

const TimelineCardV2 = ({
  data,
  showReconSummary = false,
  setShowReconSummary,
  currentStatus = "",
  showBankResponse = false,
  setShowBankResponse,
  onActionClick,
}) => {
  const handleButtonClick = (action) => {
    switch (action?.slug) {
      case "view_summary":
        setShowReconSummary(!showReconSummary);
        break;
      case "view_bank_response":
        setShowBankResponse(!showBankResponse);
        break;
      case "fetch_sb_recon_status":
        onActionClick("", action);
        break;
      case "ebrc_receipt":
        downloadDocumentUtility(action?.deeplink, action?.file_name);
        break;
    }
  };

  let timelineCardHeight = 170;
  data?.map((item) => {
    let calHeight =
      170 + item?.timeline_actions?.[0]?.timeline_buttons?.length * 20;
    if (timelineCardHeight < calHeight) {
      timelineCardHeight = calHeight;
    }
  });


  return (
    <div
      className={`v2-timeline v2-timeline--${currentStatus}`}
      style={{ height: `${timelineCardHeight}px` }}
    >
      {data?.map((item, idx) => {
        return (
          <div className="v2-timeline__timeline">
            {idx !== 0 && (
              <div
                className={`v2-timeline__line ${
                  item?.timeline_status !== "init" && "v2-timeline__line--green"
                }`}
              ></div>
            )}
            <div className="v2-timeline__card">
              <h2
                className={`v2-timeline__title v2-timeline__title--${item?.timeline_status}`}
              >
                {item?.title}
              </h2>
              <img
                className={`v2-timeline__icon ${
                  idx === 0
                    ? "v2-timeline__icon--left"
                    : idx === data?.length - 1
                    ? "v2-timeline__icon--right"
                    : "v2-timeline__icon--center"
                } v2-timeline__icon--${
                  item?.timeline_status
                } v2-timeline__icon-bg--${item?.timeline_status}`}
                src={item?.icon}
                alt={item?.timeline_status}
              />
              <div className="v2-timeline__bottom">
                <p
                  className="v2-timeline__time"
                  dangerouslySetInnerHTML={sanitizeHtml(item?.text1)}
                />
                <div className="v2-timeline__actions-cont">
                  {item?.timeline_actions?.map((item) => {
                    return (
                      <div className="v2-timeline__actions">
                        {item?.timeline_buttons &&
                          item?.timeline_buttons?.map((btn) => {
                            return (
                              <Button
                                buttonLabel={btn?.text}
                                buttonType={btn?.sub_type}
                                disabled={btn?.disabled}
                                tooltipText={btn?.tooltip_textw}
                                tooltipPosition={{
                                  bottom: "20px",
                                  left: "40px",
                                }}
                                icon={btn?.icon}
                                hoverIcon={btn?.hover_icon}
                                iconAlignment={btn?.icon_alignment}
                                onClick={() => handleButtonClick(btn)}
                              />
                            );
                          })}
                        {/* {item?.timeline_action && (
                          <BlunoTooltip
                            text={item?.timeline_action?.hover_text}
                            useChildren={true}
                          >
                            <div className="s-sbHeader__sb-download-background">
                              <img
                                src={item?.timeline_action?.icon}
                                alt={item?.timeline_action?.hover_text}
                                className={`s-sbHeader__sb-download`}
                              />
                            </div>
                          </BlunoTooltip>
                        )} */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TimelineCardV2;
