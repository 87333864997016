//react imports
import React, { useEffect, useState } from "react";

//redux imports
import { useDispatch } from "react-redux";
import { updateShowIrmForm, updateShowSbForm } from "../redux/common/slice";
import { apiRequest } from "../redux/common/actions";
import endpoints from "../api/endpoints";

//in app imports
import Dropdown from "./Dropdown";
import BlunoDatePicker from "./BlunoDatePicker";
import MuiTextInput from "./blunoInputs/MuiTextInputs";
import UploadFileV2 from "./UploadFileV2/UploadFileV2";
import Button from "./Button";
import UploadedFile from "./UploadFileV2/UploadedFile";
import { DOCUMENT_ACTION } from "../utils/constants/DocumentConstants";
import { downloadDocumentUtility } from "../utils/downloadDocument";

const AddInwardRemittance = ({ data }) => {
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [irmCount, setIrmCount] = useState(null);
  const [showIrmForm, setShowIrmForm] = useState(true);
  const [inputValues, setInputValues] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});

  const handleClose = () => {
    dispatch(updateShowIrmForm(false));
    dispatch(updateShowSbForm(false));
    setShowIrmForm(false);
  };

  const validationConditionToEnableSubmit = () => {
    const tempErrorMsg = {};

    // Helper function to check if an input exists and its value is not null
    const getInputValue = (name) => {
      const input = inputValues?.find((item) => item?.name === name);
      return input ? input?.value : null;
    };

    // Validate bank_name
    if (!getInputValue("bank_name")) {
      tempErrorMsg.bank_name = "Please select a bank";
    }

    // Validate irm_value_date
    if (!getInputValue("irm_value_date")) {
      tempErrorMsg.irm_value_date = "Value date is required";
    }

    // Validate irm_ref_no
    if (!getInputValue("irm_ref_no")) {
      tempErrorMsg.irm_ref_no = "IRM reference number is required";
    }

    // Validate purpose_code
    if (!getInputValue("purpose_code")) {
      tempErrorMsg.purpose_code = "Purpose code is required";
    }

    // Validate currency
    if (!getInputValue("currency")) {
      tempErrorMsg.currency = "Currency is required";
    }

    // Validate irm_amount
    if (!getInputValue("irm_amount")) {
      tempErrorMsg.irm_amount = "IRM amount is required";
    }

    // Validate document_id
    if (!getInputValue("document_id")) {
      tempErrorMsg.document_id = "Please upload a valid Inward Remittance";
    }

    if (Object.keys(tempErrorMsg).length > 0) {
      setErrorMessage({
        ...tempErrorMsg,
      });
      return false;
    }

    setErrorMessage({});
    return true;
  };

  const handleFormFieldChange = (value, theSlug) => {
    setErrorMessage({
      ...errorMessage,
      [theSlug]: null,
    });

    setInputValues((prevInputs) => {
      const existingInput = prevInputs?.find(
        (input) => input?.name === theSlug
      );

      if (existingInput) {
        return prevInputs?.map((input) =>
          input.name === theSlug ? { ...input, value } : input
        );
      } else {
        return [...prevInputs, { name: theSlug, value }];
      }
    });
  };

  const handleUploadIrm = async () => {
    if (!validationConditionToEnableSubmit()) {
      return;
    }

    const dataRes = await dispatch(
      apiRequest(endpoints.uploadFirstIrm, {
        user_input: inputValues,
      })
    );

    if (dataRes?.success && dataRes?.data) {
      setDisplayFiles((prevDisplayFiles) => [...prevDisplayFiles, ...files]);
      setShowIrmForm(false);
      setErrorMessage({});
      setFiles([]);
    } else {
      if (dataRes?.data?.slug_message_map) {
        const simplifiedErrorMessage = Object.keys(
          dataRes?.data?.slug_message_map
        ).reduce((acc, key) => {
          acc[key] = dataRes?.data?.slug_message_map[key].text;
          return acc;
        }, {});

        setErrorMessage(simplifiedErrorMessage);
      }
    }
  };

  const handleFileChange = () => {
    const documentId = files?.[files?.length - 1]?.[0]?.id || null;
    handleFormFieldChange(documentId, "document_id");
  };

  const handleButtonClick = (theBtn) => {
    switch (theBtn?.slug) {
      case "submit":
        handleClose();
        break;
      case "cancel":
        setShowIrmForm(false);
        setErrorMessage({});
        setInputValues([]);
        break;
      case "add_irm":
        handleUploadIrm();
        break;
    }
  };

  useEffect(() => {
    handleFileChange();
  }, [files]);

  useEffect(() => {
    setIrmCount(displayFiles?.length);
  }, [displayFiles]);

  const renderBtn = (theBtn) => {
    if (showIrmForm) {
      if (theBtn?.slug === "add_irm") {
        return (
          <Button
            buttonLabel={theBtn?.text}
            buttonType={theBtn?.sub_type}
            onClick={() => handleButtonClick(theBtn)}
          />
        );
      }

      if (theBtn?.slug === "cancel" && displayFiles?.length > 0) {
        return (
          <Button
            buttonLabel={theBtn?.text}
            buttonType={theBtn?.sub_type}
            onClick={() => handleButtonClick(theBtn)}
          />
        );
      }
    } else {
      if (theBtn?.slug === "submit") {
        return (
          <Button
            buttonLabel={theBtn?.text}
            buttonType={theBtn?.sub_type}
            onClick={() => handleButtonClick(theBtn)}
          />
        );
      }
    }
  };

  const onDocAction = (actionBtn, documentId, fileName) => {
    const docAction = async (actionBtn, documentId, fileName) => {
      const metadata = {
        flow: "irm_dashboard",
      };

      const dataRes = await dispatch(
        apiRequest(endpoints.docAction, {
          action: actionBtn,
          document_id: documentId,
          document_type: "FIRC",
          metadata: JSON.stringify(metadata),
        })
      );

      if (dataRes?.success && dataRes?.data) {
        if (dataRes?.data?.document_link) {
          if (actionBtn === DOCUMENT_ACTION?.["VIEW"]) {
            window.open(dataRes?.data?.document_link, "_blank");
          } else if (actionBtn === DOCUMENT_ACTION?.["DOWNLOAD"]) {
            downloadDocumentUtility(dataRes?.data?.document_link, fileName);
          }
        }
      }
    };
    docAction(actionBtn, documentId, fileName);
  };

  const renderField = (theItem) => {
    switch (theItem?.type) {
      case "text_box":
        return (
          <MuiTextInput
            placeholder={theItem?.placeholder}
            type={theItem?.sub_type}
            value={
              inputValues?.filter((item) => item?.name === theItem?.slug)?.[0]
                ?.value
            }
            errorMessage={errorMessage?.[theItem?.slug]}
            height="40px"
            minWidth="231px"
            width={theItem?.slug === "irm_amount" && "357px"}
            id={theItem?.slug}
            onChange={(e) => {
              handleFormFieldChange(e.target.value, theItem?.slug);
            }}
            fontSize="13px"
          />
        );
      case "dropdown":
        return (
          <Dropdown
            options={theItem?.options}
            defaultValue={
              inputValues?.filter((item) => item?.name === theItem?.slug)?.[0]
                ?.value
            }
            errorMessage={errorMessage?.[theItem?.slug]}
            height="40px"
            fontSize="13px"
            onSelectChange={(e) => {
              handleFormFieldChange(e.value, theItem?.slug);
            }}
            placeholder={theItem?.placeholder}
            placeholderFontSize="13px"
            placeholderFontWeight="400"
            fancyPlaceholder={true}
            topSpacing="-5px"
            iconWidth="10px"
            minWidth={theItem?.slug === "currency" ? "120px" : "231px"}
            isSearchable={theItem?.is_searchable}
            isSeparator={theItem?.is_separator}
            separatorIndex={theItem?.separator_index}
            menuHeight="230px"
          />
        );
      case "date_picker":
        return (
          <BlunoDatePicker
            title={theItem?.title}
            placeholder={theItem?.placeholder}
            height="40px"
            fontSize="13px"
            placeholderFontSize="13px"
            placeholderFontWeight="400"
            minWidth="231px"
            value={
              inputValues?.filter((item) => item?.name === theItem?.slug)?.[0]
                ?.value || null
            }
            errorMessage={errorMessage?.[theItem?.slug]}
            errorMessageFontSize="11px"
            onChange={(value) => {
              handleFormFieldChange(value, theItem?.slug);
            }}
          />
        );
    }
  };

  return (
    <div className="s-add-irm__container">
      <div className="s-add-irm">
        <div className="s-add-irm__header">
          <h1 className="s-add-irm__title">{data?.title}</h1>
          <img
            className="r-panel__icon s-add-irm__close"
            src={data?.close_cta?.deeplink}
            alt="close"
            onClick={() => {
              handleClose();
            }}
          />
        </div>

        {irmCount > 0 && (
          <div className="s-add-irm__uploaded">
            <p className="s-add-irm__uploaded-count">{`${irmCount} IRM uploaded`}</p>
            <div className="s-add-irm__list">
              {displayFiles?.map((item_files, mainIdx) => {
                return item_files?.map((item, idx) => {
                  return (
                    <UploadedFile
                      file={item}
                      // removeFile={removeFileFrontend}
                      onDocAction={onDocAction}
                      showDustbin={false}
                    />
                  );
                });
              })}
            </div>
            {!showIrmForm && (
              <div className={`s-add-irm__cta`}>
                <Button
                  buttonLabel={data?.add_irm_cta?.text}
                  buttonType={data?.add_irm_cta?.sub_type}
                  icon={data?.add_irm_cta?.icon}
                  hoverIcon={data?.add_irm_cta?.hover_icon}
                  iconAlignment={data?.add_irm_cta?.icon_alignment}
                  iconExtraClass="home__add-sb--extra"
                  onClick={() => {
                    setShowIrmForm(true);
                    setInputValues([]);
                  }}
                />
              </div>
            )}
          </div>
        )}

        {showIrmForm && (
          <div
          // className={`${!showIrmForm && "s-add-irm--hide"}`}
          >
            <div
              className="s-add-irm__form"
              style={{
                gridTemplateColumns: data?.form_data_v2?.grid_template_columns,
              }}
            >
              {data?.form_data_v2?.fields?.map((item) => {
                return (
                  <div
                    className={`s-add-irm__form-column ${
                      item?.slug !== "irm_amount" &&
                      "s-add-irm__form-column--width"
                    }`}
                    style={{ gridColumn: item?.colspan }}
                  >
                    {renderField(item)}
                  </div>
                );
              })}
            </div>
            <div>
              <div
                className={`s-add-shipping-bill__body-upload ${
                  files?.length > 0 && "s-add-irm__postupload"
                }`}
              >
                <UploadFileV2
                  {...data?.document_data?.[0]}
                  flow="irm_dashboard"
                  multiple_files={false}
                  onFileChange={setFiles}
                  showUploadedFiles={true}
                  extraClass={
                    files?.length > 0 && "s-add-irm__postupload--inside"
                  }
                  upload_enabled={files?.length === 0}
                  singleUploadAllowed={true}
                />
              </div>
              {errorMessage?.document_id && (
                <p className="s-add-irm__postupload--error">
                  {errorMessage?.document_id}
                </p>
              )}
            </div>
          </div>
        )}
        <div className="s-add-irm__buttons">
          {data?.buttons?.map((btn) => {
            return <>{renderBtn(btn)}</>;
          })}
        </div>
      </div>
    </div>
  );
};

export default AddInwardRemittance;
