import React from "react";

const SettingsCard = ({ title, children }) => {
  return (
    <>
      <div>
        {title && <div className="g-settings__card-title">{title}</div>}
        <div className="g-settings__card">{children}</div>
      </div>
    </>
  );
};

export default SettingsCard;
