// react imports
import { createSlice } from "@reduxjs/toolkit";

// in-app imports

export const loaderSlice = createSlice({
  name: "loader",
  initialState: {
    isShowLoader: false,
    type: "primary",
    timeoutLimit: 0,
    loaderMessage: "",
    delayedDataLoader: {
      delayDataTime: 0,
      messageList: [],
    },
    showLogo: false,
    loaderSuccess: false,
  },
  reducers: {
    showLoader: (state, action) => {
      state.isShowLoader = true;
      state.type = action?.payload?.type ?? "primary";
      state.timeoutLimit = action?.payload?.timeoutLimit ?? 0;
      state.loaderMessage = action?.payload?.message ?? "";
      state.delayedDataLoader = action?.payload?.delayedDataLoader ?? {
        delayDataTime: 0,
        messageList: [],
      };
      state.showLogo = action?.payload?.showLogo ?? false;
      state.loaderSuccess = action?.payload?.loaderSuccess ?? false;
    },

    removeLoader: (state, action) => {
      state.isShowLoader = false;
      state.type = "";
      state.timeoutLimit = 0;
      state.loaderMessage = "";
      state.delayedDataLoader = {
        delayDataTime: 0,
        messageList: [],
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { showLoader, removeLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
