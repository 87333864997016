import { createSlice } from "@reduxjs/toolkit";

export const homeSlice = createSlice({
  name: "home",
  initialState: {
    sampleData: null,
  },
  reducers: {
    initData: (state, action) => {
      state.sampleData = action?.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { initData } = homeSlice.actions;

export default homeSlice.reducer;
