// react imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//redux imports
import { useDispatch } from "react-redux";

//in app imports
import SkeletonBasic from "../../components/SkeletonLoaders/SkeletonBasic";
import Button from "../../components/Button";
import { updateShowIrmForm, updateShowSbForm } from "../../redux/common/slice";
import ModalV2 from "../../components/ModalV2";
import BuyerDetails from "../../components/buyersPage/BuyerDetails";

const SingleBuyerSummary = (props) => {
  const {
    data,
    buttonsData,
    activeBill,
    setActiveBill,
    buyerId = null,
    buyerDetails,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // modal states
  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [activeSlug, setActiveSlug] = useState("");

  const onModalClick = (slug, index) => {
    setShowModal(true);
    setModalInfo({ slug: slug, key: index });
  };

  const closeModal = () => {
    setShowModal(false);
    setModalInfo({});
  };

  const setBuyerSlug = (theSlug) => {
    setActiveBill(theSlug);
    navigate(`/buyers/${buyerId}`);
  };

  useEffect(() => {
    setActiveSlug(data?.slug);
  }, [data]);
  return (
    <>
      <div className="s-buyer__stats">
        <div className="s-buyer__stats-bar">
          <div className="s-buyer__stats-left">
            <p
              onClick={() => {
                setBuyerSlug(data?.title1?.slug);
              }}
              className={`s-buyer__stats-tab  ${
                activeBill === "sb" && "s-buyer__stats-tab--active"
              }`}
            >
              {data?.title1?.text}
            </p>
            <p
              onClick={() => {
                setBuyerSlug(data?.title2?.slug);
              }}
              className={`s-buyer__stats-tab  ${
                activeBill === "irm" && "s-buyer__stats-tab--active"
              }`}
            >
              {data?.title2?.text}
            </p>
          </div>
          {/* <div className="a-buyers__top-right">
            {buttonsData?.map((tab, index) => {
              return tab?.type === "button" ? (
                tab?.slug === "generate_bulk_ebrc_info" ? (
                  <Button
                    buttonLabel={tab?.text}
                    buttonType={tab?.sub_type}
                    icon={tab?.icon}
                    disabled={tab?.disabled}
                    iconAlignment={tab?.icon_alignment}
                    tooltipText={tab?.tooltip_text}
                    onClick={() => {
                      navigate("/sb/bulk-ebrc", {
                        state: {
                          link: document.location.pathname,
                          text: "Back To Buyer",
                        },
                      });
                    }}
                  />
                ) : activeSlug === "sb" ? (
                  tab?.slug === "add_sb" && (
                    <Button
                      buttonLabel={tab?.text}
                      buttonType={tab?.sub_type}
                      icon={tab?.icon}
                      disabled={tab?.disabled}
                      iconAlignment={tab?.icon_alignment}
                      onClick={() => dispatch(updateShowSbForm(true))}
                    />
                  )
                ) : (
                  tab?.slug === "add_irm" && (
                    <Button
                      buttonLabel={tab?.text}
                      buttonType={tab?.sub_type}
                      icon={tab?.icon}
                      disabled={tab?.disabled}
                      iconAlignment={tab?.icon_alignment}
                      onClick={() => dispatch(updateShowIrmForm(true))}
                    />
                  )
                )
              ) : (
                <div
                  className={`a-buyers__top-tab ${
                    showModal &&
                    index === modalInfo?.key &&
                    modalInfo?.slug === "buyer_details"
                      ? "a-buyers__top--show"
                      : null
                  }`}
                  onClick={(e) => {
                    tab?.slug === "buyer_details" &&
                      onModalClick(tab?.slug, index);
                  }}
                >
                  {tab?.icon && <img src={tab?.icon} alt="" />}
                  <p className={`a-buyers__top-tabtext`}>{tab?.text}</p>
                  {showModal &&
                    index === modalInfo?.key &&
                    modalInfo?.slug === "buyer_details" && (
                      <ModalV2 slug={modalInfo?.slug}>
                        <BuyerDetails
                          data={buyerDetails}
                          closeModal={closeModal}
                        />
                      </ModalV2>
                    )}
                </div>
              );
            })}
          </div> */}
        </div>
        <SkeletonBasic showLoader={data ? false : true}>
          <div className="s-buyer__stats-body">
            <div className="s-buyer__stats-details">
              {data?.details?.detail_data?.map((item) => {
                return (
                  <div className="s-buyer__detail">
                    <img src={item?.status_icon} alt="" />
                    <div className="s-buyer__stats-info">
                      <p className="s-buyer__detail-amount">
                        {item?.amount} <span>{data?.currency}</span>
                      </p>
                      <p className="s-buyer__detail-count">{item?.text}</p>
                    </div>
                  </div>
                );
              })}
              <div className="s-buyer__stats-total">
                <div className="s-buyer__detail">
                  <div className="s-buyer__stats-info">
                    <p className="s-buyer__detail-amount">
                      {data?.details?.total_data.amount}{" "}
                      <span>{data?.currency}</span>
                    </p>
                    <p className="s-buyer__detail-count">
                      {data?.details?.total_data?.text}
                    </p>
                  </div>
                </div>
              </div>
              <div className="s-buyer__detail-buttons">
                {buttonsData?.map((tab, index) => {
                  return tab?.type === "button" ? (
                    tab?.slug === "generate_bulk_ebrc_info" ? (
                      <Button
                        buttonLabel={tab?.text}
                        buttonType={tab?.sub_type}
                        icon={tab?.icon}
                        disabled={tab?.disabled}
                        iconAlignment={tab?.icon_alignment}
                        tooltipText={tab?.tooltip_text}
                        onClick={() => {
                          navigate("/sb/bulk-ebrc", {
                            state: {
                              link: document.location.pathname,
                              text: "Back To Buyer",
                            },
                          });
                        }}
                      />
                    ) : activeSlug === "sb" ? (
                      tab?.slug === "add_sb" && (
                        <Button
                          buttonLabel={tab?.text}
                          buttonType={tab?.sub_type}
                          icon={tab?.icon}
                          disabled={tab?.disabled}
                          iconAlignment={tab?.icon_alignment}
                          onClick={() => dispatch(updateShowSbForm(true))}
                        />
                      )
                    ) : (
                      tab?.slug === "add_irm" && (
                        <Button
                          buttonLabel={tab?.text}
                          buttonType={tab?.sub_type}
                          icon={tab?.icon}
                          disabled={tab?.disabled}
                          iconAlignment={tab?.icon_alignment}
                          onClick={() => dispatch(updateShowIrmForm(true))}
                        />
                      )
                    )
                  ) : (
                    <></>
                  );
                })}
              </div>
            </div>
          </div>
        </SkeletonBasic>
        <>
          {showModal && (
            <div className="a-buyers__modal-bg" onClick={closeModal} />
          )}
        </>
      </div>
    </>
  );
};

export default SingleBuyerSummary;
