// react imprts
import React, { useState } from "react";

// third-party imports
import { Checkbox, FormControlLabel } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// in-app imports
import SummaryDetails from "./SummaryDetails";
import SummaryInvoice from "./SummaryInvoice";
import SummaryMapIrm from "./SummaryMapIrm";
import SubmissionInfo from "./SubmissionInfo";
import Button from "../../components/Button";

import { useParams } from "react-router-dom";
import OptionsComponent from "../../components/export-bills/OptionsComponent";
import OptionDotComponent from "../../components/OptionDotComponent";

const ReconSummary = ({
  data,
  onActionClick,
  showReconSummary = false,
  setShowReconSummary,
}) => {
  const [isDownloadAllowed, setIsDownloadAllowed] = useState(false);

  const params = useParams();

  const sbId = params?.sbId;

  const onActionClick2 = (slug, action, reason) => {
    if (slug === "show_summary") {
      setShowReconSummary(!showReconSummary);
    } else onActionClick("", { slug: slug }, [sbId], reason);
  };

  return (
    <>
      <div className="recon-summary">
        <div
          className={`recon-summary__heading ${
            showReconSummary ? "recon-summary__heading--show" : ""
          }`}
        >
          <h2 className="recon-summary__title">{data?.header?.title}</h2>

          <img
            src={data?.header?.icon}
            alt="close"
            className="r-panel__icon"
            onClick={() => {
              setShowReconSummary(false);
            }}
          />
        </div>
        {showReconSummary && (
          <div className="recon-summary__body">
            {data?.company_details && (
              <SummaryDetails data={data?.company_details} />
            )}
            {data?.document_details && (
              <SummaryInvoice data={data?.document_details} />
            )}
            {data?.irm_details && <SummaryMapIrm data={data?.irm_details} />}
            {data?.extra_documents && (
              <SubmissionInfo data={data?.extra_documents} />
            )}
            {/* <div className="recon-summary__declaration">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checkedIcon={<CheckBoxIcon />}
                    onChange={(e) => {
                      setIsDownloadAllowed(!isDownloadAllowed);
                    }}
                    style={{
                      padding: 0,
                    }}
                  />
                }
                disabled={data?.declaration?.disabled}
                label={
                  <p className="recon-summary__declaration-label">
                    {data?.declaration?.text}
                  </p>
                }
                defaultValue={data?.declaration?.checked}
                style={{ marginLeft: 0 }}
                value={isDownloadAllowed}
              />
            </div> */}
            <div className="recon-summary__footer">
              <h2 className="recon-summary__footer-title">
                {data?.glossary?.title}
              </h2>
              <p className="recon-summary__footer-text">
                {data?.glossary?.text}
              </p>
            </div>
            {/* {data?.actions && (
              <div className="recon-summary__download">
                {data?.actions?.map((action) => {
                  return (
                    action?.type === "button" && (
                      <Button
                        buttonLabel={action?.text}
                        buttonType={action?.sub_type}
                        icon={action.icon}
                        disabled={!isDownloadAllowed}
                        iconAlignment={action?.icon_alignment}
                      />
                    )
                  );
                })}
              </div>
            )} */}
          </div>
        )}
      </div>
    </>
  );
};

export default ReconSummary;
