// react imports
import React from "react";

// in-app imports
import UploadFileCard from "./UploadFile/UploadFile";

const DocSnap = ({ data, uploadSingleFile, docId,section, onDocAction }) => {
  return (
    <>
      <div className="s-docsnap">
        <div className="s-docsnap__left">
          <p className="s-docsnap__srno">{data?.sr_no}</p>
          <p className="s-docsnap__text">{data?.text}</p>
        </div>
        <div className="s-docsnap__right">
          <div className="s-docsnap__uploadfile">
            <UploadFileCard
              upload_enabled={data?.document?.upload_enabled}
              documents={data?.document?.uploaded_documents}
              doc_type={data?.document?.slug}
              uploadSingleFile={uploadSingleFile}
              docId={docId}
              section={section}
              invoiceId={data?.id}
              onDocAction={onDocAction}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DocSnap;
