//react imports
import React, { useEffect, useState } from "react";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import endpoints from "../../api/endpoints";
import { apiRequest } from "../../redux/common/actions";
import { closeAlert, resetAlertData, showAlert } from "../../redux/alert/slice";

//in app imports
import { SETTINGS_API_RESPONSE } from "../../modules/landing/constants";
import BankDetailsCard from "../../modules/Settings/BankDetailsCard";
import Button from "../../components/Button";
import Position from "rsuite/esm/internals/Overlay/Position";

const BankDetailsSettings = () => {
  const [data, setData] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const getCompanyBankDetails = async () => {
      const bankDetailsRes = await dispatch(
        apiRequest(endpoints.getCompanyBankDetails)
      );
      if (bankDetailsRes?.success && bankDetailsRes?.data) {
        setData(bankDetailsRes?.data);
      }
    };
    getCompanyBankDetails();
  }, []);

  return (
    <div className="b-settings">
      <div className="b-settings__header">
        {data?.buttons?.map((btn) => {
          return (
            <Button
              buttonLabel={btn?.text}
              buttonType={btn?.sub_type}
              icon={btn?.icon}
              iconAlignment={btn?.icon_alignment}
              disabled={btn?.disabled}
              tooltipText="Coming Soon"
              extraClass="b-settings__header-button"
              tooltipPosition={{
                left: "50px",
                position: "fixed",
              }}
            />
          );
        })}
      </div>
      {data?.bank_list?.map((obj) => {
        return <BankDetailsCard data={obj} />;
      })}
    </div>
  );
};

export default BankDetailsSettings;
