// react imports
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// redux imports
import { useDispatch } from "react-redux";
import { apiRequest } from "../redux/common/actions";
import endpoints from "../api/endpoints";

// third party imports

// in-app imports
import Alert from "../components/Alert";
import BlunoTable from "../components/BlunoTable";
import HomeHelpCard from "../components/HomeHelpCard";
import HomeIrmSummaryV2 from "../components/HomeIrmSummaryV2";
import TimePeriodPanel from "../components/TimePeriodPanel";
import Button from "../components/Button";
import { sanitizeHtml } from "../utils/sanitizeHtml";
import { updateShowSbForm } from "../redux/common/slice";
import Task from "../components/Task";
import HomeSummary from "../components/HomeSummary";
import BlunoBanner from "../components/blunoBanners/BlunoBanner";
import BlunoKycBanner from "../components/blunoBanners/BlunoKycBanner";
import { downloadDocumentUtility } from "../utils/downloadDocument";
import { showToast } from "../redux/toast/slice";

const Home = () => {
  // defined to access react based APIs
  const navigate = useNavigate();

  const [summaryDate, setSummaryDate] = useState({});
  const [isAlertShow, setIsAlertShow] = useState(true);

  // redux state variables
  const dispatch = useDispatch();

  // local state variables
  const [recenetSb, setRecentSb] = useState(null);
  const [homeSummary, setHomeSummary] = useState(null);
  const [miscInfo, setMiscInfo] = useState(null);
  const [bannerData, setBannerData] = useState(null);
  const [isAlertExpand, setIsAlertExpand] = useState(false);
  const [isNewsExpand, setIsNewsExpand] = useState(false);

  const alertRef = useRef();
  const newsRef = useRef();

  const redirectToLink = (theLink) => {
    theLink && navigate(theLink);
  };

  const deleteTasks = async (taskIdList) => {
    const dataRes = await dispatch(
      apiRequest(endpoints.deleteTasks, {
        task_ids: taskIdList,
      })
    );
    setMiscInfo((prev) => ({
      ...prev,
      imp_tasks: {
        ...prev?.imp_tasks,
        tasks: prev?.imp_tasks?.tasks?.filter(
          (task) => !taskIdList.includes(task?.id)
        ),
        task_count: prev?.imp_tasks?.task_count - 1,
      },
    }));
  };

  const hideNotificationBanner = async () => {
    const dataRes = await dispatch(
      apiRequest(endpoints.hideNotificationBannner, {})
    );
    if (dataRes?.success) {
      setBannerData(null);
    }
  };

  const bulkDownloadEbrc = async (ids) => {
    const apiRes = await dispatch(
      apiRequest(endpoints.bulkDownloadDocManagement, {
        ids: ids,
        document_management_type: "ebrc",
        processing: "sync",
      })
    );
    if (apiRes?.success) {
      downloadDocumentUtility(
        apiRes?.data?.document_link,
        apiRes?.data?.filename
      );
    } else {
      dispatch(showToast(apiRes?.data?.toast));
    }
  };

  useEffect(() => {
    const getHomeData = async () => {
      // Notification Banner
      const bannerRes = await dispatch(
        apiRequest(endpoints.fetchNotificationBanner)
      );
      if (bannerRes?.success && bannerRes?.data) {
        setBannerData(bannerRes?.data);
      }

      // Recent Shipping Bills
      const recentBillsRes = await dispatch(
        apiRequest(
          endpoints.getRecentShippingBills,
          "?limit=10&sort_field=buyer_name&order=desc"
        )
      );
      if (recentBillsRes?.success && recentBillsRes?.data) {
        setRecentSb(recentBillsRes?.data);
      }

      // Alert and Recent eBRC
      const infoRes = await dispatch(apiRequest(endpoints.getMiscInfo));
      if (infoRes?.success && infoRes?.data) {
        setMiscInfo(infoRes?.data);
      }
    };
    getHomeData();
  }, []);

  useEffect(() => {
    const getHomeData = async () => {
      // Home Summary
      const dataRes = await dispatch(
        apiRequest(
          endpoints.getHomeSummary,
          `${
            summaryDate?.start_date
              ? `?start_date=${summaryDate.start_date}`
              : ""
          }${summaryDate?.end_date ? `&end_date=${summaryDate?.end_date}` : ""}`
        )
      );
      if (dataRes?.success && dataRes?.data) {
        setHomeSummary(dataRes?.data);
      }
    };

    getHomeData();
  }, [summaryDate]);

  return (
    <>
      <div className="home">
        {bannerData?.kyc_status_banner && (
          <div className="home_banner">
            <BlunoKycBanner data={bannerData?.kyc_status_banner} />
          </div>
        )}
        {bannerData?.dgft_link_banner && (
          <div className="home__banner">
            <BlunoBanner
              data={bannerData?.dgft_link_banner}
              hideNotificationBanner={hideNotificationBanner}
            />
          </div>
        )}
        <div className="home__container">
          <div className="home__left">
            <div className="home__left-top">
              <section className="home__top-panel">
                <TimePeriodPanel
                  setSummaryDate={setSummaryDate}
                  data={{
                    time_period_list: homeSummary?.top_panel?.time_period_list,
                    custom_time: homeSummary?.top_panel?.custom_time_period,
                  }}
                />
              </section>
              <section className="home__summary">
                <div className="home__sb-summary">
                  <HomeSummary data={homeSummary?.sb_summary} />
                </div>
                <div className="home__ir-summary">
                  <HomeIrmSummaryV2 data={homeSummary?.irm_summary} />
                </div>
              </section>
            </div>
            <div className="home__left-bottom">
              <div
                className="home__rsb"
                onClick={() => redirectToLink(recenetSb?.deeplink)}
              >
                <BlunoTable
                  data={recenetSb}
                  dataHeight="275px"
                  noTableBorder={true}
                  bulkDownloadEbrc={bulkDownloadEbrc}
                />
              </div>
              <div className={`home__hebrc `}>
                <HomeHelpCard data={miscInfo?.latest_news} />
              </div>
            </div>
          </div>
          <div className="home__right">
            <div className="home__add-sb">
              {homeSummary?.top_panel?.buttons.map((btn) => {
                return (
                  <Button
                    buttonLabel={btn?.text}
                    buttonType={btn?.sub_type}
                    icon={btn?.icon}
                    hoverIcon={btn?.hover_icon}
                    iconAlignment={btn?.icon_alignment}
                    onClick={() => dispatch(updateShowSbForm(true))}
                    buttonsSize="md"
                    iconExtraClass="home__add-sb--extra"
                  />
                );
              })}
            </div>
            <div className="home__right-content">
              {/* <div
              className={`home__right-top ${
                isAlertExpand ? "home__right-top--expand" : ""
              }`}
            > */}
              <div
                className={`home__alerts
                 ${isAlertExpand ? "home__alerts--expand" : ""}
                 ${isNewsExpand ? "home__alerts--collapse" : ""}
                 `}
              >
                {(miscInfo?.imp_alerts || miscInfo?.imp_tasks) && (
                  <div className="home__alerts-top">
                    <div
                      className={`home__alerts-tabs ${
                        isNewsExpand ? "home__alerts-tabs--collapse" : ""
                      }`}
                    >
                      <div
                        className="home__alerts-tab"
                        onClick={() => setIsAlertShow(true)}
                      >
                        <div
                          className={`home__alerts-tab-content ${
                            isAlertShow
                              ? "home__alerts-tab-content--active"
                              : ""
                          }`}
                        >
                          <p
                            className={`home__alerts-tab-text ${
                              isAlertShow ? "home__alerts-tab-text--active" : ""
                            }`}
                          >
                            {miscInfo?.imp_alerts?.title}
                          </p>
                          <span
                            className={`home__alerts-tab-count ${
                              isAlertShow
                                ? "home__alerts-tab-count--active"
                                : ""
                            }`}
                          >
                            {miscInfo?.imp_alerts?.alert_count}
                          </span>
                        </div>
                      </div>
                      <div
                        className="home__alerts-tab"
                        onClick={() => setIsAlertShow(false)}
                      >
                        <div
                          className={`home__alerts-tab-content ${
                            !isAlertShow
                              ? "home__alerts-tab-content--active"
                              : ""
                          }`}
                        >
                          <p
                            className={`home__alerts-tab-text ${
                              !isAlertShow
                                ? "home__alerts-tab-text--active"
                                : ""
                            }`}
                          >
                            {miscInfo?.imp_tasks?.title}
                          </p>
                          <span
                            className={`home__alerts-tab-count ${
                              !isAlertShow
                                ? "home__alerts-tab-count--active"
                                : ""
                            }`}
                          >
                            {miscInfo?.imp_tasks?.task_count}
                          </span>
                        </div>
                      </div>
                      {/* {isAlertExpand && (
                      <BlunoTooltip
                        text="Minimize"
                        useChildren={true}
                        position="fixed"
                        top="-10px"
                        left="-15px"
                      >
                        <div className="home__alerts-tab-minimise">
                          <img
                            src={miscInfo?.imp_tasks?.minimise_icon}
                            alt=""
                            onClick={() => setIsAlertExpand(false)}
                          />
                        </div>
                      </BlunoTooltip>
                    )} */}
                    </div>
                    {isNewsExpand && (
                      <div
                        className="home-help__top-right"
                        onClick={() => setIsNewsExpand(false)}
                        style={{ padding: "5px 0" }}
                      >
                        <p>{miscInfo?.imp_tasks?.show_details?.text}</p>
                        <img
                          src={miscInfo?.imp_tasks?.show_details?.icon}
                          style={{
                            transform: "rotate(0deg)",
                          }}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                )}
                {isAlertShow ? (
                  <div className="home__alerts-items">
                    <div className="home__alerts-container">
                      {!miscInfo?.imp_alerts?.default_view ? (
                        miscInfo?.imp_alerts?.alerts?.map(
                          (item, index) =>
                            (isAlertExpand ||
                              (!isAlertExpand && index < 4)) && (
                              <Alert
                                key={`alert-${index}`}
                                text={item?.text}
                                type={item?.type}
                                deeplink={item?.deeplink}
                                subType={item?.sub_type}
                              />
                            )
                        )
                      ) : (
                        <div className="home__no-alert">
                          <img
                            className="home__no-alert-img"
                            src={miscInfo?.imp_alerts?.default_view?.img}
                            alt="no alerts"
                          />
                          <p
                            className="home__no-alert-text"
                            dangerouslySetInnerHTML={sanitizeHtml(
                              miscInfo?.imp_alerts?.default_view?.text
                            )}
                          />
                        </div>
                      )}
                    </div>
                    {miscInfo?.imp_alerts?.view_more && !isNewsExpand && (
                      <div className="home__alerts-viewmore">
                        <div
                          className="home__alerts-viewmore-content"
                          onClick={() => setIsAlertExpand(!isAlertExpand)}
                        >
                          <p className="home__alerts-viewmore-text">
                            {!isAlertExpand
                              ? miscInfo?.imp_alerts?.view_more?.text
                              : miscInfo?.imp_alerts?.view_more?.sub_text}
                          </p>
                          <img
                            src={miscInfo?.imp_alerts?.view_more?.icon}
                            style={{
                              transform: `${
                                isAlertExpand ? "rotate(180deg)" : ""
                              }`,
                            }}
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="home__alerts-tasks">
                    <div className="home__alerts-container home__alerts-container-task">
                      {!miscInfo?.imp_tasks?.default_view ? (
                        miscInfo?.imp_tasks?.tasks?.map(
                          (item, index) =>
                            (isAlertExpand ||
                              (!isAlertExpand && index < 2)) && (
                              <Task
                                id={item?.id}
                                icon={item?.icon}
                                text={item?.text}
                                modalIcon={item?.modal_icon}
                                buttons={item?.buttons}
                                duration={item?.duration}
                                modalData={item?.modal_data}
                                deleteTask={deleteTasks}
                                flow="task"
                                isTaskBorder={false}
                              />
                            )
                        )
                      ) : (
                        <div className="home__no-alert">
                          <img
                            className="home__no-alert-img"
                            src={miscInfo?.imp_tasks?.default_view?.img}
                            alt="no alerts"
                          />
                          <p
                            className="home__no-alert-text"
                            dangerouslySetInnerHTML={sanitizeHtml(
                              miscInfo?.imp_tasks?.default_view?.text
                            )}
                          />
                        </div>
                      )}
                    </div>

                    {miscInfo?.imp_tasks?.view_more && !isNewsExpand && (
                      <div className="home__alerts-viewmore home__alerts-viewmore-top">
                        <div
                          className="home__alerts-viewmore-content"
                          onClick={() => setIsAlertExpand(!isAlertExpand)}
                        >
                          <p className="home__alerts-viewmore-text">
                            {!isAlertExpand
                              ? miscInfo?.imp_tasks?.view_more?.text
                              : miscInfo?.imp_tasks?.view_more?.sub_text}
                          </p>
                          <img
                            src={miscInfo?.imp_tasks?.view_more?.icon}
                            style={{
                              transform: `${
                                isAlertExpand ? "rotate(180deg)" : ""
                              }`,
                            }}
                            alt=""
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
