//react imports
import React, { useState } from "react";

//in app imports
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import MuiTextInput from "../../../components/blunoInputs/MuiTextInputs";

const SetPassword = ({
  data,
  phone = "",
  password = "",
  confirmPassword = "",
  handleInputChange,
  onSetPassword,
  errorMessagesObject = {},
  handleKeyDown,
  isInputTypePassword = {},
  setIsInputTypePassword,
  isPasswordValid = {},
  editFlow,
}) => {
  //temp
  const greenTickIcon = "https://static.bluno.in/miscl/green_tick.png";

  return (
    <div className="o-login__enter">
      <div>
        <h2 className="o-login__title">{data?.title}</h2>
      </div>
      <div className="o-login__notification">
        <p className="o-login__notification-content">
          <span className="o-login__notification-text">
            {data?.mobile_text?.value}
          </span>
          <span className="o-login__notification-phone">{"+91-" + phone}</span>
        </p>
      </div>
      <div className="o-login__inputs">
        {data?.inputs?.map((item) => {
          return (
            <div className="o-login__input">
              <div className="o-login__input__container">
                <MuiTextInput
                  placeholder={
                    editFlow === "forgot_password"
                      ? item?.title
                      : item?.placeholder
                  }
                  type={
                    item?.slug === "enter_password"
                      ? isInputTypePassword?.password
                        ? "password"
                        : "text"
                      : isInputTypePassword?.confirm_password
                      ? "password"
                      : "text"
                  }
                  value={
                    item?.slug === "enter_password" ? password : confirmPassword
                  }
                  icon={
                    item?.slug === "enter_password"
                      ? isInputTypePassword?.password
                        ? password && item?.icon
                        : password && item?.secondary_icon
                      : isInputTypePassword?.confirm_password
                      ? confirmPassword && item?.icon
                      : confirmPassword && item?.secondary_icon
                  }
                  isIconClickable={true}
                  onIconClick={() => {
                    if (item?.slug === "enter_password") {
                      setIsInputTypePassword((prevState) => {
                        return {
                          ...prevState,
                          password: !prevState.password,
                        };
                      });
                    } else {
                      setIsInputTypePassword((prevState) => {
                        return {
                          ...prevState,
                          confirm_password: !prevState.confirm_password,
                        };
                      });
                    }
                  }}
                  iconAlignment="right"
                  id={item?.slug}
                  errorMessage={
                    item?.slug === "enter_password"
                      ? errorMessagesObject?.password
                      : errorMessagesObject?.confirm_password
                  }
                  onChange={(e) => {
                    handleInputChange(e.target.value, item?.slug, e);
                  }}
                  onKeyDown={(e) => handleKeyDown(e.key, item?.slug, e)}
                  disabled={item?.disabled}
                  height="40px"
                  minWidth="360px"
                  fontSize="13px"
                  errorMsgBottomPosition={
                    item?.slug === "enter_password" ? "-32px" : "-20px"
                  }
                  disableCopyPaste={true}
                />
                {item?.slug === "enter_password" &&
                  isPasswordValid?.password && (
                    <img
                      className={`o-login__strong ${
                        errorMessagesObject?.password
                          ? "o-login__strong--error"
                          : ""
                      }`}
                      src={greenTickIcon}
                      alt="strong password"
                    />
                  )}
              </div>
            </div>
          );
        })}
      </div>
      {data?.buttons && (
        <div className="o-login__continue">
          {data?.buttons?.map((action) => {
            return (
              action?.type === "button" && (
                <Button
                  buttonLabel={action?.text}
                  buttonType={action?.sub_type}
                  icon={action.icon}
                  disabled={action?.disabled}
                  iconAlignment={action?.icon_alignment}
                  extraClass={`o-login__continue-button`}
                  onClick={() => onSetPassword()}
                  height="40px"
                  fontSize="13px"
                />
              )
            );
          })}
        </div>
      )}
      <div className="o-login__terms">
        <p className="o-login__terms-content">
          {data?.terms?.text_list?.map((child) => {
            switch (child?.text_type) {
              case "plain":
                return (
                  <span
                    className="o-login__terms-text"
                    style={{
                      color: child?.color ?? "",
                      fontWeight: child?.font_weight ?? "",
                    }}
                  >
                    {child?.value}
                  </span>
                );
              case "hyperlink":
                return (
                  <a
                    target="_blank"
                    href={child?.deeplink}
                    style={{
                      color: child?.color ?? "",
                      fontWeight: child?.font_weight ?? "",
                      cursor: "pointer",
                    }}
                    className="o-login__terms-text"
                  >
                    {child?.value}
                  </a>
                );
            }
          })}
        </p>
      </div>
    </div>
  );
};

export default SetPassword;
