import React from "react";
import DOMPurify from "dompurify";

export const sanitizeHtml = (theHtml, addAttribute = []) => {
  if (!theHtml) return null;

  const sanitizedHtmlContent = DOMPurify.sanitize(theHtml, {
    ADD_ATTR: addAttribute,
  });

  return { __html: sanitizedHtmlContent };
};
