export const BlockerAlert = {
  GENERAL_SETTINGS: {
    items: {
      title: "Submit Later",
      text: `You have unsaved changes in general settings. Do you want to proceed without change?`,
      type: "info",
      position: "center",
      buttons: [
        {
          text: "Continue Editing",
          sub_type: "secondary",
          type: "button",
          slug: "cancel",
          width: "140px",
        },
        {
          text: "I'll do it later",
          sub_type: "primary",
          type: "button",
          slug: "proceed",
          width: "140px",
        },
      ],
    },
    props: ["blocker"],
  },
  SECURITY_SETTINGS: {
    items: {
      title: "Submit Later",
      text: `You have unsaved changes in security settings. Do you want to proceed without change?`,
      type: "info",
      position: "center",
      buttons: [
        {
          text: "Continue Editing",
          sub_type: "secondary",
          type: "button",
          slug: "cancel",
          width: "140px",
        },
        {
          text: "I'll do it later",
          sub_type: "primary",
          type: "button",
          slug: "proceed",
          width: "140px",
        },
      ],
    },
    props: ["blocker"],
  },
  PLATFORM_SETTINGS: {
    items: {
      title: "Submit Later",
      text: `You have unsaved changes. Do you want to proceed without change?`,
      type: "info",
      position: "center",
      buttons: [
        {
          text: "Continue Editing",
          sub_type: "secondary",
          type: "button",
          slug: "cancel",
          width: "140px",
        },
        {
          text: "I'll do it later",
          sub_type: "primary",
          type: "button",
          slug: "proceed",
          width: "140px",
        },
      ],
    },
    props: ["blocker"],
  },
};

export const getBlockerAlertBySection = (section) => {
  return {
    items: {
      title: "Submit Later",
      text: `You have unsaved changes in ${section}. Do you want to proceed without change?`,
      type: "info",
      position: "center",
      buttons: [
        {
          text: "Continue Editing",
          sub_type: "secondary",
          type: "button",
          slug: "cancel",
          width: "140px",
        },
        {
          text: "I'll do it later",
          sub_type: "primary",
          type: "button",
          slug: "proceed",
          width: "140px",
        },
      ],
    },
    props: ["blocker"],
  };
};
