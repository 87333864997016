export const convertNormalTextToCenterEllipsis = (
  text,
  startTextLength,
  endTextLength
) => {
  if (text.length === 0) {
    return "";
  }

  if (
    startTextLength >= text.length ||
    endTextLength >= text.length ||
    text.length < 3
  ) {
    return text;
  }

  if (startTextLength + endTextLength >= text.length) {
    return text;
  }

  return `${text.substring(0, startTextLength)}...${text.substring(
    text.length - endTextLength
  )}`;
};
