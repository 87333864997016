import React from "react";

import { sanitizeHtml } from "../../utils/sanitizeHtml";
import BlunoTable from "../BlunoTable";

const IrmDetailsV2 = ({
  isSBDetailView = false,
  middleData,
  sbData,
  defaultView,
}) => {
  const tabledata = {
    headers: sbData?.table_data?.headers,
    data: sbData?.table_data?.data,
    default_view: defaultView,
  };

  return (
    <div className={`v2-irm ${!isSBDetailView && "v2-irm--hidden"}`}>
      <div className="v2-irm__middle">
        <div className="v2-irm__details">
          {middleData?.details?.map((item) => {
            return (
              <div className="v2-irm__details-card">
                <img
                  className="v2-irm__details-img"
                  src={item?.icon}
                  alt={item?.text}
                />
                <div className="v2-irm__details-right">
                  <span className="v2-irm__details-text">{item?.text}</span>
                  <p className="v2-irm__details-value">{item?.value}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="v2-irm__codes">
          <div className="v2-irm__codes-child">
            <div className="v2-irm__codes-top">
              <p
                className="v2-irm__codes-code"
                dangerouslySetInnerHTML={sanitizeHtml(
                  middleData?.codes?.purpose_code
                )}
              />
              <p
                className="v2-irm__codes-code"
                dangerouslySetInnerHTML={sanitizeHtml(
                  middleData?.codes?.ad_code
                )}
              />
            </div>
            <p className="v2-irm__codes-note">{middleData?.codes?.note}</p>
          </div>
        </div>
      </div>
      <div className="v2-irm__sbDetails">
        <div className="v2-irm__sbDetails-child">
          {sbData && (
            <div className="v2-irm__sbDetails-top">
              <p className="v2-irm__sbDetails-text">{sbData?.title}</p>
              <p className="v2-irm__sbDetails-count">{sbData?.total}</p>
            </div>
          )}
          <div className="v2-irm__table">
            <BlunoTable data={tabledata} />
          </div>
          {sbData && (
            <div className="v2-irm__sbDetails-bottom">
              <p
                className="v2-irm__sbDetails-bottom-text"
                dangerouslySetInnerHTML={sanitizeHtml(sbData?.total_value)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IrmDetailsV2;
