// react imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// third-party iports
import { TextField } from "@mui/material";

// in-app imports
import Button from "../components/Button";
import Toast from "../components/Toast";

const Demo = () => {
  // defined to access react based APIs
  const navigate = useNavigate();

  // local state variables
  const [userId, setUserID] = useState("");
  const [isSet, setIsSet] = useState(false);
  const [isToast, setIsToast] = useState(false);

  const closeToast = () => {
    setIsToast(false);
  };

  const onDemo = (e) => {
    e.preventDefault();
    // localStorage.setItem("token", userId);
    // setIsSet(true);
    // setIsToast(true);
  };

  const goHome = () => {
    navigate("/");
  };

  return (
    <>
      <div className="demo">
        <form onSubmit={onDemo}>
          <div className="demo__userid">
            <TextField
              id="outlined-basic"
              label="Enter User Id"
              variant="outlined"
              size="small"
              className="demo__userid-textinput"
              value={userId}
              onChange={(e) => setUserID(e.target.value)}
            />
          </div>
          <Button
            buttonLabel={!isSet ? "Set User" : "Go to HomePage"}
            submitType={!isSet ? "submit" : "button"}
            onClick={isSet ? goHome : null}
          />
          <Toast
            title="Toast Title"
            message="UserId set successfully"
            // extraMessage="This is extra info"
            duration={5000}
            type="success"
            // type="success"
            closeToast={closeToast}
            isToast={isToast}
            // isToast={true}
          />
        </form>
      </div>
    </>
  );
};

export default Demo;
