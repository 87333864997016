//react imports
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//redux imports
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/toast/slice";

//in app imports
import { GetQueryParamValue } from "../../utils";

const AadhaarRedirection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const transactionId = GetQueryParamValue("transactionId");
  const transactionId = GetQueryParamValue("state");

  const checkESignVerificationRes = async () => {
    const submitAadhaarRes = await dispatch(
      apiRequest(
        endpoints.checkRedirection,
        null,
        `?transaction_id=${transactionId}`
      )
    );
    if (submitAadhaarRes?.success && submitAadhaarRes?.data) {
      navigate("/onboarding/company-data");
    } else {
      navigate("/onboarding/company-data");
      // error toast message
      if (submitAadhaarRes?.data?.toast) {
        dispatch(showToast(submitAadhaarRes?.data?.toast));
      }
    }
  };

  useEffect(() => {
    checkESignVerificationRes();
  }, []);
  return <></>;
};

export default AadhaarRedirection;
