import React from "react";

export default function Progress({ data }) {
  return (
    <div className="o-progress">
      <div
        className={`o-progress__child ${
          data?.stage_complete >= 1 ? "o-progress__child--completed" : ""
        }`}
      ></div>
      <div
        className={`o-progress__child ${
          data?.stage_complete >= 2 ? "o-progress__child--completed" : ""
        }`}
      ></div>
      <div
        className={`o-progress__child ${
          data?.stage_complete >= 3 ? "o-progress__child--completed" : ""
        }`}
      ></div>
    </div>
  );
}
