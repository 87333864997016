export const LOGIN_SCREEN_SLUGS = {
  enterMobile: "enter_mobile",
  otpVerification: "otp_verification",
  setPassword: "set_password",
  verifyPassword: "verify_password",
  login: "login",
};

export const COMPANY_DETAILS_SLUGS = {
  companyName: "company_name",
  companyPan: "company_pan",
  iecCode: "iec_code",
  submitIECCompany: "submit_iec_company",
  continue: "continue",
};

export const BANK_DETAILS_SLUGS = {
  bankName: "bank_name",
  bankIFSCCode: "ifsc_code",
  bankAccountNumber: "account_number",
  reEnterBankAccountNumber: "re-enter_account_number",
  bankCustomerId: "customer_id",
  submitBankDetails: "verify_bank_details",
  otherBankDetails: "other_bank_submit",
  continue: "continue",
  previous: "previous",
  bankBranch: "bank_branch",
};

export const AUTHORIZED_SIG_SLUGS = {
  name: "authorized_sig_name",
  emailId: "email_id",
  emailOTP: "email_otp",
  resendOTP: "resent_otp",
  verifyOTP: "verify_otp",
  verifyEmail: "verify_email",
  verifyNameMismatchESign: "verify_esign_after_name_mismatch",
  verifyNameMismatchAadhaarESign: "verify_aadhaar_esign_after_name_mismatch",
  pan: "authorized_sig_pan",
  verifyPan: "verify_pan",
  verifyAadhaar: "verify_aadhaar",
  verifyEsignTon: "verify_aadhaar_esign_ton",
  termsCondition: "terms_condition",
  submitAuthSig: "submit_auth_sig_details",
  submitDirectorInfo: "submit_director_info",
  continue: "continue",
  nameMismatchESignCondition: "esign_name_mismatch_condition",
  authSigAndDirectorSame: "auth_sig_director_same",
  directorName1: "director_name_1",
  directorName2: "director_name_2",
  designation1: "designation_1",
  designation2: "designation_2",
};

export const BANKS = {
  yesb: "YESB",
};
