//react imports
import React from "react";
import { useNavigate } from "react-router-dom";

//redux imports
import { UseDispatch, useDispatch } from "react-redux";

//in app imports
import BlunoTooltip from "../../components/BlunoTooltip";
import Button from "../../components/Button";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";

const TopPanel = (props) => {
  const { data, onBackOnboardingTask } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = async (slug) => {
    switch (slug) {
      case "logout":
        const logout = await dispatch(apiRequest(endpoints.logout));
        if (logout?.success) {
          localStorage.clear();
          navigate("/onboarding/login");
        }
        break;
      case "back":
        onBackOnboardingTask();
    }
  };

  return (
    <div className="o-company-data__top">
      <div className={`o-company-data__top-buttons ${data?.buttons?.length === 1 ? "o-company-data__top--single" : ""}`}>
        {data?.buttons?.map((item, index) => {
          return (
            <Button
              buttonType={item?.sub_type}
              buttonLabel={item?.text}
              disabled={item?.disabled}
              key={index}
              onClick={() => {
                handleClick(item?.slug);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TopPanel;
