// react imports
import React from "react";

// third-party imports

// in-app imports
import Dropdown from "../../../components/Dropdown";
import BlunoTooltip from "../../BlunoTooltip";
import Button from "../../Button";

const PlatformCard = ({
  data,
  isEditable,
  displayValues,
  handleInputChange,
  handleButtonClick,
}) => {
  return (
    <>
      <div className="plat-settings__card">
        <div className="plat-settings__left">
          <h2 className="plat-settings__title">{data?.title}</h2>
          <p className="plat-settings__description">{data?.description}</p>
        </div>
        <div className="plat-settings__right">
          {data?.fields?.map((item) => {
            return (
              <div className="plat-settings__field">
                <h2 className="plat-settings__field-title">
                  {item?.title}
                  {!isEditable && item?.label_edit_icon && (
                    <BlunoTooltip
                      useChildren={true}
                      text={item?.label_edit_icon_tooltip}
                    >
                      <div
                        className="g-settings__fields-edit"
                        onClick={() => {
                          handleButtonClick("edit");
                        }}
                      >
                        <img
                          src={item?.label_edit_icon}
                          className="g-settings__fields-edit-img"
                        />
                      </div>
                    </BlunoTooltip>
                  )}
                </h2>
                {isEditable ? (
                  item?.type === "dropdown" ? (
                    <div className="plat-settings__field-item">
                      <Dropdown
                        options={item?.options}
                        defaultValue={
                          item?.slug === "currency_unit_rupee"
                            ? displayValues?.[item?.slug]
                            : item?.default_value
                        }
                        height="30px"
                        minWidth="221px"
                        fontSize="13px"
                        onSelectChange={(e) =>
                          handleInputChange(e.value, item?.slug)
                        }
                        isDisabled={item?.disabled}
                      />
                    </div>
                  ) : (
                    <div className="plat-settings__field-item">
                      <Button
                        buttonType={item?.sub_type}
                        buttonLabel={item?.text}
                        width="100%"
                        onClick={() => handleButtonClick(item?.slug)}
                        icon={item?.icon}
                        iconAlignment={item?.icon_alignment}
                      />
                    </div>
                  )
                ) : (
                  <p className="plat-settings__field-value">
                    {item?.slug === "currency_unit_rupee"
                      ? item?.options?.filter(
                          (val) => val.value === displayValues?.[item?.slug]
                        )?.[0]?.label
                      : item?.selected_value}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PlatformCard;
