import React, { forwardRef, useEffect, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import InputErrorMsg from "../InputErrorMsg";
import SearchIcon from "../../assets/svg/SearchIcon";

const MuiTextInput = forwardRef(
  (
    {
      placeholder = "Enter Text",
      type = "text",
      icon = "",
      iconClass = "",
      iconWidth = "16px",
      iconAlignment = "left",
      isIconClickable = false,
      onIconClick,
      height = "44px",
      minWidth = "360px",
      width = null,
      onChange,
      onKeyDown,
      value = "",
      isHighlighted,
      id,
      minLength,
      maxLength,
      disabled = false,
      errorMessage = "",
      errorIcon,
      errorIconAlignment,
      subtext = "",
      subtextIcon = "",
      subtextIconAlignment = "",
      fixedText = "",
      paddingLeft = null,
      searchIconAlignment = "left",
      searchIcon = false,
      readonly = false,
      isFocusedExternal = null,
      cursor = "text",
      fontSize = "13px",
      shrinkFontSize = "16px",
      topSpacing = "-5px",
      fixedTextLeftStyle,
      fixedTextTopStyle,
      extraClass = "",
      multiline = false,
      rows,
      borderColor = "",
      //error msg position props (add more according to use case)
      errorMsgPostionType = "block",
      errorMsgBottomPosition = "-20px",
      errorMsgLeftPosition = "0px",
      disableCopyPaste = false,
      highlightInputOnError = false,
      ellipsis = false,
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };

    useEffect(() => {
      setIsFocused(isFocusedExternal);
    }, [isFocusedExternal]);

    const handleFocus = () => {
      if (isFocusedExternal === null) setIsFocused(true);
    };

    const handleBlur = () => {
      if (isFocusedExternal === null) setIsFocused(false);
    };

    return (
      <div>
        <TextField
          variant="outlined"
          type={type}
          label={placeholder}
          onChange={onChange}
          onFocus={handleFocus}
          onCopy={(e) => {
            if (disableCopyPaste) e.preventDefault();
          }}
          onPaste={(e) => {
            if (disableCopyPaste) e.preventDefault();
          }}
          onCut={(e) => {
            if (disableCopyPaste) e.preventDefault();
          }}
          onBlur={handleBlur}
          value={value}
          id={id}
          focused={isFocused}
          autoComplete={type === "password" ? "off" : "on"}
          inputProps={{
            readOnly: readonly,
            minLength: minLength,
            maxLength: maxLength,
            style: {
              // height: height,
              fontSize: fontSize,
              paddingLeft: paddingLeft,
            },
          }}
          InputProps={{
            endAdornment: (
              <>
                {iconAlignment === "right" && icon && (
                  <InputAdornment
                    position="end"
                    sx={{
                      marginLeft: "5px",
                      marginRight: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      isIconClickable && onIconClick();
                    }}
                  >
                    <img
                      className={`s-textinput__icon ${iconClass}`}
                      src={icon}
                      style={{ width: iconWidth }}
                    />
                  </InputAdornment>
                )}
              </>
            ),
            startAdornment: (
              <>
                {fixedText && (
                  <p
                    className="s-textinput__fixed"
                    style={{
                      left: fixedTextLeftStyle,
                      top: fixedTextTopStyle,
                      fontSize: fontSize,
                    }}
                  >
                    {fixedText}
                  </p>
                )}
                {searchIconAlignment === "left" && searchIcon && (
                  <InputAdornment position="start">
                    <SearchIcon
                      color={isFocused || isHighlighted ? "#046cb8" : "black"}
                    />
                  </InputAdornment>
                )}
              </>
            ),
          }}
          multiline={multiline}
          rows={rows}
          onKeyDown={onKeyDown}
          inputRef={ref}
          disabled={disabled}
          error={!!errorMessage}
          helperText={
            errorMessage ? (
              <InputErrorMsg
                message={errorMessage}
                icon={errorIcon}
                iconAlignment={errorIconAlignment}
              />
            ) : subtext ? (
              <InputErrorMsg
                message={subtext}
                icon={subtextIcon}
                iconAlignment={subtextIconAlignment}
                type="subtext"
              />
            ) : null
          }
          sx={{
            "& .MuiInputBase-input": ellipsis
              ? {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }
              : {},
            // width: "100%",
            "& .MuiInputBase-root": {
              height: height,
              // width: "100%",
              minWidth: minWidth,
              paddingLeft: paddingLeft,
              borderRadius: "8px",
              bgcolor: "#fff",
              cursor: cursor,
              display: "flex",
              alignItems: "center",
              width: width,
            },
            "& .MuiInputLabel-root": {
              fontSize: fontSize,
              top: topSpacing,
              paddingLeft: `calc(${paddingLeft} + 5px)`,
              color: errorMessage
                ? highlightInputOnError
                  ? "#e01e5a"
                  : "#888888"
                : "",
              "&.Mui-focused": {
                color: "#046cb8",
                fontSize: fontSize,
                top: "0px",
                paddingLeft: "0px",
              },
              "&.MuiInputLabel-shrink": {
                fontSize: "16px",
                top: "0px",
                paddingLeft: "0px",
                color:
                  isHighlighted || isFocused
                    ? "#046cb8"
                    : errorMessage
                    ? highlightInputOnError
                      ? "#e01e5a"
                      : "#888888"
                    : "",
              },
            },
            "& .MuiOutlinedInput-root": {
              color: isHighlighted ? "#046cb8" : "",
              "& fieldset": {
                borderColor:
                  isHighlighted || isFocused
                    ? "#046cb8"
                    : errorMessage
                    ? highlightInputOnError
                      ? "#e01e5a !important"
                      : "#888888 !important"
                    : borderColor,
              },
              "&:hover fieldset": {
                borderColor: isHighlighted ? "#046cb8" : "",
              },
              "&.Mui-focused fieldset": {
                borderColor:
                  isHighlighted || isFocused
                    ? "#046cb8"
                    : errorMessage
                    ? highlightInputOnError
                      ? "#e01e5a"
                      : "#888888"
                    : "",
                borderWidth: "1px",
              },
            },
            input: { cursor: cursor },
            cursor: cursor,
            "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
            "& .MuiFormHelperText-root": {
              margin: "0px",
              padding: !errorMessage ? " 0px" : "5px 0px 0px 0px",
              position: errorMsgPostionType,
              bottom: errorMsgBottomPosition,
              left: errorMsgLeftPosition,
            },
            "& input:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 1000px white inset",
              WebkitTextFillColor: "inherit",
              transition: "background-color 5000s ease-in-out 0s",
            },
            "& input:-webkit-autofill:hover, & input:-webkit-autofill:focus": {
              WebkitBoxShadow: "0 0 0 1000px white inset",
            },
            "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
          InputLabelProps={{
            shrink: isFocused || Boolean(value),
          }}
          className={`${errorMessage && `s-textinput__input--error`}  ${
            (isHighlighted ? "s-textinput__input--highlighted" : "",
            disabled ? "s-textinput__input--disabled" : "")
          }
           ${extraClass}`}
          fullWidth
          onWheel={() => document.activeElement.blur()}
        />
      </div>
    );
  }
);

export default MuiTextInput;
