// react imports
import React, { useEffect, useState } from "react";

// redux imports
import { apiRequest } from "../../redux/common/actions";
import { useDispatch } from "react-redux";
import endpoints from "../../api/endpoints";

// third-party imports

// in-app imports
import DgftPartner from "../../modules/Settings/DgftPartner";
import ModalV2 from "../../components/ModalV2";
import DgftConnectModal from "../../modules/Settings/DgftConnectModal";

const Partners = () => {
  const dispatch = useDispatch();
  // local states
  const [submitValues, setSubmitValues] = useState(null);
  const [dgftPlatformData, setDgftPlatformData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationCard, setShowConfirmationCard] = useState(false);
  const [showDgftGuide, setShowDgftGuide] = useState(false);
  const [dgftLinkStatus, setDgftLinkStatus] = useState(null);

  useEffect(() => {
    const getPartnerPlatformData = async () => {
      const apiRes = await dispatch(
        apiRequest(endpoints.fetchBlunoPartnersData)
      );
      if (apiRes?.success && apiRes?.data) {
        if (apiRes?.data?.dgft_partner_data) {
          setDgftPlatformData(apiRes?.data?.dgft_partner_data);
          setDgftLinkStatus(apiRes?.data?.dgft_partner_data?.dgft_link_status);
        }
      }
    };
    getPartnerPlatformData();
  }, []);

  return (
    <>
      <div className="partners-settings">
        {dgftPlatformData && (
          <div className="partners-settings__dgft">
            <DgftPartner
              data={dgftPlatformData}
              setShowModal={setShowModal}
              showConfirmationCard={showConfirmationCard}
              setShowConfirmationCard={setShowConfirmationCard}
              dgftLinkStatus={dgftLinkStatus}
              setDgftLinkStatus={setDgftLinkStatus}
              showDgftGuide={showDgftGuide}
              setShowDgftGuide={setShowDgftGuide}
              submitValues={submitValues}
              setSubmitValues={setSubmitValues}
            />
          </div>
        )}
      </div>
      {showModal && (
        <div className="a-buyers__modal-bg">
          <ModalV2
            slug={"standard"}
            children={
              <DgftConnectModal
                data={dgftPlatformData?.modal_data}
                setShowModal={setShowModal}
                setShowConfirmationCard={setShowConfirmationCard}
                setShowDgftGuide={setShowDgftGuide}
                setSubmitValues={setSubmitValues}
              />
            }
          />
        </div>
      )}
    </>
  );
};

export default Partners;
