//react imports
import React, { useEffect, useState } from "react";

//redux imports
import { apiRequest } from "../../redux/common/actions";
import { useDispatch } from "react-redux";
import endpoints from "../../api/endpoints";

//in app imports
import Button from "../Button";
import { sanitizeHtml } from "../../utils/sanitizeHtml";
import ReviewRating from "../ReviewRating";
import ModalV2 from "../ModalV2";
import RaiseTicketModal from "../../pages/Tickets/RaiseTicketModal";

const TicketsTimeline = ({ data, rowId, setIsCallGetApi }) => {
  const [ratingValue, setRatingValue] = useState(null);

  const [showTicketActionModal, setShowTicketActionModal] = useState();
  const dispatch = useDispatch();

  const handleReviewRatingClick = async (theSlug) => {
    const apiRes = await dispatch(
      apiRequest(endpoints.rateUserTicket, {
        id: rowId,
        rating: ratingValue,
      })
    );
  };

  const handleLinkOpen = (theLink) => {
    window.open(theLink);
  };

  useEffect(() => {
    setRatingValue(data?.ticket_review_rating?.value);
  }, []);

  return (
    <>
      <div className="b-tickets-timeline">
        <div className="b-tickets-timeline__left">
          {data?.ticket_timeline?.map((timeline, index) => {
            return (
              <div className="b-tickets-timeline__single">
                {index !== 0 && (
                  <div
                    className={`b-tickets-timeline__line b-tickets-timeline__line--${timeline?.status}`}
                  ></div>
                )}
                <div className="b-tickets-timeline__content">
                  <div
                    className={`b-tickets-timeline__point b-tickets-timeline__point--${timeline?.status}`}
                  ></div>
                  <div className="b-tickets-timeline__details">
                    <div
                      className={`b-tickets-timeline__details-left b-tickets-timeline__details-left--${timeline?.status}`}
                    >
                      <p
                        className="b-tickets-timeline__details-title"
                        dangerouslySetInnerHTML={sanitizeHtml(timeline?.title)}
                      />
                      <p className="b-tickets-timeline__details-text">
                        {timeline?.text}
                      </p>
                    </div>
                    <div className="b-tickets-timeline__details-right">
                      <p
                        className="b-tickets-timeline__details-description"
                        dangerouslySetInnerHTML={sanitizeHtml(
                          timeline?.description
                        )}
                      />
                      <div className="b-tickets-timeline__details-buttons">
                        {timeline?.buttons?.map((btn) => {
                          return (
                            <Button
                              buttonLabel={btn?.text}
                              buttonType={btn?.sub_type}
                              onClick={() => {
                                handleLinkOpen(btn?.deeplink);
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="b-tickets-timeline__right">
          {data?.ticket_review_rating && (
            <div className="b-tickets-timeline__review">
              <ReviewRating
                title={data?.ticket_review_rating?.text}
                ratingValue={ratingValue}
                setRatingValue={setRatingValue}
              />
              <div className="b-tickets-timeline__review-buttons">
                {data?.ticket_review_rating?.buttons?.map((btn) => {
                  return (
                    <Button
                      buttonLabel={btn?.text}
                      buttonType={btn?.sub_type}
                      width="120px"
                      onClick={() => {
                        handleReviewRatingClick(btn?.slug);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}
          <div>
            {data?.action_buttons?.map((btn) => {
              return (
                <Button
                  buttonLabel={btn?.text}
                  buttonType={btn?.sub_type}
                  width="120px"
                  onClick={() => {
                    setShowTicketActionModal(true);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
      {showTicketActionModal && (
        <div className="a-buyers__modal-bg">
          <ModalV2
            slug={"standard"}
            children={
              <RaiseTicketModal
                setShowModal={setShowTicketActionModal}
                data={data?.action_ticket_modal}
                // newTicketId={newTicketId}
                // setNewTicketId={setNewTicketId}
                // uploadSingleFile={uploadSingleFile}
                rowId={rowId}
                setIsCallGetApi={setIsCallGetApi}
              />
            }
          />
        </div>
      )}
    </>
  );
};

export default TicketsTimeline;
