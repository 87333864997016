// react imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// third party imports
import { Checkbox } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";

// redux imports
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import { sanitizeHtml } from "../../utils/sanitizeHtml";

// in-app imports
import Tag from "../Tag";
import Button from "../Button";

import RedirectIcon from "../../assets/media/table/Redirect.png";

// utils
import { truncateText } from "../../utils";
import SortArrowIcon from "../../assets/svg/SortArrowIcon";
import { checkIfAllValuesFalse } from "../../utils";
import { checkIfAllValuesTrue } from "../../utils";
import BlunoTooltip from "../BlunoTooltip";
import { updateShowIrmForm, updateShowSbForm } from "../../redux/common/slice";

const BlunoTableV2 = ({
  data,
  section,
  activeId = null,
  onActionClick = () => {},
  onSortOrderClick = () => {},
  onTableRowSelection = () => {},
  onAllTableRowSelection = () => {},
  rowSelectionState,
  onHoverClick,
}) => {
  // defined to access react based APIs
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // other variables
  const unselected_recommended =
    "https://static.bluno.in/miscl/unselected_recommended.png";

  const redirectToLink = (theLInk) => {
    navigate(theLInk);
  };

  const onDocDownload = (docId) => {
    const download = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.docAction, {
          action: "download",
          document_id: docId,
        })
      );
      if (dataRes?.success && dataRes?.data) {
        if (dataRes?.data?.document_link) {
          window.open(dataRes?.data?.document_link, "_blank");
        }
      }
    };
    download();
  };

  const checkDisability = (isDisabled) => {
    if (isDisabled) {
      return isDisabled;
    }
  };

  const getComponent = (row, header) => {
    switch (row?.[header?.slug]?.type) {
      case "tag":
        return (
          <Tag
            text={truncateText(row?.[header?.slug]?.text)}
            extraClass={`s-tag--${row?.[header?.slug].sub_type}`}
          />
        );
      case "action":
        return (
          <div className="v2-table__table-action">
            <Button
              onClick={() => {
                redirectToLink(row?.[header?.slug]?.deeplink);
              }}
              buttonSize="sm"
              buttonLabel={row?.[header?.slug].text}
              // buttonType="tag"
              extraClass={`s-button--${row?.[header?.slug].sub_type}`}
            />
          </div>
        );
      case "image_with_link":
        return (
          <img
            className="v2-table__table-imglink"
            src={row?.[header?.slug]?.icon}
            alt={header?.slug}
            onClick={() => onDocDownload(row?.[header?.slug]?.doc_id)}
          />
        );
      case "hover":
        return (
          <div
            className="v2-table__table-cell-hztext"
            onClick={() => {
              onHoverClick(row?.id);
            }}
          >
            <p>{truncateText(row?.[header?.slug]?.text)}</p>
            <p className="v2-table__table-hztext">
              {row?.[header?.slug]?.hz_text}
            </p>
            {activeId !== row?.id && (
              <span className="V2-table__table-link">
                {row?.[header?.slug]?.hover_text}
              </span>
            )}
          </div>
        );
      default:
        if (row?.[header?.slug]?.sub_text) {
          return (
            <div className="v2-table__table-cell-subtext">
              {truncateText(row?.[header?.slug]?.text)}
              <p className="v2-table__table-subtext">
                {row?.[header?.slug]?.sub_text}
              </p>
            </div>
          );
        } else if (row?.[header?.slug]?.small_tag) {
          return (
            <div className="v2-table__table-cell-smalltag">
              {truncateText(row?.[header?.slug]?.text)}
              <div className="v2-table__table-smalltag">
                <Tag
                  text={truncateText(row?.[header?.slug]?.small_tag?.text)}
                  extraClass={`s-tag--${
                    row?.[header?.slug]?.small_tag?.sub_type
                  } s-tag--smalltag`}
                />
              </div>
            </div>
          );
        } else if (row?.[header?.slug]?.hz_text) {
          return (
            <div className="v2-table__table-cell-hztext">
              {truncateText(row?.[header?.slug]?.text)}
              <p className="v2-table__table-hztext">
                {row?.[header?.slug]?.hz_text}
              </p>
            </div>
          );
        } else {
          return (
            <p className="v2-table__ellipsis">{row?.[header?.slug]?.text}</p>
          );
        }
    }
  };

  const getSubComponent = (theObj) => {
    return (
      <div
        className={`v2-table__subgroup v2-table__subgroup--${theObj?.alignment}`}
      >
        <p className={`v2-table__group--align-${theObj?.alignment}`}>
          {theObj?.text}
        </p>
        {theObj?.hz_text && <span>{theObj?.hz_text}</span>}
      </div>
    );
  };

  return (
    <div
      className={`v2-table ${data?.border_supported ? "v2-table--border" : ""}`}
    >
      {data?.title && (
        <div className="v2-table__heading">
          <div className="v2-table__title">{data?.title}</div>
          {data?.deeplink && (
            <div className="v2-table__link">
              <img
                src={RedirectIcon}
                alt="Link"
                onClick={() => redirectToLink(data?.deeplink)}
              />
            </div>
          )}
        </div>
      )}

      <div className="v2-table__data">
        <table className="v2-table__table">
          <tr className="v2-table__data-head ">
            {data?.headers?.map((header, index) => (
              <th
                key={`th-${header?.slug}-${index}`}
                title={header?.hover_text}
              >
                <div className={`v2-table__table--align-${header?.alignment}`}>
                  {data?.select_all_supported && index === 0 && (
                    <BlunoTooltip
                      useChildren={true}
                      text={
                        (
                          rowSelectionState
                            ? Object.keys(rowSelectionState).length <=
                                data?.data?.length &&
                              Object.keys(rowSelectionState).length !== 0 &&
                              checkIfAllValuesTrue(rowSelectionState)
                            : false
                        )
                          ? "Deselect all"
                          : "Select all"
                      }
                      position="fixed"
                    >
                      <Checkbox
                        size="small"
                        defaultChecked={false}
                        checkedIcon={<CheckBoxIcon />}
                        indeterminateIcon={
                          <IndeterminateCheckBoxOutlinedIcon
                            sx={{ color: "black" }}
                          />
                        }
                        indeterminate={
                          rowSelectionState
                            ? (Object.keys(rowSelectionState).length > 0 &&
                                Object.keys(rowSelectionState).length <
                                  data?.data?.length &&
                                !checkIfAllValuesFalse(rowSelectionState)) ||
                              (Object.keys(rowSelectionState).length ===
                                data?.data?.length &&
                                !checkIfAllValuesTrue(rowSelectionState) &&
                                !checkIfAllValuesFalse(rowSelectionState))
                            : false
                        }
                        checked={
                          rowSelectionState
                            ? Object.keys(rowSelectionState).length ===
                                data?.data?.length &&
                              checkIfAllValuesTrue(rowSelectionState)
                            : false
                        }
                        // disabled={row?.is_disabled}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                          color: "black",
                          "&.Mui-checked": {
                            color: "black",
                          },
                          marginLeft: "-12px",
                          "& .MuiSvgIcon-root": { fontSize: 18 },
                        }}
                        onChange={() =>
                          !data?.selection_with_api &&
                          onAllTableRowSelection(rowSelectionState, data?.data)
                        }
                      />
                    </BlunoTooltip>
                  )}

                  {header?.sort_supported && (
                    <span
                      onClick={() =>
                        onSortOrderClick(header?.slug, header?.sort_order)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <SortArrowIcon
                        topColor={
                          header?.sort_order === "asc"
                            ? "#000000"
                            : "rgba(29, 28, 29, 0.4)"
                        }
                        bottomColor={
                          header?.sort_order === "desc"
                            ? "#000000"
                            : "rgba(29, 28, 29, 0.4)"
                        }
                      />
                    </span>
                  )}
                  {header?.text}
                </div>
              </th>
            ))}
          </tr>
          {!data?.default_view &&
            data?.data?.map((row, index) => (
              <>
                <tr
                  key={`tr-${data?.title}-${index}`}
                  className={`v2-table__data-row ${
                    (row?.is_selected ? "v2-table__data-row--selected" : "",
                    activeId && activeId === row?.id
                      ? "v2-table__data-row--active"
                      : "")
                  }`}
                >
                  {data?.headers?.map((header, index) => (
                    <td
                      key={`td-${header?.slug}-${index}`}
                      title={
                        row?.[header?.slug]?.type === "hover"
                          ? row?.[header?.slug]?.hover_text
                          : row?.[header?.slug]?.text
                      }
                      style={{ width: header?.width }}
                    >
                      <div
                        className={`v2-table__table--align-${
                          row?.[header?.slug]?.alignment
                        } ${index === 0 ? `v2-table__table-flexcell` : ""}`}
                      >
                        {data?.selection_supported && index === 0 && (
                          <BlunoTooltip
                            useChildren={true}
                            text={row?.disabled ? "" : "Select"}
                            position="fixed"
                            tooltipDivWidth="max-content"
                          >
                            <Checkbox
                              size="small"
                              defaultChecked={false}
                              checked={
                                !data?.selection_with_api
                                  ? rowSelectionState?.[row?.id] === undefined
                                    ? false
                                    : true
                                  : row?.is_selected
                              }
                              disabled={checkDisability(row?.is_disabled)}
                              inputProps={{ "aria-label": "controlled" }}
                              sx={{
                                color: "black",
                                "&.Mui-checked": {
                                  color: "black",
                                },
                                marginLeft: "-12px",
                              }}
                              onChange={() =>
                                !data?.selection_with_api &&
                                onTableRowSelection(row?.id)
                              }
                            />
                          </BlunoTooltip>
                        )}

                        {getComponent(row, header)}

                        {row?.is_recommended && index === 0 && (
                          <img
                            src={unselected_recommended}
                            alt="unselected recommonded"
                          />
                        )}
                      </div>
                    </td>
                  ))}
                </tr>

                {data?.expanded_data?.id === row?.id &&
                  data?.expanded_data?.id === activeId &&
                  data?.expanded_data?.data?.map((item, idx) => {
                    return (
                      <>
                        <tr
                          className={`${
                            activeId && activeId === row?.id
                              ? "v2-table__data-row--active"
                              : ""
                          }`}
                        >
                          {item?.map((obj) => {
                            return <td>{getSubComponent(obj)}</td>;
                          })}
                        </tr>
                      </>
                    );
                  })}

                {data?.expanded_data?.id === row?.id &&
                  data?.expanded_data?.id === activeId && (
                    <tr className="v2-table__group v2-table__group--border">
                      <td colSpan={data?.headers?.length}>
                        <div className="v2-table__help">
                          <p
                            className="v2-table__help-close"
                            onClick={() => {
                              onHoverClick(row?.id);
                            }}
                          >
                            {data?.expanded_data?.close_text}
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
              </>
            ))}
        </table>
        {data?.default_view && (
          <div className="s-table__default-view">
            <div className="s-table__default-view-illustration">
              <img
                src={data?.default_view?.icon}
                alt=""
                className="s-table__default-view-icon"
              />
            </div>
            <div className="s-table__default-view-content">
              <h2 className="s-table__default-view-title">
                {data?.default_view?.title}
              </h2>
              <p className="s-table__default-view-text">
                {data?.default_view?.text}
              </p>
              {data?.default_view?.buttons && (
                <div className="buyer-details__action">
                  {data?.default_view?.buttons?.map((action) => {
                    return (
                      action?.type === "button" && (
                        <Button
                          buttonLabel={action?.text}
                          buttonType={action?.sub_type}
                          icon={action?.icon}
                          hoverIcon={action?.hover_icon}
                          disabled={action?.disabled}
                          iconAlignment={action?.icon_alignment}
                          onClick={() => {
                            if (action?.slug === "add_irm")
                              dispatch(updateShowIrmForm(true));
                            else if (action?.slug === "add_sb")
                              dispatch(updateShowSbForm(true));
                          }}
                        />
                      )
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlunoTableV2;
