// react imports
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// redux imports
import { useDispatch } from "react-redux";

// in-app imports
import { updateSidebarItems } from "../redux/common/slice";
import SkeletonBasic from "./SkeletonLoaders/SkeletonBasic";
import ModalV2 from "./ModalV2";
import RequestFeature from "./RequestFeature";
import Button from "./Button";
import SupportModal from "./SupportModal";

const Sidebar = ({ data }) => {
  // defined to access react based APIs
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // local states
  const [showModal, setShowModal] = useState(false);
  const [requestFeatureModalData, setRequestFeatureModaldata] = useState();
  const [botClassName, setBotClassName] = useState(null);
  const onItemSelect = (item) => {
    if (item?.is_disabled) {
      return;
    }
    const updatedItems = {
      ...data,
      navigation: data?.navigation?.map((mapItem) => {
        if (item?.type === "link") {
          if (mapItem?.type === "dropdown") {
            return {
              ...mapItem,
              is_dropdown_open: false,
              options: mapItem?.options?.map((option) => ({
                ...option,
                is_selected: false,
              })),
            };
          }
          return {
            ...mapItem,
            is_selected: mapItem?.slug === item?.slug,
            is_dropdown_open: false,
          };
        } else if (item?.type === "dropdown") {
          return {
            ...mapItem,
            is_dropdown_open:
              mapItem?.slug === item?.slug && !mapItem?.is_dropdown_open,
            options: mapItem?.options?.map((option) => ({
              ...option,
              is_selected: false,
            })),
          };
        }
        return {
          ...mapItem,
          is_dropdown_open: false,
        };
      }),
    };
    dispatch(updateSidebarItems(updatedItems));

    if (item?.type === "link") {
      navigate(item?.deeplink);
    }
    if (item?.type === "modal") {
      switch (item?.slug) {
        case "request_feature":
          setRequestFeatureModaldata(item?.modal_data);
          setShowModal(true);
          break;
        default:
          break;
      }
    }
  };

  const onOptionItemSelect = (event, item, obj) => {
    event.stopPropagation();

    if (obj?.is_disabled) {
      return;
    }

    const updatedItems = {
      ...data,
      navigation: data?.navigation?.map((mapItem) => {
        if (mapItem?.slug === item?.slug) {
          return {
            ...mapItem,
            options: mapItem?.options?.map((option) => ({
              ...option,
              is_selected: option?.slug === obj?.slug,
            })),
          };
        }
        return {
          ...mapItem,
          is_selected: false,
        };
      }),
    };
    dispatch(updateSidebarItems(updatedItems));
    navigate(obj?.deeplink);
  };

  const getSidebarItems = (position) => {
    return data?.navigation?.filter((item) => item?.position === position);
  };

  useEffect(() => {
    const targetNode = document?.getElementsByClassName(
      "zsiq-float zsiq-flexM zsiq-toggle"
    )?.[0];

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          setBotClassName(
            document?.getElementsByClassName("zsiq-float zsiq-flexM")?.[0]
              ?.className
          );
        }
      }
    });

    if (targetNode) {
      observer.observe(targetNode, { attributes: true });
    } else {
      const intervalId = setInterval(() => {
        const targetNode = document?.getElementsByClassName(
          "zsiq-float zsiq-flexM"
        )?.[0];
        if (targetNode) {
          setBotClassName(
            document?.getElementsByClassName("zsiq-float zsiq-flexM")?.[0]
              ?.className
          );
          observer.observe(targetNode, { attributes: true });
          clearInterval(intervalId);
        }
      }, [100]);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      {showModal && (
        <div className="a-buyers__modal-bg">
          <ModalV2
            slug={"standard"}
            children={
              <RequestFeature
                setShowModal={setShowModal}
                data={requestFeatureModalData}
              />
            }
          />
        </div>
      )}
      <div className="s-sidebar">
        <div className="s-sidebar__wrapper">
          <div className="s-sidebar__items">
            <div className="s-sidebar__top">
              <SkeletonBasic
                showLoader={JSON.stringify(data) === "{}" ? true : false}
              >
                <div className="s-sidebar__logo">
                  <img
                    src={data?.logo}
                    alt="bluno"
                    onClick={() =>
                      data?.current_stage === "dashboard" ? navigate("/") : null
                    }
                  />
                </div>
                {getSidebarItems("top")?.map((item, index) => (
                  <SidebarItem
                    item={item}
                    key={`sidebaritemTop-${index}`}
                    onSelect={onItemSelect}
                    onOptionSelect={onOptionItemSelect}
                    botClassName={botClassName}
                  />
                ))}
              </SkeletonBasic>
            </div>
            <div className="s-sidebar__bottom">
              <SkeletonBasic
                showLoader={JSON.stringify(data) === "{}" ? true : false}
              >
                {getSidebarItems("bottom")?.map((item, index) => (
                  <SidebarItem
                    item={item}
                    key={`sidebaritemBottom-${index}`}
                    onSelect={onItemSelect}
                    onOptionSelect={onOptionItemSelect}
                    botClassName={botClassName}
                  />
                ))}
              </SkeletonBasic>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SidebarItem = ({ item, onSelect, onOptionSelect, botClassName }) => {
  return (
    <div className="s-sidebar__dropdown">
      <div
        className={`s-sidebar__item ${
          item?.is_selected ? "s-sidebar__item--selected" : ""
        } ${item?.is_disabled ? "s-sidebar__item--disabled" : ""}`}
        onClick={() => onSelect(item)}
      >
        <div className="s-sidebar__icon">
          {item?.is_selected ? (
            <img src={item?.icon_selected} alt={item?.dis} />
          ) : (
            <img src={item?.icon_unselected} alt={item?.dis} />
          )}
        </div>
        <div
          className={`s-sidebar__label ${
            item?.is_selected ? "s-sidebar__label--selected" : ""
          }`}
        >
          {item?.display_name}
        </div>
        {item?.type === "dropdown" && (
          <img
            className={`s-sidebar__arrow ${
              item?.is_dropdown_open && "s-sidebar__arrow--open"
            }`}
            src={item?.dropdown_icon}
            alt="dropdown"
          />
        )}
      </div>

      <div
        className={`s-sidebar__options ${
          item?.type === "dropdown" && item?.is_dropdown_open
            ? "s-sidebar__options--border"
            : ""
        }`}
      >
        {item?.is_dropdown_open &&
          item?.options?.map((obj) => {
            return obj?.slug === "chatbot" ? (
              <div
                className={`s-sidebar__option-item s-sidebar__option-item__button ${
                  botClassName === "zsiq-float zsiq-flexM zsiq-toggle"
                    ? "s-sidebar__option-item__button--highlight"
                    : ""
                }`}
                onClick={() => {
                  if (
                    !document?.getElementsByClassName(
                      "zsiq-float zsiq-flexM zsiq-toggle"
                    )?.[0]
                  ) {
                    document
                      ?.getElementsByClassName("zsiq-float zsiq-flexM")?.[0]
                      ?.click();
                  }
                }}
              >
                {obj?.display_name}
              </div>
            ) : (
              <div
                className={`s-sidebar__option-item  
                ${obj?.is_selected ? "s-sidebar__option-item--selected" : ""}`}
              >
                {obj?.icon_selected && (
                  <img
                    src={obj?.icon_selected}
                    className="s-sidebar__option-item__icon"
                  />
                )}
                {obj?.display_name}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Sidebar;
