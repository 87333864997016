import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

const ReviewRating = ({
  title = "",
  ratingValue = null,
  setRatingValue,
  size = "large",
  precision = 0.5,
}) => {
  return (
    <div className="s-rating">
      <p className="s-rating__title">{title}</p>
      <Rating
        name="simple-controlled"
        value={ratingValue}
        onChange={(event, newValue) => {
          setRatingValue(newValue);
        }}
        size={size}
        precision={precision}
      />
    </div>
  );
};

export default ReviewRating;
