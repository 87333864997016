// react imports
import React from "react";

// third-party imports

// in-app imports
import Button from "../../Button";

const LoginDevice = ({ data, onLogoutDevice }) => {
  return (
    <>
      <div
        className={`login-device ${
          data?.is_current_device ? "login-device--current" : ""
        }`}
      >
        <div className="login-device__left">
          <div>
            <img src={data?.icon} alt="" className="login-device__icon" />
          </div>
          <div className="login-device__info">
            <div className="login-device__content">
              <p className="login-device__name">{data?.device_name}</p>
              <p className="login-device__date">{data?.date_time}</p>
            </div>
            {data?.address && (
              <div className="login-device__address">{data?.address}</div>
            )}
          </div>
        </div>
        <div className="login-device__right">
          <p
            className={`login-device__report ${
              data?.report?.disabled ? "login-device__report--disabled" : ""
            }`}
          >
            {data?.report?.value}
          </p>
          {data?.buttons && (
            <div className="login-device__logout">
              {data?.buttons?.map((action, index) => {
                return (
                  action?.type === "button" && (
                    <Button
                      buttonLabel={action?.text}
                      buttonType={action?.sub_type}
                      buttonSize="sm2"
                      icon={action?.icon}
                      disabled={action?.disabled}
                      iconAlignment={action?.icon_alignment}
                      onClick={() =>
                        onLogoutDevice(
                          action,
                          data?.device_id,
                          data?.is_current_device
                        )
                      }
                    />
                  )
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginDevice;
