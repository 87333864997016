// react imports
import React, { useState } from "react";

// in-app imports
import IrmHeader from "../../components/export-bills/IrmHeader";
import IrmDetails from "../../components/export-bills/IrmDetails";
import Breadcrums from "../../components/Breadcrums";
import SkeletonBasic from "../../components/SkeletonLoaders/SkeletonBasic";
import IrmDetailsV2 from "../../components/export-bills/IrmDetailsV2";

const IrmSummary = (props) => {
  const { data } = props;
  const [isSBDetailView, setIsSBDetailView] = useState(true);

  return (
    <div className="m-sb-summary">
      <SkeletonBasic showLoader={data ? false : true}>
        <IrmHeader
          isDropdown={data?.header?.dropdown}
          isSBDetailView={isSBDetailView}
          onDropdownClick={() => setIsSBDetailView(!isSBDetailView)}
          data={data?.header}
        />
        <IrmDetailsV2
          isSBDetailView={isSBDetailView}
          middleData={data?.middle}
          sbData={data?.sb_details}
          defaultView={data?.default_view}
        />
      </SkeletonBasic>
    </div>
  );
};

export default IrmSummary;
