export const downloadDocumentUtility = async (
  documentLink,
  fileName,
  dispatch
) => {
  try {
    const response = await fetch(documentLink);

    if (!response.ok) {
      throw new Error(`Failed to fetch document. Status: ${response.status}`);
    }

    const blob = await response.blob();
    const fileURL = window.URL.createObjectURL(blob);

    let link = document.createElement("a");
    link.href = fileURL;
    link.download = fileName;
    link.click();

    // revoke the object URL to free up memory
    window.URL.revokeObjectURL(fileURL);
  } catch (error) {
    if (error.message.includes("Failed to fetch")) {
      console.log("Error while fetching file");
    }
  }
};
