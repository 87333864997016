// react imports
import React from "react";

// third-party imports

// in-app imports
import BlunoTable from "../../components/BlunoTable";

const SummaryMapIrm = ({ data }) => {
  return (
    <>
      <div className="recon-summary__mapirm">
        <div className="recon-summary__mapirm-header">
          <h2 className="recon-summary__section-title">{data?.title}</h2>
          <p className="recon-summary__section-count">{data?.irm_count}</p>
        </div>
        <BlunoTable data={data?.mapped_irm} dataHeight="100%" />
      </div>
    </>
  );
};

export default SummaryMapIrm;
